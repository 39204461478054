import { Add, Close, Remove } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import {
  Box,
  Divider,
  IconButton,
  InputLabel,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { Field, FieldArray, Formik, getIn } from "formik";
import React, { FC } from "react";
import toast from "react-hot-toast";

import {
  ownershipControlFormValuesInitialValue,
  OwnershipControlFormValues,
  ownershipControlFormValidationSchema,
  relatedEntityInitialValues,
  // investmentsInitialValues,
} from ".";

import { useTranslation } from "react-i18next";

import {
  BasicTextField,
  EntityField,
  EnumerableField,
  NATURAL_PERSON,
  OWNERSHIP_INDIRECT,
  OwnershipTypeField,
  YES,
  YesNoField,
} from "../../../../../../forms";
import {
  ApiEnumeration,
  DETAILS_OF_SCHEME,
  LAYER_OF_OWNERSHIP,
  RELATIONSHIP,
  STATUS_OF_APPLICATION,
} from "../../../../../../models/enumerations";
import { usePermissions } from "../../../../../../models/auth";
import {
  PENDING_STAGE_STATUS,
  ApiEntity,
  DRAFT_STAGE_STATUS,
  mapOwnershipControlFormValuesToApiStageOwnershipControl,
  useUpdateEntityStageMutation,
  ApiStageOwnershipControl,
  PENDING_APPROVAL_STAGE_STATUS,
  STAGE_2_3_TYPE,
} from "../../../../../../models/entities";
import { ApiUserResponse } from "../../../../../../models/users";
interface OwnershipControlFormProps {
  entity: ApiEntity;
  enumerables: ApiEnumeration[];
  entities: ApiEntity[];
  onClose: () => void;
  profile: ApiUserResponse | undefined;
}
// 14.1
export const OwnershipControlForm: FC<OwnershipControlFormProps> = ({
  entity,
  enumerables,
  entities,
  onClose,
  profile,
}) => {
  const [updateEntityStage] = useUpdateEntityStageMutation();
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  return (
    <Formik<OwnershipControlFormValues>
      enableReinitialize
      initialValues={{
        ...ownershipControlFormValuesInitialValue(
          entity,
          enumerables,
          entities
        ),
      }}
      validationSchema={ownershipControlFormValidationSchema(
        hasPermission("ADMIN")
      )}
      onSubmit={async (values): Promise<void> => {
        try {
          const apiOwnershipControl: ApiStageOwnershipControl =
            mapOwnershipControlFormValuesToApiStageOwnershipControl(
              entity,
              values
            );
          // const apiEntity: ApiStageBasicDetail =
          await updateEntityStage(apiOwnershipControl).unwrap();

          toast.success(t("common.updatedSuccessfully"));
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        setFieldValue,
        errors,
        touched,
      }): JSX.Element => (
        <>
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "24px 38px 24px 32px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={"textPrimary"} variant="h5">
                {t("common.ownershipAndControl")}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: "32px" }}>
            <form noValidate onSubmit={handleSubmit}>
              <Typography variant="subtitle2">
                {t("common.doesTheOwnershipStructureInclude")}
              </Typography>

              <Field
                name={`hasLegalArrangements`}
                component={YesNoField}
                label="common.offshoreVehiclesTrusts"
                // size="small"
                margin="normal"
              />
              <Field
                name={`hasNonProfitEntities`}
                component={YesNoField}
                label="common.foundationsCharities"
                // size="small"
                margin="normal"
              />
              <Field
                name={`hasShellCompanies`}
                component={YesNoField}
                label="common.aShellCompany"
                // size="small"
                margin="normal"
              />
              <Field
                name={`hasNonCooperativeJurisdictions`}
                component={YesNoField}
                label="common.entitiesIncorporated"
                // size="small"
                margin="normal"
              />
              <Link
                href={
                  "https://cfr.gov.mt/en/inlandrevenue/tcu/Pages/EU-List-of-non-cooperative-jurisdictions.aspx"
                }
                variant="body2"
                target="_blank"
                rel="noreferrer"
                sx={{ marginBottom: "20px" }}
              >
                {t("common.euTaxJurisdictions")}
              </Link>
              <Divider />
              <Box
                sx={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "#E6E8F0",
                  borderRadius: "4px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  paddingBottom: "16px",
                  marginTop: "20px",
                }}
              >
                <InputLabel
                  error={Boolean(
                    getIn(touched, "relatedEntities") &&
                      !!getIn(errors, "relatedEntities")
                  )}
                  sx={{ mt: "10px", mb: "10px" }}
                >
                  {t("common.relatedParties")}
                </InputLabel>
                {/* <InputLabel
          error={
            getIn(touched, "contactPersons") && getIn(errors, "contactPersons")
          }
        >
          {getIn(touched, "contactPersons") && t(getIn(errors, "contactPersons"))}
        </InputLabel> */}
                <FieldArray name="relatedEntities">
                  {({ push, remove }) => (
                    <>
                      {getIn(values, "relatedEntities").length > 0 &&
                        getIn(values, "relatedEntities").map(
                          (a: any, index: number) => {
                            return (
                              <div key={index}>
                                <Box
                                  sx={{
                                    borderStyle: "solid",
                                    borderWidth: "1px",
                                    borderColor: "#E6E8F0",
                                    borderRadius: "4px",
                                    paddingLeft: "16px",
                                    paddingRight: "16px",
                                    paddingBottom: "16px",
                                    marginBottom: "16px",
                                  }}
                                >
                                  <Field
                                    name={`relatedEntities.${index}.relatedEntity`}
                                    component={EntityField}
                                    label="common.entity"
                                    margin="normal"
                                  />
                                  <Field
                                    name={`relatedEntities.${index}.relationship`}
                                    component={EnumerableField}
                                    type={RELATIONSHIP}
                                    margin="normal"
                                  />
                                  <Field
                                    name={`relatedEntities.${index}.layerOfOwnership`}
                                    component={EnumerableField}
                                    type={LAYER_OF_OWNERSHIP}
                                    margin="normal"
                                  />
                                  <Field
                                    name={`relatedEntities.${index}.extentOfOwnership`}
                                    component={BasicTextField}
                                    label="common.extentOfOwnership"
                                    // size="small"
                                    margin="normal"
                                    type="number"
                                  />
                                  <Field
                                    name={`relatedEntities.${index}.natureOfOwnership`}
                                    component={OwnershipTypeField}
                                    label="common.natureOfOwnership"
                                    // size="small"
                                    margin="normal"
                                  />
                                  {getIn(
                                    values,
                                    `relatedEntities.${index}.natureOfOwnership`
                                  ) === OWNERSHIP_INDIRECT && (
                                    <Field
                                      name={`relatedEntities.${index}.indirectOwnershipDesc`}
                                      component={BasicTextField}
                                      label="common.indirectOwnershipDesc"
                                      // size="small"
                                      margin="normal"
                                    />
                                  )}
                                  {getIn(
                                    values,
                                    `relatedEntities.${index}.relationship`
                                  )?.value === "CONTROL_BY_OTHER_MEANS" && (
                                    <>
                                      <Typography variant="subtitle2">
                                        {t(
                                          "common.inputtingTheAdditionalDetailsOnTheNatureOfControlViaOtherMeans"
                                        )}
                                      </Typography>
                                      <Field
                                        name={`relatedEntities.${index}.influenceDescription`}
                                        component={BasicTextField}
                                        label="common.influenceDescription"
                                        // size="small"
                                        margin="normal"
                                      />
                                    </>
                                  )}
                                  {entity.entryType === NATURAL_PERSON &&
                                    [
                                      "ULTIMATE_BENEFICIAL_OWNER",
                                      "BENEFICIAL_OWNERS",
                                    ].includes(
                                      getIn(
                                        values,
                                        `relatedEntities.${index}.relationship`
                                      )?.value || ""
                                    ) && (
                                      <>
                                        <Typography variant="subtitle2">
                                          {t(
                                            "common.inputtingAdditionalDetailsOnTheResidencyCitizenship"
                                          )}
                                        </Typography>
                                        <Field
                                          name={`relatedEntities.${index}.hasSchemeBenefits`}
                                          component={YesNoField}
                                          label="common.hasSchemeBenefits"
                                          // size="small"
                                          margin="normal"
                                          info="common.hasSchemeBenefitsInfoNote"
                                        />
                                        {getIn(
                                          values,
                                          `relatedEntities.${index}.hasSchemeBenefits`
                                        ) === YES && (
                                          <>
                                            <Field
                                              name={`relatedEntities.${index}.schemeDetails`}
                                              component={EnumerableField}
                                              type={DETAILS_OF_SCHEME}
                                              margin="normal"
                                            />
                                            <Field
                                              name={`relatedEntities.${index}.schemeStatus`}
                                              component={EnumerableField}
                                              type={STATUS_OF_APPLICATION}
                                              margin="normal"
                                            />
                                          </>
                                        )}
                                      </>
                                    )}
                                  {/* <Field
                                    name={`relatedEntities.${index}.firstName`}
                                    component={BasicTextField}
                                    label="common.firstName"
                                    // size="small"
                                    margin="normal"
                                  />
                                  <Field
                                    name={`relatedEntities.${index}.lastName`}
                                    component={BasicTextField}
                                    label="common.lastName"
                                    // size="small"
                                    margin="normal"
                                  />
                                  <Field
                                    name={`relatedEntities.${index}.designation`}
                                    component={BasicTextField}
                                    label="common.designation"
                                    // size="small"
                                    margin="normal"
                                  /> */}

                                  {index > 0 && (
                                    <Tooltip
                                      title={t(
                                        "common.removeThisRelatedEntity"
                                      )}
                                    >
                                      <IconButton
                                        aria-label="remove"
                                        size="medium"
                                        onClick={() => remove(index)}
                                      >
                                        <Remove
                                          color="inherit"
                                          fontSize="inherit"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                              </div>
                            );
                          }
                        )}

                      <Tooltip title={t("common.addRelatedEntity")}>
                        <IconButton
                          aria-label="add"
                          size="medium"
                          onClick={() => push(relatedEntityInitialValues())}
                        >
                          <Add color="inherit" fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </FieldArray>
              </Box>
              <Box
                sx={{
                  mt: "24px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    setFieldValue("stageStatus", DRAFT_STAGE_STATUS);
                  }}
                >
                  {t("common.saveAsDraft")}
                </LoadingButton>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    // Get Stage Data
                    const stageData: ApiStageOwnershipControl =
                      entity.stages.find(
                        (s) => s.stageType === STAGE_2_3_TYPE
                      ) as ApiStageOwnershipControl;

                    if (
                      profile?.id === entity.reviewerId &&
                      stageData.stageStatus === PENDING_APPROVAL_STAGE_STATUS
                    ) {
                      setFieldValue(
                        "stageStatus",
                        PENDING_APPROVAL_STAGE_STATUS
                      );
                    } else {
                      setFieldValue("stageStatus", PENDING_STAGE_STATUS);
                    }
                  }}
                >
                  {t("common.submit")}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
