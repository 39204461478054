import * as Yup from 'yup';

export const terminationFormValidationSchema = (isAdmin: boolean) =>
    Yup.object({
        // name: Yup.string().required('validations.nameIsRequired'),
        // address: addressValidationSchema(),
        // vatRegistrationNumber: Yup.string().required('validations.vatRegistrationNumberIsRequired'),
        // companyRegistrationNumber: Yup.string().required('validations.companyRegistrationNumberIsRequired'),
        // phones: [],
        // emails: [],
        // accountManager: null,
        // services: [],
        // industries: [],
        // legalEntityTypes: [],
        // tenantIdForHeader: isAdmin ? tenantValidationSchema() : Yup.string().notRequired(),
    });