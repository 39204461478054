
import { CreateEnumerationFormValues, UpdateEnumerationFormValues } from "../../screens/Enumerations/forms";
import { ApiEnumerationCreate, ApiEnumerationUpdate } from ".";

export const mapCreateEnumerationFormValuesToApiEnumerationCreate = (
    createDto: CreateEnumerationFormValues
): ApiEnumerationCreate => ({
    value: createDto.value,
    valueDescription: createDto.valueDescription,
    type: createDto.type,
});

export const mapUpdateEnumerationFormValuesToApiEnumerationUpdate = (
    updateDto: UpdateEnumerationFormValues
): ApiEnumerationUpdate => ({
    id: updateDto.id,
    type: updateDto.type,
    value: updateDto.value,
    valueDescription: updateDto.valueDescription,
});

