import { NavigateNext } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Chip,
  Container,
  Drawer,
  Grid,
  Link,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import React, { FC, ReactElement, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import {
  Link as RouterLink,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { PermissionGuard, usePermissions } from "../../../models/auth";
import {
  ApiStageBasicDetail,
  STAGE_1_1_TYPE,
  useEntityQuery,
  useEntityWithStagesQuery,
} from "../../../models/entities";
import { LazyLoadableScreen } from "../../../navigation/LazyLoadableScreen";
import { InvoicesRoutes } from "./Invoices";
import { ReceiptsRoutes } from "./Receipts";
import {
  ApiEntityStatus,
  LEGAL_ENTITY,
  NATURAL_PERSON,
  entityStatusesColourMap,
} from "../../../forms";
import { QuotationsRoutes } from "./Quotations";
import { format } from "date-fns";
import { StatusLabel } from "../../../components/StatusLabel";
import {
  ApiUserResponse,
  useReviewersEntitiesListQuery,
  useUsersListQuery,
} from "../../../models/users";
import { Nullable } from "../../../utils/types";
import {
  BasicDetailsForm,
  SendForApprovalForm,
  UpdateEntityForm,
} from "../forms";
import { SIDEBAR_WIDTH } from "../../../components/Layouts/Dashboard/utils/config";
import { useEnumerationsQuery } from "../../../models/enumerations";
import { Stage1_1View } from "./EntityDetails/StageViews";
import { useMeQuery } from "../../../models/user";

const EntityDetailsScreen = LazyLoadableScreen(() => import("./EntityDetails"));
const StageLogScreen = LazyLoadableScreen(() => import("./StageLogDetails"));

export const EntityTabs: FC<{
  urlDepth: number;
  status: Nullable<ApiEntityStatus>;
}> = ({ urlDepth, status = null }) => {
  const { t } = useTranslation();
  const location = useLocation();
  // const { hasPermission } = usePermissions();
  // const { tenantNameOrId } = useParams<{ tenantNameOrId: string }>();
  const { id } = useParams<{ id: string }>();
  const [updateEntityOpened, setUpdateEntityOpened] = useState<boolean>(false);
  // const [createdBy, setCreatedBy] = useState<Nullable<ApiUserResponse>>(null);
  // const [updatedBy, setUpdatedBy] = useState<Nullable<ApiUserResponse>>(null);

  const { data: enumerables } = useEnumerationsQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: reviewers } = useReviewersEntitiesListQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  // const { data: entity } = useEntityQuery(
  //   { id: id as string },
  //   { refetchOnMountOrArgChange: true }
  // );

  const { data: entity } = useEntityWithStagesQuery(
    { id: id as string },
    { refetchOnMountOrArgChange: true }
  );

  const breadcrumbs = [
    <Link key="1" color={blueGrey[600]} component={RouterLink} to={"./.."}>
      <Typography variant="overline">{t("common.entityManagement")}</Typography>
    </Link>,
    <Link
      key="1"
      color={blueGrey[500]}
      component={RouterLink}
      to={`./../${id}`}
    >
      <Typography variant="overline"> {t("common.entities")}</Typography>
    </Link>,
  ];

  interface TabRoutes {
    show: boolean;
    route1: string;
    route2: string;
    label: string;
    component: ReactElement<any, any>;
  }

  interface DetailRow {
    name: string;
    value: any;
  }

  const routes: TabRoutes[] = useMemo(
    () => [
      {
        show: true,
        route1: "entity-details",
        route2: "entity-details",
        label: "common.entityDetails",
        component: <EntityDetailsScreen status={status} />,
      },
      {
        show: true,
        route1: "quotations",
        route2: "quotations/*",
        label: "common.quotations",
        component: <QuotationsRoutes />,
      },
      {
        show: true,
        route1: "invoicing",
        route2: "invoicing/*",
        label: "common.invoicing",
        component: <InvoicesRoutes />,
      },

      {
        show: true,
        route1: "receipts",
        route2: "receipts/*",
        label: "common.receipts",
        component: <ReceiptsRoutes />,
      },

      {
        show: true,
        route1: "stage-log",
        route2: "stage-log",
        label: "common.stageLog",
        component: <StageLogScreen />,
      },
    ],
    [entity]
  );

  const [sendForApproval, setSendForApproval] = useState<boolean>(false);
  const [stage1_1Opened, setStage1_1Opened] = useState<boolean>(false);
  const { data: users } = useUsersListQuery({
    refetchOnMountOrArgChange: true,
  });
  const { data: profile } = useMeQuery({}, { refetchOnMountOrArgChange: true });
  return (
    <>
      <Helmet>
        <title>{t("common.entity")}</title>
      </Helmet>
      <Box>
        <Container maxWidth={false}>
          <PermissionGuard permission="GET_ENTITY">
            {/* <pre>{JSON.stringify(entity, null, 2)}</pre> */}
            {entity && (
              <Grid id="test" container spacing={3} sx={{ marginTop: "0" }}>
                <Grid item xs={12}>
                  <Stage1_1View
                    profile={profile}
                    entity={entity}
                    stageData={
                      entity.stages.find(
                        (s) => s.stageType === STAGE_1_1_TYPE
                      ) as ApiStageBasicDetail
                    }
                    users={users!}
                    reviewers={reviewers!}
                    onEdit={setStage1_1Opened}
                    onSendForApproval={setSendForApproval}
                    status={status}
                  />
                </Grid>
              </Grid>
            )}

            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={
                  location.pathname.split("/").length > urlDepth
                    ? `${location.pathname.split("/")[urlDepth]}`
                    : routes[0].route1 // fallback details
                }
                aria-label="entity"
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                {routes.map((elem, index) => {
                  return (
                    <Tab
                      key={elem.route1}
                      style={{ minWidth: 50 }}
                      label={t(elem.label)}
                      value={`${elem.route1}`}
                      component={RouterLink}
                      to={elem.route1}
                    />
                  );
                })}
              </Tabs>
            </Box>
            <Routes>
              {routes.map((elem, index) => {
                return (
                  <Route
                    key={elem.label}
                    path={elem.route2}
                    element={<>{elem.component}</>}
                  />
                );
              })}

              <Route
                path="details"
                element={
                  <>
                    <EntityDetailsScreen id={id as string} />
                  </>
                }
              />
              <Route
                path="*"
                element={<Navigate to="entity-details" replace />}
              />
            </Routes>
          </PermissionGuard>
        </Container>
      </Box>

      <PermissionGuard permission="CREATE_ENTITY">
        <>
          <Drawer
            anchor="right"
            open={updateEntityOpened}
            onClose={() => setUpdateEntityOpened(false)}
            PaperProps={{
              sx: [
                {
                  backgroundColor: "#F3F7FE",
                },
                (theme) => ({
                  [theme.breakpoints.down("md")]: {
                    width: `100%`,
                  },
                  [theme.breakpoints.up("md")]: {
                    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                  },
                }),
              ],
            }}
          >
            <UpdateEntityForm
              entity={entity!}
              enumerables={enumerables!}
              reviewers={reviewers!}
              onClose={() => setUpdateEntityOpened(false)}
            />
          </Drawer>
        </>
      </PermissionGuard>

      <Drawer
        anchor="right"
        open={sendForApproval === true}
        onClose={() => setSendForApproval(false)}
        PaperProps={{
          sx: [
            {
              backgroundColor: "#F3F7FE",
            },
            (theme) => ({
              [theme.breakpoints.down("md")]: {
                width: `100%`,
              },
              [theme.breakpoints.up("md")]: {
                width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
              },
            }),
          ],
        }}
      >
        <SendForApprovalForm
          entity={entity!}
          enumerables={enumerables!}
          reviewers={reviewers!}
          onClose={() => setSendForApproval(false)}
        />
      </Drawer>

      <Drawer
        anchor="right"
        open={stage1_1Opened === true}
        onClose={() => setStage1_1Opened(false)}
        PaperProps={{
          sx: [
            {
              backgroundColor: "#F3F7FE",
            },
            (theme) => ({
              [theme.breakpoints.down("md")]: {
                width: `100%`,
              },
              [theme.breakpoints.up("md")]: {
                width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
              },
            }),
          ],
        }}
      >
        <BasicDetailsForm
          profile={profile}
          entity={entity!}
          enumerables={enumerables!}
          onClose={() => setStage1_1Opened(false)}
        />
      </Drawer>
    </>
  );
};
