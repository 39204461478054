import { Route, Routes } from "react-router";
import { LazyLoadableScreen } from "../../../../navigation/LazyLoadableScreen";
import { PENDING_APPROVAL_QUOTATION_STATUS } from "../../../../forms";

const QuotationListScreen = LazyLoadableScreen(
  () =>
    import("../../../../business/Entities/EntityTabs/Quotations/QuotationList")
);
const QuotationDetailsScreen = LazyLoadableScreen(
  () =>
    import(
      "../../../../business/Entities/EntityTabs/Quotations/QuotationDetails"
    )
);
export const QuotationApprovalsRoutes = () => (
  <Routes>
    <Route
      path="/"
      element={
        <QuotationListScreen status={PENDING_APPROVAL_QUOTATION_STATUS} />
      }
    />
    <Route
      path="/:quotationId"
      element={
        <QuotationDetailsScreen status={PENDING_APPROVAL_QUOTATION_STATUS} />
      }
    />
  </Routes>
);
