import { LoadingButton } from "@mui/lab";
import { Box, Button, FormHelperText, Grid } from "@mui/material";
import { Field, Formik } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  BasicTextField,
  EntityStatusField,
  EntryTypeField,
  EnumerableField,
  ReviewerEntityField,
  UserField,
} from "../../../../forms";
import { TAG, TITLE } from "../../../../models/enumerations";
import { EntityFilter } from ".";

interface FormProps {
  onFilterApply: (values: EntityFilter) => void;
  filter: EntityFilter;
  onClose: () => void;
}
export const EntitiesFilterForm: FC<FormProps> = ({
  onFilterApply,
  filter,
  onClose,
}) => {
  const { t } = useTranslation();
  const onSubmit = (values: EntityFilter) => {
    onFilterApply(values);
    return Promise.resolve();
  };
  return (
    <Formik<EntityFilter>
      initialStatus={{ error: "" }}
      initialValues={filter}
      validationSchema={null}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        isSubmitting,
        status,
        resetForm,
        values,
        setFieldValue,
      }): JSX.Element => (
        <>
          <form noValidate>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <Field
                  name={`id`}
                  component={BasicTextField}
                  label="common.id"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Field
                  name={`tags`}
                  component={EnumerableField}
                  type={TAG}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Field
                  name={`titles`}
                  component={EnumerableField}
                  type={TITLE}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Field
                  name={`firstName`}
                  component={BasicTextField}
                  label="common.firstName"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Field
                  name={`lastName`}
                  component={BasicTextField}
                  label="common.lastName"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Field
                  name={`legalEntityName`}
                  component={BasicTextField}
                  label="common.legalEntityName"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Field
                  name={`entryType`}
                  component={EntryTypeField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Field
                  name={`additionalComments`}
                  component={BasicTextField}
                  label="common.additionalComments"
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Field
                  name={`refNo`}
                  component={BasicTextField}
                  label="common.refNo"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Field
                  name={`legacyId`}
                  component={BasicTextField}
                  label="common.legacyId"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Field
                  name={`reviewer`}
                  component={ReviewerEntityField}
                  label="common.approver"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Field
                  name={"createdBy"}
                  component={UserField}
                  label={"common.createdBy"}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Field
                  name={"updatedBy"}
                  component={UserField}
                  label={"common.updatedBy"}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Field
                  name={`status`}
                  component={EntityStatusField}
                  label="common.status"
                  size="small"
                />
              </Grid>
            </Grid>
            {!isSubmitting && status.error && (
              <Box sx={{ mt: 1 }}>
                <FormHelperText error>{status.error}</FormHelperText>
              </Box>
            )}
          </form>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "right",
              padding: "24px",
            }}
          >
            <Button
              color="error"
              onClick={() => {
                resetForm();
                handleSubmit();
                onClose();
              }}
              variant="contained"
              sx={{ marginRight: "20px" }}
            >
              {t("common.clear")} X
            </Button>
            <LoadingButton
              color="primary"
              loading={isSubmitting}
              onClick={() => {
                handleSubmit();
                onClose();
              }}
              variant="contained"
            >
              {t("common.apply")}
            </LoadingButton>
          </Box>
        </>
      )}
    </Formik>
  );
};
