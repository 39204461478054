
import { ApiUserResponse, PagedUsersRequest, ApiRegistrationRequest, PageApiUserResponse, ApiConfirmRegistrationRequest, ApiUpdateUserRequest } from '.';
import { config } from '../../services/config';
import { removeEmpty } from '../../utils/removeEmpty';
import { apiWithTag } from '../emptySplitApi';


export const usersApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        users: builder.query<PageApiUserResponse, PagedUsersRequest>({
            query: ({ ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    method: 'GET',
                    params: parameters,
                    url: 'users',
                }
            },
            providesTags: ['User']
        }),
        usersList: builder.query<ApiUserResponse[], {}>({
            query: () => {
                return {
                    method: 'GET',
                    url: 'users/list',
                }
            },
            providesTags: ['User']
        }),
        user: builder.query<ApiUserResponse, { id: string }>({
            query: ({ id }) => ({
                method: 'GET',
                url: `users/${id}`,

            }),
            providesTags: ['User']
        }),
        registerUser: builder.mutation<void, ApiRegistrationRequest>({
            query: ({ tenantIdForHeader, ...user }) => ({
                headers: (tenantIdForHeader.length > 0) ? {
                    'X-TENANT-ID': tenantIdForHeader
                } : {},
                url: 'registration',
                method: 'POST',
                body: user
            }),
            invalidatesTags: ['User']
        }),
        updateUser: builder.mutation<ApiUserResponse, ApiUpdateUserRequest>({
            query: ({ id, ...rest }) => ({
                body: { ...rest },
                method: 'PUT',
                url: `users/${id}`,
            }),
            invalidatesTags: ['User']
        }),

        reviewersInvoices: builder.query<PageApiUserResponse, PagedUsersRequest>({
            query: ({ ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    method: 'GET',
                    params: parameters,
                    url: 'reviewers/quotations',
                }
            },
            providesTags: ['User']
        }),
        reviewersInvoicesList: builder.query<ApiUserResponse[], {}>({
            query: () => {
                return {
                    method: 'GET',
                    url: 'reviewers/quotations/list',
                }
            },
            providesTags: ['User']
        }),
        reviewersQuotations: builder.query<PageApiUserResponse, PagedUsersRequest>({
            query: ({ ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    method: 'GET',
                    params: parameters,
                    url: 'reviewers/quotations',
                }
            },
            providesTags: ['User']
        }),
        reviewersQuotationsList: builder.query<ApiUserResponse[], {}>({
            query: () => {
                return {
                    method: 'GET',
                    url: 'reviewers/quotations/list',
                }
            },
            providesTags: ['User']
        }),

        reviewersEntities: builder.query<PageApiUserResponse, PagedUsersRequest>({
            query: ({ ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    method: 'GET',
                    params: parameters,
                    url: 'reviewers/entities',
                }
            },
            providesTags: ['User']
        }),
        reviewersEntitiesList: builder.query<ApiUserResponse[], {}>({
            query: () => {
                return {
                    method: 'GET',
                    url: 'reviewers/entities/list',
                }
            },
            providesTags: ['User']
        }),
        accountManagers: builder.query<PageApiUserResponse, PagedUsersRequest>({
            query: ({ ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    method: 'GET',
                    params: parameters,
                    url: 'reviewers/account-managers',
                }
            },
            providesTags: ['User']
        }),
        accountManagersList: builder.query<ApiUserResponse[], {}>({
            query: () => {
                return {
                    method: 'GET',
                    url: 'reviewers/account-managers/list',
                }
            },
            providesTags: ['User']
        }),
        registerUserConfirm: builder.mutation<void, ApiConfirmRegistrationRequest>({
            query: ({ ...confirmRegistration }) => ({
                body: confirmRegistration,
                headers: {
                    Authorization: `Basic ${config.apiKey}`,
                },
                method: 'POST',
                url: 'registration/confirm',
            }),
            invalidatesTags: ['Me']
        }),

    })
})

export const {
    useUsersQuery,
    useLazyUsersQuery,
    useUsersListQuery,
    useUserQuery,
    useRegisterUserMutation,
    useUpdateUserMutation,
    useRegisterUserConfirmMutation,
    useReviewersInvoicesQuery,
    useReviewersInvoicesListQuery,
    useReviewersQuotationsQuery,
    useReviewersQuotationsListQuery,
    useReviewersEntitiesQuery,
    useReviewersEntitiesListQuery,
    useAccountManagersQuery,
    useAccountManagersListQuery,
} = usersApi;