import { Color, colors } from "@mui/material";
import { ApiTimesheetInputType } from ".";

export const TIMESHEET_INPUT_AUTOMATED = 'AUTOMATED'
export const TIMESHEET_INPUT_MANUAL = 'MANUAL'
export const TIMESHEET_INPUT_MANUAL_HOURS = 'MANUAL_HOURS'

export const timesheetInputTypes = [TIMESHEET_INPUT_AUTOMATED, TIMESHEET_INPUT_MANUAL, TIMESHEET_INPUT_MANUAL_HOURS] as const;
export const timesheetInputTypesColours = [colors.green, colors.green, colors.green];
export const timesheetInputTypesColourMap = new Map<ApiTimesheetInputType, Color>(timesheetInputTypes.map((v, i) => [v, timesheetInputTypesColours[i]]));;
