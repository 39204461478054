import { Info } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Chip,
  FormControl,
  TextField,
  Tooltip,
} from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CountryFlag } from "../../../../components/CountryFlag";
import { Nullable } from "../../../../utils/types";
import { countries, Country } from "../../../../utils/countries";
export interface CountriesFieldProps {
  disabled: boolean;
  // enumerableId?: string;
  label?: string;
  multiple?: boolean;
  onChange: (value: Country) => void;
  info: Nullable<string>;
}
export const CountriesField: FC<
  CountriesFieldProps & FieldProps<Country[], Country[]>
> = ({
  disabled = false,
  // enumerableId,
  label,
  multiple = false,
  onChange = () => null,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  info = null,
  ...props
}) => {
  const { t } = useTranslation();

  // Auto populate business form when there is only one
  // useEffect(() => {
  //   if (enumerableIds && enumerableIds?.length === 1) {
  //     setFieldValue(`${field.name}`, enumerableIds[0]);
  //   }
  // }, [enumerableIds, field.name, setFieldValue]);

  return (
    <>
      {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(getIn(values, field.name), null, 2)}</pre> */}

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FormControl fullWidth margin="normal">
          <Field
            multiple={multiple}
            freeSolo
            component={Autocomplete}
            name={`${field.name}`}
            fullWidth
            value={
              multiple
                ? getIn(values, field.name).map((v: Country) =>
                    countries.find((e) => e.code === v.code)
                  )
                : countries.find(
                    (x) => x.code === getIn(values, field.name)?.code
                  ) || null
            }
            onBlur={handleBlur(`${field.name}`)}
            onChange={(e: any, value: any) => {
              setFieldValue(`${field.name}`, value);
              onChange(value);
            }}
            // disabled={disabled || enumerableIds?.length === 1}
            options={countries}
            autoHighlight
            variant="outlined"
            filterOptions={(
              options: Array<Country>,
              state: { inputValue: string }
            ): Array<Country> => {
              return options.filter(
                (x) =>
                  x.code
                    ?.toLowerCase()
                    ?.includes(state.inputValue.toLowerCase()) ||
                  x.name
                    ?.toLowerCase()
                    ?.includes(state.inputValue.toLowerCase())
              );
            }}
            isOptionEqualToValue={(option: Country, value: Nullable<Country>) =>
              option.code === value?.code
            }
            getOptionLabel={(option: Country) => `${option?.code}`}
            renderOption={(props: any, option: Country) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <CountryFlag countryCode={option.code} />
                {option.name} ({option.code}){/* +{option.callingCode} */}
              </Box>
            )}
            renderTags={(value: Country[], getTagProps: any) =>
              value.map((option: Country, index: number) => (
                <Chip
                  sx={{ pl: "5px" }}
                  key={option.code}
                  label={`${option.name} (${option.code})`}
                  icon={
                    <CountryFlag
                      countryCode={option.code.toLocaleLowerCase()}
                    />
                  }
                  {...getTagProps({ index })}
                  // onDelete={() =>
                  //   setFieldValue(
                  //     `${field.name}`,
                  //     getIn(values, field.name).filter(
                  //       (x: Country) => x.code === value.code
                  //     )
                  //   )
                  // }
                />
              ))
            }
            renderInput={(params: AutocompleteRenderInputParams) => (
              <>
                <TextField
                  {...params}
                  label={t(label || "common.countries")}
                  error={Boolean(
                    getIn(touched, field.name) && !!getIn(errors, field.name)
                  )}
                  helperText={
                    getIn(touched, field.name) && t(getIn(errors, field.name))
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                  InputProps={{
                    ...params.InputProps,
                    // ...(values[field.name]?.id
                    //     ? {
                    //         startAdornment: (
                    //             <InputAdornment position="end">
                    //                 <>
                    //                     <Box sx={{ ml: '8px' }}>
                    //                         {/* {values[field.name]?.displayName} ({values[field.name]?.id}) &nbsp;&nbsp; */}
                    //                         <EnumerableStatusLabel status={values[field.name]?.status} />
                    //                     </Box>
                    //                 </>
                    //             </InputAdornment>
                    //         ),
                    //     }
                    //     : {}),
                  }}
                />
              </>
            )}
          ></Field>
        </FormControl>
        {info && (
          <Tooltip
            color="action"
            title={<div style={{ whiteSpace: "pre-line" }}>{t(info)}</div>}
            sx={{ mt: "14px", ml: "5px", mr: "5px" }}
          >
            <Info />
          </Tooltip>
        )}
      </Box>
    </>
  );
};
