import { Route, Routes } from "react-router";
import { LazyLoadableScreen } from "../../navigation/LazyLoadableScreen";

const UserListScreen = LazyLoadableScreen(() => import("./UserList"));
const UserDetailsScreen = LazyLoadableScreen(() => import("./UserDetails"));

export const UsersRoutes = () => (
  <Routes>
    <Route path="/" element={<UserListScreen />} />
    <Route path="/:id" element={<UserDetailsScreen />} />
  </Routes>
);
