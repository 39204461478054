import { Box, InputAdornment } from "@mui/material";
import { Field, FieldProps } from "formik";

import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Nullable } from "../../../../utils/types";
import { Money } from ".";
import { BasicTextField, EnumerableField } from "../../simple";
import { CURRENCY } from "../../../../models/enumerations";
import { useParams } from "react-router-dom";
import { useTenantQuery } from "../../../../models/tenants";
import { AccountCircle } from "@mui/icons-material";

export interface MoneyFieldProps {
  disabled?: boolean;
  label?: string;
  info: Nullable<string>;
  showInput: boolean;
}
export const MoneyField: FC<MoneyFieldProps & FieldProps<Money, Money>> = ({
  disabled = false,
  label = "common.value",
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  info = null,
  showInput = true,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* <pre>{JSON.stringify(getIn(touched, field.name), null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(getIn(errors, field.name), null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(getIn(values, field.name), null, 2)}</pre> */}
      <Box
        sx={[
          (theme) => ({
            ...(showInput && {
              display: "grid",
              gap: "16px",
              gridTemplateColumns: "160px 1fr",
              [theme.breakpoints.down("sm")]: {
                gridTemplateColumns: "1fr",
              },
            }),
          }),
        ]}
      >
        {showInput && (
          <Field
            name={`${field.name}.currency`}
            component={EnumerableField}
            type={CURRENCY}
            margin="normal"
            disabled={true}
          />
        )}
        <Field
          name={`${field.name}.value`}
          component={BasicTextField}
          type="text"
          inputmode="numeric"
          label={label}
          margin="normal"
          disabled={disabled}
          info={info}
          InputProps={{
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          }}
        />
      </Box>
    </>
  );
};
