/// <reference lib="webworker" />

// const worker = () => {
//   setInterval(() => {
//     postMessage({foo: "bar"});
//   }, 1000);
// }

// let code = worker.toString();
// code = code.substring(code.indexOf("{")+1, code.lastIndexOf("}"));

// const blob = new Blob([code], {type: "application/javascript"});
// const worker = new Worker(URL.createObjectURL(blob));

// worker.onmessage = (m) => {
//   console.log("msg", m.data.foo);
// };

export const myworker = () => {
  // Input COMMANDS
  // restart
  // start
  // stop

  // Output COMMANDS
  // ping
  // timeout value
  // timeoutstart totalValue
  // stop

  let idle: number;
  let timeout: number;
  let ping: number;
  let debug: boolean;

  // if (typeof window !== "undefined") {
    globalThis.self.addEventListener('message', ({ data }) => {
      debuglog(JSON.stringify(data));
      switch (data.command) {
        case 'start':
          ({ idle, timeout, ping, debug } = data);
          break;
        case 'restart':
          globalThis.self.clearInterval(intervalId);
          break;
        default:
          debuglog('Webworker: Command not found');
          break;
      }

      startIdleTimer();
      debuglog('startIdleTimer');
    });
  // }
  let intervalId: number;
  let countDown = 0;

  function startIdleTimer() {
    const start = Date.now();
    intervalId = globalThis.self.setInterval(() => {
      // debug("Start:"+start);
      const delta: number = Date.now() - start;
      const seconds: number = Math.floor(delta / 1000);
      debuglog('Tick:' + seconds);

      if (seconds % ping === 0) {
        postMessage({ command: 'ping' });
      }

      if (seconds >= idle) {
        debuglog('Stop-Idle');
        clearInterval(intervalId);
        countDown = timeout;
        debuglog('Reset-Countdown');
        intervalId = globalThis.self.setInterval(startTimeoutTimer, 1000);
        postMessage({ command: 'timeoutstart', timeoutValue: timeout});
        debuglog('Start-Timeout');
      }
    }, 1000);
  }

  function startTimeoutTimer() {
    debuglog('Timeout:' + countDown);
    postMessage({ command: 'timeout', timeout: countDown-- });
    if (countDown < 0) {
      debuglog('Stop-Timeout:');
      postMessage({ command: 'stop' });
      clearInterval(intervalId);
    }
  }

  function debuglog(value: string) {
    if (debug) postMessage({ command: 'log', value });
  }
};
