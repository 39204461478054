import { config } from '../../services/config';
import { apiWithTag } from '../emptySplitApi';
import { ApiConfirmForgotPasswordRequest, ApiForgotPasswordRequest, ApiResetPasswordRequest, ApiResetPasswordResponse, ApiTokenResponse } from './types-api';
import { LoginRequest } from './types-dto';

export const authApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<ApiTokenResponse, LoginRequest>({
            query: ({ tenantIdForHeader, ...credentials }) => ({
                body: new URLSearchParams({
                    password: credentials.password,
                    username: credentials.email,
                }),
                headers: {
                    Authorization: `Basic ${config.apiKey}`,
                    'X-TENANT-ID': tenantIdForHeader,
                },
                method: 'POST',
                url: 'oauth/token',
                params: {
                    grant_type: 'password',
                },
                providesTags: ['Auth']
            }),
            transformResponse: (response: ApiTokenResponse) => {
                return ({ ...response, expires_on: Date.now() + response.expires_in });
            }
        }),
        logout: builder.mutation<void, void>({
            query: () => ({
                method: 'DELETE',
                url: 'oauth/token',
            }),
            invalidatesTags: ['Auth']
        }),
        refreshToken: builder.mutation<ApiTokenResponse, void>({
            query: () => ({
                method: 'POST',
                url: 'oauth/token/refresh',
            }),
            transformResponse: (response: ApiTokenResponse) => {
                return ({ ...response, expires_on: Date.now() + response.expires_in });
            },
            invalidatesTags: ['Auth']
        }),
        forgotPassword: builder.mutation<void, ApiForgotPasswordRequest>({
            query: (body) => ({
                headers: {
                    Authorization: `Basic ${config.apiKey}`,
                },
                body,
                method: 'POST',
                url: 'forgot-password',
            }),
            invalidatesTags: ['Auth']
        }),
        forgotPasswordConfirm: builder.mutation<void, ApiConfirmForgotPasswordRequest>(
            {
                query: (body) => ({
                    headers: {
                        Authorization: `Basic ${config.apiKey}`,
                    },
                    body,
                    method: 'POST',
                    url: 'forgot-password/confirm',
                }),
                invalidatesTags: ['Auth']

            },
        ),
        // Admin reset password
        resetPassword: builder.mutation<ApiResetPasswordResponse, ApiResetPasswordRequest>({
            query: ({ tenantIdForHeader, ...body }) => ({
                headers: {
                    'X-TENANT-ID': tenantIdForHeader,
                },
                body,
                method: 'POST',
                url: 'reset-password',
            }),
        }),
    }),
});

export const {
    useLoginMutation,
    useLogoutMutation,
    useRefreshTokenMutation,
    useForgotPasswordMutation,
    useForgotPasswordConfirmMutation,
    useResetPasswordMutation

} = authApi;
