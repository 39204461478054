import { ApiCreateBillerRequest, ApiUpdateBillerRequest } from ".";
import { mapAddressToApiAddress } from "../../forms";
import { CreateBillerFormValues, UpdateBillerFormValues } from "../../screens/Billers/forms";

export const mapCreateBillerFormValuesToApiCreateBillerRequest = (
    createDto: CreateBillerFormValues
): ApiCreateBillerRequest => ({
    companyName: createDto.companyName,
    address: mapAddressToApiAddress(createDto.address),
    vatRegNo: createDto.vatRegNo,
    companyRegNo: createDto.companyRegNo,
    tenantIdForHeader: createDto.tenantIdForHeader
});

export const mapUpdateBillerFormValuesToApiUpdateBillerRequest = (
    updateDto: UpdateBillerFormValues
): ApiUpdateBillerRequest => ({
    id: updateDto.id,
    companyName: updateDto.companyName,
    address: mapAddressToApiAddress(updateDto.address),
    vatRegNo: updateDto.vatRegNo,
    companyRegNo: updateDto.companyRegNo,
});
