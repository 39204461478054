import { Chip } from "@mui/material";
import { FC } from "react";
import { ApiMoney } from "./types-api";
import getSymbolFromCurrency from "currency-symbol-map";

export interface MoneyDisplayProps {
  disabled?: boolean;
  money: ApiMoney;
}
export const MoneyDisplay: FC<MoneyDisplayProps> = ({
  disabled = false,
  money,
}) => {
  return (
    <>
      {money.currency && (
        <Chip
          sx={{
            pl: "5px",
            m: 1,
            color: Number(money.value) < 0 ? "red" : "#121828",
          }}
          label={`${getSymbolFromCurrency(money.currency)} ${(
            Math.round(Number(money.value) * 100) / 100
          ).toFixed(2)}`}
        />
      )}
    </>
  );
};
