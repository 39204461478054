import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { isValid } from "date-fns";
import { FieldProps, getIn } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Nullable } from "../../../../utils/types";

export interface DateFieldProps {
  disabled: boolean;
  label?: string;
  size?: "small" | "medium";
  margin?: "none" | "dense" | "normal";
  showErrorText: boolean;
  onChange: (value: Nullable<Date>) => void;
}
export const DateField: FC<DateFieldProps & FieldProps<Date, Date>> = ({
  disabled = false,
  label = "common.date",
  size = "medium",
  margin,
  field,
  showErrorText = true,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  onChange = () => null,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* {JSON.stringify(values)} */}
      <FormControl
        margin={margin}
        variant="outlined"
        fullWidth
        error={Boolean(
          getIn(touched, field.name) && !!getIn(errors, field.name)
        )}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            // InputAdornmentProps={{
            //   sx: { ".MuiButtonBase-root": { padding: "0 !important" } },
            // }}
            format="dd/MM/yyyy"
            // inputFormat="dd/MM/yyyy"
            // disableFuture
            label={t(label)}
            onChange={(newValue: Nullable<Date>) => {
              console.log(!isValid(newValue));

              if (!isValid(newValue)) {
                setFieldValue(`${field.name}`, null);
                onChange(null);
                return;
              }
              console.log(newValue);

              setFieldValue(`${field.name}`, newValue);
              onChange(newValue);
            }}
            openTo="year"
            reduceAnimations
            slotProps={{
              textField: {
                size,
                error: Boolean(
                  getIn(touched, field.name) && !!getIn(errors, field.name)
                ),
              },
            }}
            sx={{
              ".MuiButtonBase-root": { padding: "0 !important" },
            }}
            // onBlur={handleBlur(`${field.name}`)}
            // error={Boolean(
            //   getIn(touched, field.name) && !!getIn(errors, field.name)
            // )}
            // renderInput={(params: any) => (
            //   <>
            //     <TextField
            //       {...params}
            //       size={size}
            //       onBlur={handleBlur(`${field.name}`)}
            //       error={Boolean(
            //         getIn(touched, field.name) && !!getIn(errors, field.name)
            //       )}
            //     />
            //   </>
            // )}
            // toolbarTitle={t(label)}
            value={getIn(values, field.name)}
            views={["year", "month", "day"]}
            disabled={disabled}
          />
        </LocalizationProvider>
        <FormHelperText error>
          {showErrorText &&
            getIn(touched, field.name) &&
            t(getIn(errors, field.name))}
        </FormHelperText>
      </FormControl>
    </>
  );
};
