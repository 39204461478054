import { ApiEntity, ApiStageTermination, STAGE_4_2_TYPE } from "../../../../../../models/entities";

import { ApiEnumeration, REASON_FOR_TERMINATION } from "../../../../../../models/enumerations";
import { parseISO } from "date-fns";
import { TerminationFormValues } from ".";

export const terminationFormValuesInitialValue = (
    entity: ApiEntity,
    enumerables: ApiEnumeration[]): TerminationFormValues => {
    // Get Stage Data
    const stageData: ApiStageTermination = entity.stages.find((s) => s.stageType === STAGE_4_2_TYPE) as ApiStageTermination;
    // If draft is available use that
    const data: ApiStageTermination = stageData.draftData ? stageData.draftData as ApiStageTermination : stageData;
    return {
        stageType: STAGE_4_2_TYPE,
        stageStatus: data.stageStatus,
        id: entity.id,
        dateOfTermination: data.dateOfTermination ? parseISO(data.dateOfTermination) : null,
        reasonForTermination: data.reasonForTermination ? enumerables.filter(e => e.type === REASON_FOR_TERMINATION).find(x => x.value === data.reasonForTermination)! : null,
        reasonForTerminationInfo: data.reasonForTerminationInfo ? data.reasonForTerminationInfo : ''
    }
}
