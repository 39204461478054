import isoCountries from 'i18n-iso-countries';
import countryDataEn from 'i18n-iso-countries/langs/en.json';
import {
    CountryCallingCode,
    CountryCode,
    getCountries,
    getCountryCallingCode,
} from 'libphonenumber-js';
import { Nullable } from './types';

isoCountries.registerLocale(countryDataEn);

export const getCountryName = (code: CountryCode) => {
    let name = isoCountries.getName(code as string, 'en');

    if (!name) {
        if (code === 'AC') {
            name = 'Ascension';
        } else if (code === 'TA') {
            name = 'Tristan da Cunha';
        }
    }

    return name || '';
};

export interface Country {
    callingCode: CountryCallingCode;
    code: CountryCode;
    name: string;
}

export const countries: Country[] =
    getCountries().map((code) => ({
        callingCode: getCountryCallingCode(code),
        code,
        name: getCountryName(code),
    }))
        .sort((a, b) => (a.name > b.name ? 1 : -1));


export const getCountryByCode = (code: string): Nullable<Country> => countries.find(c => c.code === code) || null
