import { Route, Routes } from "react-router";
import { LazyLoadableScreen } from "../../navigation/LazyLoadableScreen";

const PermissionGroupListScreen = LazyLoadableScreen(
  () => import("./PermissionGroupList")
);
const PermissionGroupDetailsScreen = LazyLoadableScreen(
  () => import("./PermissionGroupDetails")
);

export const PermissionGroupsRoutes = () => (
  <Routes>
    <Route path="/" element={<PermissionGroupListScreen />} />
    <Route path="/:id" element={<PermissionGroupDetailsScreen />} />
  </Routes>
);
