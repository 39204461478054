import { ApiEntity, ApiInvestment, ApiStageBusinessActivity, STAGE_2_2_TYPE } from "../../../../../../models/entities"
import { BusinessActivityFormValues, InvestmentFormValues } from "."
import { ApiEnumeration, HIGH_RISK_INDUSTRY, INVESTMENT_TYPE, JURISDICTION_OF_REGULATOR, LICENSE_CATEGORY, NAME_OF_REGULATOR, SECTOR, TYPE_OF_LISTING } from "../../../../../../models/enumerations"
import { NO, YES, booleanToYesNo, mapApiMoneyToMoney, moneyInitialValues, tradingOrHoldingInitialValue } from "../../../../../../forms";
import { parseISO } from "date-fns";

export const mapApiInvestmentToInvestmentFormValues = (
    api: ApiInvestment,
    enumerables: ApiEnumeration[]
): InvestmentFormValues => ({
    id: api.id,
    type: api.type ? enumerables.filter(x => x.type === INVESTMENT_TYPE).find(x => x.value === api.type)! : null,
    additionalInfo: api.additionalInfo,
    jurisdiction: api.jurisdiction ? enumerables.filter(x => x.type === JURISDICTION_OF_REGULATOR).find(x => x.value === api.jurisdiction)! : null,
});


export const businessActivityFormValuesInitialValue = (
    entity: ApiEntity,
    enumerables: ApiEnumeration[],
    currency: ApiEnumeration): BusinessActivityFormValues => {
    // Get Stage Data
    const stageData: ApiStageBusinessActivity = entity.stages.find((s) => s.stageType === STAGE_2_2_TYPE) as ApiStageBusinessActivity;
    // If draft is available use that
    const data: ApiStageBusinessActivity = stageData.draftData ? stageData.draftData as ApiStageBusinessActivity : stageData;
    return {

        stageType: STAGE_2_2_TYPE,
        stageStatus: data.stageStatus,
        id: entity.id,

        sector: data.sector ? enumerables.filter(x => x.type === SECTOR).find(x => x.value === data.sector)! : null,
        licenseCategory: data.licenseCategory ? enumerables.filter(x => x.type === LICENSE_CATEGORY).find(x => x.value === data.licenseCategory)! : null,
        licenseNumber: data.licenseNumber,
        nameOfRegulator: data.nameOfRegulator ? enumerables.filter(x => x.type === NAME_OF_REGULATOR).find(x => x.value === data.nameOfRegulator)! : null,
        jurisdictionOfRegulator: data.jurisdictionOfRegulator ? enumerables.filter(x => x.type === JURISDICTION_OF_REGULATOR).find(x => x.value === data.jurisdictionOfRegulator)! : null,
        typeOfListing: data.typeOfListing ? enumerables.filter(x => x.type === TYPE_OF_LISTING).find(x => x.value === data.typeOfListing)! : null,
        tradingActivities: data.tradingActivities != null ? booleanToYesNo(data.tradingActivities) : '',
        transferredFromAnotherJurisdiction: data.transferredFromAnotherJurisdiction ? YES : NO,
        transferredFromJurisdiction: data.transferredFromJurisdiction ? enumerables.filter(x => x.type === JURISDICTION_OF_REGULATOR).find(x => x.value === data.transferredFromJurisdiction)! : null,
        transferredToAnotherJurisdiction: data.transferredToAnotherJurisdiction != null ? booleanToYesNo(data.transferredToAnotherJurisdiction) : '',
        transferredToJurisdiction: data.transferredFromJurisdiction ? enumerables.filter(x => x.type === JURISDICTION_OF_REGULATOR).find(x => x.value === data.transferredFromJurisdiction)! : null,
        tradingOrHolding: data.tradingOrHolding || tradingOrHoldingInitialValue(),
        investments: data.investments ? data.investments.map(investment => mapApiInvestmentToInvestmentFormValues(investment, enumerables)) : [],
        differentRegisteredAddress: data.differentRegisteredAddress != null ? booleanToYesNo(data.differentRegisteredAddress) : '',
        highRiskIndustries: data.highRiskIndustries != null ? enumerables.filter(x => x.type === HIGH_RISK_INDUSTRY).filter(x => data.highRiskIndustries?.split(',').includes(x.value)) : [],
        cashIntensiveBusiness: data.cashIntensiveBusiness != null ? booleanToYesNo(data.cashIntensiveBusiness) : '',
        cashIntensiveBusinessInfo: data.cashIntensiveBusinessInfo,
        dualUseItems: data.dualUseItems != null ? booleanToYesNo(data.dualUseItems) : '',
        dualUseItemsInfo: data.dualUseItemsInfo,
        businessActivitiesDescription: data.businessActivitiesDescription,
        expectedTotalIncome: data.expectedTotalIncome ? mapApiMoneyToMoney(data.expectedTotalIncome, enumerables) : moneyInitialValues(currency),
        activitiesTakingPlaceIn: data.activitiesTakingPlaceIn ? enumerables.filter(x => x.type === JURISDICTION_OF_REGULATOR).filter(x => data.activitiesTakingPlaceIn.includes(x.value)) : [], // testing

        fundsHeld: data.fundsHeld != null ? booleanToYesNo(data.fundsHeld) : '',
        fundsHeldInfo: data.fundsHeld === true ? data.fundsHeldInfo! : '',
        yearEnd: data.fundsHeld === true ? parseISO(data.yearEnd!) : null,
        amountAtYearEnd: data.fundsHeld === true && data.amountAtYearEnd ? mapApiMoneyToMoney(data.amountAtYearEnd, enumerables) : moneyInitialValues(currency),
        bankingArrangements: data.bankingArrangements != null ? booleanToYesNo(data.bankingArrangements) : '',
        bankName: data.bankName,
        bankJurisdiction: data.bankJurisdiction ? enumerables.filter(x => x.type === JURISDICTION_OF_REGULATOR).find(x => x.value === data.bankJurisdiction)! : null,
        bankFacilitiesHeld: data.bankFacilitiesHeld != null ? booleanToYesNo(data.bankFacilitiesHeld) : '',
        bankFacilitiesHeldInfo: data.bankFacilitiesHeldInfo,
        authorizedBankRepresentatives: data.authorizedBankRepresentatives,
        authorizedBankSignatories: data.authorizedBankSignatories,

        raisedCapital: data.raisedCapital != null ? booleanToYesNo(data.raisedCapital) : '',
        amountOfCapitalRaised: data.raisedCapital && data.amountOfCapitalRaised ? mapApiMoneyToMoney(data.amountOfCapitalRaised, enumerables) : moneyInitialValues(currency),
        holdsVirtualFinancialAssets: data.holdsVirtualFinancialAssets != null ? booleanToYesNo(data.holdsVirtualFinancialAssets) : '',
        transactionsVirtualFinancialAssets: data.transactionsVirtualFinancialAssets != null ? booleanToYesNo(data.transactionsVirtualFinancialAssets) : '',
    }
}

export const investmentsInitialValues = (): InvestmentFormValues => ({
    id: '',
    type: null,
    additionalInfo: '',
    jurisdiction: null
});

