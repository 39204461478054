import { ApiCreatePermissionGroupRequest, ApiPermissionGroup, ApiUpdatePermissionGroupRequest } from ".";
import { CreateUpdatePermissionGroupFormValues } from "../../screens/PermissionGroups/forms/PermissionGroupForm";


export const mapCreateUpdatePermissionGroupFormValuesToApiCreatePermissionGroupRequest = (
    permissionGroupDto: CreateUpdatePermissionGroupFormValues
): ApiCreatePermissionGroupRequest => ({
    name: permissionGroupDto.name,
});

export const mapCreateUpdatePermissionGroupFormValuesToApiUpdatePermissionGroupRequest = (
    permissionGroupDto: CreateUpdatePermissionGroupFormValues
): ApiUpdatePermissionGroupRequest => ({
    id: permissionGroupDto.id as string,
    name: permissionGroupDto.name,

});

export const mapApiPermissionGroupToCreateUpdatePermissionGroupFormValues = (
    permissionGroup: ApiPermissionGroup
): CreateUpdatePermissionGroupFormValues => ({
    id: permissionGroup.id,
    name: permissionGroup.name,
    // tenantIdForHeader: '' // TOFIX
});

