import { Info } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  FormControl,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  ApiEnumeration,
  ApiEnumerationType,
  CURRENCY,
  useEnumerationsQuery,
} from "../../../../models/enumerations";
import getSymbolFromCurrency from "currency-symbol-map";
import { Nullable } from "../../../../utils/types";
import { enumerationDataMap } from "./utils";
import { StringLiteralType } from "typescript";

export interface EnumerableTextFieldProps {
  disabled: boolean;
  label?: string;
  size?: "small" | "medium";
  margin?: "none" | "dense" | "normal";
  onChange: (value: string) => void;
  type: ApiEnumerationType;
  info: Nullable<string>;
  showErrorText: boolean;
  disableClearable: boolean;
}
export const EnumerableTextField: FC<
  EnumerableTextFieldProps & FieldProps<string, string>
> = ({
  disabled = false,
  onChange = () => null,
  margin,
  type,
  label,
  size = "medium",
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  info = null,
  showErrorText = true,
  disableClearable = false,
  ...props
}) => {
  const { t } = useTranslation();

  const { data: enumerables } = useEnumerationsQuery(
    { type: type },
    { refetchOnMountOrArgChange: true }
  );

  // Auto populate business form when there is only one
  useEffect(() => {
    if (enumerables && enumerables?.length === 1) {
      setFieldValue(`${field.name}`, enumerables[0].valueDescription);
    }
  }, [enumerables, field.name, setFieldValue]);

  return (
    <>
      {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(getIn(errors, field.name), null, 2)}</pre>
      <pre>{JSON.stringify(getIn(values, field.name), null, 2)}</pre> */}
      {enumerables && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControl fullWidth margin={margin}>
            <Field
              freeSolo
              disableClearable={disableClearable}
              size={size}
              margin={margin}
              component={Autocomplete}
              name={`${field.name}`}
              fullWidth
              value={getIn(values, field.name)}
              onBlur={handleBlur(`${field.name}`)}
              onChange={(e: any, value: string) => {
                setFieldValue(`${field.name}`, value !== null ? value : "");
                onChange(value);
              }}
              disabled={disabled || enumerables?.length === 1}
              options={enumerables.map((e) => e.valueDescription)}
              autoHighlight
              variant="outlined"
              renderInput={(params: AutocompleteRenderInputParams) => (
                <>
                  <TextField
                    {...params}
                    label={t(label || enumerationDataMap.get(type).enumeration)}
                    error={Boolean(
                      getIn(touched, field.name) && !!getIn(errors, field.name)
                    )}
                    helperText={
                      showErrorText
                        ? getIn(touched, field.name) &&
                          t(getIn(errors, field.name))
                        : ""
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue(`${field.name}`, event.target.value);
                      onChange(event.target.value);
                    }}
                    multiline={true}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      style: { resize: "both" },
                    }}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                </>
              )}
            ></Field>
          </FormControl>
          {info && (
            <Tooltip
              color="action"
              title={<div style={{ whiteSpace: "pre-line" }}>{t(info)}</div>}
              sx={{ mt: "14px", ml: "5px", mr: "5px" }}
            >
              <Info />
            </Tooltip>
          )}
        </Box>
      )}
    </>
  );
};
