import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ApiEntity, ApiStageCdd } from "../../../../../../../models/entities";
import {
  ApiCddDoneType,
  ApiEntityStatus,
  YesNo,
  booleanToYesNo,
  cddDoneTypesColourMap,
  yesNosColourMap,
  ACTIVE_CLIENT_STATUS,
  CLOSED_CLIENT_STATUS,
  PENDING_APPROVAL_CLIENT_STATUS,
} from "../../../../../../../forms";
import { getCountryByCode } from "../../../../../../../utils/countries";
import { CountryFlag } from "../../../../../../../components/CountryFlag";
import { PermissionGuard } from "../../../../../../../models/auth";
import { StatusLabel } from "../../../../../../../components/StatusLabel";
import { ApiUserResponse } from "../../../../../../../models/users";
import { Nullable } from "../../../../../../../utils/types";

interface DetailRow {
  name: string;
  value: any;
}

export const Stage2_1View: FC<{
  entity: ApiEntity;
  stageData: ApiStageCdd;
  onEdit: (value: boolean) => void;
  status: Nullable<ApiEntityStatus>;
  profile: ApiUserResponse | undefined;
}> = ({ entity, stageData, onEdit, status = null, profile }) => {
  const { t } = useTranslation();

  const step2_1Data: DetailRow[] = useMemo(
    () =>
      stageData
        ? [
            {
              name: "common.identityDocumentType",
              value: (
                <>
                  {stageData?.identityDocumentType && (
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={stageData.identityDocumentType}
                    />
                  )}
                </>
              ),
            },
            {
              name: "common.idNo",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.idNo}
                </Typography>
              ),
            },
            {
              name: "common.documentNo",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.documentNo}
                </Typography>
              ),
            },
            {
              name: "common.documentExpiryDate",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.documentExpiryDate != undefined &&
                    stageData.documentExpiryDate?.length > 0 &&
                    format(
                      parseISO(stageData.documentExpiryDate),
                      "dd-MM-yyyy"
                    )}
                </Typography>
              ),
            },
            {
              name: t("common.documentCountry"),
              value: (
                <>
                  {stageData?.documentCountry && (
                    <Chip
                      sx={{ pl: "5px", m: 1 }}
                      label={`${
                        getCountryByCode(stageData.documentCountry)?.name
                      } (${stageData.documentCountry})`}
                      icon={
                        <CountryFlag countryCode={stageData.documentCountry} />
                      }
                    />
                  )}
                </>
              ),
            },

            {
              name: "common.proofOfAddress",
              value: (
                <>
                  {stageData?.proofOfAddress && (
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={stageData.proofOfAddress}
                    />
                  )}
                </>
              ),
            },
            {
              name: "common.proofOfAddressDate",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.proofOfAddressDate != undefined &&
                    stageData.proofOfAddressDate?.length > 0 &&
                    format(
                      parseISO(stageData.proofOfAddressDate),
                      "dd-MM-yyyy"
                    )}
                </Typography>
              ),
            },
            ...(stageData.certificateIncorporation
              ? [
                  {
                    name: "common.certificateIncorporation",
                    value: (
                      <StatusLabel<ApiCddDoneType>
                        colourMap={cddDoneTypesColourMap}
                        status={stageData.certificateIncorporation}
                      />
                    ),
                  },
                ]
              : []),
            ...(stageData.memorandumAndArticles
              ? [
                  {
                    name: "common.memorandumAndArticles",
                    value: (
                      <StatusLabel<ApiCddDoneType>
                        colourMap={cddDoneTypesColourMap}
                        status={stageData.memorandumAndArticles}
                      />
                    ),
                  },
                ]
              : []),
            ...(stageData.vatRegCertificate
              ? [
                  {
                    name: "common.vatRegCertificate",
                    value: (
                      <StatusLabel<ApiCddDoneType>
                        colourMap={cddDoneTypesColourMap}
                        status={stageData.vatRegCertificate}
                      />
                    ),
                  },
                ]
              : []),
            ...(stageData.beneficialOwnersDocument
              ? [
                  {
                    name: "common.beneficialOwnersDocument",
                    value: (
                      <StatusLabel<ApiCddDoneType>
                        colourMap={cddDoneTypesColourMap}
                        status={stageData.beneficialOwnersDocument}
                      />
                    ),
                  },
                ]
              : []),
            ...(stageData.certificateGoodStanding
              ? [
                  {
                    name: "common.certificateGoodStanding",
                    value: (
                      <StatusLabel<ApiCddDoneType>
                        colourMap={cddDoneTypesColourMap}
                        status={stageData.certificateGoodStanding}
                      />
                    ),
                  },
                ]
              : []),
            ...(stageData.certificateIncumbency
              ? [
                  {
                    name: "common.certificateIncumbency",
                    value: (
                      <StatusLabel<ApiCddDoneType>
                        colourMap={cddDoneTypesColourMap}
                        status={stageData.certificateIncumbency}
                      />
                    ),
                  },
                ]
              : []),
            {
              name: "common.otherDocumentationCollected",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.otherDocumentationCollected}
                </Typography>
              ),
            },
            ...(stageData.curriculumVitae
              ? [
                  {
                    name: "common.curriculumVitae",
                    value: (
                      <StatusLabel<ApiCddDoneType>
                        colourMap={cddDoneTypesColourMap}
                        status={stageData.curriculumVitae}
                      />
                    ),
                  },
                ]
              : []),
            {
              name: "common.curriculumVitaeDate",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.curriculumVitaeDate != undefined &&
                    stageData.curriculumVitaeDate?.length > 0 &&
                    format(
                      parseISO(stageData.curriculumVitaeDate),
                      "dd-MM-yyyy"
                    )}
                </Typography>
              ),
            },
            ...(stageData.groupStructure
              ? [
                  {
                    name: "common.groupStructure",
                    value: (
                      <StatusLabel<ApiCddDoneType>
                        colourMap={cddDoneTypesColourMap}
                        status={stageData.groupStructure}
                      />
                    ),
                  },
                ]
              : []),
            {
              name: t("common.hasPepScreeningConducted"),
              value: (
                <>
                  {stageData?.hasPepScreeningConducted ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.hasPepScreeningConducted
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.pepScreeningConductedDate",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.pepScreeningConductedDate != undefined &&
                    stageData.pepScreeningConductedDate?.length > 0 &&
                    format(
                      parseISO(stageData.pepScreeningConductedDate),
                      "dd-MM-yyyy"
                    )}
                </Typography>
              ),
            },
            {
              name: t("common.hasPep"),
              value: (
                <>
                  {stageData?.hasPep ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasPep)}
                    />
                  ) : null}
                </>
              ),
            },

            {
              name: "common.pepInfo",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.pepInfo}
                </Typography>
              ),
            },
            {
              name: t("common.hasSanctions"),
              value: (
                <>
                  {stageData?.hasSanctions ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasSanctions)}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.sanctionsInfo",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.sanctionsInfo}
                </Typography>
              ),
            },
            {
              name: t("common.hasAdverseMedia"),
              value: (
                <>
                  {stageData?.hasAdverseMedia != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasAdverseMedia)}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.adverseMediaInfo",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.adverseMediaInfo}
                </Typography>
              ),
            },
            {
              name: t("common.hasLawEnforcement"),
              value: (
                <>
                  {stageData?.hasLawEnforcement != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasLawEnforcement)}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.lawEnforcementInfo",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.lawEnforcementInfo}
                </Typography>
              ),
            },
            {
              name: t("common.hasDisqualifiedDirector"),
              value: (
                <>
                  {stageData?.hasDisqualifiedDirector != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasDisqualifiedDirector)}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.disqualifiedDirectorInfo",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.disqualifiedDirectorInfo}
                </Typography>
              ),
            },
            {
              name: t("common.hasInsolvent"),
              value: (
                <>
                  {stageData?.hasInsolvent ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasInsolvent)}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.insolventInfo",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.insolventInfo}
                </Typography>
              ),
            },
            {
              name: "common.actionsTakenTrueMatch",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.actionsTakenTrueMatch}
                </Typography>
              ),
            },
            {
              name: "common.hasAdverseMediaSearchInternet",
              value: (
                <>
                  {stageData?.hasAdverseMediaSearchInternet != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.hasAdverseMediaSearchInternet
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.adverseMediaSearchInternetDate",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.adverseMediaSearchInternetDate != undefined &&
                    stageData.adverseMediaSearchInternetDate?.length > 0 &&
                    format(
                      parseISO(stageData.adverseMediaSearchInternetDate),
                      "dd-MM-yyyy"
                    )}
                </Typography>
              ),
            },

            {
              name: "common.hasAdverseMediaSearchAssetRecoveryBureau",
              value: (
                <>
                  {stageData?.hasAdverseMediaSearchAssetRecoveryBureau !=
                  null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.hasAdverseMediaSearchAssetRecoveryBureau
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.adverseMediaSearchAssetRecoveryBureauDate",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.adverseMediaSearchAssetRecoveryBureauDate !=
                    undefined &&
                    stageData.adverseMediaSearchAssetRecoveryBureauDate
                      ?.length > 0 &&
                    format(
                      parseISO(
                        stageData.adverseMediaSearchAssetRecoveryBureauDate
                      ),
                      "dd-MM-yyyy"
                    )}
                </Typography>
              ),
            },

            {
              name: "common.hasAdverseMediaSearchJudgementCourt",
              value: (
                <>
                  {stageData?.hasAdverseMediaSearchJudgementCourt ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.hasAdverseMediaSearchJudgementCourt
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.adverseMediaSearchJudgementCourtDate",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.adverseMediaSearchJudgementCourtDate !=
                    undefined &&
                    stageData.adverseMediaSearchJudgementCourtDate?.length >
                      0 &&
                    format(
                      parseISO(stageData.adverseMediaSearchJudgementCourtDate),
                      "dd-MM-yyyy"
                    )}
                </Typography>
              ),
            },

            {
              name: "common.hasAdverseMediaFoundInternet",
              value: (
                <>
                  {stageData?.hasAdverseMediaFoundInternet ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.hasAdverseMediaFoundInternet
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.adverseMediaFoundInternetInfo",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.adverseMediaFoundInternetInfo}
                </Typography>
              ),
            },
            {
              name: "common.hasAdverseMediaFoundAssetRecoveryBureau",
              value: (
                <>
                  {stageData?.hasAdverseMediaFoundAssetRecoveryBureau ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.hasAdverseMediaFoundAssetRecoveryBureau
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.adverseMediaFoundAssetRecoveryBureauInfo",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.adverseMediaFoundAssetRecoveryBureauInfo}
                </Typography>
              ),
            },
            {
              name: "common.hasAdverseMediaFoundJudgementCourt",
              value: (
                <>
                  {stageData?.hasAdverseMediaFoundJudgementCourt != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.hasAdverseMediaFoundJudgementCourt
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.adverseMediaFoundJudgementCourtInfo",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.adverseMediaFoundJudgementCourtInfo}
                </Typography>
              ),
            },
            {
              name: "common.adverseMediaSummary",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.adverseMediaSummary}
                </Typography>
              ),
            },
            ...(stageData.boInformationProof
              ? [
                  {
                    name: "common.boInformationProof",
                    value: (
                      <StatusLabel<ApiCddDoneType>
                        colourMap={cddDoneTypesColourMap}
                        status={stageData.boInformationProof}
                      />
                    ),
                  },
                ]
              : []),
            {
              name: "common.boInformationProofDate",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.boInformationProofDate != undefined &&
                    stageData.boInformationProofDate?.length > 0 &&
                    format(
                      parseISO(stageData.boInformationProofDate),
                      "dd-MM-yyyy"
                    )}
                </Typography>
              ),
            },
            {
              name: "common.otherMeasures",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.otherMeasures}
                </Typography>
              ),
            },
            {
              name: "common.hasPoliceConductCertificate",
              value: (
                <>
                  {stageData?.hasPoliceConductCertificate ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.hasPoliceConductCertificate
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.policeConductCertificateDate",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.policeConductCertificateDate != undefined &&
                    stageData.policeConductCertificateDate?.length > 0 &&
                    format(
                      parseISO(stageData.policeConductCertificateDate),
                      "dd-MM-yyyy"
                    )}
                </Typography>
              ),
            },
            {
              name: "common.hasBankReference",
              value: (
                <>
                  {stageData?.hasBankReference ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasBankReference)}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.bankReferenceDate",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.bankReferenceDate != undefined &&
                    stageData.bankReferenceDate?.length > 0 &&
                    format(parseISO(stageData.bankReferenceDate), "dd-MM-yyyy")}
                </Typography>
              ),
            },
            {
              name: "common.hasProfessionalReference",
              value: (
                <>
                  {stageData?.hasProfessionalReference ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.hasProfessionalReference
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.professionalReferenceDate",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.professionalReferenceDate != undefined &&
                    stageData.professionalReferenceDate?.length > 0 &&
                    format(
                      parseISO(stageData.professionalReferenceDate),
                      "dd-MM-yyyy"
                    )}
                </Typography>
              ),
            },
            {
              name: "common.otherEddMeasures",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.otherEddMeasures}
                </Typography>
              ),
            },
            {
              name: "common.hasObtainedMlroApproval",
              value: (
                <>
                  {stageData?.hasObtainedMlroApproval ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasObtainedMlroApproval)}
                    />
                  ) : null}
                </>
              ),
            },
          ]
        : [],
    [entity, stageData, t]
  );

  return (
    <>
      {entity ? (
        <>
          <Card sx={{ marginTop: "12px" }}>
            <CardHeader
              disableTypography
              title={
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tooltip title={t("common.stage_2_1")} enterDelay={1000}>
                    <Typography variant="h6">
                      {t("common.cddInformation")}
                    </Typography>
                  </Tooltip>
                  <Grid
                    item
                    // sm={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {((status !== PENDING_APPROVAL_CLIENT_STATUS &&
                      status !== ACTIVE_CLIENT_STATUS &&
                      status !== CLOSED_CLIENT_STATUS) ||
                      (status === PENDING_APPROVAL_CLIENT_STATUS &&
                        entity.reviewerId === profile?.id)) && (
                      <PermissionGuard permission="UPDATE_ENTITY">
                        <>
                          <Button
                            sx={[
                              {
                                whiteSpace: "nowrap",
                              },
                              (theme) => ({
                                [theme.breakpoints.down("sm")]: {
                                  minWidth: "100%",
                                },
                                [theme.breakpoints.up("sm")]: {
                                  minWidth: "auto",
                                },
                              }),
                            ]}
                            variant="contained"
                            onClick={() => onEdit(true)}
                          >
                            {t("common.edit")}
                          </Button>
                        </>
                      </PermissionGuard>
                    )}
                  </Grid>
                </Box>
              }
            />

            <Grid container rowSpacing={3} columnSpacing={2} sx={{ p: "16px" }}>
              {step2_1Data.map(({ name, value }) => (
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  key={name}
                  sx={{ paddingTop: "12px !important" }}
                >
                  <Grid container>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {t(name)}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      {value}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Card>
        </>
      ) : null}
    </>
  );
};
