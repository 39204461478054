import { ApiAddress, Address } from '.';
import { countries } from '../../../../utils/countries';

export const mapAddressToApiAddress = (addressDto: Address): ApiAddress => {
    return {
        addressLine1: addressDto.addressLine1,
        addressLine2: addressDto.addressLine2,
        town: addressDto.town,
        postCode: addressDto.postcode,
        country: addressDto.country?.code!
    }
}

export const mapApiAddressToAddress = (apiAddress: ApiAddress): Address => {
    return {
        addressLine1: apiAddress.addressLine1,
        addressLine2: apiAddress.addressLine2,
        town: apiAddress.town,
        postcode: apiAddress.postCode,
        country: countries.find(x => x.code === apiAddress.country) || null,
    }
}