import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  ApiEntity,
  ApiStageBusinessActivity,
} from "../../../../../../../models/entities";

import { PermissionGuard } from "../../../../../../../models/auth";
import getSymbolFromCurrency from "currency-symbol-map";
import { StatusLabel } from "../../../../../../../components/StatusLabel";
import {
  ApiEntityStatus,
  YesNo,
  booleanToYesNo,
  yesNosColourMap,
  ACTIVE_CLIENT_STATUS,
  CLOSED_CLIENT_STATUS,
  PENDING_APPROVAL_CLIENT_STATUS,
} from "../../../../../../../forms";
import { ApiUserResponse } from "../../../../../../../models/users";
import { Nullable } from "../../../../../../../utils/types";

interface DetailRow {
  name: string;
  value: any;
}

export const Stage2_2View: FC<{
  entity: ApiEntity;
  stageData: ApiStageBusinessActivity;
  onEdit: (value: boolean) => void;
  status: Nullable<ApiEntityStatus>;
  profile: ApiUserResponse | undefined;
}> = ({ entity, stageData, onEdit, status = null, profile }) => {
  const { t } = useTranslation();

  const step2_2Data: DetailRow[] = useMemo(
    () =>
      stageData
        ? [
            {
              name: "common.sector",
              value: (
                <>
                  {stageData.sector != null ? (
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={stageData.sector}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.licenseCategory",
              value: (
                <>
                  {stageData.licenseCategory != null ? (
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={stageData.licenseCategory}
                    />
                  ) : null}
                </>
              ),
            },

            {
              name: "common.licenseNumber",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.licenseNumber}
                </Typography>
              ),
            },

            {
              name: "common.nameOfRegulator",
              value: (
                <>
                  {stageData.nameOfRegulator != null ? (
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={stageData.nameOfRegulator}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.jurisdictionOfRegulator",
              value: (
                <>
                  {stageData.jurisdictionOfRegulator != null ? (
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={stageData.jurisdictionOfRegulator}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.typeOfListing",
              value: (
                <>
                  {stageData.typeOfListing != null ? (
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={stageData.typeOfListing}
                    />
                  ) : null}
                </>
              ),
            },
            // {
            //   name: "common.nameOfRegulator",
            //   value: (
            //     <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
            //       {stageData.nameOfRegulator}
            //     </Typography>
            //   ),
            // },

            // {
            //   name: "common.jurisdictionOfRegulator",
            //   value: (
            //     <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
            //       {stageData.jurisdictionOfRegulator}
            //     </Typography>
            //   ),
            // },
            {
              name: "common.undertakeAnyTradingActivities",
              value: (
                <>
                  {stageData.tradingActivities != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.tradingActivities)}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.transferredFromAnotherJurisdiction",
              value: (
                <>
                  {stageData.transferredFromAnotherJurisdiction != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.transferredFromAnotherJurisdiction
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.transferredFromJurisdiction",
              value: (
                <>
                  {stageData.transferredFromJurisdiction != null ? (
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={stageData.transferredFromJurisdiction}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.transferredToAnotherJurisdiction",
              value: (
                <>
                  {stageData.transferredToAnotherJurisdiction != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.transferredToAnotherJurisdiction
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.transferredToJurisdiction",
              value: (
                <>
                  {stageData.transferredToJurisdiction != null ? (
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={stageData.transferredToJurisdiction}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.tradingOrHolding",
              value: (
                <>
                  {stageData.tradingOrHolding != null ? (
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={stageData.tradingOrHolding}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.isAddressDifferentFromTheRegisteredAddress",
              value: (
                <>
                  {stageData.differentRegisteredAddress != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.differentRegisteredAddress
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.operateInAnyOfTheseHighRiskIndustries",
              value: (
                <Grid container rowSpacing={1}>
                  {stageData.highRiskIndustries?.split(",").map((hr) => (
                    <Grid item xs={12}>
                      <Chip
                        key={hr}
                        color="primary"
                        variant="outlined"
                        label={hr}
                      />
                    </Grid>
                  ))}
                </Grid>
              ),
            },

            {
              name: "common.customerOperatesACashIntensiveBusiness",
              value: (
                <>
                  {stageData.cashIntensiveBusiness != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.cashIntensiveBusiness)}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.cashIntensiveBusinessInfo",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.cashIntensiveBusinessInfo}
                </Typography>
              ),
            },
            {
              name: "common.operateIndualUseItems",
              value: (
                <>
                  {stageData.dualUseItems != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.dualUseItems)}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.dualUseItemsInfo",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.dualUseItemsInfo}
                </Typography>
              ),
            },

            {
              name: "common.descriptionOfThePrincipalBusinessActivities",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.businessActivitiesDescription}
                </Typography>
              ),
            },
            {
              name: "common.indicationOfTheExpectedTotalIncomeGenerated",
              value: (
                <>
                  {stageData.expectedTotalIncome.currency && (
                    <Chip
                      sx={{ pl: "5px", m: 1 }}
                      label={`${getSymbolFromCurrency(
                        stageData.expectedTotalIncome.currency.toLocaleUpperCase()
                      )} ${(
                        Math.round(
                          Number(stageData.expectedTotalIncome.value) * 100
                        ) / 100
                      ).toFixed(2)}`}
                    />
                  )}
                </>
              ),
            },
            {
              name: "common.indicationOfJurisdictionWhereActivities",
              value: (
                <Grid container rowSpacing={1}>
                  {stageData.activitiesTakingPlaceIn?.split(",").map((hr) => (
                    <Grid item xs={12}>
                      <Chip
                        key={hr}
                        color="primary"
                        variant="outlined"
                        label={hr}
                      />
                    </Grid>
                  ))}
                </Grid>
              ),
            },
            // {
            //   name: t("common.indicationOfJurisdictionWhereActivitiesInfo"),
            //   value: (
            //     <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
            //       {stageData.activitiesTakingPlaceInNote}
            //     </Typography>
            //   ),
            // },
            {
              name: "common.areAnyFundsHeldOnTrust",
              value: (
                <>
                  {stageData.fundsHeld != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.fundsHeld)}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.infoAboutFundsHeld",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.fundsHeldInfo}
                </Typography>
              ),
            },

            {
              name: "common.yearEnd",
              value: stageData.yearEnd != null && (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.yearEnd?.length > 0 &&
                    format(parseISO(stageData.yearEnd), "dd-MM-yyyy")}
                </Typography>
              ),
            },
            {
              name: "common.amountOfFundsHeldAtYearEnd",
              value: (
                <>
                  {stageData?.amountAtYearEnd.currency && (
                    <Chip
                      sx={{ pl: "5px", m: 1 }}
                      label={`${getSymbolFromCurrency(
                        stageData.amountAtYearEnd.currency.toLocaleUpperCase()
                      )} ${(
                        Math.round(
                          Number(stageData.amountAtYearEnd.value) * 100
                        ) / 100
                      ).toFixed(2)}`}
                    />
                  )}
                </>
              ),
            },
          ]
        : [],
    [stageData]
  );

  return (
    <>
      {entity ? (
        <>
          <Card sx={{ marginTop: "12px" }}>
            <CardHeader
              disableTypography
              title={
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tooltip title={t("common.stage_2_2")} enterDelay={1000}>
                    <Typography variant="h6">
                      {t("common.businessActivity")}
                    </Typography>
                  </Tooltip>
                  <Grid
                    item
                    // sm={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {((status !== PENDING_APPROVAL_CLIENT_STATUS &&
                      status !== ACTIVE_CLIENT_STATUS &&
                      status !== CLOSED_CLIENT_STATUS) ||
                      (status === PENDING_APPROVAL_CLIENT_STATUS &&
                        entity.reviewerId === profile?.id)) && (
                      <PermissionGuard permission="UPDATE_ENTITY">
                        <>
                          <Button
                            sx={[
                              {
                                whiteSpace: "nowrap",
                              },
                              (theme) => ({
                                [theme.breakpoints.down("sm")]: {
                                  minWidth: "100%",
                                },
                                [theme.breakpoints.up("sm")]: {
                                  minWidth: "auto",
                                },
                              }),
                            ]}
                            variant="contained"
                            onClick={() => onEdit(true)}
                          >
                            {t("common.edit")}
                          </Button>
                        </>
                      </PermissionGuard>
                    )}
                  </Grid>
                </Box>
              }
            />

            <Grid container rowSpacing={3} columnSpacing={2} sx={{ p: "16px" }}>
              {step2_2Data.map(({ name, value }) => (
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  key={name}
                  sx={{ paddingTop: "12px !important" }}
                >
                  <Grid container>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {t(name)}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      {value}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Card>
        </>
      ) : null}
    </>
  );
};
