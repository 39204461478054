import { ApiCreateSignatoryRequest, ApiSignatory, ApiUpdateSignatoryRequest } from ".";
import { CreateSignatoryFormValues, UpdateSignatoryFormValues } from "../../screens/Signatories/forms";
import { dataUrlToFile, fileToBase64 } from "../../utils/file";

export const mapCreateSignatoryFormValuesToApiCreateSignatoryRequest = async (
    createDto: CreateSignatoryFormValues
): Promise<ApiCreateSignatoryRequest> => ({
    displayName: createDto.displayName,
    imageData: await fileToBase64(createDto.imageData as File),
});

export const mapUpdateSignatoryFormValuesToApiUpdateSignatoryRequest = async (
    updateDto: UpdateSignatoryFormValues
): Promise<ApiUpdateSignatoryRequest> => ({
    id: updateDto.id,
    displayName: updateDto.displayName,
    imageData: await fileToBase64(updateDto.imageData as File),
});

// export const mapApiSignatoryToUpdateSignatoryFormValues = async (
//     apiData: ApiSignatory
// ): Promise<UpdateSignatoryFormValues> => ({
//     id: apiData.id,
//     displayName: apiData.displayName,
//     imageData: await dataUrlToFile(apiData.imageData, 'file.png'),
// });


