import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { usePermissionGroupsListQuery } from "../../../../models/permissionGroups";

export interface PermissionGroupsProps {
  disabled: boolean;
  label?: string;
  size?: "small" | "medium";
  margin?: "none" | "dense" | "normal";
  //   accountHolderId: string;
}
export const PermissionGroupsField: FC<
  PermissionGroupsProps & FieldProps<string[], string[]>
> = ({
  disabled = false,
  label = "common.permissions",
  size = "medium",
  margin,
  field,
  form: { values, touched, errors, handleBlur, setFieldValue },
}) => {
  const { t } = useTranslation();

  const { data: permissionGroups, isLoading } = usePermissionGroupsListQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <>
      {isLoading && <CircularProgress />}
      {permissionGroups && (
        <FormControl fullWidth margin={margin}>
          <InputLabel
            htmlFor={`${field.name}`}
            error={Boolean(
              getIn(touched, field.name) && !!getIn(errors, field.name)
            )}
          >
            {t(label)}
          </InputLabel>
          <Field
            labelId={field.name}
            error={Boolean(
              getIn(touched, field.name) && !!getIn(errors, field.name)
            )}
            component={Select}
            name={`${field.name}`}
            fullWidth
            multiple
            value={getIn(values, field.name)}
            onBlur={handleBlur(`${field.name}`)}
            onChange={(e: SelectChangeEvent<any>) => {
              setFieldValue(field.name, e.target.value);
            }}
            input={<OutlinedInput label={t(label)} />}
            disabled={disabled}
            size={size}
            variant="outlined"
            renderValue={(selected: string[]) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected
                  .filter((x) =>
                    permissionGroups
                      //   .filter((x) => !x.userDefault)
                      .some((y) => y.id === x)
                  )
                  .map((value) => (
                    <Chip
                      key={value}
                      label={permissionGroups.find((x) => x.id === value)?.name}
                    />
                  ))}
              </Box>
            )}
          >
            {permissionGroups
              //   .filter((x) => !x.userDefault)
              .map((permissionGroup) => (
                <MenuItem key={permissionGroup.id} value={permissionGroup.id}>
                  {permissionGroup.name}
                </MenuItem>
              ))}
          </Field>
          <FormHelperText error>
            {getIn(touched, field.name) && t(getIn(errors, field.name))}
          </FormHelperText>
        </FormControl>
      )}
    </>
  );
};
