
import { ApiBiller, PagedBillersRequest, PageApiBiller, ApiUpdateBillerRequest, ApiCreateBillerRequest } from '.';
import { removeEmpty } from '../../utils/removeEmpty';
import { apiWithTag } from '../emptySplitApi';


export const billersApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        billers: builder.query<PageApiBiller, PagedBillersRequest>({
            query: ({ ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    method: 'GET',
                    params: parameters,
                    url: 'billers',
                }
            },
            providesTags: ['Biller']
        }),
        billersList: builder.query<ApiBiller[], {}>({
            query: () => {
                return {
                    method: 'GET',
                    url: 'billers/list',
                }
            },
            providesTags: ['Biller']
        }),
        biller: builder.query<ApiBiller, { id: string }>({
            query: ({ id }) => ({
                method: 'GET',
                url: `billers/${id}`,

            }),
            providesTags: ['Biller']
        }),
        createBiller: builder.mutation<void, ApiCreateBillerRequest>({
            query: ({ tenantIdForHeader, ...biller }) => ({
                headers: (tenantIdForHeader.length > 0) ? {
                    'X-TENANT-ID': tenantIdForHeader
                } : {},
                url: 'billers',
                method: 'POST',
                body: biller
            }),
            invalidatesTags: ['Biller']
        }),
        updateBiller: builder.mutation<ApiBiller, ApiUpdateBillerRequest>({
            query: ({ id, ...rest }) => ({
                body: { ...rest },
                method: 'PUT',
                url: `billers/${id}`,
            }),
            invalidatesTags: ['Biller']
        }),
        // registerBillerConfirm: builder.mutation<void, ApiConfirmRegistrationRequest>({
        //     query: ({ ...confirmRegistration }) => ({
        //         body: confirmRegistration,
        //         headers: {
        //             Authorization: `Basic ${config.apiKey}`,
        //         },
        //         method: 'POST',
        //         url: 'registration/confirm',
        //     }),
        //     invalidatesTags: ['Me']
        // }),

    })
})

export const {
    useBillersQuery,
    useLazyBillersQuery,
    useBillersListQuery,
    useBillerQuery,
    useCreateBillerMutation,
    useUpdateBillerMutation,
    // useCreateBillerConfirmMutation,
} = billersApi;