import { FC } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Loader } from "../../components/Loader";

import { persistor, store } from "./store";

export const ReduxProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      {children}
    </PersistGate>
  </Provider>
);
