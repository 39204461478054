import * as Yup from "yup";

// export const userValidationSchema = () => Yup.string()
//     .required('validations.userIsRequired')




export const userValidationSchema = (validationMessage?: string) =>
    Yup.object()
        .required(validationMessage || 'validations.userIsRequired')
        .nullable()
        .test(
            'null-check',
            validationMessage || 'validations.userIsRequired',
            (biller) => biller != null,
        )

