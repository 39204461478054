import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  FormControl,
  TextField,
} from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Nullable } from "../../../../utils/types";
import {
  ApiUserResponse,
  useAccountManagersListQuery,
} from "../../../../models/users";

export interface AccountManagerFieldProps {
  disabled: boolean;
  accountManagerId?: string;
  label?: string;
  onChange: (value: ApiUserResponse) => void;
}
export const AccountManagerField: FC<
  AccountManagerFieldProps & FieldProps<ApiUserResponse[], ApiUserResponse[]>
> = ({
  disabled = false,
  accountManagerId,
  label = "common.accountManager",
  onChange = () => null,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();

  const { data: accountManagerIds } = useAccountManagersListQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  // Auto populate Account manager field when there is only one
  useEffect(() => {
    if (accountManagerIds && accountManagerIds?.length === 1) {
      setFieldValue(`${field.name}`, accountManagerIds[0]);
    }
  }, [accountManagerIds, field.name, setFieldValue]);

  return (
    <>
      {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(getIn(values, field.name), null, 2)}</pre> */}
      {accountManagerIds && accountManagerIds?.length > 0 && (
        <FormControl fullWidth margin="normal">
          <Field
            component={Autocomplete}
            name={`${field.name}`}
            fullWidth
            value={
              accountManagerIds.find(
                (x) => x.id === getIn(values, field.name)?.id
              ) || null
            }
            onBlur={handleBlur(`${field.name}`)}
            onChange={(e: any, value: ApiUserResponse) => {
              setFieldValue(`${field.name}`, value);
              onChange(value);
            }}
            disabled={disabled || accountManagerIds?.length === 1}
            options={accountManagerIds}
            autoHighlight
            variant="outlined"
            filterOptions={(
              options: Array<ApiUserResponse>,
              state: { inputValue: string }
            ): Array<ApiUserResponse> => {
              return options.filter(
                (x) =>
                  x.firstName
                    ?.toLowerCase()
                    ?.includes(state.inputValue.toLowerCase()) ||
                  x.lastName
                    ?.toLowerCase()
                    ?.includes(state.inputValue.toLowerCase()) ||
                  x.email
                    ?.toLowerCase()
                    ?.includes(state.inputValue.toLowerCase()) ||
                  x.activeDirectoryId
                    ?.toLowerCase()
                    ?.includes(state.inputValue.toLowerCase()) ||
                  x.id?.toLowerCase()?.includes(state.inputValue.toLowerCase())
              );
            }}
            isOptionEqualToValue={(
              option: ApiUserResponse,
              value: Nullable<ApiUserResponse>
            ) => option.id === value?.id}
            getOptionLabel={(option: ApiUserResponse) =>
              `${option?.firstName} ${option?.lastName}`
            }
            renderOption={(props: any, option: ApiUserResponse) => (
              <Box key={option} component="li" {...props}>
                {option?.firstName} {option?.lastName} {/*({option.id}) */}
                &nbsp;&nbsp;
                {/* <AccountManagerStatusLabel status={option.status} /> */}
              </Box>
            )}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <>
                <TextField
                  {...params}
                  label={t(label)}
                  error={Boolean(
                    getIn(touched, field.name) && !!getIn(errors, field.name)
                  )}
                  helperText={
                    getIn(touched, field.name) && t(getIn(errors, field.name))
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                  InputProps={{
                    ...params.InputProps,
                    // ...(values[field.name]?.id
                    //     ? {
                    //         startAdornment: (
                    //             <InputAdornment position="end">
                    //                 <>
                    //                     <Box sx={{ ml: '8px' }}>
                    //                         {/* {values[field.name]?.displayName} ({values[field.name]?.id}) &nbsp;&nbsp; */}
                    //                         <AccountManagerStatusLabel status={values[field.name]?.status} />
                    //                     </Box>
                    //                 </>
                    //             </InputAdornment>
                    //         ),
                    //     }
                    //     : {}),
                  }}
                />
              </>
            )}
          ></Field>
        </FormControl>
      )}
    </>
  );
};
