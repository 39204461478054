import { removeEmpty } from '../../utils/removeEmpty';
import { apiWithTag } from '../emptySplitApi';
import { ApiEnumeration, ApiEnumerationCreate, ApiEnumerationUpdate, EnumerationFilter } from '.';


export const enumerationsApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        enumerations: builder.query<ApiEnumeration[], EnumerationFilter>({
            query: ({ ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    method: 'GET',
                    params: { sort: 'value,asc', ...parameters },
                    url: 'enumerations',
                }
            },
            providesTags: ['Enumeration'],
            // transformResponse: (response: ApiEnumeration[]) => {
            //     const result: ApiEnumeration[] = response.map(r => ({
            //         ...r, value: r.value
            //             // .toLowerCase()
            //             .split('_')
            //             // .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            //             .join(' ')
            //     }))
            //     console.log(JSON.stringify(result));
            //     return result;
            // },
        }),

        enumeration: builder.query<ApiEnumeration, { id: string }>({
            query: ({ id }) => ({
                method: 'GET',
                url: `enumerations/${id}`,

            }),
            providesTags: ['Enumeration'],
            // transformResponse: (response: ApiEnumeration) => {
            //     const normalizedValue = response.value
            //         // .toLowerCase()
            //         .split('_')
            //         // .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            //         .join(' ');
            //     return ({ ...response, value: normalizedValue });
            // },
        }),
        createEnumeration: builder.mutation<ApiEnumeration, ApiEnumerationCreate>({
            query: ({ ...enumeration }) => ({
                body: { ...enumeration },
                method: 'POST',
                url: 'enumerations',
            }),
            invalidatesTags: ['Enumeration']
        }),
        updateEnumeration: builder.mutation<ApiEnumeration, ApiEnumerationUpdate>({
            query: ({ id, ...enumeration }) => ({
                body: { ...enumeration },
                method: 'PUT',
                url: `enumerations/${id}`,
            }),
            invalidatesTags: ['Enumeration']
        }),
        deleteEnumeration: builder.mutation<ApiEnumeration, { id: string }>({
            query: ({ id }) => ({
                method: 'DELETE',
                url: `enumerations/${id}`,
            }),
            invalidatesTags: ['Enumeration']
        }),
    })
})

export const {
    useEnumerationsQuery,
    useEnumerationQuery,
    useCreateEnumerationMutation,
    useUpdateEnumerationMutation,
    useDeleteEnumerationMutation,
} = enumerationsApi;