import { Box, FormHelperText, TextField, Typography } from "@mui/material";
import { FieldProps, getIn } from "formik";
import { FC, useCallback, FocusEvent, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { verificationCodeInitialValue } from ".";
import { localConfig } from "../../../../services/config";

export interface VerificationCodeFieldProps {
  disabled: boolean;
  label?: string;
  size?: "small" | "medium";
  margin?: "none" | "dense" | "normal";
  onBlur?: (e: any) => void;
  onChange?: (e: any) => void;
  queryParam: string;
}
export const VerificationCodeField: FC<
  VerificationCodeFieldProps & FieldProps<string, string>
> = ({
  disabled = false,
  label = "common.verificationCode",
  size = "medium",
  margin,
  onBlur,
  onChange,
  queryParam = "code",
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();

  const onBlurHandler = useCallback((e: FocusEvent<HTMLInputElement>) => {
    handleBlur(field.name)(e);
    typeof onBlur === "function" && onBlur(e);
  }, []);

  const onChangeHandler = useCallback((e: FocusEvent<HTMLInputElement>) => {
    handleChange(field.name)(e);
    typeof onChange === "function" && onChange(e);
  }, []);

  const itemsRef = useRef<HTMLDivElement[]>([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(
      0,
      localConfig.VERIFICATION_CODE_LENGTH
    );
  }, []);

  const lastFocusIndex = localConfig.VERIFICATION_CODE_LENGTH - 1;

  return (
    <>
      {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(field, null, 2)}</pre> */}

      {/* <FormControl margin={margin} variant="outlined" fullWidth>
        <Field
          label={t(label)}
          margin={margin}
          variant="outlined"
          component={TextField}
          disabled={disabled}
          type="text"
          value={getIn(values, field.name)}
          error={Boolean(
            getIn(touched, field.name) && getIn(errors, field.name)
          )}
          helperText={
            getIn(touched, field.name) && t(getIn(errors, field.name))
          }
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          size={size}
        />
      </FormControl> */}
      {searchParams.get(queryParam) != null && (
        <Box sx={{ typography: "body2", mb: "32px" }}>
          Please enter new password. Use at least{" "}
          {localConfig.PASSWORD_MIN_LENGTH} characters.
        </Box>
      )}
      {searchParams.get(queryParam) == null && (
        <>
          <Box sx={{ typography: "body2", mb: "32px" }}>
            Enter verification {localConfig.VERIFICATION_CODE_LENGTH}-digit code
            from Email and your new password below. Use at least{" "}
            {localConfig.PASSWORD_MIN_LENGTH} characters.
          </Box>
          <Typography
            color="textSecondary"
            sx={{
              mb: 1,
            }}
            variant="subtitle2"
          >
            Email code
          </Typography>

          <Box
            sx={{
              columnGap: "16px",
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              maxWidth: "300px",
              py: 1,
            }}
          >
            {verificationCodeInitialValue().map((ref, i) => (
              <TextField
                error={Boolean(
                  Array.isArray(getIn(touched, field.name)) &&
                    getIn(touched, field.name).length === 6 &&
                    getIn(errors, field.name)
                )}
                fullWidth
                inputRef={(el) => (itemsRef.current[i] = el)}
                key={`${field.name}-${i}`}
                name={`${field.name}[${i}]`}
                onBlur={handleBlur}
                onKeyDown={(event) => {
                  //   alert(event.code);
                  //   if (event.code === "BACKSPACE") {

                  //   }
                  if (event.code === "ENTER") {
                    if (getIn(values, field.name)[i]) {
                      setFieldValue(`${field.name}[${i}]`, "");
                      return;
                    }

                    if (i > 0) {
                      setFieldValue(`${field.name}[${i}]`, "");
                      itemsRef.current[i - 1].focus();
                      return;
                    }
                  }

                  if (Number.isInteger(parseInt(event.key, 10))) {
                    setFieldValue(`${field.name}[${i}]`, event.key);

                    if (i < lastFocusIndex) {
                      itemsRef.current[i + 1].focus();
                    }
                  }
                }}
                onPaste={(event) => {
                  const paste = event.clipboardData.getData("text");
                  const pasteArray = paste.split("");

                  if (pasteArray.length !== 6) {
                    return;
                  }

                  let valid = true;

                  pasteArray.forEach((x) => {
                    if (!Number.isInteger(parseInt(x, 10))) {
                      valid = false;
                    }
                  });

                  if (valid) {
                    setFieldValue(field.name, pasteArray);
                    itemsRef.current[5].focus();
                  }
                }}
                value={getIn(values, field.name)[i]}
                sx={{
                  display: "inline-block",
                  textAlign: "center",
                  "& .MuiInputBase-input": {
                    borderRadius: "16px",
                    textAlign: "center",
                  },
                }}
                variant="outlined"
              />
            ))}
          </Box>
        </>
      )}
      {Boolean(
        Array.isArray(getIn(touched, field.name)) &&
          getIn(touched, field.name).length === 6 &&
          getIn(errors, field.name)
      ) && (
        <FormHelperText error sx={{ mx: "14px" }}>
          {Array.isArray(getIn(errors, field.name)) &&
            getIn(errors, field.name).find((x: string) => x !== undefined)}
        </FormHelperText>
      )}
    </>
  );
};
