import { ApiEntity, ApiEntityRelationship, ApiStageOwnershipControl, STAGE_2_3_TYPE } from "../../../../../../models/entities"
import {
    EntityRelationshipFormValues,
    OwnershipControlFormValues
} from "."
import { ApiEnumeration, DETAILS_OF_SCHEME, LAYER_OF_OWNERSHIP, RELATIONSHIP, STATUS_OF_APPLICATION } from "../../../../../../models/enumerations"
import { booleanToYesNo } from "../../../../../../forms";


export const mapApiEntityRelationshipToEntityRelationshipFormValues = (
    data: ApiEntityRelationship,
    enumerables: ApiEnumeration[],
    entities: ApiEntity[],
    // tenants: ApiTenantResponse[]
): EntityRelationshipFormValues => ({
    id: data.id,
    tenantId: data.tenantId,
    entityId: data.entityId,
    // entity: entities.find(e => e.id === data.entityId),
    relatedEntity: entities.find(e => e.id === data.relatedEntityId)!,
    relationship: data.relationship ? enumerables.filter(x => x.type === RELATIONSHIP).find(x => x.value === data.relationship)! : null,
    layerOfOwnership: data.layerOfOwnership ? enumerables.filter(x => x.type === LAYER_OF_OWNERSHIP).find(x => x.value === data.layerOfOwnership)! : null,
    extentOfOwnership: data.extentOfOwnership || 0,
    natureOfOwnership: data.natureOfOwnership || '',
    indirectOwnershipDesc: data.indirectOwnershipDesc || '',
    influenceDescription: data.influenceDescription,
    hasSchemeBenefits: data.hasSchemeBenefits != null ? booleanToYesNo(data.hasSchemeBenefits) : '',
    schemeDetails: data.schemeDetails ? enumerables.filter(x => x.type === DETAILS_OF_SCHEME).find(x => x.value === data.schemeDetails)! : null,
    schemeStatus: data.schemeStatus ? enumerables.filter(x => x.type === STATUS_OF_APPLICATION).find(x => x.value === data.schemeStatus)! : null,
});


export const ownershipControlFormValuesInitialValue = (
    entity: ApiEntity,
    enumerables: ApiEnumeration[],
    entities: ApiEntity[]): OwnershipControlFormValues => {
    // Get Stage Data
    const stageData: ApiStageOwnershipControl = entity.stages.find((s) => s.stageType === STAGE_2_3_TYPE) as ApiStageOwnershipControl;
    // If draft is available use that
    const data: ApiStageOwnershipControl = stageData.draftData ? stageData.draftData as ApiStageOwnershipControl : stageData;
    return {
        stageType: STAGE_2_3_TYPE,
        stageStatus: data.stageStatus,
        id: entity.id,

        hasLegalArrangements: data.hasLegalArrangements != null ? booleanToYesNo(data.hasLegalArrangements) : '',
        hasNonProfitEntities: data.hasNonProfitEntities != null ? booleanToYesNo(data.hasNonProfitEntities) : '',
        hasShellCompanies: data.hasShellCompanies != null ? booleanToYesNo(data.hasShellCompanies) : '',
        hasNonCooperativeJurisdictions: data.hasNonCooperativeJurisdictions != null ? booleanToYesNo(data.hasNonCooperativeJurisdictions) : '',
        relatedEntities: data.relatedEntities ? data.relatedEntities.map(relatedEntity => mapApiEntityRelationshipToEntityRelationshipFormValues(relatedEntity, enumerables, entities)) : [],
    }
}

export const relatedEntityInitialValues = (): EntityRelationshipFormValues => ({
    id: '',
    tenantId: '',
    entityId: '',
    relatedEntity: null,
    relationship: null,
    layerOfOwnership: null,
    extentOfOwnership: 0,
    natureOfOwnership: '',
    indirectOwnershipDesc: '',
    influenceDescription: '',
    hasSchemeBenefits: '',
    schemeDetails: null,
    schemeStatus: null,
});