import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '../../utils/types';

import { ApiTenantResponse } from './types-api';

export type TenantState = {
    tenant: Nullable<ApiTenantResponse>;
};

const initialState: TenantState = {
    tenant: null
};

const resetTenentToInitial = () => {
    // alert('reset')
    return initialState;
};

export const tenantSlice = createSlice({
    name: 'tenant',
    initialState,
    reducers: {
        setTenant: (
            state,
            {
                payload: { tenant },
            }: PayloadAction<{
                tenant: ApiTenantResponse;
            }>,
        ) => {
            state.tenant = tenant;
        },
        resetTenant: resetTenentToInitial,
    },
});

export const {
    setTenant,
    resetTenant
} = tenantSlice.actions;

