import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ApiEntity, ApiStageAml } from "../../../../../../../models/entities";

import { PermissionGuard } from "../../../../../../../models/auth";
import getSymbolFromCurrency from "currency-symbol-map";
import { CountryFlag } from "../../../../../../../components/CountryFlag";
import { getCountryByCode } from "../../../../../../../utils/countries";
import { StatusLabel } from "../../../../../../../components/StatusLabel";
import {
  ApiEntityStatus,
  YesNo,
  booleanToYesNo,
  yesNosColourMap,
  ACTIVE_CLIENT_STATUS,
  CLOSED_CLIENT_STATUS,
  PENDING_APPROVAL_CLIENT_STATUS,
} from "../../../../../../../forms";
import { ApiUserResponse } from "../../../../../../../models/users";
import { Nullable } from "../../../../../../../utils/types";

interface DetailRow {
  name: string;
  value: any;
}

export const Stage3_1View: FC<{
  entity: ApiEntity;
  stageData: ApiStageAml;
  onEdit: (value: boolean) => void;
  status: Nullable<ApiEntityStatus>;
  profile: ApiUserResponse | undefined;
}> = ({ entity, stageData, onEdit, status = null, profile }) => {
  const { t } = useTranslation();

  const step3_1Data: DetailRow[] = useMemo(
    () =>
      stageData
        ? [
            {
              name: "common.refNo",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData?.refNo}
                </Typography>
              ),
            },
            {
              name: "common.transactionDate",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.transactionDate != null
                    ? format(
                        new Date(stageData.transactionDate),
                        "dd/MM/yyyy HH:mm:ss"
                      )
                    : ""}
                </Typography>
              ),
            },
            {
              name: "common.transactionDescription",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.transactionDescription}
                </Typography>
              ),
            },
            {
              name: "common.transactionAmount",
              value: (
                <>
                  {stageData.transactionAmount.currency && (
                    <Chip
                      sx={{ pl: "5px", m: 1 }}
                      label={`${getSymbolFromCurrency(
                        stageData.transactionAmount.currency.toLocaleUpperCase()
                      )} ${(
                        Math.round(
                          Number(stageData.transactionAmount.value) * 100
                        ) / 100
                      ).toFixed(2)}`}
                    />
                  )}
                </>
              ),
            },
            {
              name: "common.originalTransactionCurrency",
              value: (
                <>
                  {stageData.originalTransactionCurrency && (
                    <Chip
                      sx={{ pl: "5px", m: 1 }}
                      label={`${getSymbolFromCurrency(
                        stageData.originalTransactionCurrency.toLocaleUpperCase()
                      )}`}
                    />
                  )}
                </>
              ),
            },
            {
              name: "common.foreignAmount",
              value: (
                <>
                  {stageData.foreignAmount.currency && (
                    <Chip
                      sx={{ pl: "5px", m: 1 }}
                      label={`${getSymbolFromCurrency(
                        stageData.foreignAmount.currency.toLocaleUpperCase()
                      )} ${(
                        Math.round(
                          Number(stageData.foreignAmount.value) * 100
                        ) / 100
                      ).toFixed(2)}`}
                    />
                  )}
                </>
              ),
            },
            {
              name: "common.transactionLocation",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData?.transactionLocation}
                </Typography>
              ),
            },
            {
              name: "common.role",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData?.role}
                </Typography>
              ),
            },
            {
              name: "common.firstName",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData?.entityFirstName}
                </Typography>
              ),
            },
            {
              name: "common.lastName",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData?.entityLastName}
                </Typography>
              ),
            },
            {
              name: "common.country",
              value: (
                <>
                  {stageData?.country && (
                    <Chip
                      sx={{ pl: "5px", m: 1 }}
                      label={`${getCountryByCode(stageData.country)?.name} (${
                        stageData.country
                      })`}
                      icon={<CountryFlag countryCode={stageData.country} />}
                    />
                  )}
                </>
              ),
            },
            {
              name: "common.reasonTransactionSuspicious",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData?.reasonTransactionSuspicious}
                </Typography>
              ),
            },
            {
              name: "common.actionsTakenTransactionDetail",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData?.actionsTakenTransactionDetail}
                </Typography>
              ),
            },
            {
              name: "common.hasTransactionLegitimate",
              value: (
                <>
                  {stageData?.hasTransactionLegitimate != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.hasTransactionLegitimate
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasTransactionLegitimate == true
              ? [
                  {
                    name: "common.rationaleMlroConclusion",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData?.rationaleForConclusion}
                      </Typography>
                    ),
                  },
                ]
              : []),
            {
              name: "common.dateOfReport",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.dateOfReport != undefined &&
                    stageData.dateOfReport?.length > 0 &&
                    format(parseISO(stageData.dateOfReport), "dd-MM-yyyy")}
                </Typography>
              ),
            },
            {
              name: "common.hasMlroAgreementPreparer",
              value: (
                <>
                  {stageData?.hasMlroAgreementPreparer != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.hasMlroAgreementPreparer
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasMlroAgreementPreparer == true
              ? [
                  {
                    name: "common.rationaleMlroConclusion",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData?.rationaleMlroConclusion}
                      </Typography>
                    ),
                  },
                ]
              : []),
            {
              name: "common.hasFurtherEvidence",
              value: (
                <>
                  {stageData?.hasFurtherEvidence != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasFurtherEvidence)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasFurtherEvidence == true
              ? [
                  {
                    name: "common.furtherEvidenceInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData?.furtherEvidenceInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),
            {
              name: t("common.dateMlroReview"),
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.dateMlroReview != undefined &&
                    stageData.dateMlroReview?.length > 0 &&
                    format(parseISO(stageData.dateMlroReview), "dd-MM-yyyy")}
                </Typography>
              ),
            },
            {
              name: "common.hasInternalReportInstigatedSuspiciousTransaction",
              value: (
                <>
                  {stageData?.hasInternalReportInstigatedSuspiciousTransaction !=
                  null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.hasInternalReportInstigatedSuspiciousTransaction
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.dateStrSubmission",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.dateStrSubmission != undefined &&
                    stageData.dateStrSubmission?.length > 0 &&
                    format(parseISO(stageData.dateStrSubmission), "dd-MM-yyyy")}
                </Typography>
              ),
            },
            {
              name: "common.outcomeStr",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData?.outcomeStr}
                </Typography>
              ),
            },
          ]
        : [],
    [entity, stageData, t]
  );

  return (
    <>
      {entity ? (
        <>
          <Card sx={{ marginTop: "12px" }}>
            <CardHeader
              disableTypography
              title={
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tooltip title={t("common.stage_3_1")} enterDelay={1000}>
                    <Typography variant="h6">
                      {t("common.antiMoneyLaundering")}
                    </Typography>
                  </Tooltip>
                  <Grid
                    item
                    // sm={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {((status !== PENDING_APPROVAL_CLIENT_STATUS &&
                      status !== ACTIVE_CLIENT_STATUS &&
                      status !== CLOSED_CLIENT_STATUS) ||
                      (status === PENDING_APPROVAL_CLIENT_STATUS &&
                        entity.reviewerId === profile?.id)) && (
                      <PermissionGuard permission="UPDATE_ENTITY">
                        <>
                          <Button
                            sx={[
                              {
                                whiteSpace: "nowrap",
                              },
                              (theme) => ({
                                [theme.breakpoints.down("sm")]: {
                                  minWidth: "100%",
                                },
                                [theme.breakpoints.up("sm")]: {
                                  minWidth: "auto",
                                },
                              }),
                            ]}
                            variant="contained"
                            onClick={() => onEdit(true)}
                          >
                            {t("common.edit")}
                          </Button>
                        </>
                      </PermissionGuard>
                    )}
                  </Grid>
                </Box>
              }
            />

            <Grid container rowSpacing={3} columnSpacing={2} sx={{ p: "16px" }}>
              {step3_1Data.map(({ name, value }) => (
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  key={name}
                  sx={{ paddingTop: "12px !important" }}
                >
                  <Grid container>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {t(name)}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      {value}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Card>
        </>
      ) : null}
    </>
  );
};
