export const TITLE = 'TITLE';
// export const SERVICE = 'SERVICE';
export const LEGAL_ENTITY_TYPE = 'LEGAL_ENTITY_TYPE';
export const GENDER = 'GENDER';
export const INDUSTRY = 'INDUSTRY';
export const SECTOR = 'SECTOR';
export const LICENSE_CATEGORY = 'LICENSE_CATEGORY';
export const NAME_OF_REGULATOR = 'NAME_OF_REGULATOR';
export const JURISDICTION_OF_REGULATOR = 'JURISDICTION_OF_REGULATOR';
export const TYPE_OF_LISTING = 'TYPE_OF_LISTING';
export const INVESTMENT_TYPE = 'INVESTMENT_TYPE';
export const HIGH_RISK_INDUSTRY = 'HIGH_RISK_INDUSTRY';
export const RELATIONSHIP = 'RELATIONSHIP';
export const LAYER_OF_OWNERSHIP = 'LAYER_OF_OWNERSHIP';
export const DETAILS_OF_SCHEME = 'DETAILS_OF_SCHEME';
export const STATUS_OF_APPLICATION = 'STATUS_OF_APPLICATION';
export const PEP_RELATIONSHIP = 'PEP_RELATIONSHIP';
export const INTRODUCED_BY = 'INTRODUCED_BY';
export const DEALING_DIRECTLY_WITH = 'DEALING_DIRECTLY_WITH';
export const IDENTITY_DOCUMENT_TYPE = 'IDENTITY_DOCUMENT_TYPE';
export const PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS';
export const ESTIMATED_NET_WORTH = 'ESTIMATED_NET_WORTH';
export const REASON_FOR_NOT_ONBOARDING = 'REASON_FOR_NOT_ONBOARDING';
export const REASON_FOR_TERMINATION = 'REASON_FOR_TERMINATION';
export const CURRENCY = 'CURRENCY';
export const TAG = 'TAG';
export const VAT = 'VAT';
export const TOWN = 'TOWN';
export const QUOTE_REJECTION_REASON = 'QUOTE_REJECTION_REASON';
export const QUOTE_CANCELLATION_REASON = 'QUOTE_CANCELLATION_REASON';
export const RECEIPT_CANCELLATION_REASON = 'RECEIPT_CANCELLATION_REASON';


export const enumerationType = [
    TITLE,
    // SERVICE,
    LEGAL_ENTITY_TYPE,
    GENDER,
    INDUSTRY,
    SECTOR,
    LICENSE_CATEGORY,
    NAME_OF_REGULATOR,
    JURISDICTION_OF_REGULATOR,
    TYPE_OF_LISTING,
    INVESTMENT_TYPE,
    HIGH_RISK_INDUSTRY,
    RELATIONSHIP,
    LAYER_OF_OWNERSHIP,
    DETAILS_OF_SCHEME,
    STATUS_OF_APPLICATION,
    PEP_RELATIONSHIP,
    INTRODUCED_BY,
    DEALING_DIRECTLY_WITH,
    IDENTITY_DOCUMENT_TYPE,
    PROOF_OF_ADDRESS,
    ESTIMATED_NET_WORTH,
    REASON_FOR_NOT_ONBOARDING,
    REASON_FOR_TERMINATION,
    CURRENCY,
    TAG,
    VAT,
    TOWN,
    QUOTE_REJECTION_REASON,
    QUOTE_CANCELLATION_REASON,
    RECEIPT_CANCELLATION_REASON
] as const;
