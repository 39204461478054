import { ApiConfirmRegistrationRequest, ApiRegistrationRequest, ApiUpdateUserRequest } from ".";
import { UserVerificationConfirmFormValues } from "../../screens/Authentication/UserVerificationConfirm/forms";
import { RegisterUserFormValues, UpdateUserFormValues } from "../../screens/Users/forms";


export const mapRegisterUserFormValuesToApiRegistrationRequest = (
    registrationDto: RegisterUserFormValues
): ApiRegistrationRequest => ({
    email: registrationDto.email,
    firstName: registrationDto.firstName,
    lastName: registrationDto.lastName,
    activeDirectoryId: registrationDto.activeDirectoryId,
    permissionGroupIds: registrationDto.permissionGroupIds,
    admin: registrationDto.admin,
    tenantIdForHeader: registrationDto.tenantIdForHeader
});

export const mapUserVerificationConfirmFormValuesToApiConfirmRegistrationRequest = (
    userVerificationConfirmDto: UserVerificationConfirmFormValues
): ApiConfirmRegistrationRequest => ({
    verificationId: userVerificationConfirmDto.verificationId,
    verificationCode: userVerificationConfirmDto.verificationCode,
    password: userVerificationConfirmDto.password
});

export const mapUpdateUserFormValuesToApiUpdateUserRequest = (
    updateDto: UpdateUserFormValues
): ApiUpdateUserRequest => ({
    id: updateDto.id,
    email: updateDto.email,
    firstName: updateDto.firstName,
    lastName: updateDto.lastName,
    activeDirectoryId: updateDto.activeDirectoryId,
    status: updateDto.status,
    permissionGroupIds: updateDto.permissionGroupIds,
    admin: updateDto.admin
});
