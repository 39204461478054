import { createSlice } from '@reduxjs/toolkit';
import { authApi } from './api';
import { TenantPermission } from './types-api';

export type AuthState = {
    accessToken: string;
    tenantPermissions: TenantPermission[];
    expiresIn: number;
    userId: string;
    email: string;
    userStatus: string;
    expires_on: number;
    admin: boolean;
};

const initialState: AuthState = {
    accessToken: '',
    tenantPermissions: [],
    expiresIn: 0,
    userId: '',
    email: '',
    userStatus: '',
    expires_on: 0,
    admin: false
};

const resetCreds = () => initialState;

export const authSlice = createSlice({
    extraReducers: (builder) =>
        builder
            .addMatcher(authApi.endpoints.login.matchFulfilled, (_, action) => {
                const { access_token, expires_in, ...payload } = action.payload;
                return {
                    ...payload,
                    accessToken: access_token,
                    expiresIn: expires_in,
                };
            })
            .addMatcher(authApi.endpoints.refreshToken.matchFulfilled, (_, action) => {
                const { access_token, expires_in, ...payload } = action.payload;
                return {
                    ...payload,
                    accessToken: access_token,
                    expiresIn: expires_in,
                };
            })
            .addMatcher(authApi.endpoints.logout.matchFulfilled, resetCreds)
            .addMatcher(authApi.endpoints.logout.matchRejected, resetCreds)
    ,
    name: 'auth',
    initialState,
    reducers: {
        resetCredentials: resetCreds,
    },
});

export const { resetCredentials } = authSlice.actions;
