import { Route, Routes } from "react-router";
import { LazyLoadableScreen } from "../../../../navigation/LazyLoadableScreen";
import {
  PENDING_APPROVAL_INVOICE_STATUS,
  PENDING_APPROVAL_QUOTATION_STATUS,
} from "../../../../forms";
import { FC } from "react";
import { ApiUserResponse } from "../../../../models/users";

const InvoiceListScreen = LazyLoadableScreen(
  () => import("../../../../business/Entities/EntityTabs/Invoices/InvoiceList")
);
const InvoiceDetailsScreen = LazyLoadableScreen(
  () =>
    import("../../../../business/Entities/EntityTabs/Invoices/InvoiceDetails")
);
export const PendingInvoicesForApprovalRoutes: FC<{
  createdBy: ApiUserResponse;
}> = ({ createdBy }) => (
  <Routes>
    <Route
      path="/"
      element={
        <InvoiceListScreen
          status={PENDING_APPROVAL_INVOICE_STATUS}
          createdBy={createdBy}
          showCreateInvoice={false}
        />
      }
    />
    <Route
      path="/:invoiceId"
      element={
        <InvoiceDetailsScreen status={PENDING_APPROVAL_INVOICE_STATUS} />
      }
    />
  </Routes>
);
