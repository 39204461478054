import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  FormControl,
  TextField,
  debounce,
} from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ApiUserResponse,
  useLazyUsersQuery,
  useUsersListQuery,
} from "../../../../models/users";

import { Nullable } from "../../../../utils/types";
import { UserStatus, userStatusesColourMap } from "../userStatus";
import { StatusLabel } from "../../../../components/StatusLabel";

export interface UserFieldProps {
  disabled: boolean;
  userId?: string;
  label?: string;
  size?: "small" | "medium";
  margin?: "none" | "dense";
  onChange: (value: ApiUserResponse) => void;
  showErrorText: boolean;
  disableClearable: boolean;
}
export const UserField: FC<
  UserFieldProps & FieldProps<ApiUserResponse[], ApiUserResponse[]>
> = ({
  disabled = false,
  userId,
  label = "common.user",
  size = "medium",
  onChange = () => null,
  margin,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  showErrorText = true,
  disableClearable = false,
  ...props
}) => {
  const { t } = useTranslation();
  const { data: users } = useUsersListQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  const onChangeValue = (e: any, value: ApiUserResponse) => {
    setFieldValue(`${field.name}`, value);
    onChange(value);
  };

  const getOptionLabel = (option: ApiUserResponse) =>
    `${option?.firstName} ${option?.lastName}`;

  const filterOptions = (
    options: Array<ApiUserResponse>,
    state: { inputValue: string }
  ): Array<ApiUserResponse> => {
    return options.filter(
      (x) =>
        x.email?.toLowerCase()?.includes(state.inputValue.toLowerCase()) ||
        x.firstName?.toLowerCase()?.includes(state.inputValue.toLowerCase()) ||
        x.lastName?.toLowerCase()?.includes(state.inputValue.toLowerCase()) ||
        x.id?.toLowerCase()?.includes(state.inputValue.toLowerCase())
    );
  };
  return (
    <>
      {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(getIn(values, field.name), null, 2)}</pre> */}
      {users && (
        <FormControl fullWidth margin={margin}>
          <Field
            disableClearable={disableClearable}
            size={size}
            margin={margin}
            component={Autocomplete}
            name={`${field.name}`}
            fullWidth
            options={users}
            getOptionLabel={getOptionLabel}
            filterOptions={filterOptions}
            blurOnSelect={true}
            value={
              users.find((x) => x.id === getIn(values, field.name)?.id) || null
            }
            onChange={onChangeValue}
            renderOption={(props: any, option: ApiUserResponse) => {
              props.key = props.id;
              return (
                <Box {...props} component="li" {...props}>
                  {option?.firstName} {option?.lastName}
                  {/* <StatusLabel<UserStatus>
                  colourMap={userStatusesColourMap}
                  status={option?.status}
                /> */}
                </Box>
              );
            }}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <>
                <TextField
                  {...params}
                  label={t(label)}
                  error={Boolean(
                    getIn(touched, field.name) && !!getIn(errors, field.name)
                  )}
                  helperText={
                    showErrorText
                      ? getIn(touched, field.name) &&
                        t(getIn(errors, field.name))
                      : ""
                  }
                  multiline={true}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                    style: { resize: "both" },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    // ...(values[field.name]?.id
                    //     ? {
                    //         startAdornment: (
                    //             <InputAdornment position="end">
                    //                 <>
                    //                     <Box sx={{ ml: '8px' }}>
                    //                         {/* {values[field.name]?.displayName} ({values[field.name]?.id}) &nbsp;&nbsp; */}
                    //                         <UserStatusLabel status={values[field.name]?.status} />
                    //                     </Box>
                    //                 </>
                    //             </InputAdornment>
                    //         ),
                    //     }
                    //     : {}),
                  }}
                />
              </>
            )}
          ></Field>
        </FormControl>
      )}
    </>
  );
};
