import { LoadingButton } from "@mui/lab";
import { Box, FormHelperText, Link } from "@mui/material";
import { Field, Formik } from "formik";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  ACCOUNT_DISABLED,
  ACCOUNT_EXPIRED,
  ACCOUNT_LOCKED,
  BAD_CREDENTIALS,
  loginFormValidationSchema,
  LoginFormValues,
  loginFormValuesInitialValue,
} from ".";
import { BasicTextField, PasswordField } from "../../../../../forms";
import {
  LoginRequest,
  mapLoginFormValuesToLoginRequest,
  useLoginMutation,
} from "../../../../../models/auth";
import { selectTenant, useTenantQuery } from "../../../../../models/tenants";
import { Nullable } from "../../../../../utils/types";

export const LoginForm: FC = (props) => {
  const navigate = useNavigate();
  const [login] = useLoginMutation();
  const { t } = useTranslation();
  const [error, setError] = useState<Nullable<string>>(null);
  const tenant = useSelector(selectTenant);

  return (
    <>
      {tenant && (
        <Formik<LoginFormValues>
          initialValues={{
            ...loginFormValuesInitialValue(tenant?.id as string),
          }}
          validationSchema={loginFormValidationSchema()}
          onSubmit={async (values, { setSubmitting }): Promise<void> => {
            try {
              setError(null);
              const loginRequest: LoginRequest =
                mapLoginFormValuesToLoginRequest(values);
              const user = await login(loginRequest).unwrap();
              console.log("login API");
              if (user?.userStatus === "PENDING") {
                setError(
                  "errors.yourAccountIsStillPendingVerificationPleaseVerifyTheAccountBeforeLoggingIn"
                );
                return;
              }
              if (user?.userStatus === "EXPIRED") {
                toast.error(t("errors.passwordExpiredPleaseChangePassword"));
                navigate(`../change-password`, { replace: true });
                return;
              }
              navigate("../..", { replace: true });
              setSubmitting(false);
            } catch (err: any) {
              switch (err?.data?.errorCode) {
                case BAD_CREDENTIALS:
                  setError("errors.emailOrPasswordDoNotMatch");
                  toast.error(t("errors.wrongEmailOrPassword"), {
                    duration: 10000,
                  });
                  break;
                case ACCOUNT_EXPIRED:
                  setError("errors.accountExpired");
                  toast.error(t("errors.accountExpired"), {
                    duration: 10000,
                  });
                  break;
                case ACCOUNT_DISABLED:
                  setError("errors.accountDisabled");
                  toast.error(t("errors.accountDisabled"), {
                    duration: 10000,
                  });
                  break;
                case ACCOUNT_LOCKED:
                  setError("errors.accountLocked");
                  toast.error(t("errors.accountLocked"), {
                    duration: 10000,
                  });
                  break;
                default:
                  setError(
                    err.data?.message ||
                      err.message ||
                      "errors.somethingWentWrong"
                  );
              }

              setSubmitting(false);
            }
          }}
        >
          {({ errors, handleSubmit, isSubmitting }): JSX.Element => (
            <form noValidate onSubmit={handleSubmit} {...props}>
              <Field
                name={`email`}
                component={BasicTextField}
                label="common.email"
              />

              <Field name={`password`} component={PasswordField} />

              {error && (
                <Box sx={{ mt: 1 }}>
                  {error !== null && (
                    <FormHelperText error>{t(error)}</FormHelperText>
                  )}
                </Box>
              )}
              <Box sx={{ mt: "20px" }}>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to={`../forgot-password`}
                  variant="body1"
                >
                  {t("common.forgotYourPassword")}
                </Link>
              </Box>

              <Box sx={{ mt: "24px" }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {t("common.login")}
                </LoadingButton>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};
