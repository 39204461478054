import { i18nSlice } from '../../models/i18n';
import storage from 'redux-persist/lib/storage';

export const persistConfig = {
    key: 'root',
    storage,
    version: 1,
    blacklist: [
        i18nSlice.name,
    ]
};