import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import { Box, Divider, IconButton, Typography } from "@mui/material";
import { Field, Formik, getIn } from "formik";
import React, { FC } from "react";
import toast from "react-hot-toast";

import {
  pepSanctionsFormValuesInitialValue,
  PepSanctionsFormValues,
  pepSanctionsFormValidationSchema,
} from ".";

import { useTranslation } from "react-i18next";

import {
  BasicTextField,
  DateField,
  EnumerableField,
  NATURAL_PERSON,
  YES,
  YesNoField,
} from "../../../../../../forms";
import {
  ApiEnumeration,
  PEP_RELATIONSHIP,
} from "../../../../../../models/enumerations";
import { usePermissions } from "../../../../../../models/auth";
import {
  PENDING_STAGE_STATUS,
  ApiEntity,
  DRAFT_STAGE_STATUS,
  mapPepSanctionsFormValuesToApiStagePepSanctions,
  useUpdateEntityStageMutation,
  ApiStagePepSanctions,
  ApiStageAdditionalDetail,
  STAGE_1_2_TYPE,
  STAGE_2_4_TYPE,
  PENDING_APPROVAL_STAGE_STATUS,
} from "../../../../../../models/entities";
import { ApiUserResponse } from "../../../../../../models/users";
interface PepSanctionsFormProps {
  entity: ApiEntity;
  enumerables: ApiEnumeration[];
  onClose: () => void;
  profile: ApiUserResponse | undefined;
}
// 15/16
export const PepSanctionsForm: FC<PepSanctionsFormProps> = ({
  entity,
  enumerables,
  onClose,
  profile,
}) => {
  const [updateEntityStage] = useUpdateEntityStageMutation();
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  return (
    <Formik<PepSanctionsFormValues>
      enableReinitialize
      initialValues={{
        ...pepSanctionsFormValuesInitialValue(entity, enumerables),
      }}
      validationSchema={pepSanctionsFormValidationSchema(
        hasPermission("ADMIN")
      )}
      onSubmit={async (values): Promise<void> => {
        try {
          const apiPepSanctions: ApiStagePepSanctions =
            mapPepSanctionsFormValuesToApiStagePepSanctions(entity, values);
          await updateEntityStage(apiPepSanctions).unwrap();

          toast.success(t("common.updatedSuccessfully"));
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        setFieldValue,
        errors,
        touched,
      }): JSX.Element => (
        <>
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "24px 38px 24px 32px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={"textPrimary"} variant="h5">
                {t("common.pepAndSanctions")}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: "32px" }}>
            <form noValidate onSubmit={handleSubmit}>
              {entity.entryType === NATURAL_PERSON && (
                <>
                  <Field
                    name={`hasPep`}
                    component={YesNoField}
                    label="common.hasPep"
                    // size="small"
                    margin="normal"
                  />

                  {getIn(values, "hasPep") === YES && (
                    <>
                      <Field
                        name={`pepRelationship`}
                        component={EnumerableField}
                        type={PEP_RELATIONSHIP}
                        margin="normal"
                        label="common.relationshipwithPep"
                      />

                      {["FAMILY_MEMBER", "CLOSE_ASSOCIATE_TO_A_PEP"].includes(
                        getIn(values, "pepRelationship")?.value!
                      ) && (
                        <>
                          <Field
                            name={`pepfirstName`}
                            component={BasicTextField}
                            label="common.pepFirstName"
                            // size="small"
                            margin="normal"
                          />
                          <Field
                            name={`pepLastName`}
                            component={BasicTextField}
                            label="common.pepLastName"
                            // size="small"
                            margin="normal"
                          />
                        </>
                      )}
                      <Field
                        name={`pepPublicPosition`}
                        component={BasicTextField}
                        label="common.pepPublicPosition"
                        // size="small"
                        margin="normal"
                      />

                      <Field
                        name={`pepPublicEntity`}
                        component={BasicTextField}
                        label="common.pepPublicEntity"
                        // size="small"
                        margin="normal"
                      />

                      <Field
                        name={"pepStartDate"}
                        label="common.pepStartDate"
                        component={DateField}
                      />
                    </>
                  )}
                </>
              )}

              <Field
                name={`hasFreezingOrders`}
                component={YesNoField}
                label="common.everBeenSubjectToAnyFreezingOrder"
                // size="small"
                margin="normal"
              />

              {getIn(values, "hasFreezingOrders") === YES && (
                <Field
                  name={`freezingOrdersInfo`}
                  component={BasicTextField}
                  label="common.everBeenSubjectToAnyFreezingOrderInfo"
                  // size="small"
                  margin="normal"
                  rows={3}
                />
              )}

              <Field
                name={`hasSanctionedCountryDealings`}
                component={YesNoField}
                label="common.dealWithCountriesThatAreSubject"
                // size="small"
                margin="normal"
              />

              {getIn(values, "hasSanctionedCountryDealings") === YES && (
                <Field
                  name={`sanctionedCountryDealingsInfo`}
                  component={BasicTextField}
                  label="common.dealWithCountriesThatAreSubjectInfo"
                  // size="small"
                  margin="normal"
                  rows={3}
                />
              )}

              <Field
                name={`hasCriminalOffence`}
                component={YesNoField}
                label="common.convictedOfaCriminalOffence"
                // size="small"
                margin="normal"
              />

              {getIn(values, "hasCriminalOffence") === YES && (
                <Field
                  name={`criminalOffenceInfo`}
                  component={BasicTextField}
                  label="common.convictedOfaCriminalOffenceInfo"
                  // size="small"
                  margin="normal"
                  rows={3}
                />
              )}

              {[
                "LICENCES_ENTITY",
                "REGULATED_ENTITY",
                "PUBLIC_LISTED_ENTITY",
              ].includes(
                (
                  entity.stages.find(
                    (s) => s.stageType === STAGE_1_2_TYPE
                  ) as ApiStageAdditionalDetail
                ).legalEntityType || ""
              ) && (
                <>
                  <Field
                    name={`hasSubjectToRegulatoryActions`}
                    component={YesNoField}
                    label="common.beenSubjectToASupervisory"
                    // size="small"
                    margin="normal"
                  />

                  {getIn(values, "hasSubjectToRegulatoryActions") === YES && (
                    <Field
                      name={`subjectToRegulatoryActionsInfo`}
                      component={BasicTextField}
                      label="common.whatWasTheNatureOfTheBreach"
                      // size="small"
                      margin="normal"
                      row={3}
                    />
                  )}

                  <Field
                    name={`hasRegulatoryActionResolved`}
                    component={YesNoField}
                    label="common.haveTheRegulatoryIssuesBeenResolved"
                    // size="small"
                    margin="normal"
                  />

                  {getIn(values, "hasRegulatoryActionResolved") === YES && (
                    <Field
                      name={`regulatoryActionResolvedInfo`}
                      component={BasicTextField}
                      label="common.whatActionsWereTakenToAddress"
                      // size="small"
                      margin="normal"
                      row={3}
                    />
                  )}
                </>
              )}
              <Box
                sx={{
                  mt: "24px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    setFieldValue("stageStatus", DRAFT_STAGE_STATUS);
                  }}
                >
                  {t("common.saveAsDraft")}
                </LoadingButton>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    // Get Stage Data
                    const stageData: ApiStagePepSanctions = entity.stages.find(
                      (s) => s.stageType === STAGE_2_4_TYPE
                    ) as ApiStagePepSanctions;

                    if (
                      profile?.id === entity.reviewerId &&
                      stageData.stageStatus === PENDING_APPROVAL_STAGE_STATUS
                    ) {
                      setFieldValue(
                        "stageStatus",
                        PENDING_APPROVAL_STAGE_STATUS
                      );
                    } else {
                      setFieldValue("stageStatus", PENDING_STAGE_STATUS);
                    }
                  }}
                >
                  {t("common.submit")}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
