import { combineReducers } from '@reduxjs/toolkit';
import { authSlice } from '../../models/auth';
import { emptySplitApi } from '../../models/emptySplitApi';
import { i18nSlice } from '../../models/i18n';
import { tenantSlice } from '../../models/tenants';

export const rootReducer = combineReducers({
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
    [authSlice.name]: authSlice.reducer,
    [tenantSlice.name]: tenantSlice.reducer,
    [i18nSlice.name]: i18nSlice.reducer,
});
