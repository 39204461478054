import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  ApiEntity,
  ApiStageSourceOfFunds,
} from "../../../../../../../models/entities";

import { getCountryByCode } from "../../../../../../../utils/countries";
import { CountryFlag } from "../../../../../../../components/CountryFlag";
import { PermissionGuard } from "../../../../../../../models/auth";
import { StatusLabel } from "../../../../../../../components/StatusLabel";
import {
  ApiEntityStatus,
  YesNo,
  booleanToYesNo,
  yesNosColourMap,
  ACTIVE_CLIENT_STATUS,
  CLOSED_CLIENT_STATUS,
  PENDING_APPROVAL_CLIENT_STATUS,
} from "../../../../../../../forms";
import { ApiUserResponse } from "../../../../../../../models/users";
import { Nullable } from "../../../../../../../utils/types";

interface DetailRow {
  name: string;
  value: any;
}

export const Stage2_7View: FC<{
  entity: ApiEntity;
  stageData: ApiStageSourceOfFunds;
  onEdit: (value: boolean) => void;
  status: Nullable<ApiEntityStatus>;
  profile: ApiUserResponse | undefined;
}> = ({ entity, stageData, onEdit, status = null, profile }) => {
  const { t } = useTranslation();

  const step2_7Data1: DetailRow[] = useMemo(
    () =>
      stageData
        ? [
            {
              name: "common.hasSourceOfFundsCollated",
              value: (
                <>
                  {stageData?.hasSourceOfFundsCollated != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.hasSourceOfFundsCollated
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.sourceOfFundsCollatedInfo
              ? [
                  {
                    name: "common.sourceOfFundsCollatedInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.sourceOfFundsCollatedInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),
          ]
        : [],
    [stageData]
  );

  const step2_7Data2: DetailRow[] = useMemo(
    () =>
      stageData
        ? [
            {
              name: "common.hasEconomicActivities",
              value: (
                <>
                  {stageData?.hasEconomicActivities != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasEconomicActivities)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasEconomicActivities
              ? [
                  {
                    name: "common.economicActivitiesInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.economicActivitiesInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),

            {
              name: "common.hasNewEquity",
              value: (
                <>
                  {stageData?.hasNewEquity != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasNewEquity)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasNewEquity
              ? [
                  {
                    name: "common.newEquityInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.newEquityInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),

            {
              name: "common.hasPrivateLoan",
              value: (
                <>
                  {stageData?.hasPrivateLoan != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasPrivateLoan)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasPrivateLoan
              ? [
                  {
                    name: "common.privateLoanInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.privateLoanInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),

            {
              name: "common.hasBankLoanOrCreditFacility",
              value: (
                <>
                  {stageData?.hasBankLoanOrCreditFacility != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.hasBankLoanOrCreditFacility
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasBankLoanOrCreditFacility
              ? [
                  {
                    name: "common.bankLoanOrCreditFacilityInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.bankLoanOrCreditFacilityInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),

            {
              name: "common.hasOther",
              value: (
                <>
                  {stageData?.hasOther != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasOther)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasOther
              ? [
                  {
                    name: "common.otherInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.otherInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),

            {
              name: "common.sourceOfFundsOriginatingFrom",
              value: (
                <>
                  {stageData?.sourceOfFundsOriginatingFrom && (
                    <Chip
                      sx={{ pl: "5px", m: 1 }}
                      label={`${
                        getCountryByCode(
                          stageData?.sourceOfFundsOriginatingFrom
                        )?.name
                      } (${stageData?.sourceOfFundsOriginatingFrom})`}
                      icon={
                        <CountryFlag
                          countryCode={stageData?.sourceOfFundsOriginatingFrom}
                        />
                      }
                    />
                  )}
                </>
              ),
            },
          ]
        : [],
    [stageData]
  );

  return (
    <>
      {entity ? (
        <>
          <Card sx={{ marginTop: "12px" }}>
            <CardHeader
              disableTypography
              title={
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tooltip title={t("common.stage_2_7")} enterDelay={1000}>
                    <Typography variant="h6">
                      {t("common.sourceOfFunds")}
                    </Typography>
                  </Tooltip>
                  <Grid
                    item
                    // sm={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {((status !== PENDING_APPROVAL_CLIENT_STATUS &&
                      status !== ACTIVE_CLIENT_STATUS &&
                      status !== CLOSED_CLIENT_STATUS) ||
                      (status === PENDING_APPROVAL_CLIENT_STATUS &&
                        entity.reviewerId === profile?.id)) && (
                      <PermissionGuard permission="UPDATE_ENTITY">
                        <>
                          <Button
                            sx={[
                              {
                                whiteSpace: "nowrap",
                              },
                              (theme) => ({
                                [theme.breakpoints.down("sm")]: {
                                  minWidth: "100%",
                                },
                                [theme.breakpoints.up("sm")]: {
                                  minWidth: "auto",
                                },
                              }),
                            ]}
                            variant="contained"
                            onClick={() => onEdit(true)}
                          >
                            {t("common.edit")}
                          </Button>
                        </>
                      </PermissionGuard>
                    )}
                  </Grid>
                </Box>
              }
            />

            <Grid container rowSpacing={3} columnSpacing={2} sx={{ p: "16px" }}>
              {step2_7Data1.map(({ name, value }) => (
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  key={name}
                  sx={{ paddingTop: "12px !important" }}
                >
                  <Grid container>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {t(name)}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      {value}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                sx={{ paddingTop: "12px !important" }}
              >
                <Typography variant="subtitle2">
                  {t("common.areTheSourceOfFundsUsedRelative")}
                </Typography>
              </Grid>
              {step2_7Data2.map(({ name, value }) => (
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  key={name}
                  sx={{ paddingTop: "12px !important" }}
                >
                  <Grid container>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {t(name)}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      {value}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Card>
        </>
      ) : null}
    </>
  );
};
