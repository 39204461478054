import { Route, Routes } from "react-router";
import { LazyLoadableScreen } from "../../navigation/LazyLoadableScreen";

const TenantListScreen = LazyLoadableScreen(() => import("./TenantList"));
const TenantDetailsScreen = LazyLoadableScreen(() => import("./TenantDetails"));

export const TenantsRoutes = () => (
  <Routes>
    <Route path="/" element={<TenantListScreen />} />
    <Route path="/:id" element={<TenantDetailsScreen />} />
  </Routes>
);
