import * as Yup from "yup";
import { ApiEnumerationType } from "../../../../models/enumerations";
import { enumerationDataMap } from "./utils";

export const enumerableValidationSchema = (type: ApiEnumerationType) =>
    Yup.object()
        .required(enumerationDataMap.get(type).enumerableIsRequired)
        .nullable()
        .test(
            'null-check',
            enumerationDataMap.get(type).enumerableIsRequired,
            (enumerable) => enumerable != null,
        )


export const enumerableNotRequiredValidationSchema = (type: ApiEnumerationType) =>
    Yup.object()
        .nullable()
        .notRequired()

