import { addressInitialValues } from "../../../../forms"
import { CreateEntityFormValues } from "."

export const createEntityFormValuesInitialValue = (): CreateEntityFormValues => {
    return {
        tags: [],
        entryType: '',
        title: null,
        firstName: '',
        lastName: '',
        legalEntityName: '',
        additionalComments: '',
        tenantIdForHeader: ''
    }
}