import { Route, Routes } from "react-router";
import { LazyLoadableScreen } from "../../../../navigation/LazyLoadableScreen";
import { PENDING_APPROVAL_QUOTATION_STATUS } from "../../../../forms";
import { FC } from "react";
import { ApiUserResponse } from "../../../../models/users";

const QuotationListScreen = LazyLoadableScreen(
  () =>
    import("../../../../business/Entities/EntityTabs/Quotations/QuotationList")
);
const QuotationDetailsScreen = LazyLoadableScreen(
  () =>
    import(
      "../../../../business/Entities/EntityTabs/Quotations/QuotationDetails"
    )
);
export const QuotationsRequiringApprovalRoutes: FC<{
  reviewer: ApiUserResponse;
}> = ({ reviewer }) => (
  <Routes>
    <Route
      path="/"
      element={
        <QuotationListScreen
          status={PENDING_APPROVAL_QUOTATION_STATUS}
          reviewer={reviewer}
          showCreateQuotation={false}
        />
      }
    />
    <Route
      path="/:quotationId"
      element={
        <QuotationDetailsScreen status={PENDING_APPROVAL_QUOTATION_STATUS} />
      }
    />
  </Routes>
);
