import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  FormControl,
  TextField,
} from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StatusLabel } from "../../../../components/StatusLabel";

import {
  ApiUserResponse,
  useReviewersInvoicesListQuery,
} from "../../../../models/users";
// import { UserStatusLabel } from "src/screens/Users/UserStatusLabel";
import { Nullable } from "../../../../utils/types";
import { UserStatus, userStatusesColourMap } from "../userStatus";

export interface ReviewerInvoiceFieldProps {
  disabled: boolean;
  userId?: string;
  label?: string;
  size?: "small" | "medium";
  margin?: "none" | "dense" | "normal";
  onChange: (value: ApiUserResponse) => void;
}
export const ReviewerInvoiceField: FC<
  ReviewerInvoiceFieldProps & FieldProps<ApiUserResponse[], ApiUserResponse[]>
> = ({
  disabled = false,
  userId,
  label = "common.revewierInvoice",
  size = "medium",
  onChange = () => null,
  margin,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();

  const { data: userIds } = useReviewersInvoicesListQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  // Auto populate Biller field when there is only one
  // useEffect(() => {
  //   if (userIds && userIds?.length === 1) {
  //     setFieldValue(`${field.name}`, userIds[0]);
  //   }
  // }, [userIds, field.name, setFieldValue]);

  return (
    <>
      {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
      {userIds && (
        <FormControl fullWidth margin={margin}>
          <Field
            size={size}
            margin={margin}
            component={Autocomplete}
            name={`${field.name}`}
            fullWidth
            value={
              userIds.find((x) => x.id === getIn(values, field.name)?.id) ||
              null
            }
            onBlur={handleBlur(`${field.name}`)}
            onChange={(e: any, value: ApiUserResponse) => {
              setFieldValue(`${field.name}`, value);
              onChange(value);
            }}
            // disabled={disabled || userIds?.length === 1}
            options={userIds}
            autoHighlight
            variant="outlined"
            filterOptions={(
              options: Array<ApiUserResponse>,
              state: { inputValue: string }
            ): Array<ApiUserResponse> => {
              return options.filter(
                (x) =>
                  x.email
                    ?.toLowerCase()
                    ?.includes(state.inputValue.toLowerCase()) ||
                  x.firstName
                    ?.toLowerCase()
                    ?.includes(state.inputValue.toLowerCase()) ||
                  x.lastName
                    ?.toLowerCase()
                    ?.includes(state.inputValue.toLowerCase()) ||
                  x.id?.toLowerCase()?.includes(state.inputValue.toLowerCase())
              );
            }}
            isOptionEqualToValue={(
              option: ApiUserResponse,
              value: Nullable<ApiUserResponse>
            ) => option.id === value?.id}
            getOptionLabel={(option: ApiUserResponse) =>
              `(${option?.firstName} ${option?.lastName}) ${option?.email}`
            }
            renderOption={(props: any, option: ApiUserResponse) => (
              <Box key={option} component="li" {...props}>
                ({option?.firstName} {option?.lastName}) {option?.email}{" "}
                <StatusLabel<UserStatus>
                  colourMap={userStatusesColourMap}
                  status={option?.status}
                />
              </Box>
            )}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <>
                <TextField
                  {...params}
                  label={t(label)}
                  error={Boolean(
                    getIn(touched, field.name) && !!getIn(errors, field.name)
                  )}
                  helperText={
                    getIn(touched, field.name) && t(getIn(errors, field.name))
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                  InputProps={{
                    ...params.InputProps,
                    // ...(values[field.name]?.id
                    //     ? {
                    //         startAdornment: (
                    //             <InputAdornment position="end">
                    //                 <>
                    //                     <Box sx={{ ml: '8px' }}>
                    //                         {/* {values[field.name]?.displayName} ({values[field.name]?.id}) &nbsp;&nbsp; */}
                    //                         <UserStatusLabel status={values[field.name]?.status} />
                    //                     </Box>
                    //                 </>
                    //             </InputAdornment>
                    //         ),
                    //     }
                    //     : {}),
                  }}
                />
              </>
            )}
          ></Field>
        </FormControl>
      )}
    </>
  );
};
