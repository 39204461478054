import { addressInitialValues } from "../../../../forms"
import { UpdateEntityFormValues } from "."
import { ApiEntity } from "../../../../models/entities"
import { ApiEnumeration, TAG, TITLE } from "../../../../models/enumerations"
import { ApiUserResponse } from "../../../../models/users"

export const updateEntityFormValuesInitialValue = (entity: ApiEntity, enumerables: ApiEnumeration[], reviewers: ApiUserResponse[]): UpdateEntityFormValues => {
    return {
        id: entity.id,
        tags: entity.tags ? enumerables.filter(x => x.type === TAG && entity.tags.split(',').includes(x.value)) : [],
        entryType: entity.entryType,
        title: entity.title ? enumerables.filter(x => x.type === TITLE).find(x => x.value === entity.title)! : null,
        firstName: entity.firstName,
        lastName: entity.lastName,
        legalEntityName: entity.legalEntityName,
        additionalComments: entity.additionalComments,
        reviewer: entity.reviewerId ? reviewers.find(x => x.id === entity.reviewerId)! : null,
        status: entity.status
    }
}