import { Color, colors } from "@mui/material";
import { AuditType } from ".";

export const AUDIT_USER = 'USER';
export const AUDIT_SERVICE = 'SERVICE';
export const AUDIT_QUOTATION = 'QUOTATION';
export const AUDIT_SIGNATORY = 'SIGNATORY';
export const AUDIT_INVOICE = 'INVOICE';
export const AUDIT_RECEIPT = 'RECEIPT';


export const auditTypes = [AUDIT_USER, AUDIT_SERVICE, AUDIT_QUOTATION, AUDIT_SIGNATORY, AUDIT_INVOICE, AUDIT_RECEIPT] as const;
export const auditTypesColours = [colors.orange, colors.green, colors.grey];
export const auditTypesColourMap = new Map<AuditType, Color>(auditTypes.map((v, i) => [v, auditTypesColours[i]]));;
