import { ApiContactPerson, ApiEntity, ApiStageContactDetail, STAGE_1_3_TYPE } from "../../../../../../models/entities"
import { ContactDetailsFormValues, ContactPersonFormValues, contactDetailsFormValidationSchema } from "."
import { NO, YES, addressInitialValues, emailInitialValue, mapApiAddressToAddress, mapApiPhoneNumberToPhone, phoneInitialValues } from "../../../../../../forms";

export const mapApiContactPersonToContactPersonFormValues = (
    api: ApiContactPerson
): ContactPersonFormValues => ({
    id: api.id,
    firstName: api.firstName,
    lastName: api.lastName,
    designation: api.designation,
    email: api.email,
    contactNumber: mapApiPhoneNumberToPhone(api.contactNumber)
});


export const contactDetailsFormValuesInitialValue = (
    entity: ApiEntity): ContactDetailsFormValues => {
    // Get Stage Data
    const stageData: ApiStageContactDetail = entity.stages.find((s) => s.stageType === STAGE_1_3_TYPE) as ApiStageContactDetail;
    // If draft is available use that
    const data: ApiStageContactDetail = stageData.draftData ? stageData.draftData as ApiStageContactDetail : stageData;

    console.log(data);
    return {
        stageType: STAGE_1_3_TYPE,
        stageStatus: data.stageStatus,
        id: entity.id,

        registeredAddress: data.registeredAddress ? mapApiAddressToAddress(data.registeredAddress) : addressInitialValues(),
        hasADifferentCorrespondingAddress: data.correspondenceAddress?.addressLine1 != null ? YES : NO,
        correspondenceAddress: data.correspondenceAddress?.addressLine1 != null ? mapApiAddressToAddress(data.correspondenceAddress) : addressInitialValues(),
        contactPersons: data.contactPersons.length === 0 ? [contactPersonInitialValues()] : data.contactPersons.map(contactPerson => mapApiContactPersonToContactPersonFormValues(contactPerson))
    };
}

export const isDetailsOfContactPersonFormCompleted = (isAdmin: boolean) => async (detailsOfContactPersonFormValues: ContactDetailsFormValues): Promise<boolean> => {
    try {
        await contactDetailsFormValidationSchema(isAdmin).validate(detailsOfContactPersonFormValues);
    } catch (e) {
        return false;
    }
    return true;
}

export const contactPersonInitialValues = (): ContactPersonFormValues => ({
    id: '',
    firstName: '',
    lastName: '',
    designation: '',
    email: emailInitialValue(),
    contactNumber: phoneInitialValues(),
});