import { Route, Routes } from "react-router";
import { EntityTabs } from "../../../../business/Entities/EntityTabs";
import { LazyLoadableScreen } from "../../../../navigation/LazyLoadableScreen";
import { PENDING_APPROVAL_CLIENT_STATUS } from "../../../../forms";

const EntityListScreen = LazyLoadableScreen(
  () => import("../../../../business/Entities/EntityList")
);

export const EntityApprovalsRoutes = () => (
  <Routes>
    <Route
      path="/"
      element={<EntityListScreen status={PENDING_APPROVAL_CLIENT_STATUS} />}
    />
    <Route
      path="/:id/*"
      element={
        <EntityTabs urlDepth={5} status={PENDING_APPROVAL_CLIENT_STATUS} />
      }
    />
  </Routes>
);
