import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { isValid } from "date-fns";
import { FieldProps, getIn } from "formik";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Nullable } from "../../../../utils/types";

export interface YearFieldProps {
  disabled: boolean;
  label?: string;
  size?: "small" | "medium";
  margin?: "none" | "dense" | "normal";
  onChange: (value: Nullable<Date>) => void;
  showErrorText: boolean;
}
export const YearField: FC<YearFieldProps & FieldProps<Date, Date>> = ({
  disabled = false,
  label = "common.year",
  size = "medium",
  margin,
  onChange = () => null,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  showErrorText = true,
  ...props
}) => {
  const { t } = useTranslation();

  const onChangeHandler = useCallback((newValue: Nullable<Date>) => {
    if (!isValid(newValue)) {
      setFieldValue(`${field.name}`, null);
      typeof onChange === "function" && onChange(null);
    }
    setFieldValue(`${field.name}`, newValue);
    typeof onChange === "function" && onChange(newValue);
  }, []);

  return (
    <>
      {/* {JSON.stringify(values)} */}
      <FormControl
        margin={margin}
        variant="outlined"
        fullWidth
        error={Boolean(
          getIn(touched, field.name) && !!getIn(errors, field.name)
        )}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            // InputAdornmentProps={{
            //   sx: { ".MuiButtonBase-root": { padding: "0 !important" } },
            // }}
            format="yyyy"
            // inputFormat="yyyy"
            // disableFuture
            label={t(label)}
            onChange={onChangeHandler}
            // onChange={(newValue: Nullable<Date>) => {
            //   if (!isValid(newValue)) {
            //     return;
            //   }
            //   setFieldValue(`${field.name}`, newValue);
            // }}
            openTo="year"
            reduceAnimations
            slotProps={{ textField: { size } }}
            sx={{
              ".MuiButtonBase-root": { padding: "0 !important" },
            }}
            // renderInput={(params: any) => (
            //   <>

            //     <TextField
            //       size={size}
            //       {...params}
            //       onBlur={handleBlur(`${field.name}`)}
            //       error={Boolean(
            //         getIn(touched, field.name) && !!getIn(errors, field.name)
            //       )}
            //     />
            //   </>
            // )}
            // toolbarTitle={t(label)}
            value={getIn(values, field.name)}
            views={["year"]}
            disabled={disabled}
          />
        </LocalizationProvider>
        <FormHelperText error>
          {showErrorText
            ? getIn(touched, field.name) && t(getIn(errors, field.name))
            : ""}
        </FormHelperText>
      </FormControl>
    </>
  );
};
