import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  FormControl,
  TextField,
  debounce,
} from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useServicesListQuery,
  useLazyServicesQuery,
} from "../../../../models/services";
import { ApiService } from "../../../../models/services";

import { Nullable } from "../../../../utils/types";

export interface ServiceFieldProps {
  disabled: boolean;
  serviceId?: string;
  label?: string;
  size?: "small" | "medium";
  margin?: "none" | "dense";
  onChange: (value: ApiService) => void;
  showErrorText: boolean;
  disableClearable: boolean;
  removeOptions: ApiService[];
}
export const ServiceField: FC<
  ServiceFieldProps & FieldProps<ApiService[], ApiService[]>
> = ({
  disabled = false,
  serviceId,
  label = "common.service",
  size = "medium",
  onChange = () => null,
  margin,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  showErrorText = true,
  disableClearable = false,
  removeOptions = [],
  ...props
}) => {
  const { t } = useTranslation();

  const { data: serviceIds } = useServicesListQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  const onChangeValue = (e: any, value: ApiService) => {
    const selectedValue = value as ApiService;
    setFieldValue(`${field.name}`, selectedValue);
    onChange(value);
  };

  const getOptionLabel = (option: ApiService) => `${option?.title}`;

  const filterOptions = (
    options: Array<ApiService>,
    state: { inputValue: string }
  ): Array<ApiService> => {
    return options.filter(
      (x) =>
        x.value?.toLowerCase()?.includes(state.inputValue.toLowerCase()) ||
        x.acronym?.toLowerCase()?.includes(state.inputValue.toLowerCase()) ||
        x.title?.toLowerCase()?.includes(state.inputValue.toLowerCase()) ||
        x.predefinedInvoiceDescription
          ?.toLowerCase()
          ?.includes(state.inputValue.toLowerCase()) ||
        x.predefinedQuotationDescription
          ?.toLowerCase()
          ?.includes(state.inputValue.toLowerCase()) ||
        x.id?.toLowerCase()?.includes(state.inputValue.toLowerCase())
    );
  };
  // const [services, setServices] = useState<ApiService[]>([]);
  // useEffect(() => {
  //   if (serviceIds && serviceIds.length > 0)
  //     setServices(
  //       serviceIds?.filter(
  //         (s) => !removeOptions?.map((ro: ApiService) => ro?.id).includes(s?.id)
  //       )!
  //     );
  // }, [serviceIds]);

  return (
    <>
      {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(getIn(values, field.name), null, 2)}</pre> */}
      {serviceIds && (
        <FormControl fullWidth margin={margin}>
          <Field
            disableClearable={disableClearable}
            size={size}
            margin={margin}
            getOptionDisabled={(option: ApiService) =>
              !!removeOptions.find((element) => element?.id === option?.id)
            }
            component={Autocomplete}
            name={`${field.name}`}
            fullWidth
            options={serviceIds}
            disabled={disabled}
            getOptionLabel={getOptionLabel}
            filterOptions={filterOptions}
            blurOnSelect={true}
            value={
              serviceIds.find((x) => x.id === getIn(values, field.name)?.id) ||
              null
            }
            onChange={onChangeValue}
            isOptionEqualToValue={(
              option: ApiService,
              value: Nullable<ApiService>
            ) => option.id === value?.id}
            renderOption={(props: any, option: ApiService) => {
              props.key = props.id;
              return (
                <Box {...props} component="li" {...props}>
                  {option?.value}
                  {/* <StatusLabel<UserStatus>
                  colourMap={userStatusesColourMap}
                  status={option?.status}
                /> */}
                </Box>
              );
            }}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <>
                <TextField
                  {...params}
                  label={t(label)}
                  error={Boolean(
                    getIn(touched, field.name) && !!getIn(errors, field.name)
                  )}
                  helperText={
                    showErrorText
                      ? getIn(touched, field.name) &&
                        t(getIn(errors, field.name))
                      : ""
                  }
                  multiline={true}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                    style: { resize: "both" },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    // ...(values[field.name]?.id
                    //     ? {
                    //         startAdornment: (
                    //             <InputAdornment position="end">
                    //                 <>
                    //                     <Box sx={{ ml: '8px' }}>
                    //                         {/* {values[field.name]?.displayName} ({values[field.name]?.id}) &nbsp;&nbsp; */}
                    //                         <ServiceStatusLabel status={values[field.name]?.status} />
                    //                     </Box>
                    //                 </>
                    //             </InputAdornment>
                    //         ),
                    //     }
                    //     : {}),
                  }}
                />
              </>
            )}
          ></Field>
        </FormControl>
      )}
    </>
  );
};
