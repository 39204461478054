import * as Yup from "yup";

export const billerValidationSchema = (validationMessage?: string) =>
    Yup.object()
        .required(validationMessage || 'validations.billerIsRequired')
        .nullable()
        .test(
            'null-check',
            validationMessage || 'validations.billerIsRequired',
            (biller) => biller != null,
        )



