import { Info } from "@mui/icons-material";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { YesNo, yesNos, yesNosColourMap } from ".";
import { StatusLabel } from "../../../../components/StatusLabel";
import { Nullable } from "../../../../utils/types";

export interface YesNoFieldProps {
  disabled: boolean;
  label?: string;
  size?: "small" | "medium";
  margin?: "none" | "dense";
  info: Nullable<string>;
  onChange: (value: string) => void;
}
export const YesNoField: FC<YesNoFieldProps & FieldProps<string, string>> = ({
  disabled = false,
  label = "common.yesNo",
  size = "medium",
  margin,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  info,
  onChange = () => null,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FormControl fullWidth margin={margin}>
          <InputLabel
            htmlFor={field.name}
            error={getIn(touched, field.name) && !!getIn(errors, field.name)}
          >
            {t(label)}
          </InputLabel>
          <Field
            component={Select}
            name={field.name}
            fullWidth
            variant="outlined"
            label={t(label)}
            value={getIn(values, field.name)}
            error={Boolean(
              getIn(touched, field.name) && !!getIn(errors, field.name)
            )}
            onBlur={handleBlur(field.name)}
            onChange={(e: any, value: YesNo) => {
              handleChange(field.name)(e);
              onChange(value);
            }}
            disabled={disabled}
            size={size}
          >
            {yesNos.map((i) => (
              <MenuItem key={i} value={i}>
                <StatusLabel<YesNo> colourMap={yesNosColourMap} status={i} />
              </MenuItem>
            ))}
          </Field>
          <FormHelperText error>
            {getIn(touched, field.name) && t(getIn(errors, field.name))}
          </FormHelperText>
        </FormControl>
        {info && (
          <Tooltip
            color="action"
            title={<div style={{ whiteSpace: "pre-line" }}>{t(info)}</div>}
            sx={{ mt: "14px", ml: "5px", mr: "5px" }}
          >
            <Info />
          </Tooltip>
        )}
      </Box>
    </>
  );
};
