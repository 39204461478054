import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type I18nState = {
  language: string | null;
};

export const i18nSlice = createSlice({
  name: 'i18n',
  initialState: {
    language: localStorage.getItem('language') != null ? localStorage.getItem('language') : "en"
  } as I18nState,
  reducers: {
    setLanguage: (
      state,
      {
        payload: { language },
      }: PayloadAction<{
        language: string | null;
      }>,
    ) => {
      state.language = language;
    },
  },
});

export const { setLanguage } = i18nSlice.actions;

