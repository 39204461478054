import { Route, Routes } from "react-router";
import { LazyLoadableScreen } from "../../navigation/LazyLoadableScreen";

const ServiceListScreen = LazyLoadableScreen(() => import("./ServiceList"));
const ServiceDetailsScreen = LazyLoadableScreen(
  () => import("./ServiceDetails")
);

export const ServicesRoutes = () => (
  <Routes>
    <Route path="/" element={<ServiceListScreen />} />
    <Route path="/:id" element={<ServiceDetailsScreen />} />
  </Routes>
);
