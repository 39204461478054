import { Route, Routes } from "react-router";
import { LazyLoadableScreen } from "../../navigation/LazyLoadableScreen";

const BillerListScreen = LazyLoadableScreen(() => import("./BillerList"));
const BillerDetailsScreen = LazyLoadableScreen(() => import("./BillerDetails"));

export const BillersRoutes = () => (
  <Routes>
    <Route path="/" element={<BillerListScreen />} />
    <Route path="/:id" element={<BillerDetailsScreen />} />
  </Routes>
);
