import {
    CountryCode,
    getCountryCallingCode,
    parsePhoneNumber,
} from 'libphonenumber-js';
import { ApiPhoneNumber, composePhoneNumber, Phone } from '.';
import { countries } from '../../../../utils/countries';

export const mapPhoneToApiPhoneNumber = (phone: Phone): ApiPhoneNumber => {
    return {
        prefix: `+${getCountryCallingCode(phone!.phoneCountryCode!.code as CountryCode)}`,
        number: phone.phoneNumber,
        phoneNumber: composePhoneNumber(phone!.phoneCountryCode!.code as CountryCode, phone.phoneNumber)
    }
}

export const mapApiPhoneNumberToPhone = (apiPhone: ApiPhoneNumber): Phone => {
    return {
        phoneCountryCode: countries.find(x => x.code === parsePhoneNumber(apiPhone.phoneNumber).country) || null,
        phoneNumber: apiPhone.number
    }
}