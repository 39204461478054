import { Route, Routes } from "react-router";
import { LazyLoadableScreen } from "../../navigation/LazyLoadableScreen";

const BusinessContactListScreen = LazyLoadableScreen(
  () => import("./BusinessContactList")
);
const BusinessContactDetailsScreen = LazyLoadableScreen(
  () => import("./BusinessContactDetails")
);

export const BusinessContactsRoutes = () => (
  <Routes>
    <Route path="/" element={<BusinessContactListScreen />} />
    <Route path="/:id" element={<BusinessContactDetailsScreen />} />
  </Routes>
);
