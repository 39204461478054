import {
    LEGAL_ENTITY_TYPE, INDUSTRY,
    VAT,
    TITLE, GENDER, SECTOR, LICENSE_CATEGORY, REASON_FOR_TERMINATION, REASON_FOR_NOT_ONBOARDING, ESTIMATED_NET_WORTH, PROOF_OF_ADDRESS, IDENTITY_DOCUMENT_TYPE, DEALING_DIRECTLY_WITH, INTRODUCED_BY, PEP_RELATIONSHIP, STATUS_OF_APPLICATION, DETAILS_OF_SCHEME, LAYER_OF_OWNERSHIP, RELATIONSHIP, HIGH_RISK_INDUSTRY, INVESTMENT_TYPE, TYPE_OF_LISTING, JURISDICTION_OF_REGULATOR, NAME_OF_REGULATOR, CURRENCY, TAG, TOWN, QUOTE_REJECTION_REASON, QUOTE_CANCELLATION_REASON,
    RECEIPT_CANCELLATION_REASON
} from "../../../../models/enumerations";


export const enumerationDataMap = new Map();

const title = {
    enumeration: 'common.title',
    enumerableIsRequired: 'validations.titleIsRequired'
}
enumerationDataMap.set(TITLE, title);

const legalEntityType = {
    enumeration: 'common.legalEntityType',
    enumerableIsRequired: 'validations.legalEntityTypeIsRequired'
}
enumerationDataMap.set(LEGAL_ENTITY_TYPE, legalEntityType);


const gender = {
    enumeration: 'common.gender',
    enumerableIsRequired: 'validations.genderIsRequired'
}
enumerationDataMap.set(GENDER, gender);

const industry = {
    enumeration: 'common.industry',
    enumerableIsRequired: 'validations.industryIsRequired'
}
enumerationDataMap.set(INDUSTRY, industry);

const sector = {
    enumeration: 'common.sector',
    enumerableIsRequired: 'validations.sectorIsRequired'
}
enumerationDataMap.set(SECTOR, sector);

const licenseCategory = {
    enumeration: 'common.licenseCategory',
    enumerableIsRequired: 'validations.licenseCategoryIsRequired'
}
enumerationDataMap.set(LICENSE_CATEGORY, licenseCategory);


const nameOfRegulator = {
    enumeration: 'common.nameOfRegulator',
    enumerableIsRequired: 'validations.nameOfRegulatorIsRequired'
}
enumerationDataMap.set(NAME_OF_REGULATOR, nameOfRegulator);

const jurisdictionOfRegulator = {
    enumeration: 'common.jurisdictionOfRegulator',
    enumerableIsRequired: 'validations.jurisdictionOfRegulatorIsRequired'
}
enumerationDataMap.set(JURISDICTION_OF_REGULATOR, jurisdictionOfRegulator);

const typeOfListing = {
    enumeration: 'common.typeOfListing',
    enumerableIsRequired: 'validations.typeOfListingIsRequired'
}
enumerationDataMap.set(TYPE_OF_LISTING, typeOfListing);

const investmentType = {
    enumeration: 'common.investmentType',
    enumerableIsRequired: 'validations.investmentTypeIsRequired'
}
enumerationDataMap.set(INVESTMENT_TYPE, investmentType);

const highRiskIndustry = {
    enumeration: 'common.highRiskIndustry',
    enumerableIsRequired: 'validations.highRiskIndustryIsRequired'
}
enumerationDataMap.set(HIGH_RISK_INDUSTRY, highRiskIndustry);

const relationship = {
    enumeration: 'common.relationship',
    enumerableIsRequired: 'validations.relationshipIsRequired'
}
enumerationDataMap.set(RELATIONSHIP, relationship);

const layerOfOwnership = {
    enumeration: 'common.layerOfOwnership',
    enumerableIsRequired: 'validations.layerOfOwnershipIsRequired'
}
enumerationDataMap.set(LAYER_OF_OWNERSHIP, layerOfOwnership);

const detailsOfScheme = {
    enumeration: 'common.detailsOfScheme',
    enumerableIsRequired: 'validations.detailsOfSchemeIsRequired'
}
enumerationDataMap.set(DETAILS_OF_SCHEME, detailsOfScheme);

const statusOfApplication = {
    enumeration: 'common.statusOfApplication',
    enumerableIsRequired: 'validations.statusOfApplicationIsRequired'
}
enumerationDataMap.set(STATUS_OF_APPLICATION, statusOfApplication);

const pepRelationship = {
    enumeration: 'common.pepRelationship',
    enumerableIsRequired: 'validations.pepRelationshipIsRequired'
}
enumerationDataMap.set(PEP_RELATIONSHIP, pepRelationship);

const introducedBy = {
    enumeration: 'common.introducedBy',
    enumerableIsRequired: 'validations.introducedByIsRequired'
}
enumerationDataMap.set(INTRODUCED_BY, introducedBy);

const dealingDirectlyWith = {
    enumeration: 'common.dealingDirectlyWith',
    enumerableIsRequired: 'validations.dealingDirectlyWithIsRequired'
}
enumerationDataMap.set(DEALING_DIRECTLY_WITH, dealingDirectlyWith);

const identityDocumentType = {
    enumeration: 'common.identityDocumentType',
    enumerableIsRequired: 'validations.identityDocumentTypeIsRequired'
}
enumerationDataMap.set(IDENTITY_DOCUMENT_TYPE, identityDocumentType);

const proofOfAddress = {
    enumeration: 'common.proofOfAddress',
    enumerableIsRequired: 'validations.proofOfAddressIsRequired'
}
enumerationDataMap.set(PROOF_OF_ADDRESS, proofOfAddress);

const estimatedNetWorth = {
    enumeration: 'common.estimatedNetWorth',
    enumerableIsRequired: 'validations.estimatedNetWorthIsRequired'
}
enumerationDataMap.set(ESTIMATED_NET_WORTH, estimatedNetWorth);

const reasonForNotOnboarding = {
    enumeration: 'common.reasonForNotOnboarding',
    enumerableIsRequired: 'validations.reasonForNotOnboardingIsRequired'
}
enumerationDataMap.set(REASON_FOR_NOT_ONBOARDING, reasonForNotOnboarding);

const reasonForTermination = {
    enumeration: 'common.reasonForTermination',
    enumerableIsRequired: 'validations.reasonForTerminationIsRequired'
}
enumerationDataMap.set(REASON_FOR_TERMINATION, reasonForTermination);

const currency = {
    enumeration: 'common.currency',
    enumerableIsRequired: 'validations.currencyIsRequired'
}
enumerationDataMap.set(CURRENCY, currency);

const tag = {
    enumeration: 'common.tag',
    enumerableIsRequired: 'validations.tagIsRequired'
}
enumerationDataMap.set(TAG, tag);


const vat = {
    enumeration: 'common.vat',
    enumerableIsRequired: 'validations.vatIsRequired'
}
enumerationDataMap.set(VAT, vat);

const town = {
    enumeration: 'common.town',
    enumerableIsRequired: 'validations.townIsRequired'
}
enumerationDataMap.set(TOWN, town);

const quoteRejectionReason = {
    enumeration: 'common.quoteRejectionReason',
    enumerableIsRequired: 'validations.quoteRejectionReasonIsRequired'
}
enumerationDataMap.set(QUOTE_REJECTION_REASON, quoteRejectionReason);


const quoteCancellationReason = {
    enumeration: 'common.quoteCancellationReason',
    enumerableIsRequired: 'validations.quoteCancellationReasonIsRequired'
}
enumerationDataMap.set(QUOTE_CANCELLATION_REASON, quoteCancellationReason);

const receiptCancellationReason = {
    enumeration: 'common.receiptCancellationReason',
    enumerableIsRequired: 'validations.receiptCancellationReasonIsRequired'
}
enumerationDataMap.set(RECEIPT_CANCELLATION_REASON, receiptCancellationReason);
