import { ApiEntity, ApiStageSourceOfWealth, STAGE_2_6_TYPE } from "../../../../../../models/entities"
import { SourceOfWealthFormValues } from "."
import { ApiEnumeration, ESTIMATED_NET_WORTH } from "../../../../../../models/enumerations"
import { booleanToYesNo } from "../../../../../../forms";

export const sourceOfWealthFormValuesInitialValue = (
    entity: ApiEntity,
    enumerables: ApiEnumeration[]): SourceOfWealthFormValues => {
    // Get Stage Data
    const stageData: ApiStageSourceOfWealth = entity.stages.find((s) => s.stageType === STAGE_2_6_TYPE) as ApiStageSourceOfWealth;
    // If draft is available use that
    const data: ApiStageSourceOfWealth = stageData.draftData ? stageData.draftData as ApiStageSourceOfWealth : stageData;
    return {
        stageType: STAGE_2_6_TYPE,
        stageStatus: data.stageStatus,
        id: entity.id,

        hasSowCollated: data.hasSowCollated ? booleanToYesNo(data.hasCompanyProfits) : '',
        sowCollatedInfo: data.hasSowCollated ? data.sowCollatedInfo! : '',
        hasHighNetWorth: data.hasHighNetWorth ? booleanToYesNo(data.hasHighNetWorth) : '',
        estimatedNetWorth: data.estimatedNetWorth ? enumerables.filter(x => x.type === ESTIMATED_NET_WORTH).find(x => x.value === data.estimatedNetWorth)! : null,
        hasNewlyEstablishedEntity: data.hasNewlyEstablishedEntity ? booleanToYesNo(data.hasNewlyEstablishedEntity) : '',
        newlyEstablishedEntityInfo: data.hasNewlyEstablishedEntity ? data.newlyEstablishedEntityInfo! : '',
        hasSavingsFromSalary: data.hasSavingsFromSalary ? booleanToYesNo(data.hasSavingsFromSalary) : '',
        savingsFromSalaryInfo: data.hasSavingsFromSalary ? data.savingsFromSalaryInfo! : '',
        hasCompanyProfits: data.hasCompanyProfits ? booleanToYesNo(data.hasCompanyProfits) : '',
        companyProfitsInfo: data.hasCompanyProfits ? data.companyProfitsInfo! : '',

        hasSaleOfProperty: data.hasSaleOfProperty ? booleanToYesNo(data.hasSaleOfProperty) : '',
        saleOfPropertyInfo: data.hasSaleOfProperty ? data.saleOfPropertyInfo! : '',

        hasRentalOfProperty: data.hasRentalOfProperty ? booleanToYesNo(data.hasRentalOfProperty) : '',
        rentalOfPropertyInfo: data.hasRentalOfProperty ? data.rentalOfPropertyInfo! : '',

        hasCompanySale: data.hasCompanySale ? booleanToYesNo(data.hasCompanySale) : '',
        companySaleInfo: data.hasCompanySale ? data.companySaleInfo! : '',

        hasInheritance: data.hasInheritance ? booleanToYesNo(data.hasInheritance) : '',
        inheritanceInfo: data.hasInheritance ? data.inheritanceInfo! : '',

        hasDivorceSettlement: data.hasDivorceSettlement ? booleanToYesNo(data.hasDivorceSettlement) : '',
        divorceSettlementInfo: data.hasDivorceSettlement ? data.divorceSettlementInfo! : '',

        hasDonation: data.hasDonation ? booleanToYesNo(data.hasDonation) : '',
        donationInfo: data.hasDonation ? data.donationInfo! : '',

        hasFixedDeposit: data.hasFixedDeposit ? booleanToYesNo(data.hasFixedDeposit) : '',
        fixedDepositInfo: data.hasFixedDeposit ? data.fixedDepositInfo! : '',

        hasDividendPayment: data.hasDividendPayment ? booleanToYesNo(data.hasDividendPayment) : '',
        dividendPaymentInfo: data.hasDividendPayment ? data.dividendPaymentInfo! : '',

        hasLottery: data.hasLottery ? booleanToYesNo(data.hasLottery) : '',
        lotteryInfo: data.hasLottery ? data.lotteryInfo! : '',

        hasLifePolicy: data.hasLifePolicy ? booleanToYesNo(data.hasLifePolicy) : '',
        lifePolicyInfo: data.hasLifePolicy ? data.lifePolicyInfo! : '',

        hasMaturityOfInvestments: data.hasMaturityOfInvestments ? booleanToYesNo(data.hasMaturityOfInvestments) : '',
        maturityOfInvestmentsInfo: data.hasMaturityOfInvestments ? data.maturityOfInvestmentsInfo! : '',

        hasOther: data.hasOther ? booleanToYesNo(data.hasOther) : '',
        otherInfo: data.hasOther ? data.otherInfo! : '',
    }
}

