import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import { Box, Divider, IconButton, Typography } from "@mui/material";
import { Field, Formik, getIn } from "formik";
import React, { FC } from "react";
import toast from "react-hot-toast";

import {
  sourceOfFundsFormValuesInitialValue,
  SourceOfFundsFormValues,
  sourceOfFundsFormValidationSchema,
} from ".";

import { useTranslation } from "react-i18next";

import {
  BasicTextField,
  CountryField,
  YES,
  YesNoField,
} from "../../../../../../forms";
import { ApiEnumeration } from "../../../../../../models/enumerations";
import { usePermissions } from "../../../../../../models/auth";
import {
  PENDING_STAGE_STATUS,
  ApiEntity,
  DRAFT_STAGE_STATUS,
  mapSourceOfFundsFormValuesToApiStageSourceOfFunds,
  useUpdateEntityStageMutation,
  ApiStageSourceOfFunds,
  STAGE_2_7_TYPE,
  PENDING_APPROVAL_STAGE_STATUS,
} from "../../../../../../models/entities";
import { ApiUserResponse } from "../../../../../../models/users";
interface SourceOfFundsFormProps {
  entity: ApiEntity;
  enumerables: ApiEnumeration[];
  onClose: () => void;
  profile: ApiUserResponse | undefined;
}
// 20
export const SourceOfFundsForm: FC<SourceOfFundsFormProps> = ({
  entity,
  enumerables,
  onClose,
  profile,
}) => {
  const [updateEntityStage] = useUpdateEntityStageMutation();
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  return (
    <Formik<SourceOfFundsFormValues>
      enableReinitialize
      initialValues={{
        ...sourceOfFundsFormValuesInitialValue(entity, enumerables),
      }}
      validationSchema={sourceOfFundsFormValidationSchema(
        hasPermission("ADMIN")
      )}
      onSubmit={async (values): Promise<void> => {
        try {
          const apiSourceOfFunds: ApiStageSourceOfFunds =
            mapSourceOfFundsFormValuesToApiStageSourceOfFunds(entity, values);
          await updateEntityStage(apiSourceOfFunds).unwrap();

          toast.success(t("common.updatedSuccessfully"));
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        setFieldValue,
        errors,
        touched,
      }): JSX.Element => (
        <>
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "24px 38px 24px 32px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={"textPrimary"} variant="h5">
                {t("common.sourceOfFunds")}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: "32px" }}>
            <form noValidate onSubmit={handleSubmit}>
              <Box
                sx={{
                  mt: "24px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Field
                  name={`hasSourceOfFundsCollated`}
                  component={YesNoField}
                  label="common.hasSourceOfFundsCollated"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasSourceOfFundsCollated") === YES && (
                  <Field
                    name={`sourceOfFundsCollatedInfo`}
                    component={BasicTextField}
                    label="common.sourceOfFundsCollatedInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                  />
                )}

                <Typography variant="subtitle2">
                  {t("common.areTheSourceOfFundsUsedRelative")}
                </Typography>

                <Field
                  name={`hasEconomicActivities`}
                  component={YesNoField}
                  label="common.hasEconomicActivities"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasEconomicActivities") === YES && (
                  <Field
                    name={`economicActivitiesInfo`}
                    component={BasicTextField}
                    label="common.economicActivitiesInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                    info="common.egCompanyRevenueSalaryPensionEtc"
                  />
                )}
                <Field
                  name={`hasNewEquity`}
                  component={YesNoField}
                  label="common.hasNewEquity"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasNewEquity") === YES && (
                  <Field
                    name={`newEquityInfo`}
                    component={BasicTextField}
                    label="common.newEquityInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                    info="common.egNameOfShareHolderProvidingtheEquity"
                  />
                )}
                <Field
                  name={`hasPrivateLoan`}
                  component={YesNoField}
                  label="common.hasPrivateLoan"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasPrivateLoan") === YES && (
                  <Field
                    name={`privateLoanInfo`}
                    component={BasicTextField}
                    label="common.privateLoanInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                    info="common.egNameOfPersonProvidingTheLoan"
                  />
                )}
                <Field
                  name={`hasBankLoanOrCreditFacility`}
                  component={YesNoField}
                  label="common.hasBankLoanOrCreditFacility"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasBankLoanOrCreditFacility") === YES && (
                  <Field
                    name={`bankLoanOrCreditFacilityInfo`}
                    component={BasicTextField}
                    label="common.bankLoanOrCreditFacilityInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                    info="common.egInstitutionProvidingtheLoan"
                  />
                )}
                <Field
                  name={`hasOther`}
                  component={YesNoField}
                  label="common.hasOther"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasOther") === YES && (
                  <Field
                    name={`otherInfo`}
                    component={BasicTextField}
                    label="common.otherInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                  />
                )}
                <Field
                  name={`sourceOfFundsOriginatingFrom`}
                  component={CountryField}
                  label="common.sourceOfFundsOriginatingFrom"
                  // size="small"
                  margin="normal"
                />
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    setFieldValue("stageStatus", DRAFT_STAGE_STATUS);
                  }}
                >
                  {t("common.saveAsDraft")}
                </LoadingButton>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    // Get Stage Data
                    const stageData: ApiStageSourceOfFunds = entity.stages.find(
                      (s) => s.stageType === STAGE_2_7_TYPE
                    ) as ApiStageSourceOfFunds;

                    if (
                      profile?.id === entity.reviewerId &&
                      stageData.stageStatus === PENDING_APPROVAL_STAGE_STATUS
                    ) {
                      setFieldValue(
                        "stageStatus",
                        PENDING_APPROVAL_STAGE_STATUS
                      );
                    } else {
                      setFieldValue("stageStatus", PENDING_STAGE_STATUS);
                    }
                  }}
                >
                  {t("common.submit")}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
