
import { ApiSignatory, PagedSignatoriesRequest, PageApiSignatory, ApiUpdateSignatoryRequest, ApiCreateSignatoryRequest } from '.';
import { removeEmpty } from '../../utils/removeEmpty';
import { apiWithTag } from '../emptySplitApi';


export const signatoriesApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        signatories: builder.query<PageApiSignatory, PagedSignatoriesRequest>({
            query: ({ ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    method: 'GET',
                    params: parameters,
                    url: 'signatories',
                }
            },
            providesTags: ['Signatory']
        }),
        signatoriesList: builder.query<ApiSignatory[], {}>({
            query: () => {
                return {
                    method: 'GET',
                    url: 'signatories/list',
                }
            },
            providesTags: ['Signatory']
        }),
        signatory: builder.query<ApiSignatory, { id: string }>({
            query: ({ id }) => ({
                method: 'GET',
                url: `signatories/${id}`,

            }),
            providesTags: ['Signatory']
        }),
        createSignatory: builder.mutation<void, ApiCreateSignatoryRequest>({
            query: ({
                // tenantIdForHeader, 
                ...signatory }) => ({
                    // headers: (tenantIdForHeader.length > 0) ? {
                    //     'X-TENANT-ID': tenantIdForHeader
                    // } : {},
                    url: 'signatories',
                    method: 'POST',
                    body: signatory
                }),
            invalidatesTags: ['Signatory']
        }),
        updateSignatory: builder.mutation<ApiSignatory, ApiUpdateSignatoryRequest>({
            query: ({ id, ...rest }) => ({
                body: { ...rest },
                method: 'PUT',
                url: `signatories/${id}`,
            }),
            invalidatesTags: ['Signatory']
        }),
        deleteSignatory: builder.mutation<ApiSignatory, { id: string }>({
            query: ({ id, ...rest }) => ({
                method: 'DELETE',
                url: `signatories/${id}`,
            }),
            invalidatesTags: ['Signatory']
        }),


    })
})

export const {
    useSignatoriesQuery,
    useLazySignatoriesQuery,
    useSignatoriesListQuery,
    useSignatoryQuery,
    useCreateSignatoryMutation,
    useUpdateSignatoryMutation,
    useDeleteSignatoryMutation
} = signatoriesApi;