import { Route, Routes } from "react-router";
import { LazyLoadableScreen } from "../../navigation/LazyLoadableScreen";
import { EntityTabs } from "../../business/Entities/EntityTabs";

const EntityListScreen = LazyLoadableScreen(
  () => import("../../business/Entities/EntityList")
);

export const EntitiesRoutes = () => (
  <Routes>
    <Route path="/" element={<EntityListScreen status={null} />} />
    <Route path="/:id/*" element={<EntityTabs urlDepth={4} status={null} />} />
  </Routes>
);
