import * as Yup from 'yup';

export const createEnumerationFormValidationSchema = () =>
    Yup.object({
        value: Yup.string().required('validations.valueIsRequired'),
    });




