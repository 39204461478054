import { ApiEntity, ApiStageBasicDetail, STAGE_1_1_TYPE } from "../../../../../../models/entities"
import { BasicDetailsFormValues } from "."
import { ApiEnumeration, TAG, TITLE } from "../../../../../../models/enumerations"

export const basicDetailsFormValuesInitialValue = (
    entity: ApiEntity,
    enumerables: ApiEnumeration[]): BasicDetailsFormValues => {
    // Get Stage Data
    const stageData: ApiStageBasicDetail = entity.stages.find((s) => s.stageType === STAGE_1_1_TYPE) as ApiStageBasicDetail;
    // If draft is available use that
    const data: ApiStageBasicDetail = stageData.draftData ? stageData.draftData as ApiStageBasicDetail : stageData;
    return {
        stageType: STAGE_1_1_TYPE,
        stageStatus: data.stageStatus,
        id: entity.id,
        tags: data.tags ? enumerables.filter(x => x.type === TAG && data.tags.split(',').includes(x.value)) : [],
        entryType: data.entryType || '',
        title: data.title ? enumerables.filter(x => x.type === TITLE).find(x => x.value === data.title)! : null,
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        legalEntityName: data.legalEntityName || '',
        additionalComments: data.additionalComments || '',
        accountManager: null
    }
}
