import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  ApiEntity,
  ApiStageAdditionalDetail,
  ApiStagePepSanctions,
  STAGE_1_2_TYPE,
} from "../../../../../../../models/entities";
import { PermissionGuard } from "../../../../../../../models/auth";
import { StatusLabel } from "../../../../../../../components/StatusLabel";
import {
  ApiEntityStatus,
  NATURAL_PERSON,
  YesNo,
  booleanToYesNo,
  yesNosColourMap,
  ACTIVE_CLIENT_STATUS,
  CLOSED_CLIENT_STATUS,
  PENDING_APPROVAL_CLIENT_STATUS,
} from "../../../../../../../forms";
import { ApiUserResponse } from "../../../../../../../models/users";
import { Nullable } from "../../../../../../../utils/types";

interface DetailRow {
  name: string;
  value: any;
}

export const Stage2_4View: FC<{
  entity: ApiEntity;
  stageData: ApiStagePepSanctions;
  onEdit: (value: boolean) => void;
  status: Nullable<ApiEntityStatus>;
  profile: ApiUserResponse | undefined;
}> = ({ entity, stageData, onEdit, status = null, profile }) => {
  const { t } = useTranslation();
  // {entity.entryType === NATURAL_PERSON && (
  //   <>
  const step2_4PepData: DetailRow[] = useMemo(
    () =>
      stageData
        ? [
            ...(entity.entryType === NATURAL_PERSON
              ? [
                  {
                    name: "common.hasPep",
                    value: (
                      <>
                        {stageData?.hasPep != null ? (
                          <StatusLabel<YesNo>
                            colourMap={yesNosColourMap}
                            status={booleanToYesNo(stageData.hasPep)}
                          />
                        ) : null}
                      </>
                    ),
                  },

                  ...(stageData.hasPep
                    ? [
                        {
                          name: "common.relationshipwithPep",
                          value: (
                            <>
                              {stageData.pepRelationship ? (
                                <Chip
                                  color="primary"
                                  variant="outlined"
                                  label={stageData.pepRelationship}
                                />
                              ) : (
                                ""
                              )}
                            </>
                          ),
                        },
                        ...([
                          "CLOSE_ASSOCIATE_TO_A_PEP",
                          "FAMILY_MEMER",
                        ].includes(stageData?.pepRelationship || "")
                          ? [
                              {
                                name: "common.pepFirstName",
                                value: (
                                  <Typography
                                    variant="body2"
                                    sx={{ wordWrap: "break-word" }}
                                  >
                                    {stageData.pepFirstName}
                                  </Typography>
                                ),
                              },
                              {
                                name: "common.pepLastName",
                                value: (
                                  <Typography
                                    variant="body2"
                                    sx={{ wordWrap: "break-word" }}
                                  >
                                    {stageData.pepLastName}
                                  </Typography>
                                ),
                              },
                            ]
                          : []),

                        {
                          name: "common.pepPublicPosition",
                          value: (
                            <Typography
                              variant="body2"
                              sx={{ wordWrap: "break-word" }}
                            >
                              {stageData.pepPublicPosition}
                            </Typography>
                          ),
                        },
                        {
                          name: "common.pepPublicEntity",
                          value: (
                            <Typography
                              variant="body2"
                              sx={{ wordWrap: "break-word" }}
                            >
                              {stageData.pepPublicEntity}
                            </Typography>
                          ),
                        },
                        {
                          name: "common.pepStartDate",
                          value: (
                            <Typography
                              variant="body2"
                              sx={{ wordWrap: "break-word" }}
                            >
                              {stageData.pepStartDate?.length! > 0 &&
                                format(
                                  parseISO(stageData.pepStartDate!),
                                  "dd-MM-yyyy"
                                )}
                            </Typography>
                          ),
                        },
                      ]
                    : []),
                ]
              : []),
          ]
        : [],
    [stageData, entity, entity.stages]
  );

  const step2_4SanctionsData: DetailRow[] = useMemo(
    () =>
      stageData
        ? [
            {
              name: "common.everBeenSubjectToAnyFreezingOrder",
              value: (
                <>
                  {stageData?.hasFreezingOrders != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasFreezingOrders)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasFreezingOrders
              ? [
                  {
                    name: "common.everBeenSubjectToAnyFreezingOrderInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.freezingOrdersInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),
            {
              name: "common.dealWithCountriesThatAreSubject",
              value: (
                <>
                  {stageData?.hasSanctionedCountryDealings != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.hasSanctionedCountryDealings
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasSanctionedCountryDealings
              ? [
                  {
                    name: "common.dealWithCountriesThatAreSubjectInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.sanctionedCountryDealingsInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),

            {
              name: "common.convictedOfaCriminalOffence",
              value: (
                <>
                  {stageData?.hasCriminalOffence != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasCriminalOffence)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasCriminalOffence
              ? [
                  {
                    name: "common.convictedOfaCriminalOffenceInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.criminalOffenceInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),

            ...([
              "LICENCES_ENTITY",
              "REGULATED_ENTITY",
              "PUBLIC_LISTED_ENTITY",
            ].includes(
              (
                entity.stages.find(
                  (s) => s.stageType === STAGE_1_2_TYPE
                ) as ApiStageAdditionalDetail
              ).legalEntityType || ""
            )
              ? [
                  {
                    name: "common.beenSubjectToASupervisory",
                    value: (
                      <>
                        {stageData?.hasSubjectToRegulatoryActions != null ? (
                          <StatusLabel<YesNo>
                            colourMap={yesNosColourMap}
                            status={booleanToYesNo(
                              stageData.hasSubjectToRegulatoryActions
                            )}
                          />
                        ) : null}
                      </>
                    ),
                  },
                  ...(stageData.hasSubjectToRegulatoryActions
                    ? [
                        {
                          name: "common.whatWasTheNatureOfTheBreach",
                          value: (
                            <Typography
                              variant="body2"
                              sx={{ wordWrap: "break-word" }}
                            >
                              {stageData.subjectToRegulatoryActionsInfo}
                            </Typography>
                          ),
                        },
                      ]
                    : []),
                  {
                    name: "common.haveTheRegulatoryIssuesBeenResolved",
                    value: (
                      <>
                        {stageData?.hasRegulatoryActionResolved != null ? (
                          <StatusLabel<YesNo>
                            colourMap={yesNosColourMap}
                            status={booleanToYesNo(
                              stageData.hasRegulatoryActionResolved
                            )}
                          />
                        ) : null}
                      </>
                    ),
                  },
                  ...(stageData.hasRegulatoryActionResolved
                    ? [
                        {
                          name: "common.whatActionsWereTakenToAddress",
                          value: (
                            <Typography
                              variant="body2"
                              sx={{ wordWrap: "break-word" }}
                            >
                              {stageData.regulatoryActionResolvedInfo}
                            </Typography>
                          ),
                        },
                      ]
                    : []),
                ]
              : []),
          ]
        : [],
    [stageData, entity, entity.stages]
  );

  return (
    <>
      {entity ? (
        <>
          <Card sx={{ marginTop: "12px" }}>
            <CardHeader
              disableTypography
              title={
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tooltip title={t("common.stage_2_4")} enterDelay={1000}>
                    <Typography variant="h6">
                      {t("common.pepAndSanctions")}
                    </Typography>
                  </Tooltip>
                  <Grid
                    item
                    // sm={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {((status !== PENDING_APPROVAL_CLIENT_STATUS &&
                      status !== ACTIVE_CLIENT_STATUS &&
                      status !== CLOSED_CLIENT_STATUS) ||
                      (status === PENDING_APPROVAL_CLIENT_STATUS &&
                        entity.reviewerId === profile?.id)) && (
                      <PermissionGuard permission="UPDATE_ENTITY">
                        <>
                          <Button
                            sx={[
                              {
                                whiteSpace: "nowrap",
                              },
                              (theme) => ({
                                [theme.breakpoints.down("sm")]: {
                                  minWidth: "100%",
                                },
                                [theme.breakpoints.up("sm")]: {
                                  minWidth: "auto",
                                },
                              }),
                            ]}
                            variant="contained"
                            onClick={() => onEdit(true)}
                          >
                            {t("common.edit")}
                          </Button>
                        </>
                      </PermissionGuard>
                    )}
                  </Grid>
                </Box>
              }
            />

            <Grid container rowSpacing={3} columnSpacing={2} sx={{ p: "16px" }}>
              {step2_4PepData.map(({ name, value }) => (
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  key={name}
                  sx={{ paddingTop: "12px !important" }}
                >
                  <Grid container>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {t(name)}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      {value}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              {step2_4SanctionsData.map(({ name, value }) => (
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  key={name}
                  sx={{ paddingTop: "12px !important" }}
                >
                  <Grid container>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {t(name)}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      {value}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Card>
        </>
      ) : null}
    </>
  );
};
