import { Add, Close, Remove } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import {
  Box,
  Divider,
  IconButton,
  InputLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { Field, FieldArray, Formik, getIn } from "formik";
import React, { FC } from "react";
import toast from "react-hot-toast";

import {
  businessActivityFormValuesInitialValue,
  BusinessActivityFormValues,
  businessActivityFormValidationSchema,
  investmentsInitialValues,
} from ".";

import { useTranslation } from "react-i18next";

import {
  BasicTextField,
  DateField,
  EnumerableField,
  EnumerablesField,
  HOLDING,
  LEGAL_ENTITY,
  MoneyField,
  NATURAL_PERSON,
  TradingOrHoldingField,
  YES,
  YesNoField,
} from "../../../../../../forms";
import {
  ApiEnumeration,
  HIGH_RISK_INDUSTRY,
  INVESTMENT_TYPE,
  JURISDICTION_OF_REGULATOR,
  LICENSE_CATEGORY,
  NAME_OF_REGULATOR,
  SECTOR,
  TYPE_OF_LISTING,
} from "../../../../../../models/enumerations";
import { usePermissions } from "../../../../../../models/auth";
import {
  PENDING_STAGE_STATUS,
  ApiEntity,
  ApiStageAdditionalDetail,
  DRAFT_STAGE_STATUS,
  mapBusinessActivityFormValuesToApiStageBusinessActivity,
  useUpdateEntityStageMutation,
  POTENTIAL_CUSTOMER,
  CUSTOMER,
  STAGE_1_2_TYPE,
  ApiStageBusinessActivity,
  STAGE_2_2_TYPE,
  PENDING_APPROVAL_STAGE_STATUS,
} from "../../../../../../models/entities";
import { ApiUserResponse } from "../../../../../../models/users";
interface BusinessActivityFormProps {
  entity: ApiEntity;
  enumerables: ApiEnumeration[];
  currency: ApiEnumeration;
  onClose: () => void;
  profile: ApiUserResponse | undefined;
}
// 5,6,7,9,10,11,12
export const BusinessActivityForm: FC<BusinessActivityFormProps> = ({
  entity,
  enumerables,
  currency,
  onClose,
  profile,
}) => {
  const [updateEntityStage] = useUpdateEntityStageMutation();
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  return (
    <Formik<BusinessActivityFormValues>
      enableReinitialize
      initialValues={{
        ...businessActivityFormValuesInitialValue(
          entity,
          enumerables,
          currency
        ),
      }}
      validationSchema={businessActivityFormValidationSchema(
        hasPermission("ADMIN")
      )}
      onSubmit={async (values): Promise<void> => {
        try {
          const apiBusinessActivity: ApiStageBusinessActivity =
            mapBusinessActivityFormValuesToApiStageBusinessActivity(
              entity,
              values
            );
          // const apiEntity: ApiStageBasicDetail =
          await updateEntityStage(apiBusinessActivity).unwrap();

          toast.success(t("common.updatedSuccessfully"));
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        setFieldValue,
        errors,
        touched,
      }): JSX.Element => (
        <>
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "24px 38px 24px 32px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={"textPrimary"} variant="h5">
                {t("common.businessActivity")}
              </Typography>
            </Box>
          </Box>

          <Divider />

          <Box sx={{ padding: "32px" }}>
            <form noValidate onSubmit={handleSubmit}>
              {/* 5 */}
              <Field
                name={`sector`}
                component={EnumerableField}
                type={SECTOR}
                margin="normal"
              />
              <Field
                name={`licenseCategory`}
                component={EnumerableField}
                type={LICENSE_CATEGORY}
                margin="normal"
              />
              <Field
                name={`licenseNumber`}
                component={BasicTextField}
                label="common.licenseNumber"
                // size="small"
                margin="normal"
              />
              <Field
                name={`nameOfRegulator`}
                component={EnumerableField}
                type={NAME_OF_REGULATOR}
                margin="normal"
              />
              <Field
                name={`jurisdictionOfRegulator`}
                component={EnumerableField}
                type={JURISDICTION_OF_REGULATOR}
                margin="normal"
              />
              {/* 6 */}
              <Field
                name={`typeOfListing`}
                component={EnumerableField}
                type={TYPE_OF_LISTING}
                margin="normal"
              />
              {/* <Field
                name={`nameOfRegulator`}
                component={EnumerableField}
                type={NAME_OF_REGULATOR}
                margin="normal"
              /> */}
              {/* <Field
                name={`jurisdictionOfRegulator`}
                component={EnumerableField}
                type={JURISDICTION_OF_REGULATOR}
                margin="normal"
              /> */}
              {/* 7 */}
              {entity.entryType === NATURAL_PERSON &&
                entity.tags.split(",").includes(POTENTIAL_CUSTOMER) && (
                  <Field
                    name={`tradingActivities`}
                    component={YesNoField}
                    label="common.tradingActivities"
                    // size="small"
                    margin="normal"
                  />
                )}
              {entity.entryType === LEGAL_ENTITY &&
                (entity.tags.split(",").includes(POTENTIAL_CUSTOMER) ||
                  entity.tags.split(",").includes(CUSTOMER)) && (
                  <Field
                    name={`transferredFromAnotherJurisdiction`}
                    component={YesNoField}
                    label="common.transferredFromAnotherJurisdiction"
                    // size="small"
                    margin="normal"
                  />
                )}
              {getIn(values, "transferredFromAnotherJurisdiction") === YES && (
                <Field
                  name={`transferredFromJurisdiction`}
                  component={EnumerableField}
                  type={JURISDICTION_OF_REGULATOR}
                  label="common.transferredFromJurisdiction"
                  // size="small"
                  margin="normal"
                />
              )}
              {entity.entryType === LEGAL_ENTITY &&
                (entity.tags.split(",").includes(POTENTIAL_CUSTOMER) ||
                  entity.tags.split(",").includes(CUSTOMER)) && (
                  <Field
                    name={`transferredToAnotherJurisdiction`}
                    component={YesNoField}
                    label="common.transferredToAnotherJurisdiction"
                    // size="small"
                    margin="normal"
                  />
                )}
              {getIn(values, "transferredToAnotherJurisdiction") === YES && (
                <Field
                  name={`transferredToJurisdiction`}
                  component={EnumerableField}
                  type={JURISDICTION_OF_REGULATOR}
                  label="common.transferredToJurisdiction"
                  // size="small"
                  margin="normal"
                />
              )}
              {entity.entryType === LEGAL_ENTITY &&
                (
                  entity.stages.find(
                    (s) => s.stageType === STAGE_1_2_TYPE
                  ) as ApiStageAdditionalDetail
                ).legalEntityType === "LIMITED_LIABILITY_COMPANY" &&
                (entity.tags.includes(POTENTIAL_CUSTOMER) ||
                  entity.tags.includes(CUSTOMER)) && (
                  <Field
                    name={`tradingOrHolding`}
                    component={TradingOrHoldingField}
                    label="common.tradingOrHolding"
                    // size="small"
                    margin="normal"
                  />
                )}
              {entity.entryType === LEGAL_ENTITY &&
                (
                  entity.stages.find(
                    (s) => s.stageType === STAGE_1_2_TYPE
                  ) as ApiStageAdditionalDetail
                ).legalEntityType === "LIMITED_LIABILITY_COMPANY" &&
                getIn(values, "tradingOrHolding") === HOLDING &&
                (entity.tags.split(",").includes(POTENTIAL_CUSTOMER) ||
                  entity.tags.split(",").includes(CUSTOMER)) && (
                  <Box
                    sx={{
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderColor: "#E6E8F0",
                      borderRadius: "4px",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                      paddingBottom: "16px",
                    }}
                  >
                    <InputLabel
                      error={Boolean(
                        getIn(touched, "investments") &&
                          !!getIn(errors, "investments")
                      )}
                      sx={{ mt: "10px", mb: "10px" }}
                    >
                      {t("common.investments")} :{" "}
                      {getIn(values, "investments").length}
                    </InputLabel>
                    <FieldArray name="investments">
                      {({ push, remove }) => (
                        <>
                          {getIn(values, "investments").length > 0 &&
                            getIn(values, "investments").map(
                              (a: any, index: number) => {
                                return (
                                  <div key={index}>
                                    <Box
                                      sx={{
                                        borderStyle: "solid",
                                        borderWidth: "1px",
                                        borderColor: "#E6E8F0",
                                        borderRadius: "4px",
                                        paddingLeft: "16px",
                                        paddingRight: "16px",
                                        paddingBottom: "16px",
                                        marginBottom: "16px",
                                      }}
                                    >
                                      <Field
                                        name={`investments.${index}.type`}
                                        component={EnumerableField}
                                        type={INVESTMENT_TYPE}
                                        label="common.typeOfInvestmentsHeld"
                                        // size="small"
                                        margin="normal"
                                      />
                                      <Field
                                        name={`investments.${index}.additionalInfo`}
                                        component={BasicTextField}
                                        label="common.additionalInfoOnInvestmentHeld"
                                        // size="small"
                                        margin="normal"
                                      />
                                      <Field
                                        name={`investments.${index}.jurisdiction`}
                                        component={EnumerableField}
                                        type={JURISDICTION_OF_REGULATOR}
                                        label="common.jurisdictionWhereInvestmentIsHeld"
                                        // size="small"
                                        margin="normal"
                                      />
                                      {index > 0 && (
                                        <Tooltip
                                          title={t(
                                            "common.removeThisInvestment"
                                          )}
                                        >
                                          <IconButton
                                            aria-label="remove"
                                            size="medium"
                                            onClick={() => remove(index)}
                                          >
                                            <Remove
                                              color="inherit"
                                              fontSize="inherit"
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </Box>
                                  </div>
                                );
                              }
                            )}

                          <Tooltip title={t("common.addInvestment")}>
                            <IconButton
                              aria-label="add"
                              size="medium"
                              onClick={() => push(investmentsInitialValues())}
                            >
                              <Add color="inherit" fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </FieldArray>
                  </Box>
                )}
              {/* 9 */}
              {(entity.tags.split(",").includes(POTENTIAL_CUSTOMER) ||
                entity.tags.split(",").includes(CUSTOMER)) && (
                <Field
                  name={`differentRegisteredAddress`}
                  component={YesNoField}
                  label="common.isAddressDifferentFromTheRegisteredAddress"
                  // size="small"
                  margin="normal"
                  info="common.indicatingThePrincipalPlaceOfBusinessInfoNote"
                />
              )}
              {/* 10 */}
              {(entity.tags.split(",").includes(POTENTIAL_CUSTOMER) ||
                entity.tags.split(",").includes(CUSTOMER)) && (
                <>
                  <Field
                    label={`common.operateInAnyOfTheseHighRiskIndustries`}
                    name={`highRiskIndustries`}
                    multiple
                    component={EnumerablesField}
                    type={HIGH_RISK_INDUSTRY}
                    margin="normal"
                  />

                  <Field
                    name={`cashIntensiveBusiness`}
                    component={YesNoField}
                    label="common.customerOperatesACashIntensiveBusiness"
                    // size="small"
                    margin="normal"
                  />

                  {getIn(values, "cashIntensiveBusiness") === YES && (
                    <Field
                      name={`cashIntensiveBusinessInfo`}
                      component={BasicTextField}
                      label="common.cashIntensiveBusinessInfo"
                      // size="small"
                      margin="normal"
                    />
                  )}

                  <Field
                    name={`dualUseItems`}
                    component={YesNoField}
                    label="common.operateIndualUseItems"
                    // size="small"
                    margin="normal"
                    info="common.inAccordanceWithSL36512InfoNote"
                  />

                  {getIn(values, "dualUseItems") === YES && (
                    <Field
                      name={`dualUseItemsInfo`}
                      component={BasicTextField}
                      label="common.dualUseItemsInfo"
                      // size="small"
                      margin="normal"
                    />
                  )}
                </>
              )}
              <>
                {(entity.entryType === LEGAL_ENTITY ||
                  (entity.entryType === NATURAL_PERSON &&
                    [true, false].includes(
                      getIn(values, "tradingActivities")
                    ))) && (
                  <>
                    <Field
                      name={`businessActivitiesDescription`}
                      component={BasicTextField}
                      label="common.descriptionOfThePrincipalBusinessActivities"
                      // size="small"
                      margin="normal"
                      rows="3"
                      info={
                        entity.entryType === LEGAL_ENTITY ||
                        (entity.entryType === NATURAL_PERSON &&
                          getIn(values, "tradingActivities") === true)
                          ? `common.descriptionOfThePrincipalBusinessActivitiesIInfoNote`
                          : entity.entryType === NATURAL_PERSON &&
                            getIn(values, "tradingActivities") === false
                          ? `common.descriptionOfThePrincipalBusinessActivitiesIIInfoNote`
                          : null
                      }
                    />
                    <Field
                      name={`expectedTotalIncome`}
                      label="common.indicationOfTheExpectedTotalIncomeGenerated"
                      component={MoneyField}
                      margin="normal"
                      info={
                        entity.entryType === LEGAL_ENTITY ||
                        (entity.entryType === NATURAL_PERSON &&
                          getIn(values, "tradingActivities") === true)
                          ? `common.indicationOfTheExpectedTotalIncomeGeneratedIInfoNote`
                          : entity.entryType === NATURAL_PERSON &&
                            getIn(values, "tradingActivities") === false
                          ? `common.indicationOfTheExpectedTotalIncomeGeneratedIIInfoNote`
                          : null
                      }
                    />
                    <Field
                      label={`common.indicationOfJurisdictionWhereActivities`}
                      name={`activitiesTakingPlaceIn`}
                      multiple
                      component={EnumerablesField}
                      type={JURISDICTION_OF_REGULATOR}
                      margin="normal"
                      info={
                        entity.entryType === LEGAL_ENTITY ||
                        (entity.entryType === NATURAL_PERSON &&
                          getIn(values, "tradingActivities") === true)
                          ? `common.indicationOfJurisdictionWhereActivitiesIInfoNote`
                          : entity.entryType === NATURAL_PERSON &&
                            getIn(values, "tradingActivities") === false
                          ? `common.indicationOfJurisdictionWhereActivitiesIIInfoNote`
                          : null
                      }
                    />
                    <Field
                      name={`activitiesTakingPlaceInNote`}
                      component={BasicTextField}
                      label="common.indicationOfJurisdictionWhereActivitiesInfo"
                      // size="small"
                      margin="normal"
                      rows="3"
                    />
                  </>
                )}
              </>
              {/* 11 */}
              <Field
                name={`bankingArrangements`}
                component={YesNoField}
                label="common.customerHasBankingArrangements"
                // size="small"
                margin="normal"
              />
              {getIn(values, "transferredFromAnotherJurisdiction") === YES && (
                <>
                  <Field
                    name={`bankName`}
                    component={BasicTextField}
                    label="common.bankName"
                    // size="small"
                    margin="normal"
                  />

                  <Field
                    name={`bankJurisdiction`}
                    component={EnumerableField}
                    type={JURISDICTION_OF_REGULATOR}
                    label="common.bankJurisdiction"
                    // size="small"
                    margin="normal"
                  />
                  <Field
                    name={`bankFacilitiesHeld`}
                    component={YesNoField}
                    label="common.bankFacilitiesHeld"
                    // size="small"
                    margin="normal"
                  />
                  <Field
                    name={`bankFacilitiesHeldInfo`}
                    component={BasicTextField}
                    label="common.bankFacilitiesHeldInfo"
                    // size="small"
                    margin="normal"
                  />

                  <Field
                    name={`authorizedBankRepresentatives`}
                    component={BasicTextField}
                    label="common.authorizedBankRepresentatives"
                    // size="small"
                    margin="normal"
                  />
                  <Field
                    name={`authorizedBankSignatories`}
                    component={BasicTextField}
                    label="common.authorizedBankSignatories"
                    // size="small"
                    margin="normal"
                  />
                </>
              )}
              {/* {(entity.tags.includes(POTENTIAL_CUSTOMER) ||
                entity.tags.includes(CUSTOMER)) && (
                <Field
                  name={`bankingArrangements`}
                  component={YesNoField}
                  label="common.customerHasBankingArrangements"
                  // size="small"
                  margin="normal"
                />
              )}
              <Box
                sx={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "#E6E8F0",
                  borderRadius: "4px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  paddingBottom: "16px",
                }}
              >
                <InputLabel
                  error={Boolean(
                    getIn(touched, "bankArrangements") &&
                      !!getIn(errors, "bankArrangements")
                  )}
                  sx={{ mt: "10px", mb: "10px" }}
                >
                  {t("common.bankArrangements")} :{" "}
                  {getIn(values, "bankArrangements").length}
                </InputLabel>
                <FieldArray name="bankArrangements">
                  {({
                    push: pushBankArrangement,
                    remove: removeBankArrangement,
                  }) => (
                    <>
                      {getIn(values, "bankArrangements").length > 0 &&
                        getIn(values, "bankArrangements").map(
                          (a: any, bankArrangementsIndex: number) => {
                            return (
                              <div key={bankArrangementsIndex}>
                                <Box
                                  sx={{
                                    borderStyle: "solid",
                                    borderWidth: "1px",
                                    borderColor: "#E6E8F0",
                                    borderRadius: "4px",
                                    paddingLeft: "16px",
                                    paddingRight: "16px",
                                    paddingBottom: "16px",
                                    marginBottom: "16px",
                                  }}
                                >
                                  <Field
                                    name={`bankArrangements.${bankArrangementsIndex}.bankName`}
                                    component={BasicTextField}
                                    label="common.bankName"
                                    // size="small"
                                    margin="normal"
                                  />

                                  <Field
                                    name={`bankArrangements.${bankArrangementsIndex}.bankJurisdiction`}
                                    component={EnumerableField}
                                    type={JURISDICTION_OF_REGULATOR}
                                    label="common.bankJurisdiction"
                                    // size="small"
                                    margin="normal"
                                  />
                                  <Field
                                    name={`bankArrangements.${bankArrangementsIndex}.bankFacilitiesHeld`}
                                    component={BasicTextField}
                                    label="common.bankFacilitiesHeld"
                                    // size="small"
                                    margin="normal"
                                  />
                                  <Box
                                    sx={{
                                      borderStyle: "solid",
                                      borderWidth: "1px",
                                      borderColor: "#E6E8F0",
                                      borderRadius: "4px",
                                      paddingLeft: "16px",
                                      paddingRight: "16px",
                                      paddingBottom: "16px",
                                    }}
                                  >
                                    <InputLabel
                                      error={Boolean(
                                        getIn(
                                          touched,
                                          `bankArrangements.${bankArrangementsIndex}.bankFacilities`
                                        ) &&
                                          !!getIn(
                                            errors,
                                            `bankArrangements.${bankArrangementsIndex}.bankFacilities`
                                          )
                                      )}
                                      sx={{ mt: "10px", mb: "10px" }}
                                    >
                                      {t("common.bankFacilities")} :{" "}
                                      {
                                        getIn(
                                          values,
                                          `bankArrangements.${bankArrangementsIndex}.bankFacilities`
                                        ).length
                                      }
                                    </InputLabel>
                                    <FieldArray
                                      name={`bankArrangements.${bankArrangementsIndex}.bankFacilities`}
                                    >
                                      {({ push, remove }) => (
                                        <>
                                          {getIn(
                                            values,
                                            `bankArrangements.${bankArrangementsIndex}.bankFacilities`
                                          ).length > 0 &&
                                            getIn(
                                              values,
                                              `bankArrangements.${bankArrangementsIndex}.bankFacilities`
                                            ).map((a: any, index: number) => {
                                              return (
                                                <div key={index}>
                                                  <Field
                                                    name={`bankArrangements.${bankArrangementsIndex}.bankFacilities.${index}`}
                                                    component={BasicTextField}
                                                    label="common.bankFacility"
                                                    // size="small"
                                                    margin="normal"
                                                  />

                                                  {index > 0 && (
                                                    <Tooltip
                                                      title={t(
                                                        "common.removeBankFacility"
                                                      )}
                                                    >
                                                      <IconButton
                                                        aria-label="remove"
                                                        size="medium"
                                                        onClick={() =>
                                                          remove(index)
                                                        }
                                                      >
                                                        <Remove
                                                          color="inherit"
                                                          fontSize="inherit"
                                                        />
                                                      </IconButton>
                                                    </Tooltip>
                                                  )}
                                                </div>
                                              );
                                            })}

                                          <Tooltip
                                            title={t("common.addBankFacility")}
                                          >
                                            <IconButton
                                              aria-label="add"
                                              size="medium"
                                              onClick={() => push("")}
                                            >
                                              <Add
                                                color="inherit"
                                                fontSize="inherit"
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </>
                                      )}
                                    </FieldArray>
                                  </Box>

                                  {bankArrangementsIndex > 0 && (
                                    <Tooltip
                                      title={t(
                                        "common.removeThisBankArrangement"
                                      )}
                                    >
                                      <IconButton
                                        aria-label="remove"
                                        size="medium"
                                        onClick={() =>
                                          removeBankArrangement(
                                            bankArrangementsIndex
                                          )
                                        }
                                      >
                                        <Remove
                                          color="inherit"
                                          fontSize="inherit"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                              </div>
                            );
                          }
                        )}

                      <Tooltip title={t("common.addBankArrangement")}>
                        <IconButton
                          aria-label="add"
                          size="medium"
                          onClick={() =>
                            pushBankArrangement(bankArrangementsInitialValues())
                          }
                        >
                          <Add color="inherit" fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </FieldArray>
              </Box> */}
              {/* <Box
                sx={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "#E6E8F0",
                  borderRadius: "4px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  paddingBottom: "16px",
                }}
              >
                <InputLabel
                  error={Boolean(
                    getIn(touched, "authorizedBankRepresentatives") &&
                      !!getIn(errors, "authorizedBankRepresentatives")
                  )}
                  sx={{ mt: "10px", mb: "10px" }}
                >
                  {t("common.authorizedBankRepresentatives")} :{" "}
                  {getIn(values, "authorizedBankRepresentatives").length}
                </InputLabel>
                <FieldArray name="authorizedBankRepresentatives">
                  {({ push, remove }) => (
                    <>
                      {getIn(values, "authorizedBankRepresentatives").length >
                        0 &&
                        getIn(values, "authorizedBankRepresentatives").map(
                          (a: any, index: number) => {
                            return (
                              <div key={index}>
                                <Field
                                  name={`authorizedBankRepresentatives.${index}`}
                                  component={BasicTextField}
                                  label="common.authorizedBankRepresentative"
                                  // size="small"
                                  margin="normal"
                                />

                                {index > 0 && (
                                  <Tooltip
                                    title={t(
                                      "common.removeAuthorizedBankRepresentative"
                                    )}
                                  >
                                    <IconButton
                                      aria-label="remove"
                                      size="medium"
                                      onClick={() => remove(index)}
                                    >
                                      <Remove
                                        color="inherit"
                                        fontSize="inherit"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            );
                          }
                        )}

                      <Tooltip
                        title={t("common.addAuthorizedBankRepresentative")}
                      >
                        <IconButton
                          aria-label="add"
                          size="medium"
                          onClick={() => push("")}
                        >
                          <Add color="inherit" fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </FieldArray>
              </Box> */}
              {/* <Box
                sx={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "#E6E8F0",
                  borderRadius: "4px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  paddingBottom: "16px",
                }}
              >
                <InputLabel
                  error={Boolean(
                    getIn(touched, "authorizedBankSignatories") &&
                      !!getIn(errors, "authorizedBankSignatories")
                  )}
                  sx={{ mt: "10px", mb: "10px" }}
                >
                  {t("common.authorizedBankSignatories")} :{" "}
                  {getIn(values, "authorizedBankSignatories").length}
                </InputLabel>
                <FieldArray name="authorizedBankSignatories">
                  {({ push, remove }) => (
                    <>
                      {getIn(values, "authorizedBankSignatories").length > 0 &&
                        getIn(values, "authorizedBankSignatories").map(
                          (a: any, index: number) => {
                            return (
                              <div key={index}>
                                <Field
                                  name={`authorizedBankSignatories.${index}`}
                                  component={BasicTextField}
                                  label="common.authorizedBankSignatory"
                                  // size="small"
                                  margin="normal"
                                />

                                {index > 0 && (
                                  <Tooltip
                                    title={t(
                                      "common.RemoveAuthorizedBankSignatory"
                                    )}
                                  >
                                    <IconButton
                                      aria-label="remove"
                                      size="medium"
                                      onClick={() => remove(index)}
                                    >
                                      <Remove
                                        color="inherit"
                                        fontSize="inherit"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            );
                          }
                        )}

                      <Tooltip title={t("common.addauthorizedBankSignatory")}>
                        <IconButton
                          aria-label="add"
                          size="medium"
                          onClick={() => push("")}
                        >
                          <Add color="inherit" fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </FieldArray>
              </Box> */}
              {/* Not KYC */}
              {(entity.tags.includes(POTENTIAL_CUSTOMER) ||
                entity.tags.includes(CUSTOMER)) && (
                <Field
                  name={`fundsHeld`}
                  component={YesNoField}
                  label="common.areAnyFundsHeldOnTrust"
                  // size="small"
                  margin="normal"
                />
              )}
              {getIn(values, "fundsHeld") === YES && (
                <Field
                  name={`fundsHeldInfo`}
                  component={BasicTextField}
                  label="common.infoAboutFundsHeld"
                  // size="small"
                  margin="normal"
                />
              )}
              <Field
                name={"yearEnd"}
                label="common.yearEnd"
                component={DateField}
              />
              <Field
                name="amountAtYearEnd"
                label="common.amountOfFundsHeldAtYearEnd"
                component={MoneyField}
                margin="normal"
              />
              {/* 12 */}
              {(entity.tags.includes(POTENTIAL_CUSTOMER) ||
                entity.tags.includes(CUSTOMER)) && (
                <>
                  <Field
                    name={`raisedCapital`}
                    component={YesNoField}
                    label="common.raisedCapitalThroughInitialCoinOfferingsInfoNote"
                    // size="small"
                    margin="normal"
                  />

                  {getIn(values, "raisedCapital") === YES && (
                    <Field
                      name="amountOfCapitalRaised"
                      label="common.accountOfCapitalRaisedInfoNote"
                      component={MoneyField}
                      margin="normal"
                    />
                  )}

                  <Field
                    name={`holdsVirtualFinancialAssets`}
                    component={YesNoField}
                    label="common.holdsVirtualFinancialAssetsInfoNote"
                    // size="small"
                    margin="normal"
                    info={"common.virtualFinancialAssetShallBeInfoNote"}
                  />

                  <Field
                    name={`transactionsVirtualFinancialAssets`}
                    component={YesNoField}
                    label="common.carriesOutTransactionsInfoNote"
                    // size="small"
                    margin="normal"
                  />
                </>
              )}
              <Box
                sx={{
                  mt: "24px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    setFieldValue("stageStatus", DRAFT_STAGE_STATUS);
                  }}
                >
                  {t("common.saveAsDraft")}
                </LoadingButton>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    // Get Stage Data
                    const stageData: ApiStageBusinessActivity =
                      entity.stages.find(
                        (s) => s.stageType === STAGE_2_2_TYPE
                      ) as ApiStageBusinessActivity;

                    if (
                      profile?.id === entity.reviewerId &&
                      stageData.stageStatus === PENDING_APPROVAL_STAGE_STATUS
                    ) {
                      setFieldValue(
                        "stageStatus",
                        PENDING_APPROVAL_STAGE_STATUS
                      );
                    } else {
                      setFieldValue("stageStatus", PENDING_STAGE_STATUS);
                    }
                  }}
                >
                  {t("common.submit")}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
