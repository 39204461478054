import { Description } from "@mui/icons-material"
import { ApiEnumeration } from "../../../../models/enumerations"
import { UpdateEnumerationFormValues } from "./types-dto"

export const updateEnumerationFormValuesInitialValue = (apiEnumeration: ApiEnumeration): UpdateEnumerationFormValues => {
    return {
        id: apiEnumeration.id,
        value: apiEnumeration.value,
        type: apiEnumeration.type,
        valueDescription: apiEnumeration.valueDescription
    }
}