import { Route, Routes } from "react-router";
import MiddleTemplate from "../../components/Layouts/MiddleTemplate";
import { LazyLoadableScreen } from "../../navigation/LazyLoadableScreen";

const TenantInputScreen = LazyLoadableScreen(() => import("./TenantInput"));

export const TenantInputRoutes = () => (
  <Routes>
    <Route path="/" element={<MiddleTemplate />}>
      <Route path="/" element={<TenantInputScreen />} />
    </Route>
  </Routes>
);
