import { ApiMoney, Money } from "."
import { ApiEnumeration } from "../../../../models/enumerations"
import { Nullable } from "../../../../utils/types"


export const moneyInitialValues = (currency: ApiEnumeration, money?: Money): Money => {
    return {
        value: '',
        currency: currency,
        ...(money ? money : {})
    }
}


export const addMoney = (a: Money, b: Money): Money => {
    const result: Money = {
        value: (Number(a.value) + Number(b.value)).toString(),
        currency: b.currency
    };
    return result;
}

export const addApiMoney = (a: ApiMoney, b: ApiMoney): ApiMoney => {
    const result: ApiMoney = {
        value: (Number(a.value) + Number(b.value)).toString(),
        currency: a.currency
    };
    return result;
}


export const subtructMoney = (a: Money, b: Money): Money => {
    const result: Money = {
        value: (Number(a.value) - Number(b.value)).toString(),
        currency: a.currency
    };
    return result;
}


export const subtructApiMoney = (a: ApiMoney, b: ApiMoney): ApiMoney => {
    const result: ApiMoney = {
        value: (Number(a.value) - Number(b.value)).toString(),
        currency: a.currency
    };
    return result;
}


export const isApiMoneyEqual = (a: ApiMoney, b: ApiMoney): boolean => {
    return a.currency === b.currency && a.value === b.value;
}


export const isMoneyEqual = (a: Money, b: Money): boolean => {
    return a.currency != null && a.currency.id === b.currency?.id && a.value === b.value;
}