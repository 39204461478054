import { Add, Close, Remove } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import {
  Box,
  Divider,
  IconButton,
  InputLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { Field, FieldArray, Formik, getIn } from "formik";
import React, { FC } from "react";
import toast from "react-hot-toast";

import {
  contactDetailsFormValuesInitialValue,
  ContactDetailsFormValues,
  contactDetailsFormValidationSchema,
  contactPersonInitialValues,
} from ".";

import { useTranslation } from "react-i18next";

import {
  AddressField,
  BasicTextField,
  EmailField,
  PhoneField,
  YES,
  YesNoField,
} from "../../../../../../forms";
import { usePermissions } from "../../../../../../models/auth";
import {
  ApiEntity,
  PENDING_STAGE_STATUS,
  DRAFT_STAGE_STATUS,
  mapContactDetailsFormValuesToApiStageContactDetails,
  useUpdateEntityStageMutation,
  ApiStageContactDetail,
  STAGE_1_3_TYPE,
  PENDING_APPROVAL_STAGE_STATUS,
} from "../../../../../../models/entities";
import { ApiUserResponse } from "../../../../../../models/users";
interface ContactDetailsFormProps {
  entity: ApiEntity;
  onClose: () => void;
  profile: ApiUserResponse | undefined;
}
export const ContactDetailsForm: FC<ContactDetailsFormProps> = ({
  entity,
  onClose,
  profile,
}) => {
  const [updateEntityStage] = useUpdateEntityStageMutation();
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  return (
    <Formik<ContactDetailsFormValues>
      enableReinitialize
      initialValues={{
        ...contactDetailsFormValuesInitialValue(entity),
      }}
      validationSchema={contactDetailsFormValidationSchema(
        hasPermission("ADMIN")
      )}
      onSubmit={async (values): Promise<void> => {
        try {
          const apiContactDetails: ApiStageContactDetail =
            mapContactDetailsFormValuesToApiStageContactDetails(entity, values);
          // const apiEntity: ApiStageBasicDetail =
          await updateEntityStage(apiContactDetails).unwrap();

          toast.success(t("common.updatedSuccessfully"));
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        setFieldValue,
        errors,
        touched,
      }): JSX.Element => (
        <>
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "24px 38px 24px 32px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={"textPrimary"} variant="h5">
                {t("common.contacts")}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: "32px" }}>
            <form noValidate onSubmit={handleSubmit}>
              <Field
                name={"registeredAddress"}
                label={"common.residentialAddress"}
                component={AddressField}
                disabled={getIn(values, "filledByEntityDuringKyc")}
              />

              <Field
                name={`hasADifferentCorrespondingAddress`}
                component={YesNoField}
                label="common.hasADifferentCorrespondingAddress"
                // size="small"
                margin="normal"
                disabled={getIn(values, "filledByEntityDuringKyc")}
              />

              {getIn(values, "hasADifferentCorrespondingAddress") === YES && (
                <Field
                  name={"correspondenceAddress"}
                  label={"common.correspondenceAddress"}
                  component={AddressField}
                  disabled={getIn(values, "filledByEntityDuringKyc")}
                />
              )}

              <Box
                sx={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "#E6E8F0",
                  borderRadius: "4px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  paddingBottom: "16px",
                }}
              >
                <InputLabel
                  error={Boolean(
                    getIn(touched, "contactPersons") &&
                      !!getIn(errors, "contactPersons")
                  )}
                  sx={{ mt: "10px", mb: "10px" }}
                >
                  {t("common.contactPersons")}
                </InputLabel>
                {/* <InputLabel
          error={
            getIn(touched, "contactPersons") && getIn(errors, "contactPersons")
          }
        >
          {getIn(touched, "contactPersons") && t(getIn(errors, "contactPersons"))}
        </InputLabel> */}
                <FieldArray name="contactPersons">
                  {({ push, remove }) => (
                    <>
                      {getIn(values, "contactPersons").length > 0 &&
                        getIn(values, "contactPersons").map(
                          (a: any, index: number) => {
                            return (
                              <div key={index}>
                                <Box
                                  sx={{
                                    borderStyle: "solid",
                                    borderWidth: "1px",
                                    borderColor: "#E6E8F0",
                                    borderRadius: "4px",
                                    paddingLeft: "16px",
                                    paddingRight: "16px",
                                    paddingBottom: "16px",
                                    marginBottom: "16px",
                                  }}
                                >
                                  <Field
                                    name={`contactPersons.${index}.firstName`}
                                    component={BasicTextField}
                                    label="common.firstName"
                                    // size="small"
                                    margin="normal"
                                  />
                                  <Field
                                    name={`contactPersons.${index}.lastName`}
                                    component={BasicTextField}
                                    label="common.lastName"
                                    // size="small"
                                    margin="normal"
                                  />
                                  <Field
                                    name={`contactPersons.${index}.designation`}
                                    component={BasicTextField}
                                    label="common.designation"
                                    // size="small"
                                    margin="normal"
                                  />
                                  <Field
                                    name={`contactPersons.${index}.email`}
                                    component={EmailField}
                                    label="common.email"
                                    // size="small"
                                    margin="normal"
                                  />
                                  <Field
                                    name={`contactPersons.${index}.contactNumber`}
                                    component={PhoneField}
                                    // label="common.contactNumber"
                                    // size="small"
                                    margin="normal"
                                  />

                                  {index > 0 && (
                                    <Tooltip
                                      title={t(
                                        "common.removeThisContactPerson"
                                      )}
                                    >
                                      <IconButton
                                        aria-label="remove"
                                        size="medium"
                                        onClick={() => remove(index)}
                                      >
                                        <Remove
                                          color="inherit"
                                          fontSize="inherit"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                              </div>
                            );
                          }
                        )}

                      <Tooltip title={t("common.addContactPerson")}>
                        <IconButton
                          aria-label="add"
                          size="medium"
                          onClick={() => push(contactPersonInitialValues())}
                        >
                          <Add color="inherit" fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </FieldArray>
              </Box>

              <Box
                sx={{
                  mt: "24px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    setFieldValue("stageStatus", DRAFT_STAGE_STATUS);
                  }}
                >
                  {t("common.saveAsDraft")}
                </LoadingButton>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    // Get Stage Data
                    const stageData: ApiStageContactDetail = entity.stages.find(
                      (s) => s.stageType === STAGE_1_3_TYPE
                    ) as ApiStageContactDetail;

                    if (
                      profile?.id === entity.reviewerId &&
                      stageData.stageStatus === PENDING_APPROVAL_STAGE_STATUS
                    ) {
                      setFieldValue(
                        "stageStatus",
                        PENDING_APPROVAL_STAGE_STATUS
                      );
                    } else {
                      setFieldValue("stageStatus", PENDING_STAGE_STATUS);
                    }
                  }}
                >
                  {t("common.submit")}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
