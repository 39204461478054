import type { FC } from "react";
// import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { selectIsAuthorized } from "../selectors";

// import {
//   selectIsAuthorized,
//   //   selectUserStatus,
// } from "src/models/auth/selectors";
// import { useSelector } from "src/services/redux/store";

interface Props {
  children?: React.ReactNode;
}

export const AuthGuard: FC<Props> = ({ children }) => {
  const isAuthorized = useSelector(selectIsAuthorized);
  const { tenantNameOrId } = useParams<{ tenantNameOrId: string }>();
  // const userStatus = useSelector(selectUserStatus);
  // const location = useLocation();
  // alert(isAuthorized);
  if (!isAuthorized) {
    return <Navigate to={`/${tenantNameOrId as string}/auth`} />;
  }
  // if (userStatus == "EXPIRED" && location.pathname != "/auth/change-password") {
  //   toast.error("Password expired. Please change password.");
  //   return <Navigate to="/auth/change-password" />;
  // }

  return <>{children}</>;
};
