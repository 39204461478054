import { ApiEntity, ApiStageAdditionalDetail, ApiStageCdd, STAGE_1_2_TYPE, STAGE_2_1_TYPE } from "../../../../../../models/entities"
import { CDDInformationFormValues } from "."
import { ApiEnumeration, GENDER, IDENTITY_DOCUMENT_TYPE, INDUSTRY, LEGAL_ENTITY_TYPE, PROOF_OF_ADDRESS } from "../../../../../../models/enumerations"
import { NO, YES, addressInitialValues, booleanToYesNo, mapApiAddressToAddress } from "../../../../../../forms";
import { parseISO } from "date-fns";
import { Country, countries } from "../../../../../../utils/countries";

export const cDDInformationFormValuesInitialValue = (
    entity: ApiEntity,
    enumerables: ApiEnumeration[]): CDDInformationFormValues => {
    // Get Stage Data
    const stageData: ApiStageCdd = entity.stages.find((s) => s.stageType === STAGE_2_1_TYPE) as ApiStageCdd;
    // If draft is available use that
    const data: ApiStageCdd = stageData.draftData ? stageData.draftData as ApiStageCdd : stageData;
    return {

        stageType: STAGE_2_1_TYPE,
        stageStatus: data.stageStatus,
        id: entity.id,
        // identityDocumentType: api?.identityDocumentType ? enumerableIdentityDocumentTypes.filter(x => api.identityDocumentType?.includes(x.value)) : [],
        identityDocumentType: data?.identityDocumentType ? enumerables.filter(x => x.type === IDENTITY_DOCUMENT_TYPE).filter(x => data.identityDocumentType?.includes(x.value)) : [],
        idNo: data.idNo || '',
        documentNo: data.documentNo || '',
        documentExpiryDate: data.documentExpiryDate ? parseISO(data.documentExpiryDate) : null,
        documentCountry: data.documentCountry ? countries.find(x => x.code === data.documentCountry) as Country : null,
        proofOfAddress: data.proofOfAddress ? enumerables.filter(x => x.type === PROOF_OF_ADDRESS).filter(x => data.proofOfAddress?.includes(x.value)) : [],
        proofOfAddressDate: data.proofOfAddressDate ? parseISO(data.proofOfAddressDate) : null,
        certificateIncorporation: data.certificateIncorporation || '',
        memorandumAndArticles: data.memorandumAndArticles || '',
        vatRegCertificate: data.vatRegCertificate || '',
        beneficialOwnersDocument: data.beneficialOwnersDocument || '',
        certificateGoodStanding: data.certificateGoodStanding || '',
        certificateIncumbency: data.certificateIncumbency || '',
        otherDocumentationCollected: data.otherDocumentationCollected || '',
        curriculumVitae: data.curriculumVitae || '',
        curriculumVitaeDate: data.curriculumVitaeDate ? parseISO(data.curriculumVitaeDate) : null,
        groupStructure: data.groupStructure || '',
        hasPepScreeningConducted: data.hasPepScreeningConducted ? booleanToYesNo(data.hasPepScreeningConducted) : '',
        pepScreeningConductedDate: data.pepScreeningConductedDate ? parseISO(data.pepScreeningConductedDate) : null,
        hasPep: data.hasPep ? booleanToYesNo(data.hasPep) : '',
        pepInfo: data.pepInfo || '',
        hasSanctions: data.hasSanctions ? booleanToYesNo(data.hasSanctions) : '',
        sanctionsInfo: data.sanctionsInfo || '',
        hasAdverseMedia: data.hasAdverseMedia ? booleanToYesNo(data.hasAdverseMedia) : '',
        adverseMediaInfo: data.adverseMediaInfo || '',
        hasLawEnforcement: data.hasLawEnforcement ? booleanToYesNo(data.hasLawEnforcement) : '',
        lawEnforcementInfo: data.lawEnforcementInfo || '',
        hasDisqualifiedDirector: data.hasDisqualifiedDirector ? booleanToYesNo(data.hasDisqualifiedDirector) : '',
        disqualifiedDirectorInfo: data.disqualifiedDirectorInfo || '',
        hasInsolvent: data.hasInsolvent ? booleanToYesNo(data.hasInsolvent) : '',
        insolventInfo: data.insolventInfo || '',
        actionsTakenTrueMatch: data.actionsTakenTrueMatch || '',
        hasAdverseMediaSearchInternet: data.hasAdverseMediaSearchInternet ? booleanToYesNo(data.hasAdverseMediaSearchInternet) : '',
        adverseMediaSearchInternetDate: data.adverseMediaSearchInternetDate ? parseISO(data.adverseMediaSearchInternetDate) : null,
        hasAdverseMediaSearchAssetRecoveryBureau: data.hasAdverseMediaSearchAssetRecoveryBureau ? booleanToYesNo(data.hasAdverseMediaSearchAssetRecoveryBureau) : '',
        adverseMediaSearchAssetRecoveryBureauDate: data.adverseMediaSearchAssetRecoveryBureauDate ? parseISO(data.adverseMediaSearchAssetRecoveryBureauDate) : null,
        hasAdverseMediaSearchJudgementCourt: data.hasAdverseMediaSearchJudgementCourt ? booleanToYesNo(data.hasAdverseMediaSearchJudgementCourt) : '',
        adverseMediaSearchJudgementCourtDate: data.adverseMediaSearchJudgementCourtDate ? parseISO(data.adverseMediaSearchJudgementCourtDate) : null,
        hasAdverseMediaFoundInternet: data.hasAdverseMediaFoundInternet ? booleanToYesNo(data.hasAdverseMediaFoundInternet) : '',
        adverseMediaFoundInternetInfo: data.adverseMediaFoundInternetInfo || '',
        hasAdverseMediaFoundAssetRecoveryBureau: data.hasAdverseMediaFoundAssetRecoveryBureau ? booleanToYesNo(data.hasAdverseMediaFoundAssetRecoveryBureau) : '',
        adverseMediaFoundAssetRecoveryBureauInfo: data.adverseMediaFoundAssetRecoveryBureauInfo || '',
        hasAdverseMediaFoundJudgementCourt: data.hasAdverseMediaFoundJudgementCourt ? booleanToYesNo(data.hasAdverseMediaFoundJudgementCourt) : '',
        adverseMediaFoundJudgementCourtInfo: data.adverseMediaFoundJudgementCourtInfo || '',
        adverseMediaSummary: data.adverseMediaSummary || '',
        boInformationProof: data.boInformationProof || '',
        boInformationProofDate: data.boInformationProofDate ? parseISO(data.boInformationProofDate) : null,
        otherMeasures: data.otherMeasures || '',
        hasPoliceConductCertificate: data.hasPoliceConductCertificate ? YES : NO,
        policeConductCertificateDate: data.hasPoliceConductCertificate && data.policeConductCertificateDate ? parseISO(data.policeConductCertificateDate) : null,
        hasBankReference: data.hasBankReference ? YES : NO,
        bankReferenceDate: data.hasBankReference && data.bankReferenceDate ? parseISO(data.bankReferenceDate) : null,
        hasProfessionalReference: data.hasProfessionalReference ? YES : NO,
        professionalReferenceDate: data.hasProfessionalReference && data.professionalReferenceDate ? parseISO(data.professionalReferenceDate) : null,
        otherEddMeasures: data.otherEddMeasures || '',
        hasObtainedMlroApproval: data.hasObtainedMlroApproval ? YES : NO,
    }
}



// Only shown for
// - Entry Type 'Natural Person'; AND
// - Tagged as 'Potential Customer/Customer'; OR
// - Relationship type 'Ultimate Beneficial Owner, Director, Agent, Beneficial Owner, Introducer, Intermediary, Representative, 'Control by other means', Third Party (Reliance)

// // Can we also have the option to include such details for the non-mandatory relationship types (e.g. for Company Secretary) in case we happen to have this documentation in hand, due to for example company incorporation.
// export const showNaturalPersonFields = (entity: ApiEntity): boolean => {
//     return entity.entryType === NATURAL_PERSON && ([POTENTIAL_CUSTOMER, CUSTOMER]
//         .includes(entity.tag!) ||
//         entity.relatedParties
//             .map(rp => rp.relationship)
//             .filter(r => ['ULTIMATE_BENEFICIAL_OWNER', 'DIRECTOR', 'AGENT', 'BENEFICIAL_OWNERS', 'INTRODUCER', 'INTERMEDIARY', 'REPRESENTATIVE', 'CONTROL_BY_OTHER_MEANS', 'THIRD_PARTY_(RELIANCE)'].includes(r)).length > 0);

// }


// // Only shown for
// // - Entry Type 'Legal Entity'; AND
// // - Tagged as 'Potential Customer/Customer'; OR
// // - Relationship type 'Ultimate Beneficial Owner, Director, Agent, Beneficial Owner, Introducer, Intermediary, Representative, Third Party (Reliance)

// // Can we also have the option to include such details for the non-mandatory relationship types (e.g. for Company Secretary) in case we happen to have this documentation in hand, due to for example company incorporation.
// export const showLegalEntityFields = (entity: ApiEntity): boolean => {
//     return entity.entryType === LEGAL_ENTITY && ([POTENTIAL_CUSTOMER, CUSTOMER]
//         .includes(entity.tag!) ||
//         entity.relatedParties
//             .map(rp => rp.relationship)
//             .filter(r => ['ULTIMATE_BENEFICIAL_OWNER', 'DIRECTOR', 'AGENT', 'BENEFICIAL_OWNERS', 'INTRODUCER', 'INTERMEDIARY', 'REPRESENTATIVE', 'THIRD_PARTY_(RELIANCE)'].includes(r)).length > 0);

// }

// // Only shown for
// // - Entry Type 'Natural Person'; AND
// // - Tagged as 'Potential Customer/Customer'; OR
// // - Relationship type 'Ultimate Beneficial Owner,

// export const showCurriculumVitaeFields = (entity: ApiEntity): boolean => {
//     return entity.entryType === NATURAL_PERSON && ([POTENTIAL_CUSTOMER, CUSTOMER]
//         .includes(entity.tag!) ||
//         entity.relatedParties
//             .map(rp => rp.relationship)
//             .filter(r => ['ULTIMATE_BENEFICIAL_OWNER'].includes(r)).length > 0);

// }


// // Only shown for - Entry Type 'Legal Entity'; AND - Tagged as 'Potential Customer/Customer';

// export const showOrganogramGroupStructureFields = (entity: ApiEntity): boolean => {
//     return entity.entryType === LEGAL_ENTITY && [POTENTIAL_CUSTOMER, CUSTOMER]
//         .includes(entity.tag!);
// }


// // Only shown for
// // - Tagged as 'Potential Customer/Customer'; OR
// // - Relationship type 'Ultimate Beneficial Owner, Director, Agent, Beneficial Owner, Introducer, Representative, Intermediary, 'Control by other means', Third Party (Reliance)

// export const showPEPandAdverseMediaFields = (entity: ApiEntity): boolean => {
//     return ([POTENTIAL_CUSTOMER, CUSTOMER]
//         .includes(entity.tag!) ||
//         entity.relatedParties
//             .map(rp => rp.relationship)
//             .filter(r => ['ULTIMATE_BENEFICIAL_OWNER', 'DIRECTOR', 'AGENT', 'BENEFICIAL_OWNERS', 'INTRODUCER', 'INTERMEDIARY', 'REPRESENTATIVE', 'CONTROL_BY_OTHER_MEANS', 'THIRD_PARTY_(RELIANCE)'].includes(r)).length > 0);

// }


// // Only shown for
// // - Tagged as 'Potential Customer/Customer'; OR
// // - Relationship type 'Ultimate Beneficial Owner,

// export const showSoWSoFFields = (entity: ApiEntity): boolean => {
//     return [POTENTIAL_CUSTOMER, CUSTOMER]
//         .includes(entity.tag!) ||
//         entity.relatedParties
//             .map(rp => rp.relationship)
//             .filter(r => ['ULTIMATE_BENEFICIAL_OWNER'].includes(r)).length > 0;

// }

// // Only shown for
// // - Tagged as 'Potential Customer/Customer'; OR
// // - Relationship type 'Corporate Shareholder', 'Subsidiary'

// export const showProofOfBoFields = (entity: ApiEntity): boolean => {
//     return [POTENTIAL_CUSTOMER, CUSTOMER]
//         .includes(entity.tag!) ||
//         entity.relatedParties
//             .map(rp => rp.relationship)
//             .filter(r => ['CORPORATE_SHAREHOLDER', 'SUBSIDIARY'].includes(r)).length > 0;

// }