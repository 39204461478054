import { Color, colors } from "@mui/material";
import { ReceiptAllocationStatus } from ".";

export const NO_ALLOCATION_RECEIPT_ALLOCATION_STATUS = 'NO_ALLOCATION';
export const PARTIAL_ALLOCATION_RECEIPT_ALLOCATION_STATUS = 'PARTIAL_ALLOCATION';
export const FULL_ALLOCATION_RECEIPT_ALLOCATION_STATUS = 'FULL_ALLOCATION';
export const receiptAllocationStatuses = [NO_ALLOCATION_RECEIPT_ALLOCATION_STATUS, PARTIAL_ALLOCATION_RECEIPT_ALLOCATION_STATUS, FULL_ALLOCATION_RECEIPT_ALLOCATION_STATUS] as const;
export const receiptAllocationStatusesColours = [colors.grey, colors.orange, colors.green];
export const receiptAllocationStatusesColourMap = new Map<ReceiptAllocationStatus, Color>(receiptAllocationStatuses.map((v, i) => [v, receiptAllocationStatusesColours[i]]));;


