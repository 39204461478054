import { Route, Routes } from "react-router";
import { LazyLoadableScreen } from "../../navigation/LazyLoadableScreen";

const SignatoryListScreen = LazyLoadableScreen(() => import("./SignatoryList"));
const SignatoryDetailsScreen = LazyLoadableScreen(
  () => import("./SignatoryDetails")
);

export const SignatoriesRoutes = () => (
  <Routes>
    <Route path="/" element={<SignatoryListScreen />} />
    <Route path="/:id" element={<SignatoryDetailsScreen />} />
  </Routes>
);
