import { Chip, Color } from "@mui/material";
import { capitalCase } from "change-case";

// https://stackoverflow.com/questions/68757395/how-to-make-a-functional-react-component-with-generic-type
export interface StatusLabelProps<T> {
  colourMap: Map<T, Color>;
  status: T;
  size?: "small" | "medium";
}

export const StatusLabel = <T,>({
  colourMap,
  status,
  size = "medium",
}: StatusLabelProps<T>) => {
  const backgroundColor =
    colourMap.get(status) && `${colourMap.get(status)![50]} !important`;
  const color = colourMap.get(status) && colourMap.get(status)![400];
  return (
    <Chip
      label={capitalCase(status as string)}
      sx={{ backgroundColor, color }}
      size={size}
    />
  );
};
