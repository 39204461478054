import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box, FormControl, FormHelperText, TextField } from "@mui/material";
import { isValid } from "date-fns";
import { FieldProps, getIn } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Nullable } from "../../../../utils/types";
export interface TimeFieldProps {
  disabled: boolean;
  label?: string;
  size?: "small" | "medium";
  margin?: "none" | "dense" | "normal";
}
export const TimeField: FC<TimeFieldProps & FieldProps<Date, Date>> = ({
  disabled = false,
  label = "common.time",
  size = "medium",
  margin,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* {JSON.stringify(values)} */}
      <FormControl
        margin={margin}
        variant="outlined"
        fullWidth
        error={Boolean(
          getIn(touched, field.name) && !!getIn(errors, field.name)
        )}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            // InputAdornmentProps={{
            //   sx: { ".MuiButtonBase-root": { padding: "0 !important" } },
            // }}
            // disableOpenPicker
            ampm={false}
            label={t(label)}
            onChange={(newValue: Nullable<Date>) => {
              // if (!isValid(newValue)) {
              //   return;
              // }
              setFieldValue(`${field.name}`, newValue);
            }}
            sx={{
              textarea: {
                resize: "auto",
              },
              ".MuiButtonBase-root": { padding: "0 !important" },
            }}
            slotProps={{
              textField: {
                size,
                multiline: true,
                // rows: "10",
              },
            }}
            // renderInput={(params: any) => (
            //   <>

            //     <TextField
            //       {...params}
            //       size={size}
            //       multiline={true}
            //       onBlur={handleBlur(`${field.name}`)}
            //       error={Boolean(
            //         getIn(touched, field.name) && !!getIn(errors, field.name)
            //       )}
            //     />
            //   </>
            // )}
            value={getIn(values, field.name)}
            disabled={disabled}
          />

          {/* <DatePicker
            inputFormat="dd/MM/yyyy"
            // disableFuture
            label={t(label)}
            onChange={(newValue: Nullable<Date>) => {
              if (!isValid(newValue)) {
                return;
              }
              setFieldValue(`${field.name}`, newValue);
            }}
            openTo="year"
            reduceAnimations
            renderInput={(params: any) => (
              <>
                {JSON.stringify(params.inputProps.value)} <br />
                {JSON.stringify(params.error)}
                <br />
                {JSON.stringify(Object.keys(params))}
                <br />
                {JSON.stringify(Object.keys(params.inputProps))}
                <br />
                <TextField
                  {...params}
                  size={size}
                  onBlur={handleBlur(`${field.name}`)}
                  error={Boolean(
                    getIn(touched, field.name) && !!getIn(errors, field.name)
                  )}
                />
              </>
            )}
            toolbarTitle={t(label)}
            value={getIn(values, field.name)}
            views={["year", "month", "day"]}
            disabled={disabled}
          /> */}
        </LocalizationProvider>
        <FormHelperText error>
          {getIn(touched, field.name) && t(getIn(errors, field.name))}
        </FormHelperText>
      </FormControl>
    </>
  );
};
