import { ApiEntity, ApiStageSourceOfFunds, STAGE_2_7_TYPE } from "../../../../../../models/entities"
import { SourceOfFundsFormValues } from "."
import { ApiEnumeration, DEALING_DIRECTLY_WITH, INTRODUCED_BY } from "../../../../../../models/enumerations"
import { booleanToYesNo, onboardingTypeInitialValue } from "../../../../../../forms";
import { Country, countries } from "../../../../../../utils/countries";

export const sourceOfFundsFormValuesInitialValue = (
    entity: ApiEntity,
    enumerables: ApiEnumeration[]): SourceOfFundsFormValues => {
    // Get Stage Data
    const stageData: ApiStageSourceOfFunds = entity.stages.find((s) => s.stageType === STAGE_2_7_TYPE) as ApiStageSourceOfFunds;
    // If draft is available use that
    const data: ApiStageSourceOfFunds = stageData.draftData ? stageData.draftData as ApiStageSourceOfFunds : stageData;
    return {
        stageType: STAGE_2_7_TYPE,
        stageStatus: data.stageStatus,
        id: entity.id,

        hasSourceOfFundsCollated: data.hasSourceOfFundsCollated != null ? booleanToYesNo(data.hasSourceOfFundsCollated) : '',
        sourceOfFundsCollatedInfo: data.hasSourceOfFundsCollated ? data.sourceOfFundsCollatedInfo : '',
        hasEconomicActivities: data.hasEconomicActivities != null ? booleanToYesNo(data.hasEconomicActivities) : '',
        economicActivitiesInfo: data.economicActivitiesInfo ? data.economicActivitiesInfo : '',
        hasNewEquity: data.hasNewEquity != null ? booleanToYesNo(data.hasNewEquity) : '',
        newEquityInfo: data.newEquityInfo ? data.newEquityInfo : '',
        hasPrivateLoan: data.hasPrivateLoan != null ? booleanToYesNo(data.hasPrivateLoan) : '',
        privateLoanInfo: data.privateLoanInfo ? data.privateLoanInfo : '',
        hasBankLoanOrCreditFacility: data.hasBankLoanOrCreditFacility != null ? booleanToYesNo(data.hasBankLoanOrCreditFacility) : '',
        bankLoanOrCreditFacilityInfo: data.bankLoanOrCreditFacilityInfo ? data.bankLoanOrCreditFacilityInfo : '',
        hasOther: data.hasOther != null ? booleanToYesNo(data.hasOther) : '',
        otherInfo: data.otherInfo ? data.otherInfo : '',
        sourceOfFundsOriginatingFrom: data.sourceOfFundsOriginatingFrom ? countries.find(x => x.code === data.sourceOfFundsOriginatingFrom) as Country : null,
    }
}

