import { Menu, Notifications } from "@mui/icons-material";
import {
  Badge,
  Box,
  IconButton,
  Link,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import type { FC } from "react";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { SIDEBAR_WIDTH } from "./utils/config";
import AccountPopover from "./AccountPopover";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useParams } from "react-router-dom";

interface DashboardNavbarProps extends AppBarProps {
  open: boolean;
  toggleDrawer: () => void;
  onSidebarMobileOpen?: () => void;
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: SIDEBAR_WIDTH,
    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DashboardNavbar: FC<DashboardNavbarProps> = ({ open, toggleDrawer }) => {
  const { t } = useTranslation();

  const { tenantNameOrId } = useParams<{ tenantNameOrId: string }>();

  return (
    <AppBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <Menu />
        </IconButton>

        {/* <Link
          color="primary"
          component={RouterLink}
          to={`/${tenantNameOrId}/timesheets/${activeTimesheet?.timesheetId}`}
          variant="body1"
          sx={{ color: "white" }}
        >
          {activeTimesheet.duration &&
            `${
              activeTimesheet.duration.days && activeTimesheet.duration.days > 0
                ? activeTimesheet.duration.days + " " + t("common.days")
                : ""
            } ${
              activeTimesheet.duration.hours &&
              activeTimesheet.duration.hours > 0
                ? activeTimesheet.duration.hours + " " + t("common.hours")
                : ""
            }
          ${
            activeTimesheet.duration.minutes &&
            activeTimesheet.duration.minutes > 0
              ? activeTimesheet.duration.minutes + " " + t("common.minutes")
              : ""
          }
          ${
            activeTimesheet.duration.seconds &&
            activeTimesheet.duration.seconds > 0
              ? activeTimesheet.duration.seconds + " " + t("common.seconds")
              : ""
          }
          `}
        </Link> */}
        {/* </Tooltip> */}
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          {/* Dashboard */}
        </Typography>

        {/* <IconButton color="inherit">
          <Badge badgeContent={4} color="secondary">
            <Notifications />
          </Badge>
        </IconButton> */}
        <AccountPopover />
      </Toolbar>
    </AppBar>
  );
};

export default DashboardNavbar;
