import { Color, colors } from "@mui/material";
import { ApiTimesheetStatus } from ".";

export const PENDING_TIMESHEET_STATUS = 'PENDING';
export const PROCESSED_TIMESHEET_STATUS = 'PROCESSED';
export const WRITTEN_OFF = 'WRITTEN_OFF';


export const timesheetStatuses = [PENDING_TIMESHEET_STATUS, PROCESSED_TIMESHEET_STATUS, WRITTEN_OFF] as const;
export const timesheetStatusesColours = [colors.orange, colors.green, colors.grey];
export const timesheetStatusesColourMap = new Map<ApiTimesheetStatus, Color>(timesheetStatuses.map((v, i) => [v, timesheetStatusesColours[i]]));;
