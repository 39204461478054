import { ApiEntity, ApiStagePepSanctions, STAGE_2_4_TYPE } from "../../../../../../models/entities"
import { PepSanctionsFormValues } from "."
import { ApiEnumeration, PEP_RELATIONSHIP } from "../../../../../../models/enumerations"
import { booleanToYesNo } from "../../../../../../forms";
import { parseISO } from "date-fns";

export const pepSanctionsFormValuesInitialValue = (
    entity: ApiEntity,
    enumerables: ApiEnumeration[]): PepSanctionsFormValues => {
    // Get Stage Data
    const stageData: ApiStagePepSanctions = entity.stages.find((s) => s.stageType === STAGE_2_4_TYPE) as ApiStagePepSanctions;
    // If draft is available use that
    const data: ApiStagePepSanctions = stageData.draftData ? stageData.draftData as ApiStagePepSanctions : stageData;
    return {
        stageType: STAGE_2_4_TYPE,
        stageStatus: data.stageStatus,
        id: entity.id,

        hasPep: data.hasPep != null ? booleanToYesNo(data.hasPep) : '',
        pepRelationship: data.pepRelationship ? enumerables.filter(x => x.type === PEP_RELATIONSHIP).find(x => x.value === data.pepRelationship)! : null,
        pepFirstName: data.pepFirstName ? data.pepFirstName : '',
        pepLastName: data.pepLastName ? data.pepLastName : '',
        pepPublicPosition: data.pepPublicPosition ? data.pepPublicPosition : '',
        pepPublicEntity: data.pepPublicEntity ? data.pepPublicEntity : '',
        pepStartDate: data.pepStartDate ? parseISO(data.pepStartDate) : null,
        hasFreezingOrders: data.hasFreezingOrders != null ? booleanToYesNo(data.hasFreezingOrders) : '',
        freezingOrdersInfo: data.hasFreezingOrders ? data.freezingOrdersInfo! : '',
        hasSanctionedCountryDealings: data.hasSanctionedCountryDealings != null ? booleanToYesNo(data.hasSanctionedCountryDealings) : '',
        sanctionedCountryDealingsInfo: data.hasSanctionedCountryDealings ? data.sanctionedCountryDealingsInfo! : '',
        hasCriminalOffence: data.hasCriminalOffence != null ? booleanToYesNo(data.hasCriminalOffence) : '',
        criminalOffenceInfo: data.hasCriminalOffence ? data.criminalOffenceInfo! : '',
        hasSubjectToRegulatoryActions: data.hasSubjectToRegulatoryActions != null ? booleanToYesNo(data.hasSubjectToRegulatoryActions) : '',
        subjectToRegulatoryActionsInfo: data.hasSubjectToRegulatoryActions ? data.subjectToRegulatoryActionsInfo! : '',
        hasRegulatoryActionResolved: data.hasRegulatoryActionResolved != null ? booleanToYesNo(data.hasRegulatoryActionResolved) : '',
        regulatoryActionResolvedInfo: data.hasRegulatoryActionResolved ? data.regulatoryActionResolvedInfo! : ''
    }
}

