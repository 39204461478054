import { Route, Routes } from "react-router";
import { LazyLoadableScreen } from "../../../../navigation/LazyLoadableScreen";

const InvoiceListScreen = LazyLoadableScreen(() => import("./InvoiceList"));
const InvoiceDetailsScreen = LazyLoadableScreen(
  () => import("./InvoiceDetails")
);

export const InvoicesRoutes = () => (
  <Routes>
    <Route path="/" element={<InvoiceListScreen />} />
    <Route path="/:invoiceId" element={<InvoiceDetailsScreen />} />
  </Routes>
);
