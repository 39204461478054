import * as Yup from "yup";

export const firstNameValidationSchema = () => Yup.string().required('validations.firstNameIsRequired');
export const lastNameValidationSchema = () => Yup.string().required('validations.lastNameIsRequired');



export const positiveMoneyAmountValidationSchema = () =>
    Yup.mixed()
        .required('validations.amountIsRequired')
        .test('type', 'validations.amountMustBeANumber', (value) => !isNaN(value))
        .test(
            'value',
            'validations.amountMustBeGreaterThan0',
            (value) => Number(value) > 0,
        )
        .test(
            'is-decimal',
            'validations.amountMustNotExceed2DecimalSpaces',
            (value) => /^\d*.?\d{0,2}$/.test(value?.toString()),
        )



export const negativeMoneyAmountValidationSchema = () =>
    Yup.mixed()
        .required('validations.amountIsRequired')
        .test('type', 'validations.amountMustBeANumber', (value) => !isNaN(value))
        .test(
            'value',
            'validations.amountMustBeLessThan0',
            (value) => Number(value) < 0,
        )
        .test(
            'is-decimal',
            'validations.amountMustNotExceed2DecimalSpaces',
            (value) => /^-?\d*.?\d{0,2}$/.test(value?.toString()),
        )



export const percentValidationSchema = () =>
    Yup.mixed()
        .required('validations.percentageIsRequired')
        .test('type', 'validations.valueMustBeANumber', (value) => !isNaN(value))
        .test(
            'value',
            'validations.valueMustBeGreaterThan0',
            (value) => Number(value) > 0,
        )
        .test(
            'value',
            'validations.valueMustBeLessOrEqualTo100',
            (value) => Number(value) <= 100,
        )
        .test(
            'is-decimal',
            'validations.amountMustNotExceed2DecimalSpaces',
            (value) => /^\d*.?\d{0,2}$/.test(value?.toString()),
        )


export const decimalValidationSchema = () =>
    Yup.mixed()
        .test('type', 'validations.timeSpentMustBeANumber', (value) => !isNaN(value))
        .test(
            'value',
            'validations.timeSpentMustBeGreaterThan0',
            (value) => Number(value) > 0,
        )
        .test(
            'is-decimal',
            'validations.timeSpentMustNotExceed2DecimalSpaces',
            (value) => /^\d*.?\d{0,2}$/.test(value?.toString()),
        )
