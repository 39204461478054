import { Chip, Link, Tooltip } from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import { usePermissions } from "../../../../models/auth";
import { ApiEnumeration } from "../../../../models/enumerations";
import { FC } from "react";
import { Nullable } from "../../../../utils/types";

export interface EnumerableDisplayProps {
  disabled?: boolean;
  enumerable: Nullable<ApiEnumeration>;
  size?: "small" | "medium";
}
export const EnumerableDisplay: FC<EnumerableDisplayProps> = ({
  disabled = false,
  enumerable,
  size = "medium",
}) => {
  const { hasPermission } = usePermissions();
  const { tenantNameOrId } = useParams<{ tenantNameOrId: string }>();
  return (
    <Tooltip title={enumerable?.value}>
      {hasPermission("ADMIN") && !disabled ? (
        <Link
          color="primary"
          component={RouterLink}
          to={`/${tenantNameOrId}/${enumerable?.type
            .toLocaleLowerCase()
            .replaceAll("_", "-")}/${enumerable?.id}`}
          variant="body1"
        >
          {enumerable?.value != null ? (
            <Chip
              color="primary"
              variant="outlined"
              label={enumerable?.valueDescription || enumerable?.value}
              size={size}
            />
          ) : null}
        </Link>
      ) : (
        <Chip
          color="primary"
          variant="outlined"
          label={enumerable?.valueDescription || enumerable?.value}
        />
      )}
    </Tooltip>
  );
};
