import { Buffer } from 'buffer';
import { Nullable } from './types';

export const fileToBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
});

export const dataUrlToFile = async (dataUrl: string, filename: string): Promise<Nullable<File>> => {
    const res = await fetch(dataUrl);
    const buf = await res.arrayBuffer();

    const arr = dataUrl.split(',');
    if (arr.length < 2) { return null; }
    const mimeArr = arr[0].match(/:(.*?);/);
    if (!mimeArr || mimeArr.length < 2) { return null; }
    const mimeType = mimeArr[1];

    return new File([buf], filename, { type: mimeType });
};