// import "moment-timezone";

import {
  // AccountBalance,
  // AccountBox,
  // BarChart,
  ChevronLeft,
  ChevronRight,
  Close,
  // Computer,
  // CreditCard,
  // FolderShared,
  // Handshake,
  // Key,
  // LocalAtm,
  // Lock,
  // People,
  // PointOfSale,
  // Print,
  // SyncAlt,
  // Tune,
  // WifiCalling,
} from "@mui/icons-material";
import {
  // Box,
  Divider,
  IconButton,
  List,
  styled,
  Theme,
  // Theme,
  Toolbar,
  useMediaQuery,
  // Tooltip,
  // Typography,
} from "@mui/material";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import moment from "moment";
// import React, { useState } from "react";
import { FC, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link as RouterLink, useLocation } from "react-router-dom";

// import { LogoText } from "src/components/logo/LogoText";
// import NavSection from "src/components/NavSection";
// import Scrollbar from "src/components/Scrollbar";
// import useMounted from "src/hooks/useMounted";
// import { AccountHolderPermission } from "src/models/accountHolder";
// import { selectAccountHolderId, selectAssociations } from "src/models/auth";
// import { usePermissions } from "src/models/auth/hooks/usePermissions";
// import { setBank, useGetBankProfileQuery } from "src/models/cms";
// import Logo from "../icons/Logo";
// import { SIDEBAR_WIDTH } from "./utils/config";
import MuiDrawer from "@mui/material/Drawer";
import { mainListItems, secondaryListItems } from "./listItems";
import { SIDEBAR_WIDTH } from "./utils/config";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Logo } from "../../Logo";

interface DashboardSidebarProps {
  open: boolean;
  toggleDrawer: () => void;
  onSidebarMobileOpen?: () => void;
}

// const sections = [
//   {
//     title: 'Reports',
//     items: [
//       {
//         title: 'Dashboard',
//         path: '/dashboard',
//         icon: <BarChart fontSize="small" />,
//         permission: null,
//       },
//     ],
//   },
//   {
//     title: 'Financials',
//     items: [
//       {
//         title: 'Accounts',
//         path: '/accounts',
//         icon: <AccountBox fontSize="small" />,
//         permission: 'GET_ACCOUNT' as AccountHolderPermission,
//       },
//       {
//         title: 'Transfer',
//         path: `/transfer/${new Date()}`,
//         icon: <LocalAtm fontSize="small" />,
//         permission: 'PERFORM_TRANSFER' as AccountHolderPermission,
//       },
//       {
//         title: 'Transactions',
//         path: '/transactions',
//         icon: <AccountBalance fontSize="small" />,
//         permission: 'GET_TRANSACTION' as AccountHolderPermission,
//       },
//       {
//         title: 'Adjustment',
//         path: `/adjustment/${new Date()}`,
//         icon: <Tune fontSize="small" />,
//         permission: 'PERFORM_ADJUSTMENT' as AccountHolderPermission,
//       },
//       {
//         title: 'Telexes',
//         path: '/telexes',
//         icon: <Print fontSize="small" />,
//         permission: 'GET_TELEX' as AccountHolderPermission,
//       },
//     ],
//   },
//   {
//     title: 'User Management',
//     items: [
//       {
//         title: 'Entities',
//         path: '/account-holders',
//         icon: <FolderShared fontSize="small" />,
//         permission: 'MANAGE_ACCOUNT_HOLDER' as AccountHolderPermission,
//       },
//       {
//         title: 'Users',
//         path: '/users',
//         icon: <People fontSize="small" />,
//         permission: 'GET_USER' as AccountHolderPermission,
//       },
//       {
//         title: 'Api Keys',
//         path: '/api-keys',
//         icon: <Key fontSize="small" />,
//         permission: 'GET_API_KEY' as AccountHolderPermission,
//       },
//     ],
//   },
//   {
//     title: 'Administration',
//     items: [
//       {
//         title: 'Transfer Channels',
//         path: '/transfer-channels',
//         icon: <SyncAlt fontSize="small" />,
//         permission: 'GET_TRANSFER_CHANNEL' as AccountHolderPermission,
//       },
//       {
//         title: 'Onboarding',
//         path: '/onboarding',
//         icon: <Handshake fontSize="small" />,
//         permission: 'ADMIN' as AccountHolderPermission,
//       },
//     ],
//   },
//   {
//     title: 'Configuration',
//     items: [
//       {
//         title: 'Banking',
//         path: '/configuration/banking',
//         icon: <Computer fontSize="small" />,
//         permission: 'GET_BANKING_CONFIGURATION' as AccountHolderPermission,
//       },
//       {
//         title: 'Acquiring',
//         path: '/configuration/acquiring',
//         icon: <CreditCard fontSize="small" />,
//         permission: 'GET_ACQUIRING_CONFIGURATION' as AccountHolderPermission,
//       },
//       {
//         title: 'Telex',
//         path: '/configuration/telex',
//         icon: <WifiCalling fontSize="small" />,
//         permission: 'GET_TELEX_CONFIGURATION' as AccountHolderPermission,
//       },
//       {
//         title: 'Fees',
//         path: '/configuration/fees',
//         icon: <PointOfSale fontSize="small" />,
//         permission: 'GET_FEE_CONFIGURATION' as AccountHolderPermission,
//       },
//     ],
//   },
//   {
//     title: 'Restrictions',
//     items: [
//       {
//         title: 'Country',
//         path: '/restriction/country',
//         icon: <Lock fontSize="small" />,
//         permission: 'GET_COUNTRY_RESTRICTION' as AccountHolderPermission,
//       },
//       {
//         title: 'Currency',
//         path: '/restriction/currency',
//         icon: <Lock fontSize="small" />,
//         permission: 'GET_CURRENCY_RESTRICTION' as AccountHolderPermission,
//       },
//       {
//         title: 'Card Bin',
//         path: '/restriction/card-bin',
//         icon: <Lock fontSize="small" />,
//         permission: 'GET_CARDBIN_RESTRICTION' as AccountHolderPermission,
//       },
//       {
//         title: 'Account Currency',
//         path: '/restriction/account-currency',
//         icon: <Lock fontSize="small" />,
//         permission:
//           'GET_ACCOUNT_CURRENCY_RESTRICTION' as AccountHolderPermission,
//       },
//       {
//         title: 'Pending',
//         path: '/restriction/pending',
//         icon: <Lock fontSize="small" />,
//         permission: 'GET_RESTRICTION' as AccountHolderPermission,
//       },
//     ],
//   },
// ];

const Drawer = styled(MuiDrawer, {
  // shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: SIDEBAR_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        // width: theme.spacing(9),
      },
    }),
  },
}));

const DashboardSidebar: FC<DashboardSidebarProps> = ({
  open,
  toggleDrawer,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tenantNameOrId } = useParams<{ tenantNameOrId: string }>();
  const [openMenuItem, setOpenMenuItem] = useState<boolean>(false);
  //   const { onMobileClose, openMobile } = props;
  //   const location = useLocation();
  //   const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  //   const { hasPermission } = usePermissions();
  //   const mounted = useMounted();
  //   // const associations = useSelector(selectAssociations);
  //   // const accountHolderId = useSelector(selectAccountHolderId);
  //   // const [parentAccountHolderId, setParentAccountHolderId] =
  //   //   React.useState<string>(null);
  //   // const parentBankData = useGetBankProfileQuery(
  //   //   { accountHolderId: parentAccountHolderId },
  //   //   { skip: parentAccountHolderId == null, refetchOnMountOrArgChange: true },
  //   // );
  //   // const bankData = useGetBankProfileQuery(
  //   //   { accountHolderId: accountHolderId },
  //   //   { skip: accountHolderId == null, refetchOnMountOrArgChange: true },
  //   // );
  //   // const dispatch = useDispatch();
  //   const [myInterval, setMyInterval] =
  //     React.useState<ReturnType<typeof setInterval>>(null);

  //   // useEffect(() => {
  //   //   if (!parentBankData.isFetching && parentBankData.data !== undefined)
  //   //     dispatch(setBank({ bank: parentBankData.data }));
  //   // }, [parentBankData]);

  //   // useEffect(() => {
  //   //   if (
  //   //     !parentBankData.isFetching &&
  //   //     parentBankData.data == undefined &&
  //   //     !bankData.isFetching &&
  //   //     bankData.data !== undefined
  //   //   )
  //   //     dispatch(setBank({ bank: bankData.data }));
  //   // }, [bankData, parentBankData]);

  //   useEffect(() => {
  //     if (openMobile && onMobileClose) {
  //       onMobileClose();
  //     }
  //   }, [location.pathname]);

  //   const [localdateState, setLocalDateState] = useState<string>('');
  //   const [cetdateState, setCETDateState] = useState<string>('');
  //   useEffect(() => {
  //     // setParentAccountHolderId(
  //     //   associations.find((x) => x.id == accountHolderId)?.parentAccountHolderId,
  //     // );
  //     const interval = setInterval(() => {
  //       if (mounted.current) {
  //         setCETDateState(
  //           moment().tz('Europe/Berlin').format('DD-MM-YYYY HH:mm:ss'),
  //         );
  //         setLocalDateState(moment().format('DD-MM-YYYY HH:mm:ss'));
  //       }
  //     }, 1000);
  //     setMyInterval(interval);
  //     return () => {
  //       clearInterval(myInterval);
  //     };
  //   }, []);

  //   const content = (
  //     <Box
  //       sx={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         minHeight: '100%',
  //       }}
  //     >
  //       <Box
  //         sx={{
  //           display: 'flex',
  //           p: 2,
  //         }}
  //       >
  //         <RouterLink to="/">
  //           <LogoText />
  //         </RouterLink>
  //       </Box>
  //       <Box
  //         sx={{
  //           display: 'flex',
  //           justifyContent: 'center',
  //         }}
  //       >
  //         <Tooltip title={`CET ${cetdateState}`}>
  //           <Typography
  //             sx={{
  //               color: '#7B8B9A',
  //               fontSize: '0.75rem',
  //               lineHeight: 2.5,
  //               fontWeight: 600,
  //               textTransform: 'uppercase',
  //             }}
  //           >
  //             {/* {moment.tz.guess()} */}
  //             {localdateState}
  //           </Typography>
  //         </Tooltip>
  //       </Box>
  //       <Scrollbar options={{ suppressScrollX: true }}>
  //         <Box sx={{ p: 2 }}>
  //           {sections.map((section) => (
  //             <React.Fragment key={section.title}>
  //               {section.items
  //                 .map((i) => i.permission)
  //                 .some((p) => hasPermission(p) || p == null) && (
  //                   <NavSection
  //                     pathname={location.pathname}
  //                     sx={{
  //                       '& + &': {
  //                         mt: 3,
  //                       },
  //                     }}
  //                     {...section}
  //                   />
  //                 )}
  //             </React.Fragment>
  //           ))}
  //         </Box>
  //       </Scrollbar>
  //     </Box>
  //   );

  //   if (lgUp) {
  //     return (
  //       <Drawer
  //         anchor="left"
  //         open
  //         PaperProps={{
  //           sx: {
  //             backgroundColor: '#F3F7FE',
  //             width: SIDEBAR_WIDTH,
  //           },
  //         }}
  //         variant="permanent"
  //       >
  //         {content}
  //       </Drawer>
  //     );
  //   }
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  if (lgUp) {
    return (
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: [1],
          }}
        >
          {open && <Logo maxHeight="60px" />}
          <IconButton onClick={toggleDrawer}>
            {open ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          {mainListItems(
            tenantNameOrId as string,
            navigate,
            t,
            () => {},
            openMenuItem,
            setOpenMenuItem
          )}
          <Divider sx={{ my: 1 }} />
          {secondaryListItems}
        </List>
      </Drawer>
    );
  }

  return (
    <Drawer variant="temporary" open={open} onClose={toggleDrawer}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: [1],
        }}
      >
        <Logo maxHeight="58px" />
        <IconButton onClick={toggleDrawer}>
          <Close />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        {mainListItems(
          tenantNameOrId as string,
          navigate,
          t,
          toggleDrawer,
          openMenuItem,
          setOpenMenuItem
        )}
        <Divider sx={{ my: 1 }} />
        {secondaryListItems}
      </List>
    </Drawer>
    // <Drawer
    //   anchor="left"
    //   onClose={onMobileClose}
    //   open={openMobile}
    //   PaperProps={{
    //     sx: {
    //       backgroundColor: "#F3F7FE",
    //       width: SIDEBAR_WIDTH,
    //     },
    //   }}
    //   variant="temporary"
    // >
    //   {content}
    // </Drawer>
  );
};

//   return (
//     <>
//       {/* // <Drawer
//   //   anchor="left"
//   //   onClose={onMobileClose}
//   //   open={openMobile}
//   //   PaperProps={{
//   //     sx: {
//   //       backgroundColor: '#F3F7FE',
//   //       width: SIDEBAR_WIDTH,
//   //     },
//   //   }}
//   //   variant="temporary"
//   // >
//   //   {content}
//   // </Drawer> */}

//       <Drawer variant="temporary" open={open} onClose={toggleDrawer}>
//         {/* <Drawer variant={lgUp ? "permanent" : "temporary"} open={open}> */}
//         <Toolbar
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "flex-end",
//             px: [1],
//           }}
//         >
//           <IconButton onClick={toggleDrawer}>
//             <ChevronLeft />
//           </IconButton>
//         </Toolbar>
//         <Divider />
//         <List component="nav">
//           {mainListItems}
//           <Divider sx={{ my: 1 }} />
//           {secondaryListItems}
//         </List>
//       </Drawer>
//     </>
//   );
// };

export default DashboardSidebar;
