import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { CountryCode } from "libphonenumber-js";
import { FC } from "react";
import { useTranslation } from "react-i18next";

// import {
//   useCountriesQuery,
//   useCountryStatesQuery,
// } from "src/models/countryStates/api";
// import { ApiCountryLookup } from "src/models/countryStates/types";
import { Address } from ".";
import { CountryFlag } from "../../../../components/CountryFlag";
import { countries, Country } from "../../../../utils/countries";
import { Nullable } from "../../../../utils/types";

export interface AddressFieldProps {
  disabled: boolean;
  label?: string;
}
export const AddressField: FC<
  AddressFieldProps & FieldProps<Address, Address>
> = ({
  disabled = false,
  label = "common.address",
  field,
  form: {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
  },
  ...props
}) => {
  const { t } = useTranslation();

  // const countries = useCountriesQuery(
  //   { accountHolderId },
  //   {
  //     refetchOnMountOrArgChange: true,
  //   }
  // );

  // const countryStates = useCountryStatesQuery(
  //   { countryCode: values[field.name].countryCode?.code as CountryCode },
  //   {
  //     skip: values[field.name].countryCode?.code == undefined,
  //     refetchOnMountOrArgChange: true,
  //   }
  // );

  // Auto populate country when there is only one
  // useEffect(() => {
  //   if (countries && countries?.data && countries?.data?.length === 1) {
  //     setFieldValue(`${field.name}.countryCode`, countries.data[0]);
  //   }
  // }, [countries.data]);

  return (
    <>
      <InputLabel
        error={Boolean(
          getIn(touched, field.name) && !!getIn(errors, field.name)
        )}
        sx={{ mt: "10px", mb: "10px" }}
      >
        {t(label)}
      </InputLabel>

      <Field
        component={TextField}
        name={`${field.name}.addressLine1`}
        fullWidth
        label={t("common.addressLine1")}
        value={getIn(values, field.name)?.addressLine1}
        margin="normal"
        error={Boolean(
          getIn(touched, `${field.name}.addressLine1`) &&
            !!getIn(errors, `${field.name}.addressLine1`)
        )}
        helperText={
          getIn(touched, `${field.name}.addressLine1`) &&
          t(getIn(errors, `${field.name}.addressLine1`) as string)
        }
        onBlur={handleBlur(`${field.name}.addressLine1`)}
        onChange={handleChange(`${field.name}.addressLine1`)}
        variant="outlined"
        disabled={disabled}
      />
      <Field
        component={TextField}
        name={`${field.name}.addressLine2`}
        fullWidth
        label={t("common.addressLine2")}
        value={getIn(values, field.name)?.addressLine2}
        margin="normal"
        error={Boolean(
          getIn(touched, `${field.name}.addressLine2`) &&
            !!getIn(errors, `${field.name}.addressLine2`)
        )}
        helperText={
          getIn(touched, `${field.name}.addressLine2`) &&
          t(getIn(errors, `${field.name}.addressLine2`) as string)
        }
        onBlur={handleBlur(`${field.name}.addressLine2`)}
        onChange={handleChange(`${field.name}.addressLine2`)}
        variant="outlined"
        disabled={disabled}
      />
      <Field
        component={TextField}
        name={`${field.name}.postode`}
        fullWidth
        label={t("common.zipPostCode")}
        value={getIn(values, field.name)?.postcode}
        margin="normal"
        error={Boolean(
          getIn(touched, `${field.name}.postcode`) &&
            !!getIn(errors, `${field.name}.postcode`)
        )}
        helperText={
          getIn(touched, `${field.name}.postcode`) &&
          t(getIn(errors, `${field.name}.postcode`) as string)
        }
        onBlur={handleBlur(`${field.name}.postcode`)}
        onChange={handleChange(`${field.name}.postcode`)}
        variant="outlined"
        disabled={disabled}
      />
      <Field
        component={TextField}
        name={`${field.name}.town`}
        fullWidth
        label={t("common.town")}
        value={getIn(values, field.name)?.town}
        margin="normal"
        error={Boolean(
          getIn(touched, `${field.name}.town`) &&
            !!getIn(errors, `${field.name}.town`)
        )}
        helperText={
          getIn(touched, `${field.name}.town`) &&
          t(getIn(errors, `${field.name}.town`) as string)
        }
        onBlur={handleBlur(`${field.name}.town`)}
        onChange={handleChange(`${field.name}.town`)}
        variant="outlined"
        disabled={disabled}
      />

      <FormControl fullWidth margin="normal">
        <Field
          component={Autocomplete}
          name={`${field.name}.country`}
          fullWidth
          value={getIn(values, field.name)?.country}
          onBlur={handleBlur(`${field.name}.country`)}
          onChange={(e: any, value: Country) => {
            setFieldValue(
              `${field.name}.country`,
              value !== null ? value : null
            );
          }}
          disabled={disabled}
          options={countries}
          autoHighlight
          variant="outlined"
          filterOptions={(
            options: Array<Country>,
            state: { inputValue: string }
          ): Array<Country> => {
            return options.filter(
              (x) =>
                x.name.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                x.code.toLowerCase().includes(state.inputValue.toLowerCase())
            );
          }}
          isOptionEqualToValue={(option: Country, value: Nullable<Country>) =>
            option.code === value?.code
          }
          getOptionLabel={(option: Country) => `${option.name}` || ""}
          renderOption={(
            props: any,
            option: { callingCode: string; code: CountryCode; name: string }
          ) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
              key={option.code}
            >
              <CountryFlag countryCode={option.code} />
              {option.name}
            </Box>
          )}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <>
              <TextField
                {...params}
                label={t("common.country")}
                error={Boolean(
                  getIn(touched, `${field.name}.country`) &&
                    !!getIn(errors, `${field.name}.country`)
                )}
                helperText={
                  getIn(touched, `${field.name}.country`) &&
                  t(getIn(errors, `${field.name}.country`) as string)
                }
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
                InputProps={{
                  ...params.InputProps,
                  ...(getIn(values, `${field.name}.country`)?.name
                    ? {
                        startAdornment: (
                          <InputAdornment position="start">
                            <>
                              <Box sx={{ ml: "8px" }}>
                                <CountryFlag
                                  countryCode={
                                    getIn(values, `${field.name}.country`).code
                                  }
                                />
                              </Box>
                            </>
                          </InputAdornment>
                        ),
                      }
                    : {}),
                }}
              />
            </>
          )}
        ></Field>
      </FormControl>
    </>
  );
};
