import { Color, colors } from "@mui/material";
import { ApiEntityStatus } from ".";

export const INITIAL_CLIENT_STATUS = 'INITIAL';
export const PENDING_CLIENT_STATUS = 'PENDING';
export const PENDING_APPROVAL_CLIENT_STATUS = 'PENDING_APPROVAL';
export const ACTIVE_CLIENT_STATUS = 'ACTIVE';
export const CLOSED_CLIENT_STATUS = 'CLOSED';

export const entityStatuses = [INITIAL_CLIENT_STATUS, PENDING_CLIENT_STATUS, ACTIVE_CLIENT_STATUS, CLOSED_CLIENT_STATUS, PENDING_APPROVAL_CLIENT_STATUS] as const;
export const entityStatusesColours = [colors.orange, colors.orange, colors.green, colors.red, colors.orange];
export const entityStatusesColourMap = new Map<ApiEntityStatus, Color>(entityStatuses.map((v, i) => [v, entityStatusesColours[i]]));;
