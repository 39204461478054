import { Box, Container } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import {
  ApiTenantResponse,
  setTenant,
  useTenantQuery,
} from "../../../models/tenants";
import { Logo } from "../../Logo";

const MiddleTemplate: FC = () => {
  const dispatch = useDispatch();
  const { tenantNameOrId } = useParams<{ tenantNameOrId: string }>();
  const { data: tenant } = useTenantQuery(
    { nameOrId: tenantNameOrId as string },
    {
      skip: tenantNameOrId === undefined,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    dispatch(setTenant({ tenant: tenant as ApiTenantResponse }));
  }, [tenant, dispatch]);

  return (
    <>
      <Box
        id="container"
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          maxHeight: "100vh",
          justifyContent: "center",
        }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "40px",
          }}
        >
          <Logo />
        </Box>
        <Container
          sx={[
            {
              display: "flex",
              flexDirection: "row",
              width: "50%",
              minHeight: "100%",
            },
            (theme) => ({
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                gap: "3em",
                width: "100%",
              },
            }),
          ]}
        >
          <Box
            sx={{
              flex: "1 1 0px",
              // marginBottom: "24px",
            }}
          >
            <Outlet />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MiddleTemplate;
