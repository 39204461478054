import { Navigate, Route, Routes } from "react-router";
import MiddleTemplate from "../../components/Layouts/MiddleTemplate";
import { AuthGuard } from "../../models/auth";
import { LazyLoadableScreen } from "../../navigation/LazyLoadableScreen";
import { ForgotPasswordRoutes } from "./ForgotPassword";

const LoginScreen = LazyLoadableScreen(() => import("./Login"));

const ResetPasswordScreen = LazyLoadableScreen(() => import("./ResetPassword"));

const UserVerificationConfirmScreen = LazyLoadableScreen(
  () => import("./UserVerificationConfirm")
);

const ChangePasswordScreen = LazyLoadableScreen(
  () => import("./ChangePassword")
);

// const KYCScreen = LazyLoadableScreen(() => import("./KYC"));

export const AuthenticationRoutes = () => (
  <Routes>
    <Route element={<MiddleTemplate />}>
      {/* <Route path="kyc" element={<KYCScreen />} /> */}
      <Route path="forgot-password/*" element={<ForgotPasswordRoutes />} />
      <Route path="login" element={<LoginScreen />} />
      <Route path="reset-password" element={<ResetPasswordScreen />} />
      <Route
        path="registration/confirm"
        element={<UserVerificationConfirmScreen />}
      />
      <Route
        path="change-password"
        element={
          <AuthGuard>
            <ChangePasswordScreen />
          </AuthGuard>
        }
      />
      <Route index element={<Navigate to={`login`} replace />} />
    </Route>
  </Routes>
);
