
import { ApiCreateTenantRequest, ApiTenantResponse, ApiUpdateTenantRequest, PageApiTenantResponse, PagedTenantsRequest } from '.';
import { removeEmpty } from '../../utils/removeEmpty';
import { apiWithTag } from '../emptySplitApi';


export const tenantsApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        tenants: builder.query<PageApiTenantResponse, PagedTenantsRequest>({
            query: ({ ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    // headers: {
                    //     'x-tenant-holder-id': tenantHolderIdForHeader,
                    // },
                    method: 'GET',
                    params: parameters,
                    url: 'tenants',
                }
            },
            providesTags: ['Tenant']
        }),
        tenantsList: builder.query<ApiTenantResponse[], {}>({
            query: (
                // { ...params }
            ) => {
                // const parameters = removeEmpty({
                //     ...params
                // });
                return {
                    // headers: {
                    //     'x-tenant-holder-id': tenantHolderIdForHeader,
                    // },
                    method: 'GET',
                    // params: parameters,
                    url: 'tenants/list',
                }
            },
            providesTags: ['Tenant']
        }),
        tenant: builder.query<ApiTenantResponse, { nameOrId: string }>({
            query: ({ nameOrId }) => ({
                method: 'GET',
                // headers: {
                //     'x-tenant-holder-id': tenantHolderIdForHeader,
                // },
                url: `public/tenants/${nameOrId}`,

            }),
            // transformResponse:(response:ApiAccountResponse, meta, arg) => {
            //     const {associations, ...rest} = response;
            //     return ({...rest,  permissionGroupIds: associations.find(a => a.tenantHolderId == arg.tenantHolderId).permissionGroups.map(pg => pg.id)});
            //   },
            providesTags: ['Tenant']
        }),
        createTenant: builder.mutation<ApiTenantResponse, ApiCreateTenantRequest>({
            query: ({ ...tenant }) => ({
                url: 'tenants',
                method: 'POST',
                body: tenant
            }),
            invalidatesTags: ['Tenant']
        }),
        updateTenant: builder.mutation<ApiTenantResponse, ApiUpdateTenantRequest>({
            query: ({ id, ...rest }) => ({
                url: `tenants/${id}`,
                method: 'PUT',
                body: { ...rest }
            }),
            invalidatesTags: ['Tenant']
        }),


    })
})

export const {
    useTenantsQuery,
    useTenantsListQuery,
    useLazyTenantQuery,
    useTenantQuery,
    useCreateTenantMutation,
    useUpdateTenantMutation,
} = tenantsApi;