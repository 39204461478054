export const ADMIN = 'ADMIN';



export const CREATE_SIGNATORY = 'CREATE_SIGNATORY'


export const CREATE_QUOTATION = 'CREATE_QUOTATION';
export const DELETE_QUOTATION = 'DELETE_QUOTATION';
export const GET_QUOTATION = 'GET_QUOTATION';
export const UPDATE_QUOTATION = 'UPDATE_QUOTATION';
export const REVIEW_QUOTATION = 'REVIEW_QUOTATION';
export const GET_BILLER = 'GET_BILLER';

export const CREATE_INVOICE = 'CREATE_INVOICE';
export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const GET_INVOICE = 'GET_INVOICE';
export const REVIEW_INVOICE = 'REVIEW_INVOICE';



export const CREATE_RECEIPT = 'CREATE_RECEIPT';
export const DELETE_RECEIPT = 'DELETE_RECEIPT';
export const GET_RECEIPT = 'GET_RECEIPT';
export const UPDATE_RECEIPT = 'UPDATE_RECEIPT';


export const PermissionsList = [
    ADMIN,
    'CREATE_ACCOUNT_MANAGER',
    'CREATE_ENTITY',
    'CREATE_PERMISSION_GROUP',
    'CREATE_TENANT',
    'CREATE_USER',
    'GET_ACCOUNT_MANAGER',
    'GET_ENTITY',
    'GET_PERMISSION_GROUP',
    'GET_TENANT',
    'GET_USER',
    'RESET_PASSWORD',
    'UNLINK_USER',
    'UPDATE_ACCOUNT_MANAGER',
    'UPDATE_ENTITY',
    'UPDATE_LINKED_USER',
    'UPDATE_PERMISSION_GROUP',
    'UPDATE_TENANT',
    'UPDATE_USER',
    'REVIEW_ENTITY',

    'CREATE_BILLER',
    'UPDATE_BILLER',
    GET_BILLER,

    'CREATE_TIMESHEET',
    'UPDATE_TIMESHEET',
    'GET_TIMESHEET',
    'REVIEW_TIMESHEET',
    'TIMESHEET_ADMIN',




    'CREATE_RATE',
    'GET_RATE',
    'UPDATE_RATE',

    'REVIEW_ACCOUNT_MANAGER',

    'GET_ME',

    'CREATE_ENUMERATION',
    'GET_ENUMERATION',
    'UPDATE_ENUMERATION',


    'CREATE_SERVICE',


    CREATE_QUOTATION,
    DELETE_QUOTATION,
    GET_QUOTATION,
    UPDATE_QUOTATION,
    REVIEW_QUOTATION,

    CREATE_SIGNATORY,

    CREATE_INVOICE,
    GET_INVOICE,
    REVIEW_INVOICE,
    UPDATE_INVOICE,


    CREATE_RECEIPT,
    DELETE_RECEIPT,
    GET_RECEIPT,
    UPDATE_RECEIPT

] as const;
