import { EntityFilter } from "."
import { ApiEntityStatus } from "../../../../forms"
import { ApiUserResponse } from "../../../../models/users"
import { Nullable } from "../../../../utils/types"

export const entityFilterFormValuesInitialValue = (status: Nullable<ApiEntityStatus>, reviewer: Nullable<ApiUserResponse>, updatedBy: Nullable<ApiUserResponse>): EntityFilter => {
    return {
        id: "",
        tenantId: "",
        tags: null,
        title: null,
        firstName: "",
        lastName: "",
        legalEntityName: "",
        entryType: "",
        additionalComments: "",
        status: status || "",
        refNo: "",
        reviewer: reviewer,
        updatedBy: updatedBy,
        createdBy: null,
        legacyId: ""
    }
}


