import React, { Suspense } from "react";
import "./App.css";
import { createEmotionCache } from "./utils/create-emotion-cache";
import { CacheProvider } from "@emotion/react";
import {
  SettingsConsumer,
  SettingsProvider,
} from "./contexts/settings-context";
import { ThemeProvider } from "@mui/material/styles";
import { RTL } from "./components/system/rtl";
import { Toaster } from "react-hot-toast";
import CssBaseline from "@mui/material/CssBaseline";
import { Navigation } from "./navigation";
import { createTheme } from "./services/theme";
import { Loader } from "./components/Loader";
import { Copyright } from "./components/Layouts/Copyright";
import { AuthManager } from "./models/auth";

function App() {
  const emotionCache = createEmotionCache();

  const overrides = React.useMemo(() => {}, []);

  return (
    <CacheProvider value={emotionCache}>
      <SettingsProvider>
        <SettingsConsumer>
          {({ settings }) => (
            <ThemeProvider
              theme={{
                ...createTheme({
                  direction: settings.direction,
                  responsiveFontSizes: settings.responsiveFontSizes,
                  mode: settings.theme,
                  overrides: overrides,
                }),
              }}
            >
              <RTL direction={settings.direction}>
                <CssBaseline />
                <Toaster position="top-center" />
                <Suspense fallback={<Loader />}>
                  <AuthManager>
                    <Navigation />
                  </AuthManager>
                </Suspense>
              </RTL>
            </ThemeProvider>
          )}
        </SettingsConsumer>
      </SettingsProvider>
    </CacheProvider>
  );
}

export default App;
