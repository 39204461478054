import {
  Business,
  CorporateFare,
  Euro,
  Folder,
  LocationCity,
  TextFields,
  Title,
  Toll,
  Wc,
  Work,
} from "@mui/icons-material";
import {
  TITLE,
  // SERVICE,
  LEGAL_ENTITY_TYPE,
  GENDER,
  INDUSTRY,
  SECTOR,
  LICENSE_CATEGORY,
  NAME_OF_REGULATOR,
  JURISDICTION_OF_REGULATOR,
  TYPE_OF_LISTING,
  INVESTMENT_TYPE,
  HIGH_RISK_INDUSTRY,
  RELATIONSHIP,
  LAYER_OF_OWNERSHIP,
  DETAILS_OF_SCHEME,
  STATUS_OF_APPLICATION,
  PEP_RELATIONSHIP,
  INTRODUCED_BY,
  DEALING_DIRECTLY_WITH,
  IDENTITY_DOCUMENT_TYPE,
  PROOF_OF_ADDRESS,
  ESTIMATED_NET_WORTH,
  REASON_FOR_NOT_ONBOARDING,
  REASON_FOR_TERMINATION,
  CURRENCY,
  TAG,
  VAT,
  TOWN,
  QUOTE_REJECTION_REASON,
  QUOTE_CANCELLATION_REASON,
  RECEIPT_CANCELLATION_REASON,
} from "../../models/enumerations";

export const enumerationDataMap = new Map();

const title = {
  editable: true,
  show: true,
  path: "titles",
  icon: <Title />,
  enumerationDetails: "common.titleDetails",
  enumerationManagement: "common.titleManagement",
  enumerations: "common.titles",
  editEnumeration: "common.editTitle",
  createEnumeration: "common.createTitle",
  updateEnumeration: "common.updatetitle",
  enumerationCreatedSuccessfully: "common.titleCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.titleUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.titleAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.titleNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(TITLE, title);

// const service = {
//   editable: true,
//   show: true,
//   path: "services",
//   icon: <Work />,
//   enumerationDetails: "common.serviceDetails",
//   enumerationManagement: "common.serviceManagement",
//   enumerations: "common.services",
//   editEnumeration: "common.editService",
//   createEnumeration: "common.createService",
//   updateEnumeration: "common.updateService",
//   enumerationCreatedSuccessfully: "common.serviceCreatedSuccessfully",
//   enumerationUpdatedSuccessfully: "common.serviceUpdatedSuccessfully",
//   enumerationAlreadyExists: "errors.serviceAlreadyExists", // ENUMERATION_ALREADY_EXISTS
//   enumerationNotFound: "errors.serviceNotFound", // ENUMERATION_NOT_FOUND
// };
// enumerationDataMap.set(SERVICE, service);

const legalEntityType = {
  editable: false,
  show: true,
  path: "legal-entity-types",
  icon: <Business />,
  enumerationDetails: "common.legalEntityTypeDetails",
  enumerationManagement: "common.legalEntityTypeManagement",
  enumerations: "common.legalEntityTypes",
  editEnumeration: "common.editLegalEntityType",
  createEnumeration: "common.createLegalEntityType",
  updateEnumeration: "common.updateLegalEntityType",
  enumerationCreatedSuccessfully: "common.legalEntityTypeCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.legalEntityTypeUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.legalEntityTypeAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.legalEntityTypeNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(LEGAL_ENTITY_TYPE, legalEntityType);

const gender = {
  editable: true,
  show: true,
  path: "genders",
  icon: <Wc />,
  enumerationDetails: "common.genderDetails",
  enumerationManagement: "common.genderManagement",
  enumerations: "common.genders",
  editEnumeration: "common.editGender",
  createEnumeration: "common.createGender",
  updateEnumeration: "common.updategender",
  enumerationCreatedSuccessfully: "common.genderCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.genderUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.genderAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.genderNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(GENDER, gender);

const industry = {
  editable: true,
  show: true,
  path: "industries",
  icon: <CorporateFare />,
  enumerationDetails: "common.industryDetails",
  enumerationManagement: "common.industryManagement",
  enumerations: "common.industries",
  editEnumeration: "common.editIndustry",
  createEnumeration: "common.createIndustry",
  updateEnumeration: "common.updateIndustry",
  enumerationCreatedSuccessfully: "common.industryCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.industryUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.industryAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.industryNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(INDUSTRY, industry);

const sector = {
  editable: true,
  show: true,
  path: "sectors",
  icon: <Folder />,
  enumerationDetails: "common.sectorDetails",
  enumerationManagement: "common.sectorManagement",
  enumerations: "common.sectors",
  editEnumeration: "common.editSector",
  createEnumeration: "common.createSector",
  updateEnumeration: "common.updateIndustry",
  enumerationCreatedSuccessfully: "common.sectorCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.sectorUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.sectorAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.sectorNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(SECTOR, sector);

const licenseCategory = {
  editable: true,
  show: true,
  path: "license-categories",
  icon: <Folder />,
  enumerationDetails: "common.licenseCategoryDetails",
  enumerationManagement: "common.licenseCategoryManagement",
  enumerations: "common.licenseCategories",
  editEnumeration: "common.editLicenseCategory",
  createEnumeration: "common.createLicenseCategory",
  updateEnumeration: "common.updateLicenseCategory",
  enumerationCreatedSuccessfully: "common.licenseCategoryCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.licenseCategoryUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.licenseCategoryAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.licenseCategoryNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(LICENSE_CATEGORY, licenseCategory);

const nameOfRegulator = {
  editable: true,
  show: true,
  path: "name-of-regulators",
  icon: <Folder />,
  enumerationDetails: "common.nameOfRegulatorDetails",
  enumerationManagement: "common.nameOfRegulatorManagement",
  enumerations: "common.nameOfRegulators",
  editEnumeration: "common.editNameOfRegulator",
  createEnumeration: "common.createNameOfRegulator",
  updateEnumeration: "common.updateNameOfRegulator",
  enumerationCreatedSuccessfully: "common.nameOfRegulatorCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.nameOfRegulatorUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.nameOfRegulatorAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.nameOfRegulatorNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(NAME_OF_REGULATOR, nameOfRegulator);

const jurisdictionOfRegulator = {
  editable: true,
  show: true,
  path: "jurisdiction-of-regulator",
  icon: <Folder />,
  enumerationDetails: "common.jurisdictionOfRegulatorDetails",
  enumerationManagement: "common.jurisdictionOfRegulatorManagement",
  enumerations: "common.jurisdictionOfRegulators",
  editEnumeration: "common.editJurisdictionOfRegulator",
  createEnumeration: "common.createJurisdictionOfRegulator",
  updateEnumeration: "common.updateJurisdictionOfRegulator",
  enumerationCreatedSuccessfully:
    "common.jurisdictionOfRegulatorCreatedSuccessfully",
  enumerationUpdatedSuccessfully:
    "common.jurisdictionOfRegulatorUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.jurisdictionOfRegulatorAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.jurisdictionOfRegulatorNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(JURISDICTION_OF_REGULATOR, jurisdictionOfRegulator);

const typeOfListing = {
  editable: true,
  show: true,
  path: "type-of-listings",
  icon: <Folder />,
  enumerationDetails: "common.typeOfListingDetails",
  enumerationManagement: "common.typeOfListingManagement",
  enumerations: "common.typeOfListings",
  editEnumeration: "common.editTypeOfListing",
  createEnumeration: "common.createTypeOfListing",
  updateEnumeration: "common.updateTypeOfListing",
  enumerationCreatedSuccessfully: "common.typeOfListingCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.typeOfListingUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.typeOfListingAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.typeOfListingNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(TYPE_OF_LISTING, typeOfListing);

const investmentType = {
  editable: true,
  show: true,
  path: "investment-types",
  icon: <Folder />,
  enumerationDetails: "common.investmentTypeDetails",
  enumerationManagement: "common.investmentTypeManagement",
  enumerations: "common.investmentTypes",
  editEnumeration: "common.editInvestmentType",
  createEnumeration: "common.createInvestmentType",
  updateEnumeration: "common.updateInvestmentType",
  enumerationCreatedSuccessfully: "common.investmentTypeCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.investmentTypeUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.investmentTypeAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.investmentTypeNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(INVESTMENT_TYPE, investmentType);

const highRiskIndustry = {
  editable: true,
  show: true,
  path: "high-risk-industries",
  icon: <Folder />,
  enumerationDetails: "common.highRiskIndustryDetails",
  enumerationManagement: "common.highRiskIndustryManagement",
  enumerations: "common.highRiskIndustries",
  editEnumeration: "common.editHighRiskIndustry",
  createEnumeration: "common.createHighRiskIndustry",
  updateEnumeration: "common.updateHighRiskIndustry",
  enumerationCreatedSuccessfully: "common.highRiskIndustryCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.highRiskIndustryUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.highRiskIndustryAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.highRiskIndustryNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(HIGH_RISK_INDUSTRY, highRiskIndustry);

const relationship = {
  editable: false,
  show: true,
  path: "relationships",
  icon: <Folder />,
  enumerationDetails: "common.relationshipDetails",
  enumerationManagement: "common.relationshipManagement",
  enumerations: "common.relationships",
  editEnumeration: "common.editRelationship",
  createEnumeration: "common.createRelationship",
  updateEnumeration: "common.updateRelationship",
  enumerationCreatedSuccessfully: "common.relationshipCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.relationshipUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.relationshipAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.relationshipNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(RELATIONSHIP, relationship);

const layerOfOwnership = {
  editable: true,
  show: true,
  path: "layer-of-ownerships",
  icon: <Folder />,
  enumerationDetails: "common.layerOfOwnershipDetails",
  enumerationManagement: "common.layerOfOwnershipManagement",
  enumerations: "common.layerOfOwnership",
  editEnumeration: "common.editLayerOfOwnership",
  createEnumeration: "common.createLayerOfOwnership",
  updateEnumeration: "common.updateLayerOfOwnership",
  enumerationCreatedSuccessfully: "common.layerOfOwnershipCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.layerOfOwnershipUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.layerOfOwnershipAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.layerOfOwnershipNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(LAYER_OF_OWNERSHIP, layerOfOwnership);

const detailsOfScheme = {
  editable: true,
  show: true,
  path: "details-of-schemes",
  icon: <Folder />,
  enumerationDetails: "common.detailsOfSchemeDetails",
  enumerationManagement: "common.detailsOfSchemeManagement",
  enumerations: "common.detailsOfSchemes",
  editEnumeration: "common.editDetailsOfScheme",
  createEnumeration: "common.createDetailsOfScheme",
  updateEnumeration: "common.updateDetailsOfScheme",
  enumerationCreatedSuccessfully: "common.detailsOfSchemeCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.detailsOfSchemeUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.detailsOfSchemeAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.detailsOfSchemeNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(DETAILS_OF_SCHEME, detailsOfScheme);

const statusOfApplication = {
  editable: true,
  show: true,
  path: "status-of-applications",
  icon: <Folder />,
  enumerationDetails: "common.statusOfApplicationDetails",
  enumerationManagement: "common.statusOfApplicationManagement",
  enumerations: "common.statusesOfApplication",
  editEnumeration: "common.editStatusOfApplication",
  createEnumeration: "common.createStatusOfApplication",
  updateEnumeration: "common.updateStatusOfApplication",
  enumerationCreatedSuccessfully:
    "common.statusOfApplicationCreatedSuccessfully",
  enumerationUpdatedSuccessfully:
    "common.statusOfApplicationUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.statusOfApplicationAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.statusOfApplicationNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(STATUS_OF_APPLICATION, statusOfApplication);

const pepRelationship = {
  editable: false,
  show: true,
  path: "pep-relationships",
  icon: <Folder />,
  enumerationDetails: "common.pepRelationshipDetails",
  enumerationManagement: "common.pepRelationshipManagement",
  enumerations: "common.pepRelationships",
  editEnumeration: "common.editPepRelationship",
  createEnumeration: "common.createPepRelationship",
  updateEnumeration: "common.updatePepRelationship",
  enumerationCreatedSuccessfully: "common.pepRelationshipCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.pepRelationshipUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.pepRelationshipAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.pepRelationshipNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(PEP_RELATIONSHIP, pepRelationship);

const introducedBy = {
  editable: true,
  show: true,
  path: "intruced-by",
  icon: <Folder />,
  enumerationDetails: "common.introducedByDetails",
  enumerationManagement: "common.introducedByManagement",
  enumerations: "common.introducedBy",
  editEnumeration: "common.editIntroducedBy",
  createEnumeration: "common.createIntroducedBy",
  updateEnumeration: "common.updateIntroducedBy",
  enumerationCreatedSuccessfully: "common.introducedByCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.introducedByUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.introducedByAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.introducedByNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(INTRODUCED_BY, introducedBy);

const dealingDirectlyWith = {
  editable: false,
  show: true,
  path: "dealing-directly-with",
  icon: <Folder />,
  enumerationDetails: "common.dealingDirectlyWithDetails",
  enumerationManagement: "common.dealingDirectlyWithManagement",
  enumerations: "common.dealingDirectlyWith",
  editEnumeration: "common.editDealingDirectlyWith",
  createEnumeration: "common.createDealingDirectlyWith",
  updateEnumeration: "common.updateDealingDirectlyWith",
  enumerationCreatedSuccessfully:
    "common.dealingDirectlyWithCreatedSuccessfully",
  enumerationUpdatedSuccessfully:
    "common.dealingDirectlyWithUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.dealingDirectlyWithAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.dealingDirectlyWithNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(DEALING_DIRECTLY_WITH, dealingDirectlyWith);

const identityDocumentType = {
  editable: true,
  show: true,
  path: "identity-document-types",
  icon: <Folder />,
  enumerationDetails: "common.identityDocumentTypeDetails",
  enumerationManagement: "common.identityDocumentTypeManagement",
  enumerations: "common.identityDocumentTypes",
  editEnumeration: "common.editIdentityDocumentType",
  createEnumeration: "common.createIdentityDocumentType",
  updateEnumeration: "common.updateIdentityDocumentType",
  enumerationCreatedSuccessfully:
    "common.identityDocumentTypeCreatedSuccessfully",
  enumerationUpdatedSuccessfully:
    "common.identityDocumentTypeUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.identityDocumentTypeAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.identityDocumentTypeNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(IDENTITY_DOCUMENT_TYPE, identityDocumentType);

const proofOfAddress = {
  editable: true,
  show: true,
  path: "proof-of-addresses",
  icon: <Folder />,
  enumerationDetails: "common.proofOfAddressDetails",
  enumerationManagement: "common.proofOfAddressManagement",
  enumerations: "common.proofOfAddresses",
  editEnumeration: "common.editProofOfAddress",
  createEnumeration: "common.createProofOfAddress",
  updateEnumeration: "common.updateProofOfAddress",
  enumerationCreatedSuccessfully: "common.proofOfAddressCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.proofOfAddressUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.proofOfAddressAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.proofOfAddressNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(PROOF_OF_ADDRESS, proofOfAddress);

const estimatedNetWorth = {
  editable: true,
  show: true,
  path: "estimated-net-worths",
  icon: <Folder />,
  enumerationDetails: "common.estimatedNetWorthDetails",
  enumerationManagement: "common.estimatedNetWorthManagement",
  enumerations: "common.estimatedNetWorth",
  editEnumeration: "common.editEstimatedNetWorth",
  createEnumeration: "common.createEstimatedNetWorth",
  updateEnumeration: "common.updateEstimatedNetWorth",
  enumerationCreatedSuccessfully: "common.estimatedNetWorthCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.estimatedNetWorthUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.estimatedNetWorthAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.estimatedNetWorthNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(ESTIMATED_NET_WORTH, estimatedNetWorth);

const reasonForNotOnboarding = {
  editable: true,
  show: true,
  path: "reasons-for-not-onboarding",
  icon: <Folder />,
  enumerationDetails: "common.reasonForNotOnboardingDetails",
  enumerationManagement: "common.reasonForNotOnboardingManagement",
  enumerations: "common.reasonForNotOnboarding",
  editEnumeration: "common.editReasonForNotOnboarding",
  createEnumeration: "common.createReasonForNotOnboarding",
  updateEnumeration: "common.updateReasonForNotOnboarding",
  enumerationCreatedSuccessfully:
    "common.reasonForNotOnboardingCreatedSuccessfully",
  enumerationUpdatedSuccessfully:
    "common.reasonForNotOnboardingUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.reasonForNotOnboardingAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.reasonForNotOnboardingNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(REASON_FOR_NOT_ONBOARDING, reasonForNotOnboarding);

const reasonForTermination = {
  editable: true,
  show: true,
  path: "reasons-for-termination",
  icon: <Folder />,
  enumerationDetails: "common.reasonForTerminationDetails",
  enumerationManagement: "common.reasonForTerminationManagement",
  enumerations: "common.reasonsForTermination",
  editEnumeration: "common.editReasonForTermination",
  createEnumeration: "common.createReasonForTermination",
  updateEnumeration: "common.updateReasonForTermination",
  enumerationCreatedSuccessfully:
    "common.reasonForTerminationCreatedSuccessfully",
  enumerationUpdatedSuccessfully:
    "common.reasonForTerminationUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.reasonForTerminationAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.reasonForTerminationNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(REASON_FOR_TERMINATION, reasonForTermination);

const currency = {
  editable: true,
  show: true,
  path: "currencies",
  icon: <Euro />,
  enumerationDetails: "common.currencyDetails",
  enumerationManagement: "common.currencyManagement",
  enumerations: "common.currencies",
  editEnumeration: "common.editCurrency",
  createEnumeration: "common.createCurrency",
  updateEnumeration: "common.updateCurrency",
  enumerationCreatedSuccessfully: "common.currencyCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.currencyUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.currencyAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.currencyNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(CURRENCY, currency);

const tag = {
  editable: true,
  show: true,
  path: "tags",
  icon: <TextFields />,
  enumerationDetails: "common.tagDetails",
  enumerationManagement: "common.tagManagement",
  enumerations: "common.tags",
  editEnumeration: "common.editTag",
  createEnumeration: "common.createTag",
  updateEnumeration: "common.updateTag",
  enumerationCreatedSuccessfully: "common.tagCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.tagUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.tagAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.tagNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(TAG, tag);

const vat = {
  editable: true,
  show: true,
  path: "vats",
  icon: <Toll />,
  enumerationDetails: "common.vatDetails",
  enumerationManagement: "common.vatManagement",
  enumerations: "common.vats",
  editEnumeration: "common.editVat",
  createEnumeration: "common.createVat",
  updateEnumeration: "common.updateVat",
  enumerationCreatedSuccessfully: "common.vatCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.vatUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.vatAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.vatNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(VAT, vat);

const town = {
  editable: true,
  show: true,
  path: "towns",
  icon: <LocationCity />,
  enumerationDetails: "common.townDetails",
  enumerationManagement: "common.townManagement",
  enumerations: "common.towns",
  editEnumeration: "common.editTown",
  createEnumeration: "common.createTown",
  updateEnumeration: "common.updateTown",
  enumerationCreatedSuccessfully: "common.townCreatedSuccessfully",
  enumerationUpdatedSuccessfully: "common.townUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.townAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.townNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(TOWN, town);

const quoteRejectionReason = {
  editable: true,
  show: true,
  path: "quoteRejectionReasons",
  icon: <Folder />,
  enumerationDetails: "common.quoteRejectionReasonDetails",
  enumerationManagement: "common.quoteRejectionReasonManagement",
  enumerations: "common.quoteRejectionReasons",
  editEnumeration: "common.editQuoteRejectionReason",
  createEnumeration: "common.createQuoteRejectionReason",
  updateEnumeration: "common.updateQuoteRejectionReason",
  enumerationCreatedSuccessfully:
    "common.quoteRejectionReasonCreatedSuccessfully",
  enumerationUpdatedSuccessfully:
    "common.quoteRejectionReasonUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.quoteRejectionReasonAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.quoteRejectionReasonNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(QUOTE_REJECTION_REASON, quoteRejectionReason);

const quoteCancellationReason = {
  editable: true,
  show: true,
  path: "quoteCancellationReasons",
  icon: <Folder />,
  enumerationDetails: "common.quoteCancellationReasonDetails",
  enumerationManagement: "common.quoteCancellationReasonManagement",
  enumerations: "common.quoteCancellationReasons",
  editEnumeration: "common.editQuoteCancellationReason",
  createEnumeration: "common.createQuoteCancellationReason",
  updateEnumeration: "common.updateQuoteCancellationReason",
  enumerationCreatedSuccessfully:
    "common.quoteCancellationReasonCreatedSuccessfully",
  enumerationUpdatedSuccessfully:
    "common.quoteCancellationReasonUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.quoteCancellationReasonAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.quoteCancellationReasonNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(QUOTE_CANCELLATION_REASON, quoteCancellationReason);

const receiptCancellationReason = {
  editable: true,
  show: true,
  path: "receiptCancellationReasons",
  icon: <Folder />,
  enumerationDetails: "common.receiptCancellationReasonDetails",
  enumerationManagement: "common.receiptCancellationReasonManagement",
  enumerations: "common.receiptCancellationReasons",
  editEnumeration: "common.editReceiptCancellationReason",
  createEnumeration: "common.createReceiptCancellationReason",
  updateEnumeration: "common.updateReceiptCancellationReason",
  enumerationCreatedSuccessfully:
    "common.receiptCancellationReasonCreatedSuccessfully",
  enumerationUpdatedSuccessfully:
    "common.receiptCancellationReasonUpdatedSuccessfully",
  enumerationAlreadyExists: "errors.receiptCancellationReasonAlreadyExists", // ENUMERATION_ALREADY_EXISTS
  enumerationNotFound: "errors.receiptCancellationReasonNotFound", // ENUMERATION_NOT_FOUND
};
enumerationDataMap.set(RECEIPT_CANCELLATION_REASON, receiptCancellationReason);
