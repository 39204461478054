import { CURRENCY } from "../../../../models/enumerations";
import { enumerableValidationSchema, negativeMoneyAmountValidationSchema, positiveMoneyAmountValidationSchema } from "../../simple";
import * as Yup from "yup";

export const moneyValidationSchema = () =>
    Yup.object({
        value: positiveMoneyAmountValidationSchema(),
        currency: enumerableValidationSchema(CURRENCY)
    });


export const negativeMoneyValidationSchema = () =>
    Yup.object({
        value: negativeMoneyAmountValidationSchema(),
        currency: enumerableValidationSchema(CURRENCY)
    });
