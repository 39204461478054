
import {
    CountryCode,
    getCountryCallingCode,
} from 'libphonenumber-js';
import { Phone } from '.';

export const composePhoneNumber = (country: CountryCode, phone: string) =>
    `+${getCountryCallingCode(country)}${phone}`;

export const phoneInitialValues = (phone?: Phone): Phone => {
    return {
        phoneCountryCode: null,
        phoneNumber: '',
        ...phone
    }
}
