export const config = {
    enableReduxDevTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true',
    apiUrl: process.env.REACT_APP_API_BASE_ROOT,
    apiKey: process.env.REACT_APP_API_KEY
};


export const localConfig = {
    countryCodeDefault: 'US',
    PASSWORD_MIN_LENGTH: 6,
    VERIFICATION_CODE_LENGTH: 4,
    UPLOAD_MAX_FILE_SIZE: (2 * 1024 * 1024), // 2 Mega bytes
    VAT_RATE: 18
}