import { ErrorOutline } from "@mui/icons-material";
import {
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import { ConfirmDialogType } from ".";
import { useTranslation } from "react-i18next";

interface ConfirmDialogProps {
  confirmDialog: ConfirmDialogType;
  setConfirmDialog: (v: ConfirmDialogType) => void;
  children?: ReactNode;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const { confirmDialog, setConfirmDialog, children } = props;
  const { t } = useTranslation();
  return (
    <Dialog
      open={confirmDialog.isOpen}
      sx={(theme) => ({
        padding: theme.spacing(2),
        position: "absolute",
      })}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        <IconButton
          disableRipple
          sx={(theme) => ({
            color: colors.orange[400],
            "&:hover": {
              backgroundColor: theme.palette.secondary.light,
              cursor: "default",
            },
            "& .MuiSvgIcon-root": {
              fontSize: "8rem",
            },
          })}
        >
          <ErrorOutline />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <Typography variant="h6">{confirmDialog.title}</Typography>
        <Typography variant="subtitle2">{confirmDialog.subTitle}</Typography>
        {children}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          color="primary"
          variant="outlined"
          onClick={(e) => {
            setConfirmDialog({ ...confirmDialog, isOpen: false });
            confirmDialog.onDecline && confirmDialog.onDecline(e);
          }}
        >
          {t(
            confirmDialog.declineText ? confirmDialog.declineText : "common.no"
          )}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={(e) => {
            setConfirmDialog({ ...confirmDialog, isOpen: false });
            confirmDialog.onConfirm(e);
          }}
        >
          {t(
            confirmDialog.confirmText ? confirmDialog.confirmText : "common.yes"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
