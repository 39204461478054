import { ApiEntity, ApiStageEngagement, STAGE_4_1_TYPE } from "../../../../../../models/entities"
import { EngagementFormValues } from "."
import { ApiEnumeration, REASON_FOR_NOT_ONBOARDING, TAG, TITLE } from "../../../../../../models/enumerations"
import { NO, YES, booleanToYesNo } from "../../../../../../forms";
import { parseISO } from "date-fns";

export const engagementFormValuesInitialValue = (
    entity: ApiEntity,
    enumerables: ApiEnumeration[]): EngagementFormValues => {
    // Get Stage Data
    const stageData: ApiStageEngagement = entity.stages.find((s) => s.stageType === STAGE_4_1_TYPE) as ApiStageEngagement;
    // If draft is available use that
    const data: ApiStageEngagement = stageData.draftData ? stageData.draftData as ApiStageEngagement : stageData;
    return {
        stageType: STAGE_4_1_TYPE,
        stageStatus: data.stageStatus,
        id: entity.id,
        onboarded: data.onboarded != null ? booleanToYesNo(data.onboarded) : '',
        dateOfCommencement: data.onboarded && data.dateOfCommencement ? parseISO(data.dateOfCommencement) : null,
        reasonForNotOnboarding: !data.onboarded && data.reasonForNotOnboarding ? enumerables.filter(x => x.type === REASON_FOR_NOT_ONBOARDING).find(x => x.value === data.reasonForNotOnboarding)! : null,
        reasonForNotOnboardingInfo: !data.onboarded ? data.reasonForNotOnboardingInfo! : '',
        dateOfSignatureEngagement: data.dateOfSignatureEngagement ? parseISO(data.dateOfSignatureEngagement) : null,

    }
}
