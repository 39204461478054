import { apiWithTag } from '../emptySplitApi';
// import { ApiTotp, ApiTotpRequest, MeRequest, MeResponse } from './types';
import { ApiChangePasswordRequest, ApiMeTimesheet } from '.';
import { ApiUserResponse } from '../users';

export const userApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    me: builder.query<ApiUserResponse, {}>({
      query: () => ({
        method: 'GET',
        url: 'me',
      }),
      providesTags: ['Me']
    }),

    meTimesheet: builder.query<ApiMeTimesheet, {}>({
      query: () => ({
        method: 'GET',
        url: 'me/timesheets',
      }),
      providesTags: ['Me', 'Timesheet']
    }),
    // qrCode: builder.mutation<ApiTotp, { accountHolderId: string }>({
    //   query: ({ accountHolderId }) => ({
    //     headers: {
    //       'x-account-holder-id': accountHolderId,
    //     },
    //     method: 'POST',
    //     url: 'auth/me/totp',
    //   }),
    //   invalidatesTags: ['Me']
    // }),
    // enable2FA: builder.mutation<ApiTotp, ApiTotpRequest>({
    //   query: ({ accountHolderIdForHeader, totpCode }) => ({
    //     body: { totpCode },
    //     headers: {
    //       'x-account-holder-id': accountHolderIdForHeader,
    //     },
    //     method: 'PUT',
    //     url: 'auth/me/totp',
    //   }),
    //   invalidatesTags: ['Me']
    // }),
    // disable2FA: builder.mutation<void, { accountHolderId: string }>({
    //   query: (payload) => ({
    //     headers: {
    //       'x-account-holder-id': `${payload.accountHolderId}`,
    //     },
    //     method: 'DELETE',
    //     url: `auth/me/totp`,
    //   }),
    //   invalidatesTags: ['Me']
    // }),
    changePassword: builder.mutation<void, ApiChangePasswordRequest>({
      query: (body) => ({
        body,
        method: 'PUT',
        invalidatesTags: ['Me'],
        url: 'me/password',
      }),
    }),
  }),
});

export const {
  useMeQuery,
  useLazyMeQuery,
  useMeTimesheetQuery,
  // useQrCodeMutation,
  // useEnable2FAMutation,
  // useDisable2FAMutation,
  useChangePasswordMutation
} = userApi;
