import { Color, colors } from "@mui/material";
import { ServiceLine } from ".";

export const AUDIT = 'AUDIT'
export const ACCOUNTING = 'ACCOUNTING'
export const COMPLIANCE = 'COMPLIANCE'



export const serviceLines = [ACCOUNTING, AUDIT, COMPLIANCE] as const;
export const serviceLinesColours = [colors.green, colors.green, colors.green];
export const serviceLinesColourMap = new Map<ServiceLine, Color>(serviceLines.map((v, i) => [v, serviceLinesColours[i]]));;
