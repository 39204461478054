import { Route, Routes } from "react-router";
import { LazyLoadableScreen } from "../../navigation/LazyLoadableScreen";

const TimesheetListScreen = LazyLoadableScreen(() => import("./TimesheetList"));
const TimesheetDetailsScreen = LazyLoadableScreen(
  () => import("./TimesheetDetails")
);

export const TimesheetsRoutes = () => (
  <Routes>
    <Route path="/" element={<TimesheetListScreen />} />
    <Route path="/:id" element={<TimesheetDetailsScreen />} />
  </Routes>
);
