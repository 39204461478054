import { FC } from "react";
import { Route, Routes } from "react-router";
import { ApiEnumerationType } from "../../models/enumerations";
import { LazyLoadableScreen } from "../../navigation/LazyLoadableScreen";

const EnumerationListScreen = LazyLoadableScreen(
  () => import("./EnumerationList")
);
const EnumerationDetailsScreen = LazyLoadableScreen(
  () => import("./EnumerationDetails")
);

export const EnumerationsRoutes: FC<{ type: ApiEnumerationType }> = ({
  type,
}) => (
  <Routes>
    <Route path="/" element={<EnumerationListScreen type={type} />} />
    <Route path="/:id" element={<EnumerationDetailsScreen type={type} />} />
  </Routes>
);
