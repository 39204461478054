
import {
    ApiEntity,
    // ApiUpdateEntityRequest, 
    PageApiEntityResponse, PagedEntitiesRequest, CUSTOMER, POTENTIAL_CUSTOMER, ApiStageBase, ApiCreateEntity, ApiUpdateEntity, ApiStageBasicDetail, ApiEntityBalance, ApiEntityFilter,
    ApiInvoiceStatistic,
    ApiQuotedService
} from '.';
import { removeEmpty } from '../../utils/removeEmpty';
// import { ApiEmbellishedEntity } from '../entityStage';
import { apiWithTag } from '../emptySplitApi';

// const transformResponse = (response: ApiEntity): ApiEmbellishedEntity => {
//     return {
//         ...response,
//         tag: response.dateOfCommencement != null ? CUSTOMER : response.quotationAccepted ? POTENTIAL_CUSTOMER : null
//     }
// }

// const transformPaginatedListResponse = (response: PageApiEntityResponse): PageApiEntityResponse => {
//     return {
//         ...response,
//         content: [...response.content.map(entity => ({ ...entity, tag: entity.dateOfCommencement != null ? CUSTOMER : entity.quotationAccepted ? POTENTIAL_CUSTOMER : null }))]
//     }
// }

// const transformListResponse = (response: ApiEntity[]): ApiEmbellishedEntity[] => {
//     return [...response.map(entity => ({ ...entity, tag: entity.dateOfCommencement != null ? CUSTOMER : entity.quotationAccepted ? POTENTIAL_CUSTOMER : null }))]
// }

export const entitiesApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        entities: builder.query<PageApiEntityResponse, PagedEntitiesRequest>({
            query: ({ ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    // headers: {
                    //     'x-tenant-holder-id': tenantHolderIdForHeader,
                    // },
                    method: 'GET',
                    params: parameters,
                    url: 'entities',
                }
            },
            providesTags: ['Entity'],
            // transformResponse: transformPaginatedListResponse
        }),
        entitiesList: builder.query<ApiEntity[], ApiEntityFilter>({
            query: ({ ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    // headers: {
                    //     'x-tenant-holder-id': tenantHolderIdForHeader,
                    // },
                    method: 'GET',
                    params: parameters,
                    url: 'entities/list',
                }
            },
            providesTags: ['Entity'],
            // transformResponse: transformListResponse
        }),
        entity: builder.query<ApiEntity, { id: string }>({
            query: ({ id }) => ({
                method: 'GET',
                // headers: {
                //     'x-tenant-holder-id': tenantHolderIdForHeader,
                // },
                url: `entities/${id}`,
            }),
            // transformResponse:(response:ApiAccountResponse, meta, arg) => {
            //     const {associations, ...rest} = response;
            //     return ({...rest,  permissionGroupIds: associations.find(a => a.tenantHolderId == arg.tenantHolderId).permissionGroups.map(pg => pg.id)});
            //   },
            providesTags: ['Entity'],
            // transformResponse
        }),
        createEntity: builder.mutation<ApiEntity, ApiCreateEntity>({
            query: ({ tenantIdForHeader, ...body }) => ({
                url: 'entities',
                method: 'POST',
                headers: (tenantIdForHeader.length > 0) ? {
                    'X-TENANT-ID': tenantIdForHeader
                } : {},
                body: { ...body }
            }),
            invalidatesTags: ['Entity']
        }),
        updateEntity: builder.mutation<ApiEntity, ApiUpdateEntity>({
            query: ({ id, ...rest }) => ({
                url: `entities/${id}`,
                method: 'PUT',
                body: { ...rest }
            }),
            invalidatesTags: ['Entity']
        }),
        updateEntityStage: builder.mutation<ApiStageBase, ApiStageBase>({
            query: ({ id, ...rest }) => ({
                url: `entities/${id}/stages`,
                method: 'PUT',
                body: { ...rest }
            }),
            invalidatesTags: ['Entity']
        }),

        entityWithStages: builder.query<ApiEntity, { id: string }>({
            query: ({ id }) => ({
                method: 'GET',
                url: `entities/${id}/stages`,
            }),
            providesTags: ['Entity'],
        }),

        getEntityBalanceById: builder.query<ApiEntityBalance, { id: string }>({
            query: ({ id }) => ({
                method: 'GET',
                url: `entities/${id}/balance`,
            }),
            providesTags: ['Entity'],
        }),

        getBalanceDueaAndInvoiceStatistics: builder.query<ApiInvoiceStatistic, { id: string }>({
            query: ({ id }) => ({
                method: 'GET',
                url: `entities/${id}/invoices/statistics`,
            }),
            providesTags: ['Entity'],
        }),

        getQuotedServices: builder.query<ApiQuotedService[], { id: string }>({
            query: ({ id }) => ({
                method: 'GET',
                url: `entities/${id}/invoices/quoted-services`,
            }),
            providesTags: ['Entity'],
        }),


    })
})

export const {
    useEntitiesQuery,
    useLazyEntitiesQuery,
    useEntitiesListQuery,
    useLazyEntityQuery,
    useEntityQuery,
    useCreateEntityMutation,
    useUpdateEntityMutation,
    useUpdateEntityStageMutation,
    useEntityWithStagesQuery,
    useGetEntityBalanceByIdQuery,
    useGetBalanceDueaAndInvoiceStatisticsQuery,
    useGetQuotedServicesQuery
} = entitiesApi;