import { CountryCode } from "libphonenumber-js";
import ReactCountryFlag from "react-country-flag";

export const CountryFlag = (props: { countryCode: CountryCode | string }) => (
  <ReactCountryFlag
    countryCode={props.countryCode}
    style={{
      height: "1.25em",
      marginTop: "-2px",
      width: "1.25em",
    }}
    svg
  />
);
