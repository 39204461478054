import * as Yup from "yup";

export const imageValidationSchema = () => Yup
    .mixed()
    .required("validations.imageIsRequired")
    .test("fileSize", "validations.fileIsTooLarge", (value) => {
        return value && value.size <= 200000;
    })
    .test("type", "validations.onlyImageFormatsAccepted", (value) => {
        return value && (
            value.type === "image/jpeg" ||
            value.type === "image/bmp" ||
            value.type === "image/png" ||
            value.type === "application/pdf"
        );
    })

