import { FormControlLabel, Switch } from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export interface SwitchFieldProps {
  disabled: boolean;
  label?: string;
  size?: "small" | "medium";
  onBlur?: (e: any) => void;
  onChange?: (e: any) => void;
}
export const BasicSwitchField: FC<
  SwitchFieldProps & FieldProps<boolean, boolean>
> = ({
  disabled = false,
  label = "common.switch",
  size = "medium",
  onBlur,
  onChange,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(field, null, 2)}</pre> */}

      <FormControlLabel
        control={
          <Field
            label={t(label)}
            name={field.name}
            component={Switch}
            onChange={(
              ev: React.ChangeEvent<HTMLInputElement>,
              checked: boolean
            ) => {
              setFieldValue(field.name, checked);
            }}
            checked={getIn(values, field.name)}
            size={size}
            disabled={disabled}
          />
        }
        label={t(label)}
      />
    </>
  );
};
