import * as Yup from "yup";

export const signatoryValidationSchema = (validationMessage?: string) =>
    Yup.object()
        .required(validationMessage || 'validations.signatoryIsRequired')
        .nullable()
        .test(
            'null-check',
            validationMessage || 'validations.signatoryIsRequired',
            (signatory) => signatory != null,
        )




