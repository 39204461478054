import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  FormControl,
  TextField,
} from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  ApiTenantResponse,
  useTenantsListQuery,
} from "../../../../models/tenants";
// import { TenantStatusLabel } from "src/screens/Tenants/TenantStatusLabel";
import { Nullable } from "../../../../utils/types";

export interface TenantFieldProps {
  disabled: boolean;
  tenantId?: string;
  label?: string;
  size?: "small" | "medium";
  margin?: "none" | "dense" | "normal";
  onChange: (value: string) => void;
}
export const TenantField: FC<
  TenantFieldProps & FieldProps<ApiTenantResponse[], ApiTenantResponse[]>
> = ({
  disabled = false,
  tenantId,
  label = "common.tenant",
  size = "medium",
  margin,
  onChange = () => null,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();

  const { data: tenants } = useTenantsListQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  // Auto populate Account manager field when there is only one
  useEffect(() => {
    if (tenants && tenants?.length === 1) {
      setFieldValue(`${field.name}`, tenants[0].id);
    }
  }, [tenants, field.name, setFieldValue]);

  return (
    <>
      {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
      {tenants && tenants?.length > 0 && (
        <FormControl fullWidth margin={margin}>
          <Field
            size={size}
            margin={margin}
            component={Autocomplete}
            name={`${field.name}`}
            fullWidth
            value={
              tenants.find((x) => x.id === getIn(values, field.name)) || null
            }
            onBlur={handleBlur(`${field.name}`)}
            onChange={(e: any, value: ApiTenantResponse) => {
              setFieldValue(`${field.name}`, value !== null ? value.id : "");
              onChange(value !== null ? value.id : "");
            }}
            disabled={disabled || tenants?.length === 1}
            options={tenants}
            autoHighlight
            variant="outlined"
            filterOptions={(
              options: Array<ApiTenantResponse>,
              state: { inputValue: string }
            ): Array<ApiTenantResponse> => {
              return options.filter(
                (x) =>
                  x.name
                    ?.toLowerCase()
                    ?.includes(state.inputValue.toLowerCase()) ||
                  x.id?.toLowerCase()?.includes(state.inputValue.toLowerCase())
              );
            }}
            isOptionEqualToValue={(
              option: ApiTenantResponse,
              value: Nullable<ApiTenantResponse>
            ) => option.id === value?.id}
            getOptionLabel={(option: ApiTenantResponse) => `${option?.name}`}
            renderOption={(props: any, option: ApiTenantResponse) => (
              <Box key={option} component="li" {...props}>
                {option.name}
                {/* <TenantStatusLabel status={option.status} /> */}
              </Box>
            )}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <>
                <TextField
                  {...params}
                  label={t(label)}
                  error={Boolean(
                    getIn(touched, field.name) && !!getIn(errors, field.name)
                  )}
                  helperText={
                    getIn(touched, field.name) && t(getIn(errors, field.name))
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              </>
            )}
          ></Field>
        </FormControl>
      )}
    </>
  );
};
