import {
  DateField,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { isValid } from "date-fns";
import { FieldProps, getIn } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Nullable } from "../../../../utils/types";

export interface DateOfBirthFieldProps {
  disabled: boolean;
}
export const DateOfBirthField: FC<
  DateOfBirthFieldProps & FieldProps<Date, Date>
> = ({
  disabled = false,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* {JSON.stringify(values)} */}
      <FormControl fullWidth margin="normal">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            format="DD/MM/YYYY"
            // inputFormat="dd/MM/yyyy"
            disableFuture
            label={t("common.dateOfBirth")}
            onChange={(newValue: Nullable<Date>) => {
              if (!isValid(newValue)) {
                return;
              }
              setFieldValue(`${field.name}`, newValue);
            }}
            openTo="year"
            reduceAnimations
            sx={{
              ".MuiButtonBase-root": { padding: "0 !important" },
            }}
            // renderInput={(params: any) => (
            //   <>
            //     <TextField
            //       {...params}
            //       onBlur={handleBlur(`${field.name}`)}
            //       error={Boolean(
            //         getIn(touched, field.name) && !!getIn(errors, field.name)
            //       )}
            //     />
            //   </>
            // )}
            // toolbarTitle={t("common.dateOfBirth")}
            value={getIn(values, field.name)}
            views={["year", "month", "day"]}
            disabled={disabled}
          />
        </LocalizationProvider>
        <FormHelperText error>
          {getIn(touched, field.name) && t(getIn(errors, field.name))}
        </FormHelperText>
      </FormControl>
    </>
  );
};
