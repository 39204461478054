import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Permission } from '../../permissionGroups';


import { selectPermissionsMap } from '../selectors';

export const usePermissions = () => {
  const permissionsMap = useSelector(selectPermissionsMap);

  const hasPermission = useCallback(
    (permission: Permission) => permissionsMap?.includes(permission) || permissionsMap?.includes('ADMIN'),
    [permissionsMap],
  );

  return { hasPermission, permissionsMap };
};
