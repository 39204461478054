import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  FormControl,
  TextField,
} from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEntitiesListQuery } from "../../../../models/entities";
import { ApiEntity } from "../../../../models/entities";

import { ApiEntityStatus } from "../entityStatus";
import { Nullable } from "../../../../utils/types";

export interface EntityFieldProps {
  disabled: boolean;
  entityId?: string;
  label?: string;
  size?: "small" | "medium";
  margin?: "none" | "dense" | "normal";
  onChange: (value: ApiEntity) => void;
  tags: string[];
  notStatus: ApiEntityStatus[];
  showErrorText: boolean;
  disableClearable: boolean;
}
export const EntityField: FC<
  EntityFieldProps & FieldProps<ApiEntity[], ApiEntity[]>
> = ({
  disabled = false,
  entityId,
  label = "common.entity",
  size = "medium",
  onChange = () => null,
  margin,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  tags = [],
  notStatus = [],
  showErrorText = true,
  disableClearable = false,
  ...props
}) => {
  const { t } = useTranslation();

  const { data: fullEntities } = useEntitiesListQuery(
    {
      // tags: tags.join(",")
    },
    { refetchOnMountOrArgChange: true }
  );

  const [entities, setEntities] = useState<Nullable<ApiEntity[]>>(null);

  useEffect(() => {
    if (fullEntities) {
      if (notStatus.length > 0) {
        setEntities(fullEntities.filter((e) => !notStatus.includes(e.status)));
      } else {
        setEntities(fullEntities);
      }
    }
  }, [fullEntities]);

  const onChangeValue = (e: any, value: ApiEntity) => {
    setFieldValue(`${field.name}`, value);
    onChange(value);
  };

  const getOptionLabel = (option: ApiEntity) =>
    `${option?.firstName || ""} ${option?.lastName || ""} ${
      option?.legalEntityName || ""
    }`;

  const filterOptions = (
    options: Array<ApiEntity>,
    state: { inputValue: string }
  ): Array<ApiEntity> => {
    return options.filter(
      (x) =>
        x.firstName?.toLowerCase()?.includes(state.inputValue.toLowerCase()) ||
        x.lastName?.toLowerCase()?.includes(state.inputValue.toLowerCase()) ||
        x.refNo?.toLowerCase()?.includes(state.inputValue.toLowerCase()) ||
        x.legalEntityName
          ?.toLowerCase()
          ?.includes(state.inputValue.toLowerCase()) ||
        x.additionalComments
          ?.toLowerCase()
          ?.includes(state.inputValue.toLowerCase()) ||
        x.status?.toLowerCase()?.includes(state.inputValue.toLowerCase()) ||
        x.id?.toLowerCase()?.includes(state.inputValue.toLowerCase())
    );
  };

  const isOptionEqualToValue = (
    option: ApiEntity,
    value: Nullable<ApiEntity>
  ) => option.id === value?.id;

  return (
    <>
      {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(getIn(values, field.name), null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(entities, null, 2)}</pre> */}
      {entities && entities.length > 0 && (
        <FormControl fullWidth margin={margin}>
          <Field
            disableClearable={disableClearable}
            size={size}
            margin={margin}
            component={Autocomplete}
            name={`${field.name}`}
            blurOnSelect={true}
            fullWidth
            value={
              entities.find((x) => x.id === getIn(values, field.name)?.id) ||
              null
            }
            // onBlur={handleBlur(`${field.name}`)}
            onChange={onChangeValue}
            disabled={disabled}
            options={entities}
            autoHighlight
            variant="outlined"
            filterOptions={filterOptions}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionLabel={getOptionLabel}
            renderOption={(props: any, option: ApiEntity) => {
              props.key = props.id;
              return (
                <Box {...props} component="li" {...props}>
                  {option?.firstName} {option?.lastName}{" "}
                  {option?.legalEntityName}
                </Box>
              );
            }}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <>
                <TextField
                  {...params}
                  label={t(label)}
                  error={Boolean(
                    getIn(touched, field.name) && !!getIn(errors, field.name)
                  )}
                  helperText={
                    showErrorText
                      ? getIn(touched, field.name) &&
                        t(getIn(errors, field.name))
                      : ""
                  }
                  multiline={true}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                    style: { resize: "both" },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    // ...(values[field.name]?.id
                    //     ? {
                    //         startAdornment: (
                    //             <InputAdornment position="end">
                    //                 <>
                    //                     <Box sx={{ ml: '8px' }}>
                    //                         {/* {values[field.name]?.displayName} ({values[field.name]?.id}) &nbsp;&nbsp; */}
                    //                         <EntityStatusLabel status={values[field.name]?.status} />
                    //                     </Box>
                    //                 </>
                    //             </InputAdornment>
                    //         ),
                    //     }
                    //     : {}),
                  }}
                />
              </>
            )}
          ></Field>
        </FormControl>
      )}
    </>
  );
};
