import { Info } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Chip,
  FormControl,
  TextField,
  Tooltip,
} from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { ChangeEventHandler, FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  ApiEnumeration,
  ApiEnumerationType,
  CURRENCY,
  useEnumerationsQuery,
} from "../../../../models/enumerations";
import getSymbolFromCurrency from "currency-symbol-map";
import { Nullable } from "../../../../utils/types";
import { enumerationDataMap } from "./utils";

export interface EnumerablesFieldProps {
  disabled: boolean;
  enumerableId?: string;
  label?: string;
  multiple?: boolean;
  onChange: (value: ApiEnumeration) => void;
  type: ApiEnumerationType;
  info: Nullable<string>;
}
export const EnumerablesField: FC<
  EnumerablesFieldProps & FieldProps<ApiEnumeration[], ApiEnumeration[]>
> = ({
  disabled = false,
  enumerableId,
  label,
  multiple = false,
  onChange = () => null,
  type,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  info = null,
  ...props
}) => {
  const { t } = useTranslation();

  const { data: enumerableIds } = useEnumerationsQuery(
    { type: type },
    { refetchOnMountOrArgChange: true }
  );

  // Auto populate business form when there is only one
  useEffect(() => {
    if (enumerableIds && enumerableIds?.length === 1) {
      setFieldValue(`${field.name}`, enumerableIds[0]);
    }
  }, [enumerableIds, field.name, setFieldValue]);

  return (
    <>
      {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(getIn(values, field.name), null, 2)}</pre> */}
      {enumerableIds && enumerableIds?.length > 0 && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControl fullWidth margin="normal">
            <Field
              multiple={multiple}
              freeSolo
              component={Autocomplete}
              name={`${field.name}`}
              fullWidth
              value={
                multiple
                  ? getIn(values, field.name).map((v: ApiEnumeration) =>
                      enumerableIds.find((e) => e.value === v.value)
                    )
                  : enumerableIds.find(
                      (x) => x.id === getIn(values, field.name)?.id
                    ) || null
              }
              onBlur={handleBlur(`${field.name}`)}
              onChange={(e: any, value: any) => {
                setFieldValue(`${field.name}`, value);
                onChange(value);
              }}
              disabled={disabled || enumerableIds?.length === 1}
              options={enumerableIds}
              autoHighlight
              variant="outlined"
              filterOptions={(
                options: Array<ApiEnumeration>,
                state: { inputValue: string }
              ): Array<ApiEnumeration> => {
                return options.filter(
                  (x) =>
                    x.value
                      ?.toLowerCase()
                      ?.includes(state.inputValue.toLowerCase()) ||
                    x.id
                      ?.toLowerCase()
                      ?.includes(state.inputValue.toLowerCase())
                );
              }}
              isOptionEqualToValue={(
                option: ApiEnumeration,
                value: Nullable<ApiEnumeration>
              ) => option.id === value?.id}
              getOptionLabel={(option: ApiEnumeration) => `${option?.value}`}
              renderOption={(props: any, option: ApiEnumeration) =>
                option.type !== CURRENCY ? (
                  <Box key={option} component="li" {...props}>
                    {option?.value}
                    {/* <EnumerableStatusLabel status={option.status} /> */}
                  </Box>
                ) : (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    ${getSymbolFromCurrency(option.value)}
                    {option.value}
                  </Box>
                )
              }
              renderTags={(value: ApiEnumeration[], getTagProps: any) =>
                value.map((option: ApiEnumeration, index: number) =>
                  option.type !== CURRENCY ? (
                    <Chip
                      variant="outlined"
                      label={option.value}
                      {...getTagProps({ index })}
                    />
                  ) : (
                    <Chip
                      sx={{ pl: "5px" }}
                      key={option.value}
                      label={`${getSymbolFromCurrency(option.value)} ${
                        option.value
                      } (${option.value})`}
                      {...getTagProps({ index })}
                    />
                  )
                )
              }
              renderInput={(params: AutocompleteRenderInputParams) => (
                <>
                  <TextField
                    {...params}
                    label={t(label || enumerationDataMap.get(type).enumeration)}
                    error={Boolean(
                      getIn(touched, field.name) && !!getIn(errors, field.name)
                    )}
                    helperText={
                      getIn(touched, field.name) && t(getIn(errors, field.name))
                    }
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      // ...(values[field.name]?.id
                      //     ? {
                      //         startAdornment: (
                      //             <InputAdornment position="end">
                      //                 <>
                      //                     <Box sx={{ ml: '8px' }}>
                      //                         {/* {values[field.name]?.displayName} ({values[field.name]?.id}) &nbsp;&nbsp; */}
                      //                         <EnumerableStatusLabel status={values[field.name]?.status} />
                      //                     </Box>
                      //                 </>
                      //             </InputAdornment>
                      //         ),
                      //     }
                      //     : {}),
                    }}
                  />
                </>
              )}
            ></Field>
          </FormControl>
          {info && (
            <Tooltip
              color="action"
              title={<div style={{ whiteSpace: "pre-line" }}>{t(info)}</div>}
              sx={{ mt: "14px", ml: "5px", mr: "5px" }}
            >
              <Info />
            </Tooltip>
          )}
        </Box>
      )}
    </>
  );
};
