import * as Yup from 'yup';
import { tenantValidationSchema } from '../../../../forms';

export const updateEntityFormValidationSchema = (isAdmin: boolean) =>
    Yup.object({
        // name: Yup.string().required('validations.nameIsRequired'),
        // address: addressValidationSchema(),
        // vatRegistrationNumber: Yup.string().required('validations.vatRegistrationNumberIsRequired'),
        // companyRegistrationNumber: Yup.string().required('validations.companyRegistrationNumberIsRequired'),
        // phones: [],
        // emails: [],
        // accountManager: null,
        // services: [],
        // industries: [],
        // legalEntityTypes: [],
    });