import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  ApiEntity,
  ApiStageContactDetail,
} from "../../../../../../../models/entities";
import {
  ACTIVE_CLIENT_STATUS,
  ApiEntityStatus,
  CLOSED_CLIENT_STATUS,
  NATURAL_PERSON,
  PENDING_APPROVAL_CLIENT_STATUS,
} from "../../../../../../../forms";
import { getCountryByCode } from "../../../../../../../utils/countries";
import { CountryFlag } from "../../../../../../../components/CountryFlag";
import { PermissionGuard } from "../../../../../../../models/auth";
import { ApiUserResponse } from "../../../../../../../models/users";
import { Nullable } from "../../../../../../../utils/types";

interface DetailRow {
  name: string;
  value: any;
}

export const Stage1_3View: FC<{
  entity: ApiEntity;
  stageData: ApiStageContactDetail;
  onEdit: (value: boolean) => void;
  status: Nullable<ApiEntityStatus>;
  profile: ApiUserResponse | undefined;
}> = ({ entity, stageData, onEdit, status = null, profile }) => {
  const { t } = useTranslation();

  const registeredAddress: DetailRow[] = useMemo(
    () =>
      stageData.registeredAddress
        ? [
            {
              name: "common.addressLine1",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.registeredAddress.addressLine1}
                </Typography>
              ),
            },
            {
              name: "common.addressLine2",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.registeredAddress.addressLine2}
                </Typography>
              ),
            },
            {
              name: "common.town",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.registeredAddress.town}
                </Typography>
              ),
            },
            {
              name: "common.zipPostCode",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.registeredAddress.postCode}
                </Typography>
              ),
            },
            {
              name: "common.country",
              value: (
                <>
                  {stageData?.registeredAddress.country && (
                    <Chip
                      sx={{ pl: "5px", m: 1 }}
                      label={`${
                        getCountryByCode(stageData.registeredAddress.country)
                          ?.name
                      } (${stageData.registeredAddress.country})`}
                      icon={
                        <CountryFlag
                          countryCode={stageData.registeredAddress.country}
                        />
                      }
                    />
                  )}
                </>
              ),
            },
          ]
        : [],
    [stageData.registeredAddress]
  );

  const correspondenceAddress: DetailRow[] = useMemo(
    () =>
      stageData.correspondenceAddress
        ? [
            {
              name: "common.addressLine1",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.correspondenceAddress.addressLine1}
                </Typography>
              ),
            },
            {
              name: "common.addressLine2",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.correspondenceAddress.addressLine2}
                </Typography>
              ),
            },
            {
              name: "common.town",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.correspondenceAddress.town}
                </Typography>
              ),
            },
            {
              name: "common.zipPostCode",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.correspondenceAddress.postCode}
                </Typography>
              ),
            },
            {
              name: "common.country",
              value: (
                <>
                  {stageData?.correspondenceAddress.country && (
                    <Chip
                      sx={{ pl: "5px", m: 1 }}
                      label={`${
                        getCountryByCode(
                          stageData.correspondenceAddress.country
                        )?.name
                      } (${stageData.correspondenceAddress.country})`}
                      icon={
                        <CountryFlag
                          countryCode={stageData.correspondenceAddress.country}
                        />
                      }
                    />
                  )}
                </>
              ),
            },
          ]
        : [],
    [stageData.correspondenceAddress]
  );

  const contacts: DetailRow[][] = useMemo(
    () =>
      stageData?.contactPersons
        ? stageData?.contactPersons.map((contactPerson) => [
            // {
            //   name: "common.id",
            //   value: (
            //     <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
            //       {contactPerson.id}
            //     </Typography>
            //   ),
            // },
            {
              name: "common.firstName",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {contactPerson.firstName}
                </Typography>
              ),
            },
            {
              name: "common.lastName",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {contactPerson.lastName}
                </Typography>
              ),
            },
            {
              name: "common.designation",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {contactPerson.designation}
                </Typography>
              ),
            },
            {
              name: "common.email",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  <Link href={`mailto:${contactPerson.email}`}>
                    {contactPerson.email}
                  </Link>
                </Typography>
              ),
            },
            {
              name: "common.contactNumber",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  <Link
                    href={`tel:$${contactPerson.contactNumber.phoneNumber}`}
                  >
                    {contactPerson.contactNumber.phoneNumber}
                  </Link>
                </Typography>
              ),
            },
          ])
        : [],
    [stageData.contactPersons]
  );

  return (
    <>
      {entity ? (
        <>
          <Card sx={{ marginTop: "12px" }}>
            <CardHeader
              disableTypography
              title={
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tooltip title={t("common.stage_1_3")} enterDelay={1000}>
                    <Typography variant="h6">{t("common.contacts")}</Typography>
                  </Tooltip>
                  <Grid
                    item
                    // sm={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {((status !== PENDING_APPROVAL_CLIENT_STATUS &&
                      status !== ACTIVE_CLIENT_STATUS &&
                      status !== CLOSED_CLIENT_STATUS) ||
                      (status === PENDING_APPROVAL_CLIENT_STATUS &&
                        entity.reviewerId === profile?.id)) && (
                      <PermissionGuard permission="UPDATE_ENTITY">
                        <>
                          <Button
                            sx={[
                              {
                                whiteSpace: "nowrap",
                              },
                              (theme) => ({
                                [theme.breakpoints.down("sm")]: {
                                  minWidth: "100%",
                                },
                                [theme.breakpoints.up("sm")]: {
                                  minWidth: "auto",
                                },
                              }),
                            ]}
                            variant="contained"
                            onClick={() => onEdit(true)}
                          >
                            {t("common.edit")}
                          </Button>
                        </>
                      </PermissionGuard>
                    )}
                  </Grid>
                </Box>
              }
            />

            {stageData.registeredAddress && (
              <>
                <CardHeader
                  disableTypography
                  sx={{ paddingBottom: "0" }}
                  title={
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h6">
                        {t(
                          entity.entryType === NATURAL_PERSON
                            ? "common.residentialAddress"
                            : "common.registeredOffice"
                        )}
                      </Typography>
                    </Box>
                  }
                />
                <CardContent sx={{ paddingTop: "0" }}>
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={2}
                    sx={{ p: "16px" }}
                  >
                    {registeredAddress.map(({ name, value }) => (
                      <Grid
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        key={name}
                        sx={{ paddingTop: "12px !important" }}
                      >
                        <Grid container>
                          <Grid item sm={6} xs={12}>
                            <Typography variant="body2" color="textSecondary">
                              {t(name)}
                            </Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            {value}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </>
            )}
            {stageData.correspondenceAddress && (
              <>
                <CardHeader
                  disableTypography
                  sx={{ paddingBottom: "0" }}
                  title={
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h6">
                        {t("common.correspondenceAddress")}
                      </Typography>
                    </Box>
                  }
                />
                <CardContent sx={{ paddingTop: "0" }}>
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={2}
                    sx={{ p: "16px" }}
                  >
                    {correspondenceAddress.map(({ name, value }) => (
                      <Grid
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        key={name}
                        sx={{ paddingTop: "12px !important" }}
                      >
                        <Grid container>
                          <Grid item sm={6} xs={12}>
                            <Typography variant="body2" color="textSecondary">
                              {t(name)}
                            </Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            {value}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </>
            )}

            {contacts.map((contact, index) => {
              return (
                <Card
                  key={index}
                  sx={{
                    margin: "12px",
                  }}
                >
                  <CardHeader
                    disableTypography
                    title={
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h6">
                          {t("common.contactPerson")} #{index + 1}
                        </Typography>
                      </Box>
                    }
                  />

                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={2}
                    sx={{ p: "16px" }}
                  >
                    {contact.map(({ name, value }) => (
                      <Grid
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        key={name}
                        sx={{ paddingTop: "12px !important" }}
                      >
                        <Grid container>
                          <Grid item sm={6} xs={12}>
                            <Typography variant="body2" color="textSecondary">
                              {t(name)}
                            </Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            {value}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              );
            })}
          </Card>
        </>
      ) : null}
    </>
  );
};
