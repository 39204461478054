import {
  AnyAction,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
  Store,
  ThunkDispatch,
} from '@reduxjs/toolkit'
import i18n from 'i18next';
import toast from 'react-hot-toast';
import { authApi, authSlice } from '../../models/auth';
import { PASSWORD_ALREADY_USED, PASSWORD_NOT_MATCHED } from '../../screens/Authentication/ChangePassword/forms';
import { ACCOUNT_DISABLED, ACCOUNT_EXPIRED, ACCOUNT_LOCKED, BAD_CREDENTIALS } from '../../screens/Authentication/Login/forms';
import { USERNAME_NOT_FOUND, VERIFICATION_EXPIRED, VERIFICATION_FAILED, } from '../../screens/Authentication/UserVerificationConfirm/forms';
import { ENUMERATION_ALREADY_EXISTS } from '../../screens/Enumerations/forms';

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      const duration: number = 10000;
      switch (action?.payload?.data?.errorCode) {
        case "EXPIRED_TOKEN":
          (api.dispatch as ThunkDispatch<Store, void, AnyAction>)(
            authSlice.actions.resetCredentials(),
            // authApi.endpoints.logout.initiate(),
          );
          toast.error(i18n.t("errors.expiredToken"), {
            duration,
          });
          break;
        case "INVALID_TOKEN":
          (api.dispatch as ThunkDispatch<Store, void, AnyAction>)(
            authSlice.actions.resetCredentials(),
          );
          // toast.error(i18n.t("errors.invalidToken"), {
          //   duration,
          // });
          break;
        case "ACCESS_DENIED":
          toast.error(i18n.t("errors.accessDenied"), {
            duration,
          });
          break;
        case "UNAUTHORIZED":
          toast.error(i18n.t("errors.unauthorized"), {
            duration,
          });
          break;
        // case "FIELD_VALIDATION_FAILED":
        //   toast.error(i18n.t("errors.fieldValidationError"), {
        //     duration,
        //   });
        //   // const errorMsg = action?.payload?.data?.fieldErrors
        //   //   .map((fieldError) => fieldError.errorMessage)
        //   //   .join(', ');
        //   // toast.error(`Field Error:${errorMsg}`);
        //   break;
        case "CONVERSION_FAILED":
          toast.error(i18n.t("errors.conversionFailed"), {
            duration,
          });
          break;
        case "INVALID_TOKEN_REQUEST":
          toast.error(i18n.t("errors.invalidTokenRequest"), {
            duration,
          });
          break;
        case "USER_STATUS_CHANGE_NOT_ALLOWED":
          toast.error(i18n.t("errors.userStatusChangeNotAllowed"), {
            duration,
          });
          break;
        case "INVALID_GRANT_TYPE":
          toast.error(i18n.t("errors.invalidGrantType"), {
            duration,
          });
          break;
        case "DATA_CANNOT_BE_EDITED":
          toast.error(i18n.t("errors.dataCannotBeUpdated"), {
            duration,
          });
          break;
        case "RECEIPT_CANNOT_BE_LINKED_TO_CREDIT_NOTE":
          toast.error(i18n.t("errors.receiptCannotBeLinkedToACreditNote"), {
            duration,
          });
          break;

        case "VALUE_TOO_LONG":
          toast.error(i18n.t("errors.valueTooLong"), {
            duration,
          });
          break;

        case "EMAIL_ALREADY_USED":
          toast.error(i18n.t("errors.anAccountWithThisEmailAlreadyExists"), {
            duration,
          });
          break;
        case "USER_ALREADY_VERIFIED":
          toast.error(i18n.t("errors.userAlreadyVerified"), {
            duration,
          });
          break;
        case "EMAIL_ERROR":
          toast.error(i18n.t("errors.emailError"), {
            duration,
          });
          break;

        case "USERNAME_NOT_FOUND":
          toast.error(i18n.t("errors.usernameNotFound"), {
            duration,
          });
          break;
        case "AUTHENTICATION_SERVER_ERROR":
          toast.error(i18n.t("errors.authenticationServerError"), {
            duration,
          });
          break;
        case "TIMESHEET_MANUAL_INPUT_TOTALS_DO_NOT_MATCH":
          toast.error(i18n.t("errors.allocationAndManualInputsTotalsMustMatch"), {
            duration,
          });
          break;

        case "DATE_CANNOT_BE_IN_THE_FUTURE":
          toast.error(i18n.t("errors.dateCannotBeInTheFuture"), {
            duration,
          });
          break;
        case "NOT_A_CREDIT_NOTE":
          toast.error(i18n.t("errors.notACreditNote"), {
            duration,
          });
          break;

        case "PERMISSION_NOT_FOUND":
          toast.error(i18n.t("errors.permissionNotFound"), {
            duration,
          });
          break;
        case "PERMISSION_GROUP_NOT_FOUND":
          toast.error(i18n.t("errors.permissionGroupNotFound"), {
            duration,
          });
          break;
        case "PERMISSION_GROUP_ALREADY_EXISTS":
          toast.error(i18n.t("errors.permissionGroupAlreadyExists"), {
            duration,
          });
          break;
        case "TENANT_ALREADY_EXISTS":
          toast.error(i18n.t("errors.tenantAlreadyExists"), {
            duration,
          });
          break;
        case "RATE_ALREADY_EXISTS":
          toast.error(i18n.t("errors.rateAlreadyExists"), {
            duration,
          });
          break;
        case "RATE_NOT_FOUND":
          toast.error(i18n.t("errors.rateIsMissing"), {
            duration,
          });
          break;
        case "INVALID_USER":
          break;
        case "INVALID_TENANT_ID":
          // Skip Error since this is expected in me call
          // toast.error(i18n.t("errors.invalidTenantId"), {
          //   duration,
          // });
          break;
        case "TENANT_NOT_FOUND":
          // Skip Error since this is used in validation
          // toast.error(i18n.t("errors.tenantNotFound"), {
          //   duration,
          // });
          break;


        case "INVALID_QUOTATION_DATE":
          break;
        case "REVIEWER_REQUIRED":
          break;
        case "SIGNATORY_REQUIRED":
          break;
        case "SERVICES_REQUIRED":
          break;
        case "INVALID_SERVICES":
          break;

        case "QUOTATION_CANNOT_BE_CANCELLED":
          toast.error(i18n.t("errors.quotationCannotBeCanceled"), {
            duration,
          });
          break;
        case "INVALID_ALLOCATIONS":
          toast.error(i18n.t("errors.invalidAllocations"), {
            duration,
          });
          break;
        case "INVALID_INVOICE_DATE":
          toast.error(i18n.t("errors.invalidInvoiceDate"), {
            duration,
          });
          break;
        case "REVIEWER_REQUIRED":
          toast.error(i18n.t("errors.reviewerIsRequired"), {
            duration,
          });
          break;
        case "SIGNATORY_REQUIRED":
          toast.error(i18n.t("errors.signatoryIsRequired"), {
            duration,
          });
          break;
        case "ALLOCATIONS_REQUIRED":
          toast.error(i18n.t("errors.allocationsAreRequired"), {
            duration,
          });
          break;


        // Skip these errors as they are handled inside their own form
        // Enumerations
        case ENUMERATION_ALREADY_EXISTS: break;
        // Skip these errors as they are handled inside their own form
        // Login
        case BAD_CREDENTIALS: break;
        case ACCOUNT_EXPIRED: break;
        case ACCOUNT_DISABLED: break;
        case ACCOUNT_LOCKED: break;
        // Change Password
        case PASSWORD_NOT_MATCHED: break;
        case PASSWORD_ALREADY_USED: break;

        // User verification
        case VERIFICATION_EXPIRED: break;
        case VERIFICATION_FAILED: break;
        // case VERIFICATION_RETRIES_EXCEEDED: break;
        case USERNAME_NOT_FOUND: break
        default: {
          console.warn('We got a rejected action!')
          console.warn(JSON.stringify(action));
          toast.error(action.error.message)
        }
      }
    }

    return next(action)
  }