import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import { Box, Divider, IconButton, Typography } from "@mui/material";
import { Field, Formik, getIn } from "formik";
import React, { FC } from "react";
import toast from "react-hot-toast";

import {
  sourceOfWealthFormValuesInitialValue,
  SourceOfWealthFormValues,
  sourceOfWealthFormValidationSchema,
} from ".";

import { useTranslation } from "react-i18next";

import {
  BasicTextField,
  CountryField,
  EnumerableField,
  YES,
  YesNoField,
} from "../../../../../../forms";
import {
  ApiEnumeration,
  ESTIMATED_NET_WORTH,
} from "../../../../../../models/enumerations";
import { usePermissions } from "../../../../../../models/auth";
import {
  PENDING_STAGE_STATUS,
  ApiEntity,
  DRAFT_STAGE_STATUS,
  mapSourceOfWealthFormValuesToApiStageSourceOfWealth,
  useUpdateEntityStageMutation,
  ApiStageSourceOfWealth,
  STAGE_2_6_TYPE,
  PENDING_APPROVAL_STAGE_STATUS,
} from "../../../../../../models/entities";
import { ApiUserResponse } from "../../../../../../models/users";
interface SourceOfWealthFormProps {
  entity: ApiEntity;
  enumerables: ApiEnumeration[];
  onClose: () => void;
  profile: ApiUserResponse | undefined;
}
// 19.1/19.2
export const SourceOfWealthForm: FC<SourceOfWealthFormProps> = ({
  entity,
  enumerables,
  onClose,
  profile,
}) => {
  const [updateEntityStage] = useUpdateEntityStageMutation();
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  return (
    <Formik<SourceOfWealthFormValues>
      enableReinitialize
      initialValues={{
        ...sourceOfWealthFormValuesInitialValue(entity, enumerables),
      }}
      validationSchema={sourceOfWealthFormValidationSchema(
        hasPermission("ADMIN")
      )}
      onSubmit={async (values): Promise<void> => {
        try {
          const apiSourceOfWealth: ApiStageSourceOfWealth =
            mapSourceOfWealthFormValuesToApiStageSourceOfWealth(entity, values);
          await updateEntityStage(apiSourceOfWealth).unwrap();

          toast.success(t("common.updatedSuccessfully"));
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        setFieldValue,
        errors,
        touched,
      }): JSX.Element => (
        <>
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "24px 38px 24px 32px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={"textPrimary"} variant="h5">
                {t("common.sourceOfWealth")}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: "32px" }}>
            <form noValidate onSubmit={handleSubmit}>
              <Box
                sx={{
                  mt: "24px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Field
                  name={`estimatedNetWorth`}
                  component={EnumerableField}
                  type={ESTIMATED_NET_WORTH}
                  margin="normal"
                  label="common.estimatedTotalNetWorth"
                />
                <Field
                  name={`hasHighNetWorth`}
                  component={YesNoField}
                  label="common.indicateIfHighNetworthIndividual"
                  // size="small"
                  margin="normal"
                />
                <Field
                  name={`hasSowCollated`}
                  component={YesNoField}
                  label="common.sourceOfWealthInformationNeedtoBecollated"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasSowCollated") === YES && (
                  <Field
                    name={`sowCollatedInfo`}
                    component={BasicTextField}
                    label="common.sourceOfWealthInformationNeedtoBecollatedRationale"
                    // size="small"
                    margin="normal"
                    rows={3}
                    info="common.egNaturalPersonOfferedIncomeTaxCompliance"
                  />
                )}

                <Typography variant="subtitle2">
                  {t("common.indicateTheEconomicActivitiesThatGenerated")}
                </Typography>
                <Field
                  name={`hasNewlyEstablishedEntity`}
                  component={YesNoField}
                  label="common.naAsNewlyEstablishedEntity"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasNewlyEstablishedEntity") === YES && (
                  <Field
                    name={`newlyEstablishedEntityInfo`}
                    component={BasicTextField}
                    label="common.naAsNewlyEstablishedEntityInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                  />
                )}
                <Field
                  name={`hasSavingsFromSalary`}
                  component={YesNoField}
                  label="common.incomeSavingsFromSalary"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasSavingsFromSalary") === YES && (
                  <Field
                    name={`savingsFromSalaryInfo`}
                    component={BasicTextField}
                    label="common.incomeSavingsFromSalaryInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                    info="common.incomeSavingsFromSalaryInfoNote"
                  />
                )}
                <Field
                  name={`hasCompanyProfits`}
                  component={YesNoField}
                  label="common.businessIncomeCompanyProfits"
                  // size="small"
                  margin="normal"
                  disabled={getIn(values, "filledByEntityDuringKyc")}
                />
                {getIn(values, "hasCompanyProfits") === YES && (
                  <Field
                    name={`companyProfitsInfo`}
                    component={BasicTextField}
                    label="common.businessIncomeCompanyProfitsInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                    info="common.businessIncomeCompanyProfitsInfoNote"
                    disabled={getIn(values, "filledByEntityDuringKyc")}
                  />
                )}
                <Field
                  name={`hasSaleOfProperty`}
                  component={YesNoField}
                  label="common.saleOfPropertyAssets"
                  // size="small"
                  margin="normal"
                  disabled={getIn(values, "filledByEntityDuringKyc")}
                />
                {getIn(values, "hasSaleOfProperty") === YES && (
                  <Field
                    name={`saleOfPropertyInfo`}
                    component={BasicTextField}
                    label="common.saleOfPropertyAssetsInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                    info="common.saleOfPropertyAssetsInfoNote"
                    disabled={getIn(values, "filledByEntityDuringKyc")}
                  />
                )}
                <Field
                  name={`hasRentalOfProperty`}
                  component={YesNoField}
                  label="common.rentalOfProperty"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasRentalOfProperty") === YES && (
                  <Field
                    name={`rentalOfPropertyInfo`}
                    component={BasicTextField}
                    label="common.rentalOfPropertyInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                    info="common.rentalOfPropertyInfoNote"
                  />
                )}
                <Field
                  name={`hasCompanySale`}
                  component={YesNoField}
                  label="common.companySale"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasCompanySale") === YES && (
                  <Field
                    name={`companySaleInfo`}
                    component={BasicTextField}
                    label="common.companySaleInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                    info="common.companySaleInfoNote"
                  />
                )}
                <Field
                  name={`hasInheritance`}
                  component={YesNoField}
                  label="common.inheritance"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasInheritance") === YES && (
                  <Field
                    name={`inheritanceInfo`}
                    component={BasicTextField}
                    label="common.inheritanceInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                    info="common.inheritanceInfoNote"
                  />
                )}
                <Field
                  name={`hasDivorceSettlement`}
                  component={YesNoField}
                  label="common.divorceSettlement"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasDivorceSettlement") === YES && (
                  <Field
                    name={`divorceSettlementInfo`}
                    component={BasicTextField}
                    label="common.divorceSettlementInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                    info="common.divorceSettlementInfoNote"
                  />
                )}
                <Field
                  name={`hasDonation`}
                  component={YesNoField}
                  label="common.giftDonation"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasDonation") === YES && (
                  <Field
                    name={`donationInfo`}
                    component={BasicTextField}
                    label="common.giftDonationInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                    info="common.giftDonationInfoNote"
                  />
                )}
                <Field
                  name={`hasFixedDeposit`}
                  component={YesNoField}
                  label="common.fixedDepositSavings"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasFixedDeposit") === YES && (
                  <Field
                    name={`fixedDepositInfo`}
                    component={BasicTextField}
                    label="common.fixedDepositSavingsInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                    info="common.fixedDepositSavingsInfoNote"
                    disabled={getIn(values, "x")}
                  />
                )}
                <Field
                  name={`hasDividendPayment`}
                  component={YesNoField}
                  label="common.dividendPayment"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasDividendPayment") === YES && (
                  <Field
                    name={`dividendPaymentInfo`}
                    component={BasicTextField}
                    label="common.dividendPaymentInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                    info="common.dividentPaymentInfoNote"
                    disabled={getIn(values, "filledByEntityDuringKyc")}
                  />
                )}
                <Field
                  name={`hasLottery`}
                  component={YesNoField}
                  label="common.lotteryBettingCasinoWin"
                  // size="small"
                  margin="normal"
                  disabled={getIn(values, "filledByEntityDuringKyc")}
                />
                {getIn(values, "hasLottery") === YES && (
                  <Field
                    name={`lotteryInfo`}
                    component={BasicTextField}
                    label="common.lotteryBettingCasinoWinInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                    info="common.lotteryBettingCasinoWinInfoNote"
                    disabled={getIn(values, "filledByEntityDuringKyc")}
                  />
                )}
                <Field
                  name={`hasLifePolicy`}
                  component={YesNoField}
                  label="common.maturitySurrenderOfLifePolicy"
                  // size="small"
                  margin="normal"
                  disabled={getIn(values, "filledByEntityDuringKyc")}
                />
                {getIn(values, "hasLifePolicy") === YES && (
                  <Field
                    name={`lifePolicyInfo`}
                    component={BasicTextField}
                    label="common.maturitySurrenderOfLifePolicyInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                    info="common.maturitySurrenderOfLifePolicyInfoNote"
                    disabled={getIn(values, "filledByEntityDuringKyc")}
                  />
                )}
                <Field
                  name={`hasMaturityOfInvestments`}
                  component={YesNoField}
                  label="common.saleMaturityOfInvestments"
                  // size="small"
                  margin="normal"
                  disabled={getIn(values, "filledByEntityDuringKyc")}
                />
                {getIn(values, "hasMaturityOfInvestments") === YES && (
                  <Field
                    name={`maturityOfInvestmentsInfo`}
                    component={BasicTextField}
                    label="common.saleMaturityOfInvestmentsInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                    info="common.saleMaturityOfInvestmentsInfoNote"
                    disabled={getIn(values, "filledByEntityDuringKyc")}
                  />
                )}
                <Field
                  name={`hasOther`}
                  component={YesNoField}
                  label="common.otherPleaseSpecify"
                  // size="small"
                  margin="normal"
                  disabled={getIn(values, "filledByEntityDuringKyc")}
                />
                {getIn(values, "hasOther") === YES && (
                  <Field
                    name={`otherInfo`}
                    component={BasicTextField}
                    label="common.otherPleaseSpecifyInfo"
                    // size="small"
                    margin="normal"
                    rows={3}
                    disabled={getIn(values, "filledByEntityDuringKyc")}
                  />
                )}
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    setFieldValue("stageStatus", DRAFT_STAGE_STATUS);
                  }}
                >
                  {t("common.saveAsDraft")}
                </LoadingButton>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    // Get Stage Data
                    const stageData: ApiStageSourceOfWealth =
                      entity.stages.find(
                        (s) => s.stageType === STAGE_2_6_TYPE
                      ) as ApiStageSourceOfWealth;

                    if (
                      profile?.id === entity.reviewerId &&
                      stageData.stageStatus === PENDING_APPROVAL_STAGE_STATUS
                    ) {
                      setFieldValue(
                        "stageStatus",
                        PENDING_APPROVAL_STAGE_STATUS
                      );
                    } else {
                      setFieldValue("stageStatus", PENDING_STAGE_STATUS);
                    }
                  }}
                >
                  {t("common.submit")}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
