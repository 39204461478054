
import { ApiCreatePermissionGroupRequest, ApiLinkPermissionGroupRequest, ApiPermissionGroup, ApiUnlinkPermissionGroupRequest, ApiUpdatePermissionGroupRequest, PageApiPermissionGroup, PagedPermissionGroupsRequest } from '.';
import { removeEmpty } from '../../utils/removeEmpty';
import { apiWithTag } from '../emptySplitApi';


export const permissionGroupsApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        permissionGroups: builder.query<PageApiPermissionGroup, PagedPermissionGroupsRequest>({
            query: ({ ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    method: 'GET',
                    params: parameters,
                    url: 'permission-groups',
                }
            },
            providesTags: ['Permission Group']
        }),
        permissionGroupsList: builder.query<ApiPermissionGroup[], {}>({
            query: (
                // { ...params }
            ) => {

                return {
                    method: 'GET',
                    url: 'permission-groups/list',
                }
            },
            providesTags: ['Permission Group']
        }),
        permissionGroup: builder.query<ApiPermissionGroup, { id: string }>({
            query: ({ id }) => ({
                method: 'GET',
                url: `permission-groups/${id}`,

            }),
            providesTags: ['Permission Group']
        }),
        createPermissionGroup: builder.mutation<ApiPermissionGroup, ApiCreatePermissionGroupRequest>({
            query: ({ ...permissionGroup }) => ({
                url: 'permission-groups',
                method: 'POST',
                body: permissionGroup
            }),
            invalidatesTags: ['Permission Group']
        }),
        updatePermissionGroup: builder.mutation<ApiPermissionGroup, ApiUpdatePermissionGroupRequest>({
            query: ({ id, ...rest }) => ({
                url: `permission-groups/${id}`,
                method: 'PUT',
                body: { ...rest }
            }),
            invalidatesTags: ['Permission Group']
        }),
        linkPermissionGroup: builder.mutation<ApiPermissionGroup, ApiLinkPermissionGroupRequest>({
            query: ({ id, ...rest }) => ({
                url: `permission-groups/${id}/link`,
                method: 'PUT',
                body: { ...rest }
            }),
            invalidatesTags: ['Permission Group']
        }),
        unlinkPermissionGroup: builder.mutation<ApiPermissionGroup, ApiUnlinkPermissionGroupRequest>({
            query: ({ id, ...rest }) => ({
                url: `permission-groups/${id}/unlink`,
                method: 'PUT',
                body: { ...rest }
            }),
            invalidatesTags: ['Permission Group']
        }),

    })
})

export const {
    usePermissionGroupsQuery,
    usePermissionGroupsListQuery,
    usePermissionGroupQuery,
    useCreatePermissionGroupMutation,
    useUpdatePermissionGroupMutation,
    useLinkPermissionGroupMutation,
    useUnlinkPermissionGroupMutation,
} = permissionGroupsApi;