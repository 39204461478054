import { RootState } from '../../services/redux/store';
import { Permission } from '../permissionGroups';


export const selectAccountId = (state: RootState) => state.auth.userId;

export const selectIsAuthorized = (state: RootState) =>
    Boolean(state.auth?.accessToken);

export const selectToken = (state: RootState) =>
    state.auth?.accessToken;

export const selectExpiresOn = (state: RootState): number =>
    state.auth?.expires_on;

export const selectLoggedInUserId = (state: RootState) =>
    state.auth?.userId;


export const selectUserStatus = (state: RootState) =>
    state.auth?.userStatus;

export const selectPermissionsMap = (state: RootState): Permission[] =>
    [...state.auth?.tenantPermissions[0] ? state.auth?.tenantPermissions[0]?.permissionGroups.map(pg => pg.permissions).flat().map(x => x.name) : [],
    ...(state.auth.admin === true ? ['ADMIN' as Permission] : [])];


export const selectTenantId = (state: RootState): string =>
    state.auth?.tenantPermissions[0]?.tenantId;
