import { Info } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  FormControl,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  ApiEnumeration,
  ApiEnumerationType,
  CURRENCY,
  useEnumerationsQuery,
} from "../../../../models/enumerations";
import getSymbolFromCurrency from "currency-symbol-map";
import { Nullable } from "../../../../utils/types";
import { enumerationDataMap } from "./utils";

export interface EnumerableFieldProps {
  disabled: boolean;
  enumerableId?: string;
  label?: string;
  size?: "small" | "medium";
  margin?: "none" | "dense" | "normal";
  onChange: (value: ApiEnumeration) => void;
  type: ApiEnumerationType;
  info: Nullable<string>;
  showErrorText: boolean;
  disableClearable: boolean;
}
export const EnumerableField: FC<
  EnumerableFieldProps & FieldProps<ApiEnumeration[], ApiEnumeration[]>
> = ({
  disabled = false,
  enumerableId,
  onChange = () => null,
  margin,
  type,
  label,
  size = "medium",
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  info = null,
  showErrorText = true,
  disableClearable = false,
  ...props
}) => {
  const { t } = useTranslation();

  const { data: enumerableIds } = useEnumerationsQuery(
    { type: type },
    { refetchOnMountOrArgChange: true }
  );

  // Auto populate business form when there is only one
  useEffect(() => {
    if (enumerableIds && enumerableIds?.length === 1) {
      setFieldValue(`${field.name}`, enumerableIds[0]);
    }
  }, [enumerableIds, field.name, setFieldValue]);

  return (
    <>
      {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(getIn(errors, field.name), null, 2)}</pre>
      <pre>{JSON.stringify(getIn(values, field.name), null, 2)}</pre> */}
      {enumerableIds && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControl fullWidth margin={margin}>
            <Field
              disableClearable={disableClearable}
              size={size}
              margin={margin}
              component={Autocomplete}
              name={`${field.name}`}
              fullWidth
              value={
                enumerableIds.find(
                  (x) => x.id === getIn(values, field.name)?.id
                ) || null
              }
              onBlur={handleBlur(`${field.name}`)}
              onChange={(e: any, value: ApiEnumeration) => {
                setFieldValue(`${field.name}`, value !== null ? value : "");
                onChange(value);
              }}
              disabled={disabled || enumerableIds?.length === 1}
              options={enumerableIds}
              autoHighlight
              variant="outlined"
              filterOptions={(
                options: Array<ApiEnumeration>,
                state: { inputValue: string }
              ): Array<ApiEnumeration> => {
                return options.filter(
                  (x) =>
                    x.value
                      ?.toLowerCase()
                      ?.includes(state.inputValue.toLowerCase()) ||
                    x.id
                      ?.toLowerCase()
                      ?.includes(state.inputValue.toLowerCase())
                );
              }}
              isOptionEqualToValue={(
                option: ApiEnumeration,
                value: Nullable<ApiEnumeration>
              ) => option.id === value?.id}
              getOptionLabel={(option: ApiEnumeration) =>
                `${option.valueDescription || option?.value}`
              }
              // renderOption={(props: any, option: ApiEnumeration) =>
              //   option.type !== CURRENCY ? (
              //     <Box key={option} component="li" {...props}>
              //       {/* {option?.value} */}
              //       {option.valueDescription || option.value}
              //       {/* <EnumerableStatusLabel status={option.status} /> */}
              //     </Box>
              //   ) : (
              //     <Box
              //       key={option}
              //       component="li"
              //       sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              //       {...props}
              //     >
              //       <CurrencyFlag
              //         currency={option.value.toLocaleLowerCase()}
              //         size="md"
              //       />
              //       <Box sx={{ pl: "16px" }} component="span">
              //         {option.value}
              //       </Box>
              //     </Box>
              //   )
              // }
              renderOption={(props: any, option: ApiEnumeration) => {
                props.key = props.id;
                if (option.type !== CURRENCY) {
                  return (
                    <Box {...props} component="li" {...props}>
                      {/* {option?.value} */}
                      {option.valueDescription || option.value}
                      {/* <EnumerableStatusLabel status={option.status} /> */}
                    </Box>
                  );
                } else {
                  return (
                    <Box
                      {...props}
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <Box sx={{ pl: "16px" }} component="span">
                        {" "}
                        {option.value}
                      </Box>
                    </Box>
                  );
                }
              }}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <>
                  <TextField
                    {...params}
                    label={t(label || enumerationDataMap.get(type).enumeration)}
                    error={Boolean(
                      getIn(touched, field.name) && !!getIn(errors, field.name)
                    )}
                    helperText={
                      showErrorText
                        ? getIn(touched, field.name) &&
                          t(getIn(errors, field.name))
                        : ""
                    }
                    multiline={true}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      style: { resize: "both" },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      ...(getIn(values, field.name)?.value && type === CURRENCY
                        ? {
                            startAdornment: (
                              <InputAdornment position="start">
                                <>
                                  <Box
                                    sx={{
                                      ml: "8px",
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    {getSymbolFromCurrency(
                                      getIn(values, field.name)?.value
                                    )}
                                  </Box>
                                </>
                              </InputAdornment>
                            ),
                          }
                        : {}),
                    }}
                  />
                </>
              )}
            ></Field>
          </FormControl>
          {info && (
            <Tooltip
              color="action"
              title={<div style={{ whiteSpace: "pre-line" }}>{t(info)}</div>}
              sx={{ mt: "14px", ml: "5px", mr: "5px" }}
            >
              <Info />
            </Tooltip>
          )}
        </Box>
      )}
    </>
  );
};
