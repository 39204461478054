import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Loader } from "../../../components/Loader";

import { useLazyMeQuery } from "../../user";
import { useLogoutMutation } from "../api";
import { resetCredentials } from "../slice";

export const AuthManager: FC<any> = ({ children }) => {
  const [logout] = useLogoutMutation();

  const [isLoading, setIsLoading] = useState(true);
  const [fetchMe] = useLazyMeQuery();
  const dispatch = useDispatch();
  useEffect(() => {
    fetchMe({})
      .unwrap()
      .catch(() => {
        logout();
        dispatch(resetCredentials());
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, fetchMe, logout]);

  return isLoading ? (
    <Loader />
  ) : (
    <Box
      sx={
        {
          // paddingBottom: "20px",
        }
      }
    >
      {" "}
      {children}
    </Box>
  );
};
