import { ApiCreateServiceRequest, ApiUpdateServiceRequest } from ".";
import { Frequency, ServiceLine } from "../../forms";
import { CreateServiceFormValues, UpdateServiceFormValues } from "../../screens/Services/forms";

export const mapCreateServiceFormValuesToApiCreateServiceRequest = (
    createDto: CreateServiceFormValues
): ApiCreateServiceRequest => ({
    value: createDto.value,
    acronym: createDto.acronym,
    title: createDto.title,
    frequency: createDto.frequency as Frequency,
    serviceLine: createDto.serviceLine as ServiceLine,
    billerId: createDto.biller?.id as string,
    predefinedInvoiceDescription: createDto.predefinedInvoiceDescription,
    predefinedQuotationDescription: createDto.predefinedQuotationDescription,
});

export const mapUpdateServiceFormValuesToApiUpdateServiceRequest = (
    updateDto: UpdateServiceFormValues
): ApiUpdateServiceRequest => ({
    id: updateDto.id,
    value: updateDto.value,
    acronym: updateDto.acronym,
    title: updateDto.title,
    frequency: updateDto.frequency,
    serviceLine: updateDto.serviceLine,
    billerId: updateDto.biller?.id as string,
    predefinedInvoiceDescription: updateDto.predefinedInvoiceDescription,
    predefinedQuotationDescription: updateDto.predefinedQuotationDescription,
});
