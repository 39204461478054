import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Grid,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  ApiEntity,
  ApiStageOwnershipControl,
} from "../../../../../../../models/entities";

import {
  PermissionGuard,
  usePermissions,
} from "../../../../../../../models/auth";
import {
  ApiEntityStatus,
  YesNo,
  booleanToYesNo,
  yesNosColourMap,
  ACTIVE_CLIENT_STATUS,
  CLOSED_CLIENT_STATUS,
  PENDING_APPROVAL_CLIENT_STATUS,
} from "../../../../../../../forms";
import { StatusLabel } from "../../../../../../../components/StatusLabel";
import { Link as RouterLink, useParams } from "react-router-dom";
import { ApiUserResponse } from "../../../../../../../models/users";
import { Nullable } from "../../../../../../../utils/types";

interface DetailRow {
  name: string;
  value: any;
}

export const Stage2_3View: FC<{
  entity: ApiEntity;
  stageData: ApiStageOwnershipControl;
  entities: ApiEntity[];
  onEdit: (value: boolean) => void;
  status: Nullable<ApiEntityStatus>;
  profile: ApiUserResponse | undefined;
}> = ({ entity, stageData, entities, onEdit, status = null, profile }) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const { tenantNameOrId } = useParams<{ tenantNameOrId: string }>();

  const step2_3Data: DetailRow[] = useMemo(
    () =>
      stageData
        ? [
            {
              name: "common.offshoreVehiclesTrusts",
              value: (
                <>
                  {stageData?.hasLegalArrangements ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasLegalArrangements)}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.foundationsCharities",
              value: (
                <>
                  {stageData?.hasNonProfitEntities ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasNonProfitEntities)}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.aShellCompany",
              value: (
                <>
                  {stageData?.hasShellCompanies ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasShellCompanies)}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.entitiesIncorporated",
              value: (
                <>
                  {stageData?.hasNonCooperativeJurisdictions ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.hasNonCooperativeJurisdictions
                      )}
                    />
                  ) : null}
                </>
              ),
            },
          ]
        : [],
    [stageData]
  );

  const relatedEntities: DetailRow[][] = useMemo(
    () =>
      stageData?.relatedEntities
        ? stageData?.relatedEntities.map((relatedEntity) => [
            // {
            //   name: "common.id",
            //   value: (
            //     <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
            //       {relatedEntity.id}
            //     </Typography>
            //   ),
            // },
            // {
            //   name: "common.relatedEntity",
            //   value: (
            //     <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
            //       {relatedEntity.relatedEntityId}
            //     </Typography>
            //   ),
            // },

            ...(relatedEntity.relatedEntityId && entities
              ? [
                  {
                    name: "common.relatedParty",
                    value: (
                      <Tooltip title={relatedEntity.relatedEntityId}>
                        {hasPermission("GET_ENTITY") ? (
                          <Link
                            color="primary"
                            component={RouterLink}
                            to={`/${tenantNameOrId}/entities/${relatedEntity.relatedEntityId}`}
                            variant="body1"
                          >
                            {
                              entities.find(
                                (e) => e.id === relatedEntity.relatedEntityId
                              )?.firstName!
                            }{" "}
                            {
                              entities.find(
                                (e) => e.id === relatedEntity.relatedEntityId
                              )?.lastName!
                            }
                          </Link>
                        ) : (
                          <span>
                            {" "}
                            {
                              entities.find(
                                (e) => e.id === relatedEntity.relatedEntityId
                              )?.firstName!
                            }{" "}
                            {
                              entities.find(
                                (e) => e.id === relatedEntity.relatedEntityId
                              )?.lastName!
                            }
                          </span>
                        )}
                      </Tooltip>
                    ),
                  },
                ]
              : []),
            {
              name: "common.relationship",
              value: (
                <>
                  {relatedEntity.relationship ? (
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={relatedEntity.relationship}
                    />
                  ) : (
                    ""
                  )}
                </>
              ),
            },
            {
              name: "common.layerOfOwnership",
              value: (
                <>
                  {relatedEntity.layerOfOwnership ? (
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={relatedEntity.layerOfOwnership}
                    />
                  ) : (
                    ""
                  )}
                </>
              ),
            },
            {
              name: "common.extentOfOwnership",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {relatedEntity.extentOfOwnership}%
                </Typography>
              ),
            },
            {
              name: "common.natureOfOwnership",
              value: (
                <>
                  {relatedEntity.natureOfOwnership ? (
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={relatedEntity.natureOfOwnership}
                    />
                  ) : (
                    ""
                  )}
                </>
              ),
            },
            {
              name: "common.indirectOwnershipDesc",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {relatedEntity.indirectOwnershipDesc}
                </Typography>
              ),
            },
            {
              name: "common.influenceDescription",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {relatedEntity.influenceDescription}
                </Typography>
              ),
            },
            {
              name: "common.hasSchemeBenefits",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {relatedEntity.hasSchemeBenefits}
                </Typography>
              ),
            },
            {
              name: "common.schemeDetails",
              value: (
                <>
                  {relatedEntity.schemeDetails ? (
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={relatedEntity.schemeDetails}
                    />
                  ) : (
                    ""
                  )}
                </>
              ),
            },
            {
              name: "common.schemeStatus",
              value: (
                <>
                  {relatedEntity.schemeStatus ? (
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={relatedEntity.schemeStatus}
                    />
                  ) : (
                    ""
                  )}
                </>
              ),
            },
          ])
        : [],
    [stageData.relatedEntities, entities]
  );

  return (
    <>
      {entity ? (
        <>
          <Card sx={{ marginTop: "12px" }}>
            <CardHeader
              disableTypography
              title={
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tooltip title={t("common.stage_2_3")} enterDelay={1000}>
                    <Typography variant="h6">
                      {t("common.ownershipAndControl")}
                    </Typography>
                  </Tooltip>
                  <Grid
                    item
                    // sm={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {((status !== PENDING_APPROVAL_CLIENT_STATUS &&
                      status !== ACTIVE_CLIENT_STATUS &&
                      status !== CLOSED_CLIENT_STATUS) ||
                      (status === PENDING_APPROVAL_CLIENT_STATUS &&
                        entity.reviewerId === profile?.id)) && (
                      <PermissionGuard permission="UPDATE_ENTITY">
                        <>
                          <Button
                            sx={[
                              {
                                whiteSpace: "nowrap",
                              },
                              (theme) => ({
                                [theme.breakpoints.down("sm")]: {
                                  minWidth: "100%",
                                },
                                [theme.breakpoints.up("sm")]: {
                                  minWidth: "auto",
                                },
                              }),
                            ]}
                            variant="contained"
                            onClick={() => onEdit(true)}
                          >
                            {t("common.edit")}
                          </Button>
                        </>
                      </PermissionGuard>
                    )}
                  </Grid>
                </Box>
              }
            />

            <Grid container rowSpacing={3} columnSpacing={2} sx={{ p: "16px" }}>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                sx={{ paddingTop: "12px !important" }}
              >
                <Typography variant="subtitle2">
                  {t("common.doesTheOwnershipStructureInclude")}
                </Typography>
              </Grid>
              {step2_3Data.map(({ name, value }) => (
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  key={name}
                  sx={{ paddingTop: "12px !important" }}
                >
                  <Grid container>
                    <Grid item sm={10} xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {t(name)}
                      </Typography>
                    </Grid>
                    <Grid item sm={2} xs={12}>
                      {value}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            {relatedEntities.map((relatedEntity, index) => {
              return (
                <Card
                  key={index}
                  sx={{
                    margin: "12px",
                  }}
                >
                  <CardHeader
                    disableTypography
                    title={
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h6">
                          {t("common.relatedParty")} #{index + 1}
                        </Typography>
                      </Box>
                    }
                  />

                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={2}
                    sx={{ p: "16px" }}
                  >
                    {relatedEntity.map(({ name, value }) => (
                      <Grid
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        key={name}
                        sx={{ paddingTop: "12px !important" }}
                      >
                        <Grid container>
                          <Grid item sm={6} xs={12}>
                            <Typography variant="body2" color="textSecondary">
                              {t(name)}
                            </Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            {value}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              );
            })}
          </Card>
        </>
      ) : null}
    </>
  );
};
