import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Grid,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ApiEntity,
  ApiStageBasicDetail,
  ApiUpdateEntity,
  mapUpdateEntityFormValuesToApiUpdateEntity,
  useUpdateEntityMutation,
  useUpdateEntityStageMutation,
} from "../../../../../../../models/entities";
import {
  ACTIVE_CLIENT_STATUS,
  ApiEntityStatus,
  CLOSED_CLIENT_STATUS,
  LEGAL_ENTITY,
  NATURAL_PERSON,
  PENDING_APPROVAL_CLIENT_STATUS,
  entityStatusesColourMap,
} from "../../../../../../../forms";
import {
  PermissionGuard,
  usePermissions,
} from "../../../../../../../models/auth";
import { StatusLabel } from "../../../../../../../components/StatusLabel";
import { ApiUserResponse } from "../../../../../../../models/users";
import { Nullable } from "../../../../../../../utils/types";
import { Link as RouterLink, useParams } from "react-router-dom";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  UpdateEntityFormValues,
  updateEntityFormValuesInitialValue,
} from "../../../../../forms";
import toast from "react-hot-toast";
import { ConfirmDialogType } from "../../../../../../../components/ConfirmDialog";
import ConfirmDialog from "../../../../../../../components/ConfirmDialog/ConfirmDialog";
import { useMeQuery } from "../../../../../../../models/user";
interface DetailRow {
  name: string;
  value: any;
}

export const Stage1_1View: FC<{
  entity: ApiEntity;
  stageData: ApiStageBasicDetail;
  users: ApiUserResponse[];
  reviewers: ApiUserResponse[];
  onEdit: (value: boolean) => void;
  onSendForApproval: (value: boolean) => void;
  status: Nullable<ApiEntityStatus>;
  profile: ApiUserResponse | undefined;
}> = ({
  entity,
  stageData,
  users,
  reviewers,
  onEdit,
  onSendForApproval,
  status = null,
  profile,
}) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const { tenantNameOrId } = useParams<{ tenantNameOrId: string }>();
  const [createdBy, setCreatedBy] = useState<Nullable<ApiUserResponse>>(null);
  const [updatedBy, setUpdatedBy] = useState<Nullable<ApiUserResponse>>(null);
  const [reviewer, setReviewer] = useState<Nullable<ApiUserResponse>>(null);
  const [updateEntity] = useUpdateEntityMutation();

  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogType>({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => void 0,
  });

  useEffect(() => {
    if (entity && users) {
      setCreatedBy(
        entity.createdByUserId
          ? users.find((x) => x.id === entity.createdByUserId)!
          : null
      );
      setUpdatedBy(
        entity.updatedByUserId
          ? users.find((x) => x.id === entity.updatedByUserId)!
          : null
      );
    }
    if (entity && reviewers) {
      setReviewer(
        entity.reviewerId
          ? reviewers.find((x) => x.id === entity.reviewerId)!
          : null
      );
    }
  }, [entity, users, reviewers]);

  const Stage1_1Data: DetailRow[] = useMemo(
    () =>
      entity
        ? [
            {
              name: "common.refNo",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {entity?.refNo}
                </Typography>
              ),
            },
            {
              name: "common.tags",
              value: (
                <>
                  <Grid container rowSpacing={1}>
                    {entity.tags?.split(",").map((tag: string) => (
                      <Grid item xs={12} key={tag}>
                        <Chip color="primary" variant="outlined" label={tag} />
                      </Grid>
                    ))}
                  </Grid>
                </>
              ),
            },
            {
              name: "common.entryType",
              value: (
                <Chip
                  color="primary"
                  variant="outlined"
                  label={entity.entryType}
                />
              ),
            },
            ...(entity.entryType === LEGAL_ENTITY
              ? [
                  {
                    name: "common.legalEntityName",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {entity.legalEntityName}
                      </Typography>
                    ),
                  },
                ]
              : []),
            ...(entity.entryType === NATURAL_PERSON
              ? [
                  {
                    name: "common.title",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {entity.title}
                      </Typography>
                    ),
                  },
                ]
              : []),
            ...(entity.entryType === NATURAL_PERSON
              ? [
                  {
                    name: "common.firstName",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {entity.firstName}
                      </Typography>
                    ),
                  },
                ]
              : []),
            ...(entity.entryType === NATURAL_PERSON
              ? [
                  {
                    name: "common.lastName",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {entity.lastName}
                      </Typography>
                    ),
                  },
                ]
              : []),

            {
              name: "common.additionalComments",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {entity.additionalComments}
                </Typography>
              ),
            },
            ...(entity.status
              ? [
                  {
                    name: "common.status",
                    value: (
                      <StatusLabel<ApiEntityStatus>
                        colourMap={entityStatusesColourMap}
                        status={entity.status}
                      />
                    ),
                  },
                ]
              : []),

            {
              name: "common.createdDate",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {format(
                    new Date(entity.createdDateTime),
                    "dd/MM/yyyy HH:mm:ss"
                  )}
                </Typography>
              ),
            },
            {
              name: "common.updatedDate",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {entity.updatedDateTime != null
                    ? format(
                        new Date(entity.updatedDateTime),
                        "dd/MM/yyyy HH:mm:ss"
                      )
                    : ""}
                </Typography>
              ),
            },

            ...(createdBy
              ? [
                  {
                    name: "common.createdBy",
                    value: (
                      <Tooltip title={createdBy.email}>
                        {hasPermission("GET_USER") ? (
                          <Link
                            color="primary"
                            component={RouterLink}
                            to={`/${tenantNameOrId}/users/${createdBy.id}`}
                            variant="body1"
                          >
                            {createdBy.firstName} {createdBy.lastName}
                          </Link>
                        ) : (
                          <span>
                            {" "}
                            {createdBy.firstName} {createdBy.lastName}
                          </span>
                        )}
                      </Tooltip>
                    ),
                  },
                ]
              : []),

            ...(updatedBy
              ? [
                  {
                    name: "common.updatedBy",
                    value: (
                      <Tooltip title={updatedBy.email}>
                        {hasPermission("GET_USER") ? (
                          <Link
                            color="primary"
                            component={RouterLink}
                            to={`/${tenantNameOrId}/users/${updatedBy.id}`}
                            variant="body1"
                          >
                            {updatedBy.firstName} {updatedBy.lastName}
                          </Link>
                        ) : (
                          <span>
                            {" "}
                            {updatedBy.firstName} {updatedBy.lastName}
                          </span>
                        )}
                      </Tooltip>
                    ),
                  },
                ]
              : []),
            {
              name: "common.legacyId",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {entity.legacyId || "N/A"}
                </Typography>
              ),
            },

            ...(reviewer
              ? [
                  {
                    name: "common.approver",
                    value: (
                      <Tooltip title={reviewer.email}>
                        {hasPermission("GET_USER") ? (
                          <Link
                            color="primary"
                            component={RouterLink}
                            to={`/${tenantNameOrId}/users/${reviewer.id}`}
                            variant="body1"
                          >
                            {reviewer.firstName} {reviewer.lastName}
                          </Link>
                        ) : (
                          <span>
                            {" "}
                            {reviewer.firstName} {reviewer.lastName}
                          </span>
                        )}
                      </Tooltip>
                    ),
                  },
                ]
              : []),

            {
              name: "common.pendingBalance",
              value: (
                <>
                  {entity?.pendingBalance.currency ? (
                    <Chip
                      sx={{ pl: "5px", m: 1 }}
                      label={`${getSymbolFromCurrency(
                        entity?.pendingBalance.currency.toLocaleUpperCase()
                      )} ${(
                        Math.round(Number(entity?.pendingBalance.value) * 100) /
                        100
                      ).toFixed(2)}`}
                    />
                  ) : (
                    <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                      N/A
                    </Typography>
                  )}
                </>
              ),
            },

            {
              name: "common.creditBalance",
              value: (
                <>
                  {entity?.creditBalance.currency ? (
                    <Chip
                      sx={{ pl: "5px", m: 1 }}
                      label={`${getSymbolFromCurrency(
                        entity?.creditBalance.currency.toLocaleUpperCase()
                      )} ${(
                        Math.round(Number(entity?.creditBalance.value) * 100) /
                        100
                      ).toFixed(2)}`}
                    />
                  ) : (
                    <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                      N/A
                    </Typography>
                  )}
                </>
              ),
            },
          ]
        : [],
    [entity, createdBy, updatedBy, hasPermission, tenantNameOrId]
  );

  return (
    <>
      {entity ? (
        <>
          <Card sx={{}}>
            <CardHeader
              disableTypography
              title={
                // <Box
                //   sx={{
                //     alignItems: "center",
                //     display: "flex",
                //     justifyContent: "space-between",
                //   }}
                // >
                <Grid container spacing={3}>
                  <Grid
                    alignItems="center"
                    container
                    justifyContent="space-between"
                    item
                    xs={12}
                  >
                    <Grid item>
                      <Tooltip title={t("common.stage_1_1")} enterDelay={1000}>
                        <Typography variant="h6">
                          {t("common.basicDetails")}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    {/* <Grid
                    item
                    // sm={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  > */}
                    <Grid
                      item
                      sx={[
                        {
                          width: "100%",
                          display: "flex",
                          gap: 1,
                          marginBottom: "10px",
                        },
                        (theme) => ({
                          [theme.breakpoints.down("sm")]: {
                            flexDirection: "column",
                            gap: "10px",
                          },
                          [theme.breakpoints.up("sm")]: {
                            flexDirection: "row",
                            justifyContent: "flex-end",
                          },
                        }),
                      ]}
                    >
                      {entity.status === PENDING_APPROVAL_CLIENT_STATUS &&
                        entity.reviewerId === profile?.id && (
                          <PermissionGuard permission="REVIEW_ENTITY">
                            <Button
                              sx={[
                                {
                                  whiteSpace: "nowrap",
                                },
                                (theme) => ({
                                  [theme.breakpoints.down("sm")]: {
                                    minWidth: "100%",
                                  },
                                  [theme.breakpoints.up("sm")]: {
                                    minWidth: "auto",
                                  },
                                }),
                              ]}
                              variant="contained"
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: `Are you sure you want to approve ${entity.refNo}?`,
                                  subTitle: "This operation cannot be undone",
                                  onConfirm: async () => {
                                    await updateEntity({
                                      ...entity,
                                      status: ACTIVE_CLIENT_STATUS,
                                    }).unwrap();
                                  },
                                });
                              }}
                            >
                              {t("common.approve")}
                            </Button>
                          </PermissionGuard>
                        )}
                      {entity.status === PENDING_APPROVAL_CLIENT_STATUS &&
                        entity.reviewerId === profile?.id && (
                          <PermissionGuard permission="REVIEW_ENTITY">
                            <Button
                              sx={[
                                {
                                  whiteSpace: "nowrap",
                                },
                                (theme) => ({
                                  [theme.breakpoints.down("sm")]: {
                                    minWidth: "100%",
                                  },
                                  [theme.breakpoints.up("sm")]: {
                                    minWidth: "auto",
                                  },
                                }),
                              ]}
                              variant="contained"
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: `Are you sure you want to reject ${entity.refNo}?`,
                                  subTitle: "This operation cannot be undone",
                                  onConfirm: async () => {
                                    await updateEntity({
                                      ...entity,
                                      status: CLOSED_CLIENT_STATUS,
                                    }).unwrap();
                                  },
                                });
                              }}
                            >
                              {t("common.reject")}
                            </Button>
                          </PermissionGuard>
                        )}
                      {![
                        PENDING_APPROVAL_CLIENT_STATUS,
                        ACTIVE_CLIENT_STATUS,
                        CLOSED_CLIENT_STATUS,
                      ].includes(entity.status) && (
                        <PermissionGuard permission="UPDATE_ENTITY">
                          <Button
                            sx={[
                              {
                                whiteSpace: "nowrap",
                              },
                              (theme) => ({
                                [theme.breakpoints.down("sm")]: {
                                  minWidth: "100%",
                                },
                                [theme.breakpoints.up("sm")]: {
                                  minWidth: "auto",
                                },
                              }),
                            ]}
                            variant="contained"
                            onClick={() => onSendForApproval(true)}
                          >
                            {t("common.sendForApproval")}
                          </Button>
                        </PermissionGuard>
                      )}
                      {(entity.status !== PENDING_APPROVAL_CLIENT_STATUS ||
                        (entity.status === PENDING_APPROVAL_CLIENT_STATUS &&
                          entity.reviewerId === profile?.id)) && (
                        <PermissionGuard permission="UPDATE_ENTITY">
                          <Button
                            sx={[
                              {
                                whiteSpace: "nowrap",
                              },
                              (theme) => ({
                                [theme.breakpoints.down("sm")]: {
                                  minWidth: "100%",
                                },
                                [theme.breakpoints.up("sm")]: {
                                  minWidth: "auto",
                                },
                              }),
                            ]}
                            variant="contained"
                            onClick={() => onEdit(true)}
                          >
                            {t("common.edit")}
                          </Button>
                        </PermissionGuard>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              }
            />

            <Grid container rowSpacing={3} columnSpacing={2} sx={{ p: "16px" }}>
              {Stage1_1Data.map(({ name, value }) => (
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  key={name}
                  sx={{ paddingTop: "12px !important" }}
                >
                  <Grid container>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {t(name)}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      {value}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Card>
        </>
      ) : null}

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};
