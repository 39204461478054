import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  ApiEntity,
  ApiStageInterfaceDistribution,
} from "../../../../../../../models/entities";

import { PermissionGuard } from "../../../../../../../models/auth";
import {
  ApiEntityStatus,
  YesNo,
  booleanToYesNo,
  yesNosColourMap,
  ACTIVE_CLIENT_STATUS,
  CLOSED_CLIENT_STATUS,
  PENDING_APPROVAL_CLIENT_STATUS,
} from "../../../../../../../forms";
import { StatusLabel } from "../../../../../../../components/StatusLabel";
import { ApiUserResponse } from "../../../../../../../models/users";
import { Nullable } from "../../../../../../../utils/types";

interface DetailRow {
  name: string;
  value: any;
}

export const Stage2_5View: FC<{
  entity: ApiEntity;
  stageData: ApiStageInterfaceDistribution;
  onEdit: (value: boolean) => void;
  status: Nullable<ApiEntityStatus>;
  profile: ApiUserResponse | undefined;
}> = ({ entity, stageData, onEdit, status = null, profile }) => {
  const { t } = useTranslation();

  const step2_5Data: DetailRow[] = useMemo(
    () =>
      stageData
        ? [
            {
              name: "common.onboardedOnAFaceToFaceOrANonFaceToFaceBasis",
              value: (
                <>
                  {stageData?.onboardingType != null ? (
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={stageData.onboardingType}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.introducedBy",
              value: (
                <>
                  {stageData?.introducedBy != null ? (
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={stageData.introducedBy}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.dealingDirectlyWith",
              value: (
                <>
                  {stageData?.dealingDirectlyWith != null ? (
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={stageData.dealingDirectlyWith}
                    />
                  ) : null}
                </>
              ),
            },
            ...(["AGENT", "REPRESENTATIVE"].includes(
              stageData.dealingDirectlyWith
            )
              ? [
                  {
                    name: "common.hasPoaAuthorized",
                    value: (
                      <>
                        {stageData?.hasPoaAuthorized != null ? (
                          <StatusLabel<YesNo>
                            colourMap={yesNosColourMap}
                            status={booleanToYesNo(stageData.hasPoaAuthorized)}
                          />
                        ) : null}
                      </>
                    ),
                  },

                  {
                    name: "common.hasPoaDocumentCollected",
                    value: (
                      <>
                        {stageData?.hasPoaDocumentCollected != null ? (
                          <StatusLabel<YesNo>
                            colourMap={yesNosColourMap}
                            status={booleanToYesNo(
                              stageData.hasPoaDocumentCollected
                            )}
                          />
                        ) : null}
                      </>
                    ),
                  },
                  {
                    name: "common.hasReasonableRationale",
                    value: (
                      <>
                        {stageData?.hasReasonableRationale != null ? (
                          <StatusLabel<YesNo>
                            colourMap={yesNosColourMap}
                            status={booleanToYesNo(
                              stageData.hasReasonableRationale
                            )}
                          />
                        ) : null}
                      </>
                    ),
                  },
                  ...(stageData.hasReasonableRationale
                    ? [
                        {
                          name: "common.reasonableRationaleInfo",
                          value: (
                            <Typography
                              variant="body2"
                              sx={{ wordWrap: "break-word" }}
                            >
                              {stageData.reasonableRationaleInfo}
                            </Typography>
                          ),
                        },
                      ]
                    : []),
                ]
              : []),
            {
              name: "common.hasRelianceAgreementThirdPartyCdd",
              value: (
                <>
                  {stageData?.hasRelianceAgreementThirdPartyCdd != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.hasRelianceAgreementThirdPartyCdd
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasRelianceAgreementThirdPartyCdd
              ? [
                  {
                    name: "common.hasRelianceAgreementSigned",
                    value: (
                      <>
                        {stageData?.hasRelianceAgreementSigned != null ? (
                          <StatusLabel<YesNo>
                            colourMap={yesNosColourMap}
                            status={booleanToYesNo(
                              stageData.hasRelianceAgreementSigned
                            )}
                          />
                        ) : null}
                      </>
                    ),
                  },
                ]
              : []),
          ]
        : [],
    [stageData]
  );

  return (
    <>
      {entity ? (
        <>
          <Card sx={{ marginTop: "12px" }}>
            <CardHeader
              disableTypography
              title={
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tooltip title={t("common.stage_2_5")} enterDelay={1000}>
                    <Typography variant="h6">
                      {t("common.interfaceAndDistribution")}
                    </Typography>
                  </Tooltip>
                  <Grid
                    item
                    // sm={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {((status !== PENDING_APPROVAL_CLIENT_STATUS &&
                      status !== ACTIVE_CLIENT_STATUS &&
                      status !== CLOSED_CLIENT_STATUS) ||
                      (status === PENDING_APPROVAL_CLIENT_STATUS &&
                        entity.reviewerId === profile?.id)) && (
                      <PermissionGuard permission="UPDATE_ENTITY">
                        <>
                          <Button
                            sx={[
                              {
                                whiteSpace: "nowrap",
                              },
                              (theme) => ({
                                [theme.breakpoints.down("sm")]: {
                                  minWidth: "100%",
                                },
                                [theme.breakpoints.up("sm")]: {
                                  minWidth: "auto",
                                },
                              }),
                            ]}
                            variant="contained"
                            onClick={() => onEdit(true)}
                          >
                            {t("common.edit")}
                          </Button>
                        </>
                      </PermissionGuard>
                    )}
                  </Grid>
                </Box>
              }
            />

            <Grid container rowSpacing={3} columnSpacing={2} sx={{ p: "16px" }}>
              {step2_5Data.map(({ name, value }) => (
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  key={name}
                  sx={{ paddingTop: "12px !important" }}
                >
                  <Grid container>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {t(name)}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      {value}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Card>
        </>
      ) : null}
    </>
  );
};
