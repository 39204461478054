import { Color, colors } from "@mui/material";
import { ApiQuotationStatus } from ".";

export const INITIAL_QUOTATION_STATUS = 'INITIAL';
export const DRAFT_QUOTATION_STATUS = 'DRAFT';
export const PENDING_APPROVAL_QUOTATION_STATUS = 'PENDING_APPROVAL';
export const APPROVED_QUOTATION_STATUS = 'APPROVED';
export const SENT_QUOTATION_STATUS = 'SENT';
export const CANCELLED_QUOTATION_STATUS = 'CANCELLED';
export const ACCEPTED_QUOTATION_STATUS = 'ACCEPTED';
export const REJECTED_QUOTATION_STATUS = 'REJECTED';
export const SUPERSEDED_QUOTATION_STATUS = 'SUPERSEDED';

export const quotationStatuses = [INITIAL_QUOTATION_STATUS,
    DRAFT_QUOTATION_STATUS,
    PENDING_APPROVAL_QUOTATION_STATUS,
    APPROVED_QUOTATION_STATUS,
    SENT_QUOTATION_STATUS,
    CANCELLED_QUOTATION_STATUS,
    ACCEPTED_QUOTATION_STATUS,
    REJECTED_QUOTATION_STATUS,
    SUPERSEDED_QUOTATION_STATUS] as const;
export const quotationStatusesColours = [colors.orange, colors.orange, colors.orange, colors.green, colors.orange, colors.red, colors.green, colors.red, colors.grey];
export const quotationStatusesColourMap = new Map<ApiQuotationStatus, Color>(quotationStatuses.map((v, i) => [v, quotationStatusesColours[i]]));;
