import * as Yup from "yup";

export const reviewerQuotationValidationSchema = (validationMessage?: string) =>
    Yup.object()
        .required(validationMessage || 'validations.reviewerIsRequired')
        .nullable()
        .test(
            'null-check',
            validationMessage || 'validations.reviewerIsRequired',
            (reviewer) => reviewer != null,
        )
