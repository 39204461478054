import { Route, Routes } from "react-router";
import { LazyLoadableScreen } from "../../../../navigation/LazyLoadableScreen";
import { ApiEntity } from "../../../../models/entities";
import { FC } from "react";

const ReceiptListScreen = LazyLoadableScreen(() => import("./ReceiptList"));
const ReceiptDetailsScreen = LazyLoadableScreen(
  () => import("./ReceiptDetails")
);

export const ReceiptsRoutes: FC<{}> = ({}) => (
  <Routes>
    <Route path="/" element={<ReceiptListScreen />} />
    <Route path="/:receiptId" element={<ReceiptDetailsScreen />} />
  </Routes>
);
