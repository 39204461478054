import { Route, Routes } from "react-router";
import { LazyLoadableScreen } from "../../../../navigation/LazyLoadableScreen";

const QuotationListScreen = LazyLoadableScreen(() => import("./QuotationList"));
const QuotationDetailsScreen = LazyLoadableScreen(
  () => import("./QuotationDetails")
);

export const QuotationsRoutes = () => (
  <Routes>
    <Route path="/" element={<QuotationListScreen status={null} />} />
    <Route
      path="/:quotationId"
      element={<QuotationDetailsScreen status={null} />}
    />
  </Routes>
);
