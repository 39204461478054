import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import { Box, Divider, IconButton, Typography } from "@mui/material";
import { Field, Formik, getIn } from "formik";
import React, { FC } from "react";
import toast from "react-hot-toast";

import {
  interfaceDistributionFormValuesInitialValue,
  InterfaceDistributionFormValues,
  interfaceDistributionFormValidationSchema,
} from ".";

import { useTranslation } from "react-i18next";

import {
  BasicTextField,
  EnumerableField,
  OnboardingTypeField,
  YES,
  YesNoField,
} from "../../../../../../forms";
import {
  ApiEnumeration,
  DEALING_DIRECTLY_WITH,
  INTRODUCED_BY,
} from "../../../../../../models/enumerations";
import { usePermissions } from "../../../../../../models/auth";
import {
  PENDING_STAGE_STATUS,
  ApiEntity,
  DRAFT_STAGE_STATUS,
  mapInterfaceDistributionFormValuesToApiStageInterfaceDistribution,
  useUpdateEntityStageMutation,
  ApiStageInterfaceDistribution,
  STAGE_2_5_TYPE,
  PENDING_APPROVAL_STAGE_STATUS,
} from "../../../../../../models/entities";
import { ApiUserResponse } from "../../../../../../models/users";
interface InterfaceDistributionFormProps {
  entity: ApiEntity;
  enumerables: ApiEnumeration[];
  onClose: () => void;
  profile: ApiUserResponse | undefined;
}
// 17.1/17.3/17.4
export const InterfaceDistributionForm: FC<InterfaceDistributionFormProps> = ({
  entity,
  enumerables,
  onClose,
  profile,
}) => {
  const [updateEntityStage] = useUpdateEntityStageMutation();
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  return (
    <Formik<InterfaceDistributionFormValues>
      enableReinitialize
      initialValues={{
        ...interfaceDistributionFormValuesInitialValue(entity, enumerables),
      }}
      validationSchema={interfaceDistributionFormValidationSchema(
        hasPermission("ADMIN")
      )}
      onSubmit={async (values): Promise<void> => {
        try {
          const apiInterfaceDistribution: ApiStageInterfaceDistribution =
            mapInterfaceDistributionFormValuesToApiStageInterfaceDistribution(
              entity,
              values
            );
          await updateEntityStage(apiInterfaceDistribution).unwrap();

          toast.success(t("common.updatedSuccessfully"));
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        setFieldValue,
        errors,
        touched,
      }): JSX.Element => (
        <>
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "24px 38px 24px 32px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={"textPrimary"} variant="h5">
                {t("common.interfaceAndDistribution")}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: "32px" }}>
            <form noValidate onSubmit={handleSubmit}>
              <Field
                name={`onboardingType`}
                component={OnboardingTypeField}
                label="common.onboardedOnAFaceToFaceOrANonFaceToFaceBasis"
                // size="small"
                margin="normal"
              />
              <Field
                name={`introducedBy`}
                component={EnumerableField}
                type={INTRODUCED_BY}
                margin="normal"
                info="common.introducedByInfoNote"
              />
              <Field
                name={`dealingDirectlyWith`}
                component={EnumerableField}
                type={DEALING_DIRECTLY_WITH}
                margin="normal"
                label="common.dealDirectlywith"
              />
              {["AGENT", "REPRESENTATIVE"].includes(
                getIn(values, "dealingDirectlyWith")?.value || ""
              ) && (
                <>
                  <Field
                    name={`hasPoaAuthorized`}
                    component={YesNoField}
                    label="common.hasPoaAuthorized"
                    // size="small"
                    margin="normal"
                  />
                  <Field
                    name={`hasPoaDocumentCollected`}
                    component={YesNoField}
                    label="common.hasPoaDocumentCollected"
                    // size="small"
                    margin="normal"
                  />
                  <Field
                    name={`hasReasonableRationale`}
                    component={YesNoField}
                    label="common.hasReasonableRationale"
                    // size="small"
                    margin="normal"
                  />
                  <Field
                    name={`reasonableRationaleInfo`}
                    component={BasicTextField}
                    label="common.reasonableRationaleInfo"
                    // size="small"
                    margin="normal"
                  />
                </>
              )}
              <Field
                name={`hasRelianceAgreementThirdPartyCdd`}
                component={YesNoField}
                label="common.hasRelianceAgreementThirdPartyCdd"
                // size="small"
                margin="normal"
              />

              {getIn(values, "hasRelianceAgreementThirdPartyCdd") === YES && (
                <Field
                  name={`hasRelianceAgreementSigned`}
                  component={YesNoField}
                  label="common.hasRelianceAgreementSigned"
                  // size="small"
                  margin="normal"
                />
              )}
              <Box
                sx={{
                  mt: "24px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    setFieldValue("stageStatus", DRAFT_STAGE_STATUS);
                  }}
                >
                  {t("common.saveAsDraft")}
                </LoadingButton>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    // Get Stage Data
                    const stageData: ApiStageInterfaceDistribution =
                      entity.stages.find(
                        (s) => s.stageType === STAGE_2_5_TYPE
                      ) as ApiStageInterfaceDistribution;

                    if (
                      profile?.id === entity.reviewerId &&
                      stageData.stageStatus === PENDING_APPROVAL_STAGE_STATUS
                    ) {
                      setFieldValue(
                        "stageStatus",
                        PENDING_APPROVAL_STAGE_STATUS
                      );
                    } else {
                      setFieldValue("stageStatus", PENDING_STAGE_STATUS);
                    }
                  }}
                >
                  {t("common.submit")}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
