import { Color, colors } from "@mui/material";
import { ApiStageStatus } from ".";
import { ApiStageType } from ".";

export const INITIAL_STAGE_STATUS = 'INITIAL';
export const DRAFT_STAGE_STATUS = 'DRAFT';
export const COMPLETED_STAGE_STATUS = 'COMPLETED';
export const DELETED_STAGE_STATUS = 'DELETED';
export const PENDING_STAGE_STATUS = 'PENDING';
export const PENDING_APPROVAL_STAGE_STATUS = 'PENDING_APPROVAL';



export const stageStatuses = [INITIAL_STAGE_STATUS, DRAFT_STAGE_STATUS, COMPLETED_STAGE_STATUS, DELETED_STAGE_STATUS, PENDING_APPROVAL_STAGE_STATUS, PENDING_STAGE_STATUS] as const;
export const stageStatusesColours = [colors.orange, colors.orange, colors.green, colors.red, colors.orange, colors.orange];
export const stageStatusesColourMap = new Map<ApiStageStatus, Color>(stageStatuses.map((v, i) => [v, stageStatusesColours[i]]));;


export const STAGE_1_1_TYPE = 'STAGE_1_1';
export const STAGE_1_2_TYPE = 'STAGE_1_2';
export const STAGE_1_3_TYPE = 'STAGE_1_3';
export const STAGE_2_1_TYPE = 'STAGE_2_1';
export const STAGE_2_2_TYPE = 'STAGE_2_2';
export const STAGE_2_3_TYPE = 'STAGE_2_3';
export const STAGE_2_4_TYPE = 'STAGE_2_4';
export const STAGE_2_5_TYPE = 'STAGE_2_5';
export const STAGE_2_6_TYPE = 'STAGE_2_6';
export const STAGE_2_7_TYPE = 'STAGE_2_7';
export const STAGE_3_1_TYPE = 'STAGE_3_1';
export const STAGE_4_1_TYPE = 'STAGE_4_1';
export const STAGE_4_2_TYPE = 'STAGE_4_2';


export const stageTypes = [STAGE_1_1_TYPE, STAGE_1_2_TYPE, STAGE_1_3_TYPE, STAGE_2_1_TYPE, STAGE_2_2_TYPE, STAGE_2_3_TYPE, STAGE_2_4_TYPE, STAGE_2_5_TYPE, STAGE_2_6_TYPE, STAGE_2_7_TYPE, STAGE_3_1_TYPE, STAGE_4_1_TYPE, STAGE_4_2_TYPE] as const;
// export const stageTypesColours = [colors.orange, colors.orange, colors.green, colors.red];
// export const stageTypesColourMap = new Map<ApiStageStatus, Color>(stageStatuses.map((v, i) => [v, stageStatusesColours[i]]));;




export const POTENTIAL_CUSTOMER = 'POTENTIAL_CUSTOMER';
export const CUSTOMER = 'CUSTOMER';