import * as Yup from "yup";

// export const reviewerEntityValidationSchema = () => Yup.string()
//     .required('validations.reviewerIsRequired')



export const reviewerEntityValidationSchema = (validationMessage?: string) =>
    Yup.object()
        .required(validationMessage || 'validations.reviewerIsRequired')
        .nullable()
        .test(
            'null-check',
            validationMessage || 'validations.reviewerIsRequired',
            (legalEntityType) => legalEntityType != null,
        )
