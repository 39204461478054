import { FormControl, TextField } from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { FC, useCallback, useState, FocusEvent } from "react";
import { useTranslation } from "react-i18next";

export interface EmailFieldProps {
  disabled: boolean;
  label?: string;
  size?: "small" | "medium";
  margin?: "none" | "dense" | "normal";
  onBlur?: (e: any) => void;
  onChange?: (e: any) => void;
}
export const EmailField: FC<EmailFieldProps & FieldProps<string, string>> = ({
  disabled = false,
  label = "common.email",
  size = "medium",
  margin = "normal",
  onBlur,
  onChange,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();

  const onBlurHandler = useCallback((e: FocusEvent<HTMLInputElement>) => {
    handleBlur(field.name)(e);
    typeof onBlur === "function" && onBlur(e);
  }, []);

  const onChangeHandler = useCallback((e: FocusEvent<HTMLInputElement>) => {
    handleChange(field.name)(e);
    typeof onChange === "function" && onChange(e);
  }, []);

  return (
    <>
      {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(field, null, 2)}</pre> */}

      <FormControl
        margin={margin}
        variant="outlined"
        fullWidth
        error={Boolean(
          getIn(touched, field.name) && !!getIn(errors, field.name)
        )}
      >
        <Field
          label={t(label)}
          margin={margin}
          variant="outlined"
          component={TextField}
          disabled={disabled}
          type="email"
          value={getIn(values, field.name)}
          error={Boolean(
            getIn(touched, field.name) && !!getIn(errors, field.name)
          )}
          helperText={
            getIn(touched, field.name) && t(getIn(errors, field.name))
          }
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          //   size={size}
        />
      </FormControl>
    </>
  );
};
