import { ApiEntity, ApiStageAdditionalDetail, STAGE_1_2_TYPE } from "../../../../../../models/entities"
import { AdditionalDetailsFormValues } from "."
import { ApiEnumeration, GENDER, INDUSTRY, LEGAL_ENTITY_TYPE } from "../../../../../../models/enumerations"
import { addressInitialValues, mapApiAddressToAddress } from "../../../../../../forms";
import { parseISO } from "date-fns";
import { Country, countries } from "../../../../../../utils/countries";

export const additionalDetailsFormValuesInitialValue = (
    entity: ApiEntity,
    enumerables: ApiEnumeration[]): AdditionalDetailsFormValues => {
    // Get Stage Data
    const stageData: ApiStageAdditionalDetail = entity.stages.find((s) => s.stageType === STAGE_1_2_TYPE) as ApiStageAdditionalDetail;
    // If draft is available use that
    const data: ApiStageAdditionalDetail = stageData.draftData ? stageData.draftData as ApiStageAdditionalDetail : stageData;
    return {

        stageType: STAGE_1_2_TYPE,
        stageStatus: data.stageStatus,
        id: entity.id,

        address: data.address ? mapApiAddressToAddress(data.address) : addressInitialValues(),
        // Legal Entity Type (Only shown for Entry Type 'Legal Entity')
        legalEntityType: data.legalEntityType ? enumerables.filter(x => x.type === LEGAL_ENTITY_TYPE).find(x => x.value === data.legalEntityType)! : null,
        // Registration No	 (Only shown for Entry Type 'Legal Entity')
        registrationNo: data.registrationNo || '',
        // Date of Incorporation/Registration (Only shown for Entry Type 'Legal Entity')
        dateOfIncorporation: data.dateOfIncorporation ? parseISO(data.dateOfIncorporation) : null,
        // Country of Incorporation	(Only shown for Entry Type 'Legal Entity')
        countryOfIncorporation: data.countryOfIncorporation ? countries.find(x => x.code === data.countryOfIncorporation) as Country : null,
        // Place of Birth (Only shown for Entry Type 'Natural Person')
        placeOfBirth: data.placeOfBirth ? countries.find(x => x.code === data.placeOfBirth) as Country : null,
        // Nationality (Only shown for Entry Type 'Natural Person')
        nationality: data.nationality ? data.nationality.map(n => countries.find(x => x.code === n) as Country) : [],
        // Country of Citizenship (Only shown for Entry Type 'Natural Person')
        countryOfCitizenship: data.countryOfCitizenship ? data.countryOfCitizenship.map(n => countries.find(x => x.code === n) as Country) : [],
        dateOfBirth: data.dateOfBirth ? parseISO(data.dateOfBirth) : null,
        gender: data.gender ? enumerables.filter(x => x.type === GENDER).find(x => x.value === data.gender)! : null,
        // Occupation/Profession (Only shown for Entry Type 'Natural Person')
        profession: data.profession || '',
        // Employer/Business (Only shown for Entry Type 'Natural Person')
        business: data.business || '',
        countryOfBusiness: data.countryOfBusiness ? countries.find(x => x.code === data.countryOfBusiness) as Country : null,
        industry: data.industry ? enumerables.filter(x => x.type === INDUSTRY).find(x => x.value === data.industry)! : null,
        vatNo: data.vatNo,
        // Tax Identification No
        taxIdentificationNumber: data.taxIdentificationNo,
        // NI Number (Only shown for 'Entry Type' Natural Person' and tagged as 'Potential Customer'/'Customer')
        niNumber: data.niNumber || '',
        // PE Number (Only shown for Tagged as 'Potential Customer'/'Customer')
        peNumber: data.peNumber,
        // Website
        website: data.website,
    }
}


// id: api.id,
// updateEntity:true,
// legalEntityType: api.legalEntityType ? enumerableLegalEntityTypes.find(x => x.value === api.legalEntityType)! : null,
// registrationNumber: api.registrationNo || '',
// dateOfIncorporation: api.dateOfIncorporation ? parseISO(api.dateOfIncorporation) : null,
// countryOfIncorporation: api.countryOfIncorporation ? countries.find(x => x.code === api.countryOfIncorporation) as Country : null,
// placeOfBirth: api.placeOfBirth ? countries.find(x => x.code === api.placeOfBirth) as Country : null,
// nationality: api.nationality ? api.nationality.map(n => countries.find(x => x.code === n) as Country) : [],
// countryOfCitizenship: api.countryOfCitizenship ? api.countryOfCitizenship.map(n => countries.find(x => x.code === n) as Country) : [],
// dateOfBirth: api.dateOfBirth ? parseISO(api.dateOfBirth) : null,
// gender: api.gender || '',
// profession: api.profession || '',
// business: api.business || '',
// countryOfBusiness: api.countryOfBusiness ? countries.find(x => x.code === api.countryOfBusiness) as Country : null,
// registeredAddress: api.registeredAddress ? mapApiAddressToAddress(api.registeredAddress) : addressInitialValues(),
// hasADifferentCorrespondingAddress: api.correspondenceAddress ? YES : NO,
// correspondenceAddress: api.correspondenceAddress ? mapApiAddressToAddress(api.correspondenceAddress) : addressInitialValues(),
// industry: api.industry ? enumerableIndustries.find(x => x.value === api.industry)! : null,
// valueAddedTaxNumber: api.vatNo,
// taxIdentificationNumber: api.taxIdentificationNo,
// nationalInsuranceNumber: api.niNumber || '',
// permissionToEmployeeNumber: api.peNumber,
// website: api.website,
// filledByEntityDuringKyc: false