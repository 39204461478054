import { Backdrop, Box, keyframes } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import {
  selectExpiresOn,
  useLogoutMutation,
  useRefreshTokenMutation,
} from "../../models/auth";
import { persistor } from "../../services/redux/store";

import { CountdownCircleTimer } from "./CountdownCircleTimer";
import { UserIdleConfig } from "./idle.config";
import { myworker } from "./idle.worker";
import { ColorFormat } from "./shared/types";

interface Props {
  config: UserIdleConfig;
}

const scaleIn = keyframes`
from {
  transform: scale(31, 31);
  opacity: .5;
},
to {
  transform: scale(2.5, 2.5);
  opacity: 0;
},
`;

const RenderTime = ({
  remainingTime,
  elapsedTime,
  color,
}: {
  remainingTime: number;
  elapsedTime: number;
  color: ColorFormat;
}) => {
  // console.log(remainingTime,elapsedTime);
  if (remainingTime === 0) {
    return (
      <Box
        component="div"
        sx={{
          // fontFamily: "Montserrat",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        Logging out
      </Box>
    );
  }

  return (
    <>
      {remainingTime < 6 && (
        <Box
          component="div"
          sx={{
            borderRadius: "50%",
            backgroundColor: "#2B2D2F",
            width: "5px",
            height: "5px",
            position: "absolute",
            opacity: "1",
            animation: `${scaleIn} 1s infinite cubic-bezier(.36, .11, .89, .32)`,
          }}
        ></Box>
      )}
      <Box
        component="div"
        sx={{
          // fontFamily: "Montserrat",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box component="div" sx={{ color: "white" }}>
          User idle
        </Box>
        <Box component="div" sx={{ fontSize: "20px" }}>
          Logging out in
        </Box>
        <Box component="div" sx={{ color: "white" }}>
          {remainingTime}
        </Box>
        <Box component="div" sx={{ color: "white" }}>
          seconds
        </Box>
      </Box>
    </>
  );
};

export const Idle: FC<Props> = (props) => {
  const [logout] = useLogoutMutation();
  const [refreshToken] = useRefreshTokenMutation();
  const [idleWarning, setIdleWarning] = useState(null);
  const dispatch = useDispatch();
  const expiresOn = useSelector(selectExpiresOn);

  useEffect(() => {
    if (expiresOn > 0 && Date.now() > expiresOn) {
      persistor.purge();
      logout();
    }
  }, [expiresOn, dispatch, logout]);

  useEffect(() => {
    let worker: Worker | undefined;

    const startWatching = () => {
      if (typeof Worker !== "undefined") {
        let code = myworker.toString();
        code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

        const blob = new Blob([code], { type: "application/javascript" });
        worker = new Worker(URL.createObjectURL(blob));
        // alert(worker);
        worker.onmessage = ({ data }) => {
          // console.log(`message: ${JSON.stringify(data)}`);
          switch (data.command) {
            case "ping":
              // refresh Token
              refreshToken();
              break;
            case "timeout":
              // we will not use its countdown
              // setIdleWarning(data.timeout);
              break;
            case "timeoutstart":
              setIdleWarning(data.timeoutValue);
              break;
            case "stop":
              // logout
              logout();
              // dispatch(resetCredentials());
              break;
            case "log":
              // Log command for debugging
              console.log(data.value);
              break;
            default:
              // Command not found
              break;
          }
        };
        worker.postMessage({ command: "start", ...props.config });
      } else {
        // Web workers are not supported in this environment.
        // You should add a fallback so that your program still executes correctly.
      }
    };

    const handleResetTimer = () => {
      // console.log(`should reset ${idleWarning || 'null'}`);
      // alert(idleWarning);
      // if (idleWarning) {
      // alert('reset idle warning');
      setIdleWarning(null);
      // refresh token
      // }
      resetTimer();
    };

    const terminate = () => {
      worker?.terminate();
    };

    const resetTimer = () => {
      if (typeof Worker !== "undefined") {
        worker?.postMessage({ command: "restart" });
      }
    };
    // console.log('on create');
    document.addEventListener("mousemove", handleResetTimer);
    document.addEventListener("keydown", handleResetTimer);
    startWatching();
    // Remove event listener on cleanup
    return () => {
      // console.log('on destroy');
      document.removeEventListener("mousemove", handleResetTimer);
      document.removeEventListener("keydown", handleResetTimer);
      terminate();
    };
  }, [dispatch, logout, props.config, refreshToken]);

  return (
    <div>
      {/* <div>
        <h1>Timeout: {props.config.timeout}ms</h1>
        <h1>Idle: {props.config.idle}ms</h1>
        <h1>Ping: {props.config.ping}ms</h1>
        <h1>Countdown: {idleWarning}</h1>
        <h1>Open:  {(idleWarning != null).toString()}</h1>
      </div> */}
      {/* {props.children} */}
      <Outlet />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={idleWarning != null}
      >
        {idleWarning != null && (
          <CountdownCircleTimer
            isPlaying
            duration={idleWarning as unknown as number}
            // colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
            colors={["#1976d2", "#ff9800", "#D14343"]}
            colorsTime={[30, 25, 0]}
            onComplete={() => ({ shouldRepeat: false, delay: 1 })}
          >
            {RenderTime}
          </CountdownCircleTimer>
        )}
      </Backdrop>
    </div>
  );
};

export default Idle;

// import React, { Component } from 'react';
// import { render } from 'react-dom';
// import Idle from './idle/Idle';
// import './style.css';

// interface AppProps {}
// interface AppState {
//   name: string;
// }

// class App extends Component<AppProps, AppState> {
//   constructor(props:AppProps) {
//     super(props);
//     this.state = {
//       name: 'React',
//     };
//   }

//   render() {
//     const config = { idle: 870, timeout: 30, ping: 60, debug: true };
//     const config = { idle: 10, timeout: 30, ping: 60, debug: true };

//     return (
//       <div>
//         <Idle config={config}>
//           <p>Start editing to see some magic happen :)</p>
//         </Idle>
//       </div>
//     );
//   }
// }

// render(<App />, document.getElementById('root'));
