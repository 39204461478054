import { Color, colors } from "@mui/material";
import { ReceiptStatus } from ".";


export const SUBMITTED_MANUALLY_RECEIPT_STATUS = 'SUBMITTED_MANUALLY';
export const CANCELLED_RECEIPT_STATUS = 'CANCELLED';
export const FINAL_RECEIPT_STATUS = 'FINAL';


export const receiptStatuses = [SUBMITTED_MANUALLY_RECEIPT_STATUS,
    CANCELLED_RECEIPT_STATUS,
    FINAL_RECEIPT_STATUS] as const;
export const receiptStatusesColours = [colors.orange, colors.red, colors.green];
export const receiptStatusesColourMap = new Map<ReceiptStatus, Color>(receiptStatuses.map((v, i) => [v, receiptStatusesColours[i]]));;
