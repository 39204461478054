import * as Yup from "yup";

const d = new Date();
d.setFullYear(d.getFullYear() - 100);


export const dateValidationSchema = (invalidValidationMessage?: string, requiredValidationMessage?: string) => Yup.date()
    .min(d, invalidValidationMessage || `validations.dateIsInvalid`)
    .required(requiredValidationMessage || 'validations.dateIsRequired')
    .nullable();



// export const dateOfBirthValidationSchema = () => Yup.date()
//     .max(new Date(), 'validations.dateOfBirthCannotBeInTheFuture')
//     .min(d, `validations.dateOfBirthIsInvalid`)
//     .required('validations.dateOfBirthIsRequired')
//     .nullable();



export const dateNoFutureValidationSchema = (invalidValidationMessage?: string, requiredValidationMessage?: string) => Yup.date()
    .max(new Date(), 'validations.dateCannotBeInTheFuture')
    .nullable();

