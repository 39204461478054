import React, { FC } from "react";
import { Permission } from "../../permissionGroups";

import { usePermissions } from "../hooks/usePermissions";

interface Props {
  permission: Permission;
  placeholder?: React.ReactNode;
  children?: React.ReactNode;
}

export const PermissionGuard: FC<Props> = ({
  children,
  permission,
  placeholder,
}) => {
  const { hasPermission } = usePermissions();

  return (
    <>
      {hasPermission(permission) || permission == null
        ? children
        : placeholder || null}
    </>
  );
};
