import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import { Box, Divider, IconButton, Typography } from "@mui/material";
import { Field, Formik, getIn } from "formik";
import React, { FC } from "react";
import toast from "react-hot-toast";

import {
  additionalDetailsFormValuesInitialValue,
  AdditionalDetailsFormValues,
  additionalDetailsFormValidationSchema,
} from ".";

import { useTranslation } from "react-i18next";

import {
  AccountManagerField,
  AddressField,
  BasicTextField,
  CountriesField,
  CountryField,
  DateField,
  DateOfBirthField,
  EntryTypeField,
  EnumerableField,
  EnumerablesField,
  LEGAL_ENTITY,
  NATURAL_PERSON,
  YES,
  YesNoField,
} from "../../../../../../forms";
import {
  ApiEnumeration,
  GENDER,
  INDUSTRY,
  LEGAL_ENTITY_TYPE,
  TAG,
  TITLE,
} from "../../../../../../models/enumerations";
import { usePermissions } from "../../../../../../models/auth";
import {
  PENDING_STAGE_STATUS,
  ApiEntity,
  ApiStageAdditionalDetail,
  DRAFT_STAGE_STATUS,
  mapAdditionalDetailsFormValuesToApiStageAdditionalDetails,
  useUpdateEntityStageMutation,
  STAGE_1_2_TYPE,
  PENDING_APPROVAL_STAGE_STATUS,
} from "../../../../../../models/entities";
import { ApiUserResponse } from "../../../../../../models/users";
interface AdditionalDetailsFormProps {
  entity: ApiEntity;
  enumerables: ApiEnumeration[];
  onClose: () => void;
  profile: ApiUserResponse | undefined;
}
export const AdditionalDetailsForm: FC<AdditionalDetailsFormProps> = ({
  entity,
  enumerables,
  onClose,
  profile,
}) => {
  const [updateEntityStage] = useUpdateEntityStageMutation();
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  return (
    <Formik<AdditionalDetailsFormValues>
      enableReinitialize
      initialValues={{
        ...additionalDetailsFormValuesInitialValue(entity, enumerables),
      }}
      validationSchema={additionalDetailsFormValidationSchema(
        hasPermission("ADMIN")
      )}
      onSubmit={async (values): Promise<void> => {
        try {
          const apiAdditionalDetails: ApiStageAdditionalDetail =
            mapAdditionalDetailsFormValuesToApiStageAdditionalDetails(
              entity,
              values
            );
          // const apiEntity: ApiStageBasicDetail =
          await updateEntityStage(apiAdditionalDetails).unwrap();

          toast.success(t("common.updatedSuccessfully"));
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }): JSX.Element => (
        <>
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "24px 38px 24px 32px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={"textPrimary"} variant="h5">
                {t("common.additionalDetails")}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: "32px" }}>
            <form noValidate onSubmit={handleSubmit}>
              {/* <Field
                name={`tags`}
                component={EnumerablesField}
                type={TAG}
                multiple
                margin="normal"
              />

              <Field
                name={`entryType`}
                component={EntryTypeField}
                margin="normal"
              />

              {getIn(values, "entryType") === LEGAL_ENTITY && (
                <Field
                  name={`legalEntityName`}
                  component={BasicTextField}
                  label="common.legalEntityName"
                  margin="normal"
                />
              )}

              {getIn(values, "entryType") === NATURAL_PERSON && (
                <>
                  <Field
                    name={`title`}
                    component={EnumerableField}
                    type={TITLE}
                    margin="normal"
                  />
                  <Field
                    name={`firstName`}
                    component={BasicTextField}
                    label="common.firstName"
                    margin="normal"
                  />
                  <Field
                    name={`lastName`}
                    component={BasicTextField}
                    label="common.lastName"
                    margin="normal"
                  />
                </>
              )}

              <Field
                name={`additionalComments`}
                component={BasicTextField}
                label="common.additionalComments"
                // size="small"
                margin="normal"
                info="common.refeeredByOrKnownAs"
              /> */}

              <Field
                name={"address"}
                label={"common.address"}
                component={AddressField}
              />

              {entity.entryType === LEGAL_ENTITY && (
                <>
                  <Field
                    name={`legalEntityType`}
                    component={EnumerableField}
                    type={LEGAL_ENTITY_TYPE}
                    margin="normal"
                  />

                  <Field
                    name={`registrationNumber`}
                    component={BasicTextField}
                    label="common.registrationNumber"
                    // size="small"
                    margin="normal"
                  />

                  <Field
                    name={"dateOfIncorporation"}
                    label="common.dateOfIncorporation"
                    component={DateField}
                  />

                  <Field
                    name={`countryOfIncorporation`}
                    component={CountryField}
                    label="common.countryOfIncorporation"
                    margin="normal"
                  />
                </>
              )}
              {entity.entryType === NATURAL_PERSON && (
                <>
                  <Field
                    name={`placeOfBirth`}
                    component={CountryField}
                    label="common.placeOfBirth"
                    margin="normal"
                  />

                  <Field
                    name={`nationality`}
                    component={CountriesField}
                    label="common.nationality"
                    margin="normal"
                    multiple
                  />

                  <Field
                    name={`countryOfCitizenship`}
                    component={CountriesField}
                    label="common.countryOfCitizenship"
                    margin="normal"
                    multiple
                  />
                  <Field name={"dateOfBirth"} component={DateOfBirthField} />

                  <Field
                    name={`gender`}
                    component={EnumerableField}
                    type={GENDER}
                    margin="normal"
                  />

                  <Field
                    name={`profession`}
                    component={BasicTextField}
                    label="common.occupationProfession"
                    margin="normal"
                  />

                  <Field
                    name={`business`}
                    component={BasicTextField}
                    label="common.employerBusiness"
                    margin="normal"
                  />

                  <Field
                    name={`countryOfBusiness`}
                    component={CountryField}
                    label="common.countryOfEmploymentBusiness"
                    margin="normal"
                  />

                  <Field
                    name={"registeredAddress"}
                    label={"common.residentialAddress"}
                    component={AddressField}
                  />
                </>
              )}

              <Field
                name={`industry`}
                component={EnumerableField}
                type={INDUSTRY}
                margin="normal"
              />

              <Field
                name={`vatNo`}
                component={BasicTextField}
                label="common.valueAddedTaxNumber"
                margin="normal"
              />
              <Field
                name={`taxIdentificationNumber`}
                component={BasicTextField}
                label="common.taxIdentificationNumber"
                margin="normal"
              />
              {getIn(values, "entryType") === NATURAL_PERSON && (
                <>
                  <Field
                    name={`niNumber`}
                    component={BasicTextField}
                    label="common.nationalInsuranceNumber"
                    margin="normal"
                  />
                </>
              )}
              <Field
                name={`peNumber`}
                component={BasicTextField}
                label="common.permissionToEmployeeNumber"
                margin="normal"
              />

              <Field
                name={`website`}
                component={BasicTextField}
                label="common.website"
                margin="normal"
              />

              <Box
                sx={{
                  mt: "24px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    setFieldValue("stageStatus", DRAFT_STAGE_STATUS);
                  }}
                >
                  {t("common.saveAsDraft")}
                </LoadingButton>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    // Get Stage Data
                    const stageData: ApiStageAdditionalDetail =
                      entity.stages.find(
                        (s) => s.stageType === STAGE_1_2_TYPE
                      ) as ApiStageAdditionalDetail;

                    if (
                      profile?.id === entity.reviewerId &&
                      stageData.stageStatus === PENDING_APPROVAL_STAGE_STATUS
                    ) {
                      setFieldValue(
                        "stageStatus",
                        PENDING_APPROVAL_STAGE_STATUS
                      );
                    } else {
                      setFieldValue("stageStatus", PENDING_STAGE_STATUS);
                    }
                  }}
                >
                  {t("common.submit")}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
