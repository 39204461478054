import { Color, colors } from "@mui/material";
import { ApiCddDoneType } from ".";

export const CDD_DONE_YES = 'YES'
export const CDD_DONE_NO = 'NO'
export const CDD_DONE_NOT_APPLICABLE = 'NOT_APPLICABLE'


export const cddDoneTypes = [CDD_DONE_YES, CDD_DONE_NO, CDD_DONE_NOT_APPLICABLE] as const;
export const cddDoneTypesColours = [colors.green, colors.red, colors.blueGrey];
export const cddDoneTypesColourMap = new Map<ApiCddDoneType, Color>(cddDoneTypes.map((v, i) => [v, cddDoneTypesColours[i]]));;
