import { Address } from "."

export const addressInitialValues = (address?: Address): Address => {
    return {
        addressLine1: '',
        addressLine2: '',
        town: '',
        postcode: '',
        country: null,
        ...address
    }
}