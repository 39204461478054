import * as Yup from "yup";

const d = new Date();
d.setFullYear(d.getFullYear() - 100);


export const yearValidationSchema = () => Yup.date()
    .min(d, `validations.yearIsInvalid`)
    .required('validations.yearIsRequired')
    .nullable();



// export const dateOfBirthValidationSchema = () => Yup.date()
//     .max(new Date(), 'validations.dateOfBirthCannotBeInTheFuture')
//     .min(d, `validations.dateOfBirthIsInvalid`)
//     .required('validations.dateOfBirthIsRequired')
//     .nullable();

