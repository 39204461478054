import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import Idle from "../components/idle/Idle";
import DashboardLayout from "../components/Layouts/Dashboard/DashboardLayout";
import { AuthGuard } from "../models/auth";
import { AuthenticationRoutes } from "../screens/Authentication";
import { PermissionGroupsRoutes } from "../screens/PermissionGroups";
import { EntitiesRoutes } from "../screens/Entities";
import { TenantInputRoutes } from "../screens/TenantInput";
import { TenantsRoutes } from "../screens/Tenants";
import { UsersRoutes } from "../screens/Users";
import { LazyLoadableScreen } from "./LazyLoadableScreen";
import { BusinessContactsRoutes } from "../screens/BusinessContacts";
import { EnumerationsRoutes } from "../screens/Enumerations";
import { enumerationDataMap } from "../screens/Enumerations/utils";
import { BillersRoutes } from "../screens/Billers";
import { TimesheetsRoutes } from "../screens/Timesheets";
import { ApprovalsRoutes } from "../screens/Approvals";
import { DashboardRoutes } from "../screens/Dashboard";
import { ServicesRoutes } from "../screens/Services";
import { SignatoriesRoutes } from "../screens/Signatories";

const NotFoundScreen = LazyLoadableScreen(() => import("../screens/NotFound"));

// const DemoScreen = LazyLoadableScreen(() => import("../screens/Demo"));

const ManageProfileScreen = LazyLoadableScreen(
  () => import("../screens/ManageProfile")
);

const config = { idle: 870, timeout: 30, ping: 400, debug: false };

export const Navigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:tenantNameOrId" element={<Outlet />}>
          <Route path="auth/*" element={<AuthenticationRoutes />} />
          <Route element={<Idle config={config} />}>
            <Route element={<DashboardLayout />}>
              <Route
                path="dashboard/*"
                element={
                  <AuthGuard>
                    <DashboardRoutes />
                  </AuthGuard>
                }
              />
              <Route
                path="users/*"
                element={
                  <AuthGuard>
                    <UsersRoutes />
                  </AuthGuard>
                }
              />
              <Route
                path="tenants/*"
                element={
                  <AuthGuard>
                    <TenantsRoutes />
                  </AuthGuard>
                }
              />
              <Route
                path="permission-groups/*"
                element={
                  <AuthGuard>
                    <PermissionGroupsRoutes />
                  </AuthGuard>
                }
              />
              <Route
                path="entities/*"
                element={
                  <AuthGuard>
                    <EntitiesRoutes />
                  </AuthGuard>
                }
              />
              <Route
                path="business-contacts/*"
                element={
                  <AuthGuard>
                    <BusinessContactsRoutes />
                  </AuthGuard>
                }
              />
              <Route
                path="billers/*"
                element={
                  <AuthGuard>
                    <BillersRoutes />
                  </AuthGuard>
                }
              />
              <Route
                path="timesheets/*"
                element={
                  <AuthGuard>
                    <TimesheetsRoutes />
                  </AuthGuard>
                }
              />
              <Route
                path="approvals/*"
                element={
                  <AuthGuard>
                    <ApprovalsRoutes />
                  </AuthGuard>
                }
              />
              <Route
                path="manage-profile"
                element={
                  <AuthGuard>
                    <ManageProfileScreen />
                  </AuthGuard>
                }
              />

              <Route
                path="services/*"
                element={
                  <AuthGuard>
                    <ServicesRoutes />
                  </AuthGuard>
                }
              />

              <Route
                path="signatories/*"
                element={
                  <AuthGuard>
                    <SignatoriesRoutes />
                  </AuthGuard>
                }
              />

              {Array.from(enumerationDataMap.keys()).map((key) =>
                enumerationDataMap.get(key).show ? (
                  <Route
                    key={key}
                    path={`${key.toLowerCase().replaceAll("_", "-")}/*`}
                    element={
                      <AuthGuard>
                        <EnumerationsRoutes type={key} key={key} />
                      </AuthGuard>
                    }
                  />
                ) : null
              )}

              {/* <Route
                path="titles/*"
                element={
                  <AuthGuard>
                    <EnumerationsRoutes type={TITLE} key={TITLE} />
                  </AuthGuard>
                }
              /> */}
            </Route>
          </Route>

          <Route path="404" element={<NotFoundScreen />} />
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route path="*" element={<Navigate to="404" replace />} />
        </Route>
        <Route path="*" element={<TenantInputRoutes />} />
      </Routes>
    </BrowserRouter>
  );
};
