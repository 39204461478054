import * as Yup from "yup";

export const serviceValidationSchema = (validationMessage?: string) =>
    Yup.object()
        .required(validationMessage || 'validations.serviceIsRequired')
        .nullable()
        .test(
            'null-check',
            validationMessage || 'validations.serviceIsRequired',
            (service) => service != null,
        )



