import { AdditionalDetailsFormValues, AmlFormValues, BasicDetailsFormValues, BusinessActivityFormValues, CDDInformationFormValues, ContactDetailsFormValues, ContactPersonFormValues, CreateEntityFormValues, EngagementFormValues, EntityFilter, EntityRelationshipFormValues, InterfaceDistributionFormValues, InvestmentFormValues, OwnershipControlFormValues, PepSanctionsFormValues, SendForApprovalFormValues, SourceOfFundsFormValues, SourceOfWealthFormValues, TerminationFormValues, UpdateEntityFormValues } from "../../business/Entities/forms";
import {
    ApiContactPerson,
    ApiCreateEntity,
    ApiEntity,
    ApiEntityFilter,
    ApiEntityRelationship,
    ApiInvestment,
    ApiStageAdditionalDetail,
    ApiStageAml,
    ApiStageBasicDetail,
    ApiStageBusinessActivity,
    ApiStageCdd,
    ApiStageContactDetail,
    ApiStageEngagement,
    ApiStageInterfaceDistribution,
    ApiStageOwnershipControl,
    ApiStagePepSanctions,
    ApiStageSourceOfFunds,
    ApiStageSourceOfWealth,
    ApiStageTermination,
    ApiUpdateEntity,
    CUSTOMER,
    POTENTIAL_CUSTOMER,
    STAGE_1_2_TYPE,
} from ".";
import { ApiCddDoneType, ApiOnboardingType, ApiOwnershipType, ApiTradingOrHolding, HOLDING, LEGAL_ENTITY, mapAddressToApiAddress, mapMoneyToApiMoney, mapPhoneToApiPhoneNumber, NATURAL_PERSON, NO, PENDING_APPROVAL_CLIENT_STATUS, YES, yesNoToBoolean } from "../../forms";

import { format } from "date-fns";


export const mapEntityFilterToApiEntityFilter = (
    dto: EntityFilter
): ApiEntityFilter => ({
    id: dto.id,
    tenantId: dto.tenantId,
    tags: dto.tags?.value!,
    title: dto.title?.value!,
    firstName: dto.firstName,
    lastName: dto.lastName,
    legalEntityName: dto.legalEntityName,
    entryType: dto.entryType,
    additionalComments: dto.additionalComments,
    status: dto.status,
    refNo: dto.refNo,
    legacyId: dto.legacyId,
    reviewerId: dto.reviewer?.id || "",
    createdByUserId: dto.createdBy?.id || "",
    updatedByUserId: dto.updatedBy?.id || "",
})

export const mapCreateEntityFormValuesToApiCreateEntity = (
    dto: CreateEntityFormValues
): ApiCreateEntity => ({
    tags: dto.tags.map(s => s.value).join(','),
    entryType: dto.entryType,
    title: dto.entryType === NATURAL_PERSON ? dto.title?.value! : null,
    firstName: dto.entryType === NATURAL_PERSON ? dto.firstName : null,
    lastName: dto.entryType === NATURAL_PERSON ? dto.lastName : null,
    legalEntityName: dto.entryType === LEGAL_ENTITY ? dto.legalEntityName : null,
    additionalComments: dto.additionalComments,
    tenantIdForHeader: dto.tenantIdForHeader
});

export const mapUpdateEntityFormValuesToApiUpdateEntity = (
    dto: UpdateEntityFormValues
): ApiUpdateEntity => ({
    id: dto.id,
    tags: dto.tags.map(s => s.value).join(','),
    entryType: dto.entryType,
    title: dto.entryType === NATURAL_PERSON ? dto.title?.value! : null,
    firstName: dto.entryType === NATURAL_PERSON ? dto.firstName : null,
    lastName: dto.entryType === NATURAL_PERSON ? dto.lastName : null,
    legalEntityName: dto.entryType === LEGAL_ENTITY ? dto.legalEntityName : null,
    additionalComments: dto.additionalComments,
    reviewerId: dto.reviewer?.id!,
    status: dto.status
});


export const mapSendForApprovalFormValuesToApiUpdateEntity = (
    dto: SendForApprovalFormValues
): ApiUpdateEntity => ({
    id: dto.id,
    tags: dto.tags.map(s => s.value).join(','),
    entryType: dto.entryType,
    title: dto.entryType === NATURAL_PERSON ? dto.title?.value! : null,
    firstName: dto.entryType === NATURAL_PERSON ? dto.firstName : null,
    lastName: dto.entryType === NATURAL_PERSON ? dto.lastName : null,
    legalEntityName: dto.entryType === LEGAL_ENTITY ? dto.legalEntityName : null,
    additionalComments: dto.additionalComments,
    reviewerId: dto.reviewer?.id!,
    status: PENDING_APPROVAL_CLIENT_STATUS
});

export const mapBasicDetailsFormValuesToApiStageBasicDetails = (dto: BasicDetailsFormValues): ApiStageBasicDetail => ({
    id: dto.id,
    tags: dto.tags.map(s => s.value).join(','),
    entryType: dto.entryType,
    title: dto.entryType === NATURAL_PERSON ? dto.title?.value! : null,
    firstName: dto.entryType === NATURAL_PERSON ? dto.firstName : null,
    lastName: dto.entryType === NATURAL_PERSON ? dto.lastName : null,
    legalEntityName: dto.entryType === LEGAL_ENTITY ? dto.legalEntityName : null,
    additionalComments: dto.additionalComments,
    stageType: dto.stageType,
    stageStatus: dto.stageStatus,
    draftData: null,
    accountManagerId: dto.accountManager ? dto.accountManager.id : null,
});


export const mapAdditionalDetailsFormValuesToApiStageAdditionalDetails = (entity: ApiEntity, dto: AdditionalDetailsFormValues): ApiStageAdditionalDetail => ({
    id: dto.id,

    legalEntityType: entity.entryType === LEGAL_ENTITY ? dto.legalEntityType?.value! : null,
    registrationNo: entity.entryType === LEGAL_ENTITY ? dto.registrationNo : null,
    dateOfIncorporation: entity.entryType === LEGAL_ENTITY ? format(dto.dateOfIncorporation!, 'yyyy-MM-dd') : null,
    countryOfIncorporation: dto.countryOfIncorporation?.code as string,
    placeOfBirth: entity.entryType === NATURAL_PERSON ? dto.placeOfBirth?.code as string : null,
    nationality: entity.entryType === NATURAL_PERSON ? dto.nationality.map(c => c.code) : null,
    countryOfCitizenship: entity.entryType === NATURAL_PERSON ? dto.countryOfCitizenship.map(c => c.code) : null,
    dateOfBirth: entity.entryType === NATURAL_PERSON ? format(dto.dateOfBirth!, 'yyyy-MM-dd') : null,
    gender: entity.entryType === NATURAL_PERSON ? dto.gender?.value! : null,
    profession: entity.entryType === NATURAL_PERSON ? dto.profession : null,
    business: entity.entryType === NATURAL_PERSON ? dto.business : null,
    countryOfBusiness: entity.entryType === NATURAL_PERSON ? dto.countryOfBusiness?.code as string : null,
    address: mapAddressToApiAddress(dto.address),
    // correspondenceAddress: dto.hasADifferentCorrespondingAddress === YES ? mapAddressToApiAddress(dto.correspondenceAddress) : null,
    industry: dto.industry?.value!,
    vatNo: dto.vatNo,
    taxIdentificationNo: dto.taxIdentificationNumber,
    niNumber: entity.entryType === NATURAL_PERSON ? dto.niNumber : null,
    peNumber: dto.peNumber,
    website: dto.website,


    stageType: dto.stageType,
    stageStatus: dto.stageStatus,
    draftData: null

});



export const mapContactPersonFormValuesToApiContactPerson = (
    dto: ContactPersonFormValues
): ApiContactPerson => ({
    id: dto.id,
    firstName: dto.firstName,
    lastName: dto.lastName,
    designation: dto.designation,
    email: dto.email,
    contactNumber: mapPhoneToApiPhoneNumber(dto.contactNumber)
});



export const mapContactDetailsFormValuesToApiStageContactDetails = (entity: ApiEntity, dto: ContactDetailsFormValues): ApiStageContactDetail => ({
    id: dto.id,
    registeredAddress: mapAddressToApiAddress(dto.registeredAddress),
    correspondenceAddress: dto.hasADifferentCorrespondingAddress === YES ? mapAddressToApiAddress(dto.correspondenceAddress) : null,
    contactPersons: dto.contactPersons.map(contactPerson => mapContactPersonFormValuesToApiContactPerson(contactPerson)),

    stageType: dto.stageType,
    stageStatus: dto.stageStatus,
    draftData: null

});




export const mapCDDInformationFormValuesToApiStageCDDInformation = (entity: ApiEntity, dto: CDDInformationFormValues): ApiStageCdd => ({
    id: dto.id,

    identityDocumentType: dto.identityDocumentType.map(a => a.value).join(','),
    idNo: dto.idNo.length > 0 ? dto.idNo : null,
    documentNo: dto.idNo.length > 0 ? dto.idNo : null,
    documentExpiryDate: dto.documentExpiryDate ? format(dto.documentExpiryDate!, 'yyyy-MM-dd') : null,
    documentCountry: dto.documentCountry?.code!,
    proofOfAddress: dto.proofOfAddress ? dto.proofOfAddress.map(a => a.value).join(',') : null,
    proofOfAddressDate: dto.proofOfAddressDate ? format(dto.proofOfAddressDate!, 'yyyy-MM-dd') : null,
    certificateIncorporation: dto.certificateIncorporation.length > 0 ? dto.certificateIncorporation as ApiCddDoneType : null,
    memorandumAndArticles: dto.memorandumAndArticles.length > 0 ? dto.memorandumAndArticles as ApiCddDoneType : null,
    vatRegCertificate: dto.vatRegCertificate.length > 0 ? dto.vatRegCertificate as ApiCddDoneType : null,
    beneficialOwnersDocument: dto.beneficialOwnersDocument.length > 0 ? dto.beneficialOwnersDocument as ApiCddDoneType : null,
    certificateGoodStanding: dto.certificateGoodStanding.length > 0 ? dto.certificateGoodStanding as ApiCddDoneType : null,
    certificateIncumbency: dto.certificateIncumbency.length > 0 ? dto.certificateIncumbency as ApiCddDoneType : null,
    otherDocumentationCollected: dto.otherDocumentationCollected.length > 0 ? dto.otherDocumentationCollected : null,
    curriculumVitae: dto.curriculumVitae.length > 0 ? dto.curriculumVitae as ApiCddDoneType : null,
    curriculumVitaeDate: dto.curriculumVitaeDate ? format(dto.curriculumVitaeDate!, 'yyyy-MM-dd') : null,
    groupStructure: dto.groupStructure.length > 0 ? dto.groupStructure as ApiCddDoneType : null,
    hasPepScreeningConducted: dto.hasPepScreeningConducted.length > 0 ? yesNoToBoolean(dto.hasPepScreeningConducted) : null,
    pepScreeningConductedDate: dto.pepScreeningConductedDate ? format(dto.pepScreeningConductedDate!, 'yyyy-MM-dd') : null,
    hasPep: dto.hasPep.length > 0 ? yesNoToBoolean(dto.hasPep) : null,
    pepInfo: dto.pepInfo.length > 0 ? dto.pepInfo : null,
    hasSanctions: dto.hasSanctions.length > 0 ? yesNoToBoolean(dto.hasSanctions) : null,
    sanctionsInfo: dto.hasPepScreeningConducted === YES ? dto.sanctionsInfo : null,
    hasAdverseMedia: dto.hasAdverseMedia.length > 0 ? yesNoToBoolean(dto.hasAdverseMedia) : null,
    adverseMediaInfo: dto.hasAdverseMedia === YES ? dto.adverseMediaInfo : null,
    hasLawEnforcement: dto.hasLawEnforcement.length > 0 ? yesNoToBoolean(dto.hasLawEnforcement) : null,
    lawEnforcementInfo: dto.hasLawEnforcement === YES ? dto.lawEnforcementInfo : null,
    hasDisqualifiedDirector: dto.hasDisqualifiedDirector.length > 0 ? yesNoToBoolean(dto.hasDisqualifiedDirector) : null,
    disqualifiedDirectorInfo: dto.hasDisqualifiedDirector === YES ? dto.disqualifiedDirectorInfo : null,
    hasInsolvent: dto.hasInsolvent.length > 0 ? yesNoToBoolean(dto.hasInsolvent) : null,
    insolventInfo: dto.hasInsolvent === YES ? dto.insolventInfo : null,
    actionsTakenTrueMatch: dto.actionsTakenTrueMatch.length > 0 ? dto.actionsTakenTrueMatch : null,
    hasAdverseMediaSearchInternet: dto.hasAdverseMediaSearchInternet.length > 0 ? yesNoToBoolean(dto.hasAdverseMediaSearchInternet) : null,
    adverseMediaSearchInternetDate: dto.adverseMediaSearchInternetDate ? format(dto.adverseMediaSearchInternetDate!, 'yyyy-MM-dd') : null,
    hasAdverseMediaSearchAssetRecoveryBureau: dto.hasAdverseMediaSearchAssetRecoveryBureau.length > 0 ? yesNoToBoolean(dto.hasAdverseMediaSearchAssetRecoveryBureau) : null,
    adverseMediaSearchAssetRecoveryBureauDate: dto.adverseMediaSearchAssetRecoveryBureauDate ? format(dto.adverseMediaSearchAssetRecoveryBureauDate!, 'yyyy-MM-dd') : null,
    hasAdverseMediaSearchJudgementCourt: dto.hasAdverseMediaSearchJudgementCourt.length > 0 ? yesNoToBoolean(dto.hasAdverseMediaSearchJudgementCourt) : null,
    adverseMediaSearchJudgementCourtDate: dto.adverseMediaSearchJudgementCourtDate ? format(dto.adverseMediaSearchJudgementCourtDate!, 'yyyy-MM-dd') : null,
    hasAdverseMediaFoundInternet: dto.hasAdverseMediaFoundInternet.length > 0 ? yesNoToBoolean(dto.hasAdverseMediaFoundInternet) : null,
    adverseMediaFoundInternetInfo: dto.adverseMediaFoundInternetInfo || null,
    hasAdverseMediaFoundAssetRecoveryBureau: dto.hasAdverseMediaFoundAssetRecoveryBureau.length > 0 ? yesNoToBoolean(dto.hasAdverseMediaFoundAssetRecoveryBureau) : null,
    adverseMediaFoundAssetRecoveryBureauInfo: dto.adverseMediaFoundAssetRecoveryBureauInfo || null,
    hasAdverseMediaFoundJudgementCourt: dto.hasAdverseMediaFoundJudgementCourt.length > 0 ? yesNoToBoolean(dto.hasAdverseMediaFoundJudgementCourt) : null,
    adverseMediaFoundJudgementCourtInfo: dto.adverseMediaFoundJudgementCourtInfo || null,
    adverseMediaSummary: dto.adverseMediaSummary.length > 0 ? dto.adverseMediaSummary : null,
    boInformationProof: dto.boInformationProof.length > 0 ? dto.boInformationProof as ApiCddDoneType : null,
    boInformationProofDate: dto.boInformationProofDate ? format(dto.boInformationProofDate!, 'yyyy-MM-dd') : null,
    otherMeasures: dto.otherMeasures.length > 0 ? dto.otherMeasures : null,

    hasPoliceConductCertificate: yesNoToBoolean(dto.hasPoliceConductCertificate),
    policeConductCertificateDate: dto.hasPoliceConductCertificate === YES ? format(dto.policeConductCertificateDate!, 'yyyy-MM-dd') : null,
    hasBankReference: yesNoToBoolean(dto.hasBankReference),
    bankReferenceDate: dto.hasBankReference === YES ? format(dto.bankReferenceDate!, 'yyyy-MM-dd') : null,
    hasProfessionalReference: yesNoToBoolean(dto.hasProfessionalReference),
    professionalReferenceDate: dto.hasProfessionalReference === YES ? format(dto.professionalReferenceDate!, 'yyyy-MM-dd') : null,
    otherEddMeasures: dto.otherEddMeasures,
    hasObtainedMlroApproval: yesNoToBoolean(dto.hasObtainedMlroApproval),


    stageType: dto.stageType,
    stageStatus: dto.stageStatus,
    draftData: null

});

export const mapInvestmentFormValuesToApiInvestment = (
    dto: InvestmentFormValues
): ApiInvestment => ({
    id: dto.id,
    type: dto.type?.value!,
    additionalInfo: dto.additionalInfo,
    jurisdiction: dto.jurisdiction?.value!
});

export const mapBusinessActivityFormValuesToApiStageBusinessActivity = (entity: ApiEntity, dto: BusinessActivityFormValues): ApiStageBusinessActivity => ({
    id: dto.id,
    sector: dto.sector?.value!,
    licenseCategory: dto.licenseCategory?.value!,
    licenseNumber: dto.licenseNumber,
    nameOfRegulator: dto.nameOfRegulator?.value!,
    jurisdictionOfRegulator: dto.jurisdictionOfRegulator?.value!,
    typeOfListing: dto.typeOfListing?.value!,
    tradingActivities: entity.entryType === NATURAL_PERSON && (entity.tags.includes(POTENTIAL_CUSTOMER) || entity.tags.includes(CUSTOMER)) ? yesNoToBoolean(dto.tradingActivities) : null,
    transferredFromAnotherJurisdiction: entity.entryType === LEGAL_ENTITY && (entity.tags.includes(POTENTIAL_CUSTOMER) || entity.tags.includes(CUSTOMER)) ? yesNoToBoolean(dto.transferredFromAnotherJurisdiction) : null,
    transferredFromJurisdiction: dto.transferredFromAnotherJurisdiction === YES ? dto.transferredFromJurisdiction?.value! : null,
    transferredToAnotherJurisdiction: entity.entryType === LEGAL_ENTITY && (entity.tags.includes(POTENTIAL_CUSTOMER) || entity.tags.includes(CUSTOMER)) ? yesNoToBoolean(dto.transferredToAnotherJurisdiction) : null,
    transferredToJurisdiction: dto.transferredToAnotherJurisdiction === YES ? dto.transferredToJurisdiction?.value! : null,
    tradingOrHolding: entity.entryType === LEGAL_ENTITY && (entity.stages.find((s) => s.stageType === STAGE_1_2_TYPE) as ApiStageAdditionalDetail).legalEntityType === "LIMITED_LIABILITY_COMPANY" && (entity.tags.includes(POTENTIAL_CUSTOMER) || entity.tags.includes(CUSTOMER)) ? dto.tradingOrHolding as ApiTradingOrHolding : null,
    investments: entity.entryType === LEGAL_ENTITY && (entity.stages.find((s) => s.stageType === STAGE_1_2_TYPE) as ApiStageAdditionalDetail).legalEntityType === "LIMITED_LIABILITY_COMPANY" && (entity.tags.includes(POTENTIAL_CUSTOMER) || entity.tags.includes(CUSTOMER)) && dto.tradingOrHolding === HOLDING ? dto.investments.map(investment => mapInvestmentFormValuesToApiInvestment(investment)) : [],
    differentRegisteredAddress: dto.differentRegisteredAddress != null ? yesNoToBoolean(dto.differentRegisteredAddress) : null,
    highRiskIndustries: dto.highRiskIndustries.length > 0 ? dto.highRiskIndustries.map(a => a.value).join(',') : null,
    cashIntensiveBusiness: yesNoToBoolean(dto.cashIntensiveBusiness),
    cashIntensiveBusinessInfo: dto.cashIntensiveBusinessInfo,
    dualUseItems: yesNoToBoolean(dto.dualUseItems),
    dualUseItemsInfo: dto.dualUseItemsInfo,
    businessActivitiesDescription: dto.businessActivitiesDescription,
    expectedTotalIncome: mapMoneyToApiMoney(dto.expectedTotalIncome),
    activitiesTakingPlaceIn: dto.activitiesTakingPlaceIn.map(a => a.value).join(','),
    fundsHeld: yesNoToBoolean(dto.fundsHeld),
    fundsHeldInfo: dto.fundsHeld === YES ? dto.fundsHeldInfo : null,
    yearEnd: dto.fundsHeld === YES ? format(dto.yearEnd!, 'yyyy-MM-dd') : null,
    amountAtYearEnd: dto.fundsHeld === YES ? mapMoneyToApiMoney(dto.amountAtYearEnd) : {
        value: null,
        currency: null
    },
    bankingArrangements: yesNoToBoolean(dto.bankingArrangements),
    bankName: dto.bankName,
    bankJurisdiction: dto.bankingArrangements === YES ? dto.bankJurisdiction?.value! : null,
    bankFacilitiesHeld: yesNoToBoolean(dto.bankFacilitiesHeld),
    bankFacilitiesHeldInfo: dto.bankFacilitiesHeldInfo,
    authorizedBankRepresentatives: dto.authorizedBankRepresentatives,
    authorizedBankSignatories: dto.authorizedBankSignatories,
    raisedCapital: yesNoToBoolean(dto.raisedCapital),
    amountOfCapitalRaised: dto.raisedCapital === YES ? mapMoneyToApiMoney(dto.amountOfCapitalRaised) : {
        value: null,
        currency: null
    },
    holdsVirtualFinancialAssets: yesNoToBoolean(dto.holdsVirtualFinancialAssets),
    transactionsVirtualFinancialAssets: yesNoToBoolean(dto.transactionsVirtualFinancialAssets),

    stageType: dto.stageType,
    stageStatus: dto.stageStatus,
    draftData: null
});



export const mapEntityRelationshipFormValuesToApiEntityRelationship = (entity: ApiEntity, dto: EntityRelationshipFormValues): ApiEntityRelationship => ({
    id: dto.id,
    tenantId: dto.tenantId,
    entityId: dto.entityId,
    relatedEntityId: dto.relatedEntity?.id!,
    relationship: dto.relationship?.value!,
    layerOfOwnership: dto.layerOfOwnership?.value!,
    extentOfOwnership: dto.extentOfOwnership,
    natureOfOwnership: dto.natureOfOwnership.length > 0 ? dto.natureOfOwnership as ApiOwnershipType : null,
    indirectOwnershipDesc: dto.indirectOwnershipDesc,
    influenceDescription: dto.influenceDescription,
    hasSchemeBenefits: yesNoToBoolean(dto.hasSchemeBenefits),
    schemeDetails: dto.schemeDetails ? dto.schemeDetails?.value : null,
    schemeStatus: dto.schemeStatus ? dto.schemeStatus?.value : null,

});

export const mapOwnershipControlFormValuesToApiStageOwnershipControl = (entity: ApiEntity, dto: OwnershipControlFormValues): ApiStageOwnershipControl => ({
    id: dto.id,
    hasLegalArrangements: yesNoToBoolean(dto.hasLegalArrangements),
    hasNonProfitEntities: yesNoToBoolean(dto.hasNonProfitEntities),
    hasShellCompanies: yesNoToBoolean(dto.hasShellCompanies),
    hasNonCooperativeJurisdictions: yesNoToBoolean(dto.hasNonCooperativeJurisdictions),
    relatedEntities: dto.relatedEntities.map(relatedEntity => mapEntityRelationshipFormValuesToApiEntityRelationship(entity, relatedEntity)),


    stageType: dto.stageType,
    stageStatus: dto.stageStatus,
    draftData: null
});

export const mapPepSanctionsFormValuesToApiStagePepSanctions = (entity: ApiEntity, dto: PepSanctionsFormValues): ApiStagePepSanctions => ({
    id: dto.id,
    hasPep: yesNoToBoolean(dto.hasPep),
    pepRelationship: dto.hasPep === YES ? dto.pepRelationship?.value! : null,
    pepFirstName: dto.hasPep === YES && ['FAMILY_MEMBER', 'CLOSE_ASSOCIATE_TO_A_PEP'].includes(dto.pepRelationship?.value!) ? dto.pepFirstName : null,
    pepLastName: dto.hasPep === YES && ['FAMILY_MEMBER', 'CLOSE_ASSOCIATE_TO_A_PEP'].includes(dto.pepRelationship?.value!) ? dto.pepLastName : null,
    pepPublicPosition: dto.hasPep === YES ? dto.pepPublicPosition : null,
    pepPublicEntity: dto.hasPep === YES ? dto.pepPublicEntity : null,
    pepStartDate: dto.hasPep === YES ? format(dto.pepStartDate!, 'yyyy-MM-dd') : null,
    hasFreezingOrders: yesNoToBoolean(dto.hasFreezingOrders),
    freezingOrdersInfo: dto.hasFreezingOrders === YES ? dto.freezingOrdersInfo : null,
    hasSanctionedCountryDealings: yesNoToBoolean(dto.hasSanctionedCountryDealings),
    sanctionedCountryDealingsInfo: dto.hasSanctionedCountryDealings === YES ? dto.sanctionedCountryDealingsInfo : null,
    hasCriminalOffence: yesNoToBoolean(dto.hasCriminalOffence),
    criminalOffenceInfo: dto.hasCriminalOffence === YES ? dto.criminalOffenceInfo : null,
    hasSubjectToRegulatoryActions: yesNoToBoolean(dto.hasSubjectToRegulatoryActions),
    subjectToRegulatoryActionsInfo: dto.hasSubjectToRegulatoryActions === YES ? dto.subjectToRegulatoryActionsInfo : null,
    hasRegulatoryActionResolved: yesNoToBoolean(dto.hasRegulatoryActionResolved),
    regulatoryActionResolvedInfo: dto.hasRegulatoryActionResolved === YES ? dto.regulatoryActionResolvedInfo : null,
    stageType: dto.stageType,
    stageStatus: dto.stageStatus,
    draftData: null
});


export const mapInterfaceDistributionFormValuesToApiStageInterfaceDistribution = (entity: ApiEntity, dto: InterfaceDistributionFormValues): ApiStageInterfaceDistribution => ({
    id: dto.id,
    onboardingType: dto.onboardingType.length > 0 ? dto.onboardingType as ApiOnboardingType : null,
    introducedBy: dto.introducedBy?.value!,
    dealingDirectlyWith: dto.dealingDirectlyWith?.value!,
    hasPoaAuthorized: yesNoToBoolean(dto.hasPoaAuthorized),
    hasPoaDocumentCollected: yesNoToBoolean(dto.hasPoaDocumentCollected),
    hasReasonableRationale: yesNoToBoolean(dto.hasReasonableRationale),
    reasonableRationaleInfo: dto.hasReasonableRationale === YES ? dto.reasonableRationaleInfo : null,
    hasRelianceAgreementThirdPartyCdd: yesNoToBoolean(dto.hasRelianceAgreementThirdPartyCdd),
    hasRelianceAgreementSigned: yesNoToBoolean(dto.hasRelianceAgreementSigned),


    stageType: dto.stageType,
    stageStatus: dto.stageStatus,
    draftData: null
});

// 2.6


export const mapSourceOfWealthFormValuesToApiStageSourceOfWealth = (entity: ApiEntity, dto: SourceOfWealthFormValues): ApiStageSourceOfWealth => ({
    id: dto.id,
    hasHighNetWorth: yesNoToBoolean(dto.hasHighNetWorth),
    hasSowCollated: yesNoToBoolean(dto.hasSowCollated),
    sowCollatedInfo: dto.hasSowCollated === YES ? dto.sowCollatedInfo : null,
    estimatedNetWorth: dto.estimatedNetWorth?.value!,
    hasNewlyEstablishedEntity: yesNoToBoolean(dto.hasNewlyEstablishedEntity),
    newlyEstablishedEntityInfo: dto.hasNewlyEstablishedEntity === YES ? dto.newlyEstablishedEntityInfo : null,
    hasSavingsFromSalary: yesNoToBoolean(dto.hasSavingsFromSalary),
    savingsFromSalaryInfo: dto.hasSavingsFromSalary === YES ? dto.savingsFromSalaryInfo : null,
    hasCompanyProfits: yesNoToBoolean(dto.hasCompanyProfits),
    companyProfitsInfo: dto.hasCompanyProfits === YES ? dto.companyProfitsInfo : null,

    hasSaleOfProperty: yesNoToBoolean(dto.hasSaleOfProperty),
    saleOfPropertyInfo: dto.hasSaleOfProperty === YES ? dto.saleOfPropertyInfo : null,

    hasRentalOfProperty: yesNoToBoolean(dto.hasRentalOfProperty),
    rentalOfPropertyInfo: dto.hasRentalOfProperty === YES ? dto.rentalOfPropertyInfo : null,

    hasCompanySale: yesNoToBoolean(dto.hasCompanySale),
    companySaleInfo: dto.hasCompanySale === YES ? dto.companySaleInfo : null,

    hasInheritance: yesNoToBoolean(dto.hasInheritance),
    inheritanceInfo: dto.hasInheritance === YES ? dto.inheritanceInfo : null,

    hasDivorceSettlement: yesNoToBoolean(dto.hasDivorceSettlement),
    divorceSettlementInfo: dto.hasDivorceSettlement === YES ? dto.divorceSettlementInfo : null,

    hasDonation: yesNoToBoolean(dto.hasDonation),
    donationInfo: dto.hasDonation === YES ? dto.donationInfo : null,

    hasFixedDeposit: yesNoToBoolean(dto.hasFixedDeposit),
    fixedDepositInfo: dto.hasFixedDeposit === YES ? dto.fixedDepositInfo : null,

    hasDividendPayment: yesNoToBoolean(dto.hasDividendPayment),
    dividendPaymentInfo: dto.hasDividendPayment === YES ? dto.dividendPaymentInfo : null,

    hasLottery: yesNoToBoolean(dto.hasLottery),
    lotteryInfo: dto.hasLottery === YES ? dto.lotteryInfo : null,

    hasLifePolicy: yesNoToBoolean(dto.hasLifePolicy),
    lifePolicyInfo: dto.hasLifePolicy === YES ? dto.lifePolicyInfo : null,

    hasMaturityOfInvestments: yesNoToBoolean(dto.hasMaturityOfInvestments),
    maturityOfInvestmentsInfo: dto.hasMaturityOfInvestments === YES ? dto.maturityOfInvestmentsInfo : null,

    hasOther: yesNoToBoolean(dto.hasOther),
    otherInfo: dto.hasOther === YES ? dto.otherInfo : null,

    stageType: dto.stageType,
    stageStatus: dto.stageStatus,
    draftData: null
});
// 2.7

export const mapSourceOfFundsFormValuesToApiStageSourceOfFunds = (entity: ApiEntity, dto: SourceOfFundsFormValues): ApiStageSourceOfFunds => ({
    id: dto.id,
    hasSourceOfFundsCollated: yesNoToBoolean(dto.hasSourceOfFundsCollated),
    sourceOfFundsCollatedInfo: dto.hasSourceOfFundsCollated === YES ? dto.sourceOfFundsCollatedInfo : '',
    hasEconomicActivities: yesNoToBoolean(dto.hasEconomicActivities),
    economicActivitiesInfo: dto.hasEconomicActivities === YES ? dto.economicActivitiesInfo : '',
    hasNewEquity: yesNoToBoolean(dto.hasNewEquity),
    newEquityInfo: dto.hasNewEquity === YES ? dto.economicActivitiesInfo : '',
    hasPrivateLoan: yesNoToBoolean(dto.hasPrivateLoan),
    privateLoanInfo: dto.hasPrivateLoan === YES ? dto.privateLoanInfo : '',
    hasBankLoanOrCreditFacility: yesNoToBoolean(dto.hasBankLoanOrCreditFacility),
    bankLoanOrCreditFacilityInfo: dto.hasBankLoanOrCreditFacility === YES ? dto.bankLoanOrCreditFacilityInfo : '',
    hasOther: yesNoToBoolean(dto.hasOther),
    otherInfo: dto.hasOther === YES ? dto.otherInfo : '',
    sourceOfFundsOriginatingFrom: dto.sourceOfFundsOriginatingFrom?.code as string,

    stageType: dto.stageType,
    stageStatus: dto.stageStatus,
    draftData: null
});
// 3.1

export const mapAmlFormValuesToApiStageAml = (entity: ApiEntity, dto: AmlFormValues): ApiStageAml => ({
    id: dto.id,


    refNo: dto.refNo,
    transactionDate: dto.transactionDate ? format(dto.transactionDate!, 'yyyy-MM-dd') : null,
    transactionDescription: dto.transactionDescription,
    transactionAmount: dto.transactionAmount.currency != null ? mapMoneyToApiMoney(dto.transactionAmount) : {
        value: null,
        currency: null
    },
    originalTransactionCurrency: dto.originalTransactionCurrency ? dto.originalTransactionCurrency.value! : null,
    foreignAmount: dto.foreignAmount.currency != null ? mapMoneyToApiMoney(dto.foreignAmount) : {
        value: null,
        currency: null
    },
    transactionLocation: dto.transactionLocation,
    role: dto.role,
    entityFirstName: dto.entityFirstName,
    entityLastName: dto.entityLastName,
    country: dto.country ? dto.country?.code as string : null,
    reasonTransactionSuspicious: dto.reasonTransactionSuspicious,
    actionsTakenTransactionDetail: dto.actionsTakenTransactionDetail,
    hasTransactionLegitimate: yesNoToBoolean(dto.hasTransactionLegitimate),
    rationaleForConclusion: dto.hasTransactionLegitimate === YES ? dto.rationaleForConclusion : null,
    dateOfReport: dto.dateOfReport ? format(dto.dateOfReport!, 'yyyy-MM-dd') : null,
    hasMlroAgreementPreparer: yesNoToBoolean(dto.hasMlroAgreementPreparer),
    rationaleMlroConclusion: dto.hasMlroAgreementPreparer === YES ? dto.rationaleMlroConclusion : null,
    hasFurtherEvidence: yesNoToBoolean(dto.hasFurtherEvidence),
    furtherEvidenceInfo: dto.hasFurtherEvidence === YES ? dto.furtherEvidenceInfo : null,
    dateMlroReview: dto.dateMlroReview ? format(dto.dateMlroReview!, 'yyyy-MM-dd') : null,
    hasInternalReportInstigatedSuspiciousTransaction: yesNoToBoolean(dto.hasInternalReportInstigatedSuspiciousTransaction),
    dateStrSubmission: dto.dateStrSubmission ? format(dto.dateStrSubmission!, 'yyyy-MM-dd') : null,
    outcomeStr: dto.outcomeStr,

    stageType: dto.stageType,
    stageStatus: dto.stageStatus,
    draftData: null
});


// 4.1
export const mapEngagementFormValuesToApiStageEngagement = (entity: ApiEntity, dto: EngagementFormValues): ApiStageEngagement => ({
    id: dto.id,
    onboarded: yesNoToBoolean(dto.onboarded),
    dateOfCommencement: dto.onboarded === YES ? format(dto.dateOfCommencement!, 'yyyy-MM-dd') : null,
    reasonForNotOnboarding: dto.onboarded === NO && dto?.reasonForNotOnboarding ? dto?.reasonForNotOnboarding.value! : null,
    reasonForNotOnboardingInfo: dto.onboarded === NO ? dto.reasonForNotOnboardingInfo : null,
    dateOfSignatureEngagement: dto.dateOfSignatureEngagement ? format(dto.dateOfSignatureEngagement!, 'yyyy-MM-dd') : null,

    stageType: dto.stageType,
    stageStatus: dto.stageStatus,
    draftData: null
});

export const mapTerminationFormValuesToApiStageTermination = (entity: ApiEntity, dto: TerminationFormValues): ApiStageTermination => ({
    id: dto.id,
    dateOfTermination: format(dto.dateOfTermination!, 'yyyy-MM-dd'),
    reasonForTermination: dto?.reasonForTermination ? dto?.reasonForTermination.value! : null,
    reasonForTerminationInfo: dto.reasonForTerminationInfo,

    stageType: dto.stageType,
    stageStatus: dto.stageStatus,
    draftData: null
});

// export const mapApiAccountToCreateUpdateAccountFormValues = (
//     account: ApiAccount, accountHolderIdForHeader: string
// ): CreateUpdateAccountFormValues => ({
//     id: account.id,
//     friendlyName: account.friendlyName,
//     accountType: account.accountType,
//     currencyCode: account.currencyCode,
//     accountHolderIdForHeader
// });


// export const mapUpdateEntityFormValuesToApiUpdateEntityRequest = async (
//     entityDto: UpdateEntityFormValues
// ): Promise<ApiUpdateEntityRequest> => ({
//     id: entityDto.id as string,
//     entryType: entityDto.entryType as ApiEntryType,
//     title: entityDto?.title?.value!,
//     firstName: entityDto.firstName,
//     lastName: entityDto.lastName,

//     legalEntityName: entityDto.legalEntityName,
//     otherUsefulInformation: entityDto.otherUsefulInformation,
// });



// entryType: ApiEntryType | string;

// // entryType === NATURAL_PERSON
// title: Nullable<ApiEnumeration>;
// firstName: string;
// lastName: string;
// // entryType === LEGAL_ENTITY
// legalEntityName:string;

// otherUsefulInformation: string;

// export const mapUpdateEntityFormValuesToApiUpdateEntityRequest = async (
//     entityDto: UpdateEntityFormValues
// ): Promise<ApiUpdateEntityRequest> => ({
//     id: entityDto.id as string,
//     firstName: entityDto.firstName,
//     lastName: entityDto.lastName,
//     // address: mapAddressToApiAddress(entityDto.address),
//     // vatRegistrationNumber: entityDto.vatRegistrationNumber,
//     // companyRegistrationNumber: entityDto.companyRegistrationNumber,
//     // phones: entityDto.phones.map((p: Phone) => mapPhoneToApiPhoneNumber(p)),
//     // emails: entityDto.emails,
//     accountManager: entityDto.accountManager,
//     // services: entityDto.services,
//     // industry: entityDto.industry,
//     // legalEntityTypes: entityDto.legalEntityTypes,
//     status: entityDto.status,
// });

// export const mapApiEntityResponseToUpdateEntityFormValues = async (
//     apiEntityResponse: ApiEntity
// ): Promise<UpdateEntityFormValues> => ({
//     id: apiEntityResponse.id as string,
//     firstName: apiEntityResponse.firstName,
//     lastName: apiEntityResponse.lastName,
//     // address: mapApiAddressToAddress(apiEntityResponse.address),
//     // vatRegistrationNumber: apiEntityResponse.vatRegistrationNumber,
//     // companyRegistrationNumber: apiEntityResponse.companyRegistrationNumber,
//     // phones: apiEntityResponse.phones.map((p: ApiPhoneNumber) => mapApiPhoneNumberToPhone(p)),
//     // emails: apiEntityResponse.emails,
//     accountManager: apiEntityResponse.accountManager,
//     // services: apiEntityResponse.services,
//     // industry: apiEntityResponse.industry,
//     // legalEntityType: apiEntityResponse.legalEntityType,
//     status: apiEntityResponse.status,
// });