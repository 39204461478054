import { ApiConfirmForgotPasswordRequest, ApiForgotPasswordRequest, LoginRequest } from ".";
import { ForgotPasswordFormValues } from "../../screens/Authentication/ForgotPassword/forms";
import { LoginFormValues } from "../../screens/Authentication/Login/forms";
import { ResetPasswordFormValues } from "../../screens/Authentication/ResetPassword/forms";

export const mapLoginFormValuesToLoginRequest = (
    loginDto: LoginFormValues
): LoginRequest => ({
    email: loginDto.email,
    password: loginDto.password,
    tenantIdForHeader: loginDto.tenantIdForHeader
});

export const mapForgotPasswordFormValuesToApiForgotPasswordRequest = (
    forgotPasswordDto: ForgotPasswordFormValues
): ApiForgotPasswordRequest => ({
    email: forgotPasswordDto.email
});

export const mapResetPasswordFormValuesToApiConfirmForgotPasswordRequest = (
    resetPasswordDto: ResetPasswordFormValues
): ApiConfirmForgotPasswordRequest => ({
    forgotPasswordId: resetPasswordDto.forgotPasswordId,
    verificationCode: resetPasswordDto.verificationCode.join(""),
    newPassword: resetPasswordDto.newPassword
});
