import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  ApiEntity,
  ApiStageSourceOfWealth,
} from "../../../../../../../models/entities";

import { PermissionGuard } from "../../../../../../../models/auth";
import {
  ApiEntityStatus,
  YesNo,
  booleanToYesNo,
  yesNosColourMap,
  ACTIVE_CLIENT_STATUS,
  CLOSED_CLIENT_STATUS,
  PENDING_APPROVAL_CLIENT_STATUS,
} from "../../../../../../../forms";
import { StatusLabel } from "../../../../../../../components/StatusLabel";
import { ApiUserResponse } from "../../../../../../../models/users";
import { Nullable } from "../../../../../../../utils/types";

interface DetailRow {
  name: string;
  value: any;
}

export const Stage2_6View: FC<{
  entity: ApiEntity;
  stageData: ApiStageSourceOfWealth;
  onEdit: (value: boolean) => void;
  status: Nullable<ApiEntityStatus>;
  profile: ApiUserResponse | undefined;
}> = ({ entity, stageData, onEdit, status = null, profile }) => {
  const { t } = useTranslation();

  const step2_6Data: DetailRow[] = useMemo(
    () =>
      stageData
        ? [
            {
              name: "common.estimatedTotalNetWorth",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.estimatedNetWorth}
                </Typography>
              ),
            },
            {
              name: "common.indicateIfHighNetworthIndividual",
              value: (
                <>
                  {stageData?.hasHighNetWorth != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasHighNetWorth)}
                    />
                  ) : null}
                </>
              ),
            },
            {
              name: "common.sourceOfWealthInformationNeedtoBecollated",
              value: (
                <>
                  {stageData?.hasSowCollated != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasSowCollated)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasSowCollated
              ? [
                  {
                    name: "common.sourceOfWealthInformationNeedtoBecollatedRationale",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.sowCollatedInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),

            {
              name: "common.naAsNewlyEstablishedEntity",
              value: (
                <>
                  {stageData?.hasNewlyEstablishedEntity != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.hasNewlyEstablishedEntity
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasNewlyEstablishedEntity
              ? [
                  {
                    name: "common.naAsNewlyEstablishedEntityInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.newlyEstablishedEntityInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),

            {
              name: "common.incomeSavingsFromSalary",
              value: (
                <>
                  {stageData?.hasSavingsFromSalary != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasSavingsFromSalary)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasSavingsFromSalary
              ? [
                  {
                    name: "common.incomeSavingsFromSalaryInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.savingsFromSalaryInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),
            {
              name: "common.businessIncomeCompanyProfits",
              value: (
                <>
                  {stageData?.hasCompanyProfits != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasCompanyProfits)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasCompanyProfits
              ? [
                  {
                    name: "common.businessIncomeCompanyProfitsInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.companyProfitsInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),
            {
              name: "common.saleOfPropertyAssets",
              value: (
                <>
                  {stageData?.hasSaleOfProperty != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasSaleOfProperty)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasSaleOfProperty
              ? [
                  {
                    name: "common.saleOfPropertyAssetsInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.saleOfPropertyInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),

            {
              name: "common.rentalOfProperty",
              value: (
                <>
                  {stageData?.hasRentalOfProperty != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasRentalOfProperty)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasRentalOfProperty
              ? [
                  {
                    name: "common.rentalOfPropertyInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.rentalOfPropertyInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),
            {
              name: "common.companySale",
              value: (
                <>
                  {stageData?.hasCompanySale != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasCompanySale)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasCompanySale
              ? [
                  {
                    name: "common.companySaleInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.companySaleInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),
            {
              name: "common.inheritance",
              value: (
                <>
                  {stageData?.hasInheritance != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasInheritance)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasInheritance
              ? [
                  {
                    name: "common.inheritanceInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.inheritanceInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),
            {
              name: "common.divorceSettlement",
              value: (
                <>
                  {stageData?.hasDivorceSettlement != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasDivorceSettlement)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasDivorceSettlement
              ? [
                  {
                    name: "common.divorceSettlementInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.divorceSettlementInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),
            {
              name: "common.giftDonation",
              value: (
                <>
                  {stageData?.hasDonation != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasDonation)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasDonation
              ? [
                  {
                    name: "common.giftDonationInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.donationInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),
            {
              name: "common.fixedDepositSavings",
              value: (
                <>
                  {stageData?.hasFixedDeposit != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasFixedDeposit)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasFixedDeposit
              ? [
                  {
                    name: "common.fixedDepositSavingsInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.fixedDepositInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),
            {
              name: "common.dividendPayment",
              value: (
                <>
                  {stageData?.hasDividendPayment != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasDividendPayment)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasDividendPayment
              ? [
                  {
                    name: "common.dividentPaymentInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.dividendPaymentInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),
            {
              name: "common.lotteryBettingCasinoWin",
              value: (
                <>
                  {stageData?.hasLottery != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasLottery)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasLottery
              ? [
                  {
                    name: "common.lotteryBettingCasinoWinInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.lotteryInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),
            {
              name: "common.maturitySurrenderOfLifePolicy",
              value: (
                <>
                  {stageData?.hasLifePolicy != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasLifePolicy)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasLifePolicy
              ? [
                  {
                    name: "common.maturitySurrenderOfLifePolicyInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.lifePolicyInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),
            {
              name: "common.saleMaturityOfInvestments",
              value: (
                <>
                  {stageData?.hasMaturityOfInvestments != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(
                        stageData.hasMaturityOfInvestments
                      )}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasMaturityOfInvestments
              ? [
                  {
                    name: "common.saleMaturityOfInvestmentsInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.maturityOfInvestmentsInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),
            {
              name: "common.otherPleaseSpecify",
              value: (
                <>
                  {stageData?.hasOther != null ? (
                    <StatusLabel<YesNo>
                      colourMap={yesNosColourMap}
                      status={booleanToYesNo(stageData.hasOther)}
                    />
                  ) : null}
                </>
              ),
            },
            ...(stageData.hasOther
              ? [
                  {
                    name: "common.otherPleaseSpecifyInfo",
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.otherInfo}
                      </Typography>
                    ),
                  },
                ]
              : []),
          ]
        : [],
    [stageData]
  );

  return (
    <>
      {entity ? (
        <>
          <Card sx={{ marginTop: "12px" }}>
            <CardHeader
              disableTypography
              title={
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tooltip title={t("common.stage_2_6")} enterDelay={1000}>
                    <Typography variant="h6">
                      {t("common.sourceOfWealth")}
                    </Typography>
                  </Tooltip>
                  <Grid
                    item
                    // sm={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {((status !== PENDING_APPROVAL_CLIENT_STATUS &&
                      status !== ACTIVE_CLIENT_STATUS &&
                      status !== CLOSED_CLIENT_STATUS) ||
                      (status === PENDING_APPROVAL_CLIENT_STATUS &&
                        entity.reviewerId === profile?.id)) && (
                      <PermissionGuard permission="UPDATE_ENTITY">
                        <>
                          <Button
                            sx={[
                              {
                                whiteSpace: "nowrap",
                              },
                              (theme) => ({
                                [theme.breakpoints.down("sm")]: {
                                  minWidth: "100%",
                                },
                                [theme.breakpoints.up("sm")]: {
                                  minWidth: "auto",
                                },
                              }),
                            ]}
                            variant="contained"
                            onClick={() => onEdit(true)}
                          >
                            {t("common.edit")}
                          </Button>
                        </>
                      </PermissionGuard>
                    )}
                  </Grid>
                </Box>
              }
            />

            <Grid container rowSpacing={3} columnSpacing={2} sx={{ p: "16px" }}>
              {step2_6Data.map(({ name, value }) => (
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  key={name}
                  sx={{ paddingTop: "12px !important" }}
                >
                  <Grid container>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {t(name)}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      {value}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Card>
        </>
      ) : null}
    </>
  );
};
