import { Route, Routes } from "react-router";
import { LazyLoadableScreen } from "../../../navigation/LazyLoadableScreen";

const ForgotPasswordScreen = LazyLoadableScreen(
  () => import("./ForgotPassword")
);
const ForgotPasswordConfirmScreen = LazyLoadableScreen(
  () => import("./ForgotPasswordConfirm")
);

export const ForgotPasswordRoutes = () => (
  <Routes>
    <Route path="/">
      <Route path="confirm" element={<ForgotPasswordConfirmScreen />} />
      <Route index element={<ForgotPasswordScreen />} />
    </Route>
  </Routes>
);
