import { ApiEntity, ApiStageInterfaceDistribution, STAGE_2_5_TYPE } from "../../../../../../models/entities"
import { InterfaceDistributionFormValues } from "."
import { ApiEnumeration, DEALING_DIRECTLY_WITH, INTRODUCED_BY } from "../../../../../../models/enumerations"
import { booleanToYesNo, onboardingTypeInitialValue } from "../../../../../../forms";

export const interfaceDistributionFormValuesInitialValue = (
    entity: ApiEntity,
    enumerables: ApiEnumeration[]): InterfaceDistributionFormValues => {
    // Get Stage Data
    const stageData: ApiStageInterfaceDistribution = entity.stages.find((s) => s.stageType === STAGE_2_5_TYPE) as ApiStageInterfaceDistribution;
    // If draft is available use that
    const data: ApiStageInterfaceDistribution = stageData.draftData ? stageData.draftData as ApiStageInterfaceDistribution : stageData;
    return {
        stageType: STAGE_2_5_TYPE,
        stageStatus: data.stageStatus,
        id: entity.id,

        onboardingType: data.onboardingType || onboardingTypeInitialValue(),
        introducedBy: data.introducedBy ? enumerables.filter(x => x.type === INTRODUCED_BY).find(x => x.value === data.introducedBy)! : null,
        dealingDirectlyWith: data.dealingDirectlyWith ? enumerables.filter(x => x.type === DEALING_DIRECTLY_WITH).find(x => x.value === data.dealingDirectlyWith)! : null,
        hasPoaAuthorized: data.hasPoaAuthorized != null ? booleanToYesNo(data.hasPoaAuthorized) : '',
        hasPoaDocumentCollected: data.hasPoaDocumentCollected != null ? booleanToYesNo(data.hasPoaDocumentCollected) : '',
        hasReasonableRationale: data.hasReasonableRationale != null ? booleanToYesNo(data.hasReasonableRationale) : '',
        reasonableRationaleInfo: data.reasonableRationaleInfo || '',
        hasRelianceAgreementThirdPartyCdd: data.hasRelianceAgreementThirdPartyCdd != null ? booleanToYesNo(data.hasRelianceAgreementThirdPartyCdd) : '',
        hasRelianceAgreementSigned: data.hasRelianceAgreementSigned != null ? booleanToYesNo(data.hasRelianceAgreementSigned) : '',

    }
}

