import { Box } from "@mui/material";
import { FC } from "react";
import { useSelector } from "react-redux";
import { selectTenant } from "../../models/tenants";

export const Logo: FC<{ maxHeight?: string }> = (maxHeight) => {
  const tenant = useSelector(selectTenant);

  return tenant && tenant.logoString?.length > 0 ? (
    <Box
      sx={{
        maxWidth: 225,
        height: "auto",
        ...(maxHeight ? { maxHeight } : {}),
      }}
      component="img"
      src={`${tenant?.logoString}`}
    />
  ) : (
    <></>
  );
};
