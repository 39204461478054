import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

export interface PasswordFieldProps {
  disabled: boolean;
  label?: string;
}
export const PasswordField: FC<
  PasswordFieldProps & FieldProps<string, string>
> = ({
  disabled = false,
  label = "common.password",
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

      <FormControl
        margin="normal"
        variant="outlined"
        fullWidth
        error={Boolean(
          getIn(touched, field.name) && !!getIn(errors, field.name)
        )}
      >
        <InputLabel
          error={Boolean(
            getIn(touched, field.name) && !!getIn(errors, field.name)
          )}
        >
          {t(label)}
        </InputLabel>

        <Field
          component={OutlinedInput}
          disabled={disabled}
          type={showPassword ? "text" : "password"}
          value={getIn(values, field.name)}
          label={t(label)}
          onBlur={handleBlur(field.name)}
          onChange={handleChange(field.name)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText error>
          {getIn(touched, field.name) && t(getIn(errors, field.name))}
        </FormHelperText>
      </FormControl>
    </>
  );
};
