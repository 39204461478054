import { CreateUpdateTenantFormValues } from "../../screens/Tenants/forms";
import { ApiCreateTenantRequest, ApiTenantResponse, ApiUpdateTenantRequest, defaultLogo, defaultLogoFilename } from ".";
import { dataUrlToFile, fileToBase64 } from "../../utils/file";
import { ApiEnumeration, CURRENCY } from "../enumerations";

// export const mapApiAccountToCreateUpdateAccountFormValues = (
//     account: ApiAccount, accountHolderIdForHeader: string
// ): CreateUpdateAccountFormValues => ({
//     id: account.id,
//     friendlyName: account.friendlyName,
//     accountType: account.accountType,
//     currencyCode: account.currencyCode,
//     accountHolderIdForHeader
// });


export const mapCreateUpdateTenantFormValuesToApiTenantCreate = async (
    tenantDto: CreateUpdateTenantFormValues
): Promise<ApiCreateTenantRequest> => ({
    name: tenantDto.name,
    description: tenantDto.description,
    logoName: (tenantDto.logo as File).name,
    logoString: await fileToBase64(tenantDto.logo as File),
    currency: tenantDto.currency!.value
});

export const mapCreateUpdateTenantFormValuesToApiTenantUpdate = async (
    tenantDto: CreateUpdateTenantFormValues
): Promise<ApiUpdateTenantRequest> => ({
    id: tenantDto.id as string,
    name: tenantDto.name,
    description: tenantDto.description,
    logoName: (tenantDto.logo as File).name,
    logoString: await fileToBase64(tenantDto.logo as File),
    currency: tenantDto.currency!.value
});

export const mapApiTenantResponseToCreateUpdateTenantFormValues = async (
    apiTenantResponse: ApiTenantResponse,
    enumerations: ApiEnumeration[]
): Promise<CreateUpdateTenantFormValues> => ({
    id: apiTenantResponse.id as string,
    name: apiTenantResponse.name,
    description: apiTenantResponse.description,
    logo: await dataUrlToFile(apiTenantResponse.logoString || defaultLogo, apiTenantResponse.logoName || defaultLogoFilename),
    currency: apiTenantResponse.currency ? enumerations.filter(e => e.type === CURRENCY).find(x => x.value === apiTenantResponse.currency)! : null,
});

