import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import { Box, Divider, IconButton, Link, Typography } from "@mui/material";
import { Field, Formik, getIn } from "formik";
import React, { FC } from "react";
import toast from "react-hot-toast";

import {
  cDDInformationFormValuesInitialValue,
  CDDInformationFormValues,
  cDDInformationFormValidationSchema,
} from ".";

import { useTranslation } from "react-i18next";

import {
  BasicTextField,
  CddDoneTypeField,
  CountryField,
  DateField,
  EnumerablesField,
  YES,
  YesNoField,
} from "../../../../../../forms";
import {
  ApiEnumeration,
  IDENTITY_DOCUMENT_TYPE,
  PROOF_OF_ADDRESS,
} from "../../../../../../models/enumerations";
import { usePermissions } from "../../../../../../models/auth";
import {
  PENDING_STAGE_STATUS,
  ApiEntity,
  DRAFT_STAGE_STATUS,
  mapCDDInformationFormValuesToApiStageCDDInformation,
  useUpdateEntityStageMutation,
  ApiStageCdd,
  PENDING_APPROVAL_STAGE_STATUS,
  STAGE_2_1_TYPE,
} from "../../../../../../models/entities";
import { ApiUserResponse } from "../../../../../../models/users";
interface CDDInformationFormProps {
  entity: ApiEntity;
  enumerables: ApiEnumeration[];
  onClose: () => void;
  profile: ApiUserResponse | undefined;
}
export const CDDInformationForm: FC<CDDInformationFormProps> = ({
  entity,
  enumerables,
  onClose,
  profile,
}) => {
  const [updateEntityStage] = useUpdateEntityStageMutation();
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  return (
    <Formik<CDDInformationFormValues>
      enableReinitialize
      initialValues={{
        ...cDDInformationFormValuesInitialValue(entity, enumerables),
      }}
      validationSchema={cDDInformationFormValidationSchema(
        hasPermission("ADMIN")
      )}
      onSubmit={async (values): Promise<void> => {
        try {
          const apiCDDInformation: ApiStageCdd =
            mapCDDInformationFormValuesToApiStageCDDInformation(entity, values);
          // const apiEntity: ApiStageBasicDetail =
          await updateEntityStage(apiCDDInformation).unwrap();

          toast.success(t("common.updatedSuccessfully"));
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }): JSX.Element => (
        <>
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "24px 38px 24px 32px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={"textPrimary"} variant="h5">
                {t("common.cddInformation")}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: "32px" }}>
            <form noValidate onSubmit={handleSubmit}>
              {/* {showNaturalPersonFields(entity) && ( */}
              <>
                <Typography variant="subtitle2">
                  {t("common.a1TypeOfVerificationDocumentObdatinedIndividuals")}
                </Typography>
                <Field
                  label={`common.identityVerificationDocumentType`}
                  name={`identityDocumentType`}
                  multiple
                  component={EnumerablesField}
                  type={IDENTITY_DOCUMENT_TYPE}
                  margin="normal"
                />
                <Field
                  name={`idNo`}
                  component={BasicTextField}
                  label="common.idNo"
                  // size="small"
                  margin="normal"
                />
                <Field
                  name={`documentNo`}
                  component={BasicTextField}
                  label="common.documentNo"
                  // size="small"
                  margin="normal"
                />
                <Field
                  name={"documentExpiryDate"}
                  label="common.documentExpiryDate"
                  component={DateField}
                />
                <Field
                  name={`documentCountry`}
                  component={CountryField}
                  label="common.documentCountry"
                  // size="small"
                  margin="normal"
                />
                <Typography variant="subtitle2">
                  {t("common.a2TypeOfDocumentObtained")}
                </Typography>
                <Field
                  label={`common.proofOfAddress`}
                  name={`proofOfAddress`}
                  multiple
                  component={EnumerablesField}
                  type={PROOF_OF_ADDRESS}
                  margin="normal"
                />
                <Field
                  name={"proofOfAddressDate"}
                  label="common.proofOfAddressDate"
                  component={DateField}
                />
              </>
              {/* )} */}
              {/* {showLegalEntityFields(entity) && ( */}
              <>
                <Typography variant="subtitle2">
                  {t(
                    "common.a3TypeOfVerificationDocumentObtainedLegalEntities"
                  )}
                </Typography>
                <Field
                  name={"certificateIncorporation"}
                  label="common.certificateIncorporation"
                  component={CddDoneTypeField}
                  margin="normal"
                />
                <Field
                  name={"memorandumAndArticles"}
                  label="common.memorandumAndArticles"
                  component={CddDoneTypeField}
                  margin="normal"
                />
                <Field
                  name={"vatRegCertificate"}
                  label="common.vatRegCertificate"
                  component={CddDoneTypeField}
                  margin="normal"
                />
                <Field
                  name={"beneficialOwnersDocument"}
                  label="common.beneficialOwnersDocument"
                  component={CddDoneTypeField}
                  margin="normal"
                />
                <Field
                  name={"certificateGoodStanding"}
                  label="common.certificateGoodStanding"
                  component={CddDoneTypeField}
                  margin="normal"
                />
                <Field
                  name={"certificateIncumbency"}
                  label="common.certificateIncumbency"
                  component={CddDoneTypeField}
                  margin="normal"
                />
                <Typography variant="subtitle2">
                  {t("common.a4OtherVerificationDocumentsObtained")}
                </Typography>
                <Field
                  name={`otherDocumentationCollected`}
                  component={BasicTextField}
                  label="common.otherDocumentationCollected"
                  // size="small"
                  margin="normal"
                />
              </>
              {/* )} */}

              {/* {showCurriculumVitaeFields(entity) && ( */}
              <>
                <Typography variant="subtitle2">
                  {t("common.bCurriculumVitae")}
                </Typography>
                <Field
                  name={"curriculumVitae"}
                  label="common.curriculumVitae"
                  component={CddDoneTypeField}
                  margin="normal"
                />
                <Field
                  name={"curriculumVitaeDate"}
                  label="common.curriculumVitaeDate"
                  component={DateField}
                />
              </>
              {/* )} */}

              {/* {showOrganogramGroupStructureFields(entity) && ( */}
              <>
                <Typography variant="subtitle2">
                  {t("common.cOrganogramGroupStructure")}
                </Typography>
                <Field
                  name={"groupStructure"}
                  label="common.groupStructure"
                  component={CddDoneTypeField}
                  margin="normal"
                />
              </>
              {/* )} */}

              {/* {showPEPandAdverseMediaFields(entity) && ( */}
              <>
                <Typography variant="subtitle2">
                  {t("common.dPepAndSanctionScreening")}
                </Typography>
                <Field
                  name={`hasPepScreeningConducted`}
                  component={YesNoField}
                  label="common.hasPepScreeningConducted"
                  // size="small"
                  margin="normal"
                />
                <Field
                  name={"pepScreeningConductedDate"}
                  label="common.pepScreeningConductedDate"
                  component={DateField}
                />
                <Typography variant="body2">
                  {t(
                    "common.searchResultedInTrueMatchInOneOfThefollowingAreas"
                  )}
                </Typography>
                <Field
                  name={`hasPep`}
                  component={YesNoField}
                  label="common.hasPep1"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasPep") === YES && (
                  <Field
                    name={`pepInfo`}
                    component={BasicTextField}
                    label="common.pepInfo"
                    // size="small"
                    margin="normal"
                  />
                )}
                <Field
                  name={`hasSanctions`}
                  component={YesNoField}
                  label="common.hasSanctions"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasSanctions") === YES && (
                  <Field
                    name={`sanctionsInfo`}
                    component={BasicTextField}
                    label="common.sanctionsInfo"
                    // size="small"
                    margin="normal"
                  />
                )}
                <Field
                  name={`hasAdverseMedia`}
                  component={YesNoField}
                  label="common.hasAdverseMedia"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasAdverseMedia") === YES && (
                  <Field
                    name={`adverseMediaInfo`}
                    component={BasicTextField}
                    label="common.adverseMediaInfo"
                    // size="small"
                    margin="normal"
                  />
                )}
                <Field
                  name={`hasLawEnforcement`}
                  component={YesNoField}
                  label="common.hasLawEnforcement"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasLawEnforcement") === YES && (
                  <Field
                    name={`lawEnforcementInfo`}
                    component={BasicTextField}
                    label="common.lawEnforcementInfo"
                    // size="small"
                    margin="normal"
                  />
                )}
                <Field
                  name={`hasDisqualifiedDirector`}
                  component={YesNoField}
                  label="common.hasDisqualifiedDirector"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasDisqualifiedDirector") === YES && (
                  <Field
                    name={`lawEnforcementInfo`}
                    component={BasicTextField}
                    label="common.disqualifiedDirectorInfo"
                    // size="small"
                    margin="normal"
                  />
                )}
                <Field
                  name={`hasInsolvent`}
                  component={YesNoField}
                  label="common.hasInsolvent"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasInsolvent") === YES && (
                  <Field
                    name={`insolventInfo`}
                    component={BasicTextField}
                    label="common.insolventInfo"
                    // size="small"
                    margin="normal"
                  />
                )}
                <Field
                  name={`actionsTakenTrueMatch`}
                  component={BasicTextField}
                  label="common.actionsTakenTrueMatch"
                  // size="small"
                  margin="normal"
                />

                <Typography variant="subtitle2">
                  {t("common.eAdverseMedia")}
                </Typography>
                <Typography variant="body2">
                  {t("common.adverseMediaSearchConductedOnTheseMediaChannels")}
                </Typography>
                <Field
                  name={`hasAdverseMediaSearchInternet`}
                  component={YesNoField}
                  label="common.hasAdverseMediaSearchInternet"
                  // size="small"
                  margin="normal"
                  info={"common.hasAdverseMediaSearchInternetInfoNote"}
                />
                <Field
                  name={"adverseMediaSearchInternetDate"}
                  label="common.adverseMediaSearchInternetDate"
                  component={DateField}
                />
                <Field
                  name={`hasAdverseMediaSearchAssetRecoveryBureau`}
                  component={YesNoField}
                  label="common.hasAdverseMediaSearchAssetRecoveryBureau"
                  // size="small"
                  margin="normal"
                />
                <Link
                  href={"https://assetrecovery.mt/en/Pages/Court-Orders.aspx"}
                  variant="body2"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("common.assetrecovery")}
                </Link>
                <Field
                  name={"adverseMediaSearchAssetRecoveryBureauDate"}
                  label="common.adverseMediaSearchAssetRecoveryBureauDate"
                  component={DateField}
                />
                <Field
                  name={`hasAdverseMediaSearchJudgementCourt`}
                  component={YesNoField}
                  label="common.hasAdverseMediaSearchJudgementCourt"
                  // size="small"
                  margin="normal"
                />
                <Link
                  href={"https://ecourts.gov.mt/onlineservices/Judgements"}
                  variant="body2"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("common.ecourts")}
                </Link>
                <Field
                  name={"adverseMediaSearchJudgementCourtDate"}
                  label="common.adverseMediaSearchJudgementCourtDate"
                  component={DateField}
                />
                <Typography variant="body2">
                  {t(
                    "common.adverseMediaResultsFoundFromAnyOfTheFollowingSearches"
                  )}
                </Typography>
                <Field
                  name={`hasAdverseMediaFoundInternet`}
                  component={YesNoField}
                  label="common.hasAdverseMediaFoundInternet"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasAdverseMediaFoundInternet") === YES && (
                  <Field
                    name={`adverseMediaFoundInternetInfo`}
                    component={BasicTextField}
                    label="common.adverseMediaFoundInternetInfo"
                    // size="small"
                    margin="normal"
                  />
                )}
                <Field
                  name={`hasAdverseMediaFoundAssetRecoveryBureau`}
                  component={YesNoField}
                  label="common.hasAdverseMediaFoundAssetRecoveryBureau"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasAdverseMediaFoundAssetRecoveryBureau") ===
                  YES && (
                  <Field
                    name={`adverseMediaFoundInternetInfo`}
                    component={BasicTextField}
                    label="common.adverseMediaFoundAssetRecoveryBureauInfo"
                    // size="small"
                    margin="normal"
                  />
                )}
                <Field
                  name={`hasAdverseMediaFoundJudgementCourt`}
                  component={YesNoField}
                  label="common.hasAdverseMediaFoundJudgementCourt"
                  // size="small"
                  margin="normal"
                />
                {getIn(values, "hasAdverseMediaFoundJudgementCourt") ===
                  YES && (
                  <Field
                    name={`adverseMediaFoundJudgementCourtInfo`}
                    component={BasicTextField}
                    label="common.adverseMediaFoundJudgementCourtInfo"
                    // size="small"
                    margin="normal"
                  />
                )}
                <Field
                  name={`adverseMediaSummary`}
                  component={BasicTextField}
                  label="common.adverseMediaSummary"
                  // size="small"
                  margin="normal"
                />
              </>
              {/* )} */}

              {/* {showSoWSoFFields(entity) && ( */}
              <>
                <Typography variant="subtitle2">
                  {t("common.fSourceOfWealthAndSourceOfFunds")}
                </Typography>
                ??
              </>
              {/* )} */}

              {/* {showProofOfBoFields(entity) && ( */}
              <>
                <Typography variant="subtitle2">
                  {t("common.gProofOfBOInformation")}
                </Typography>
                <Field
                  name={"boInformationProof"}
                  label="common.boInformationProof"
                  component={CddDoneTypeField}
                  margin="normal"
                />
                <Field
                  name={"boInformationProofDate"}
                  label="common.boInformationProofDate"
                  component={DateField}
                />
              </>
              {/* )} */}
              <Typography variant="subtitle2">
                {t("common.hOtherMeasures")}
              </Typography>
              <Field
                name={`otherMeasures`}
                component={BasicTextField}
                label="common.otherMeasures"
                // size="small"
                margin="normal"
              />
              <Typography variant="subtitle2">
                {t("common.aAGoodConduct")}
              </Typography>

              <Field
                name={`hasPoliceConductCertificate`}
                component={YesNoField}
                label="common.hasPoliceConductCertificate"
                // size="small"
                margin="normal"
              />
              {getIn(values, "hasPoliceConductCertificate") === YES && (
                <Field
                  name={"policeConductCertificateDate"}
                  label="common.policeConductCertificateDate"
                  component={DateField}
                />
              )}
              <Typography variant="subtitle2">
                {t("common.bReferences")}
              </Typography>
              <Field
                name={`hasBankReference`}
                component={YesNoField}
                label="common.hasBankReference"
                // size="small"
                margin="normal"
              />
              {getIn(values, "hasBankReference") === YES && (
                <Field
                  name={"bankReferenceDate"}
                  label="common.bankReferenceDate"
                  component={DateField}
                />
              )}
              <Field
                name={`hasProfessionalReference`}
                component={YesNoField}
                label="common.hasProfessionalReference"
                // size="small"
                margin="normal"
              />
              {getIn(values, "hasProfessionalReference") === YES && (
                <Field
                  name={"professionalReferenceDate"}
                  label="common.professionalReferenceDate"
                  component={DateField}
                />
              )}
              <Typography variant="subtitle2">
                {t("common.cSourceOfWealthSourceOfFunds")}
              </Typography>
              <Typography variant="subtitle2">
                {t("common.dOtherMeasures")}
              </Typography>

              <Field
                name={`otherEddMeasures`}
                component={BasicTextField}
                label="common.otherEddMeasures"
                // size="small"
                margin="normal"
              />
              <Typography variant="subtitle2">
                {t("common.eCompletionAndApproval")}
              </Typography>
              <Field
                name={`hasObtainedMlroApproval`}
                component={YesNoField}
                label="common.hasObtainedMlroApproval"
                // size="small"
                margin="normal"
              />
              <Box
                sx={{
                  mt: "24px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    setFieldValue("stageStatus", DRAFT_STAGE_STATUS);
                  }}
                >
                  {t("common.saveAsDraft")}
                </LoadingButton>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    // Get Stage Data
                    const stageData: ApiStageCdd = entity.stages.find(
                      (s) => s.stageType === STAGE_2_1_TYPE
                    ) as ApiStageCdd;

                    if (
                      profile?.id === entity.reviewerId &&
                      stageData.stageStatus === PENDING_APPROVAL_STAGE_STATUS
                    ) {
                      setFieldValue(
                        "stageStatus",
                        PENDING_APPROVAL_STAGE_STATUS
                      );
                    } else {
                      setFieldValue("stageStatus", PENDING_STAGE_STATUS);
                    }
                  }}
                >
                  {t("common.submit")}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
