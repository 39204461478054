import {
    CountryCode,
    getCountries,
} from 'libphonenumber-js';
import * as Yup from "yup";


const countries = getCountries();

const countryCodeSchema = (validationMessage?: string) => Yup.object().nullable().shape({
    callingCode: Yup.string().required(validationMessage || 'validations.mustSelectACountry'),
    code: Yup.mixed<CountryCode>().oneOf(countries, validationMessage || 'validations.mustSelectACountry'),
    name: Yup.string().required(validationMessage || 'validations.mustSelectACountry'),
})
    .test(
        'null-check',
        validationMessage || 'validations.mustSelectACountry',
        country => country != null
    );

export const countryValidationSchema = (validationMessage?: string) => countryCodeSchema(validationMessage).required(validationMessage || 'validations.mustSelectACountry');

export const countryNotRequiredValidationSchema = () => Yup.object().nullable().notRequired();