import {
  Box,
  Container,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
  colors,
} from "@mui/material";
import { FC, ReactElement, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import {
  Link as RouterLink,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";

import { PermissionGuard, usePermissions } from "../../../models/auth";
import { QuotationsRequiringApprovalRoutes } from "./QuotationsRequiringApproval";
import { InvoicesRequiringApprovalRoutes } from "./InvoicesRequiringApproval";
import { useMeQuery } from "../../../models/user";
import {
  CREATE_INVOICE,
  CREATE_QUOTATION,
  REVIEW_INVOICE,
  REVIEW_QUOTATION,
} from "../../../models/permissionGroups";
import { PendingQuotationsForApprovalRoutes } from "./PendingQuotationsForApprovalRoutes";
import { PendingInvoicesForApprovalRoutes } from "./PendingInvoicesForApprovalRoutes";

export const DashboardTabs: FC<{}> = ({}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { hasPermission } = usePermissions();
  const { id } = useParams<{ id: string }>();

  interface TabRoutes {
    show: boolean;
    route1: string;
    route2: string;
    label: string;
    component: ReactElement<any, any>;
  }

  interface DetailRow {
    name: string;
    value: any;
  }
  const { data: profile } = useMeQuery({}, { refetchOnMountOrArgChange: true });
  const routes: TabRoutes[] = useMemo(
    () => [
      ...(hasPermission(REVIEW_QUOTATION)
        ? [
            {
              show: true,
              route1: "quotations-requiring-approval",
              route2: "quotations-requiring-approval/*",
              label: "common.quotationsRequiringApproval",
              component: (
                <QuotationsRequiringApprovalRoutes reviewer={profile!} />
              ),
            },
          ]
        : []),

      ...(hasPermission(CREATE_QUOTATION)
        ? [
            {
              show: true,
              route1: "pending-quotations-for-approval",
              route2: "pending-quotations-for-approval/*",
              label: "common.pendingQuotationsForApproval",
              component: (
                <PendingQuotationsForApprovalRoutes createdBy={profile!} />
              ),
            },
          ]
        : []),

      ...(hasPermission(REVIEW_INVOICE)
        ? [
            {
              show: true,
              route1: "invoices-requiring-approval",
              route2: "invoices-requiring-approval/*",
              label: "common.invoicesRequiringApproval",
              component: (
                <InvoicesRequiringApprovalRoutes reviewer={profile!} />
              ),
            },
          ]
        : []),
      ...(hasPermission(CREATE_INVOICE)
        ? [
            {
              show: true,
              route1: "pending-invoices-for-approval",
              route2: "pending-invoices-for-approval/*",
              label: "common.pendingInvoicesForApproval",
              component: (
                <PendingInvoicesForApprovalRoutes createdBy={profile!} />
              ),
            },
          ]
        : []),
    ],
    []
  );
  return (
    <>
      <Helmet>
        <title>{t("common.dashboard")}</title>
      </Helmet>
      <Box>
        <Container maxWidth={false}>
          <Grid alignItems="center" container item xs={12}>
            <Typography
              sx={{ color: colors.blueGrey[600], textTransform: "uppercase" }}
              variant="overline"
            >
              {t("common.dashboard")}
            </Typography>
          </Grid>
          <PermissionGuard permission="GET_ENTITY">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={
                  location.pathname.split("/").length > 3
                    ? `${location.pathname.split("/")[3]}`
                    : routes[0].route1 // fallback details
                }
                aria-label="entity"
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                {routes.map((elem, index) => {
                  return (
                    <Tab
                      key={elem.route1}
                      style={{ minWidth: 50 }}
                      label={t(elem.label)}
                      value={`${elem.route1}`}
                      component={RouterLink}
                      to={elem.route1}
                    />
                  );
                })}
              </Tabs>
            </Box>
            <Routes>
              {routes.map((elem, index) => {
                return (
                  <Route
                    key={elem.label}
                    path={elem.route2}
                    element={<>{elem.component}</>}
                  />
                );
              })}

              <Route
                path="*"
                element={<Navigate to={routes[0].route1} replace />}
              />
            </Routes>
          </PermissionGuard>
        </Container>
      </Box>
    </>
  );
};
