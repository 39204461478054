import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  ApiEntity,
  ApiStageAdditionalDetail,
  ApiStageStatus,
  stageStatusesColourMap,
  stageStatusesColours,
} from "../../../../../../../models/entities";
import {
  ACTIVE_CLIENT_STATUS,
  ApiEntityStatus,
  CLOSED_CLIENT_STATUS,
  LEGAL_ENTITY,
  NATURAL_PERSON,
  PENDING_APPROVAL_CLIENT_STATUS,
} from "../../../../../../../forms";
import { getCountryByCode } from "../../../../../../../utils/countries";
import { CountryFlag } from "../../../../../../../components/CountryFlag";
import { PermissionGuard } from "../../../../../../../models/auth";
import { ApiUserResponse } from "../../../../../../../models/users";
import { Nullable } from "../../../../../../../utils/types";
import { StatusLabel } from "../../../../../../../components/StatusLabel";

interface DetailRow {
  name: string;
  value: any;
}

export const Stage1_2View: FC<{
  entity: ApiEntity;
  stageData: ApiStageAdditionalDetail;
  onEdit: (value: boolean) => void;
  status: Nullable<ApiEntityStatus>;
  profile: ApiUserResponse | undefined;
}> = ({ entity, stageData, onEdit, status = null, profile }) => {
  const { t } = useTranslation();

  const step1_2Data: DetailRow[] = useMemo(
    () =>
      entity
        ? [
            // ...(stageData.stageStatus
            //   ? [
            //       {
            //         name: t("common.status"),
            //         value: (
            //           <StatusLabel<ApiStageStatus>
            //             colourMap={stageStatusesColourMap}
            //             status={stageData.stageStatus}
            //           />
            //         ),
            //       },
            //     ]
            //   : []),
            ...(entity.entryType === LEGAL_ENTITY
              ? [
                  {
                    name: t("common.legalEntityType"),
                    value: (
                      <>
                        {stageData.legalEntityType ? (
                          <Chip
                            color="primary"
                            variant="outlined"
                            label={stageData.legalEntityType}
                          />
                        ) : (
                          ""
                        )}
                      </>
                    ),
                  },
                  {
                    name: t("common.registrationNumber"),
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.registrationNo}
                      </Typography>
                    ),
                  },
                  {
                    name: t("common.dateOfIncorporation"),
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.dateOfIncorporation != undefined &&
                          stageData.dateOfIncorporation?.length > 0 &&
                          format(
                            parseISO(stageData.dateOfIncorporation),
                            "dd-MM-yyyy"
                          )}
                      </Typography>
                    ),
                  },
                  {
                    name: t("common.countryOfIncorporation"),
                    value: (
                      <>
                        {stageData?.countryOfIncorporation && (
                          <Chip
                            sx={{ pl: "5px", m: 1 }}
                            label={`${
                              getCountryByCode(stageData.countryOfIncorporation)
                                ?.name
                            } (${stageData.countryOfIncorporation})`}
                            icon={
                              <CountryFlag
                                countryCode={stageData.countryOfIncorporation}
                              />
                            }
                          />
                        )}
                      </>
                    ),
                  },
                  {
                    name: t("common.industry"),
                    value: (
                      <>
                        {stageData.industry ? (
                          <Chip
                            color="primary"
                            variant="outlined"
                            label={stageData.industry}
                          />
                        ) : (
                          ""
                        )}
                      </>
                    ),
                  },
                  {
                    name: t("common.valueAddedTaxNumber"),
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.vatNo}
                      </Typography>
                    ),
                  },
                  {
                    name: t("common.taxIdentificationNumber"),
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.taxIdentificationNo}
                      </Typography>
                    ),
                  },
                  {
                    name: t("common.permissionToEmployeeNumber"),
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.peNumber}
                      </Typography>
                    ),
                  },
                  {
                    name: t("common.website"),
                    value: (
                      <Link
                        href={stageData.website}
                        variant="body2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {stageData.website}
                      </Link>
                    ),
                  },
                ]
              : []),

            ...(entity.entryType === NATURAL_PERSON
              ? [
                  {
                    name: t("common.nationality"),
                    value: (
                      <>
                        {stageData?.nationality &&
                          stageData?.nationality.length > 0 && (
                            <Grid container rowSpacing={1}>
                              {stageData?.nationality.map((national) => (
                                <Grid item xs={12}>
                                  <Chip
                                    key={national}
                                    sx={{ pl: "5px", m: 1 }}
                                    label={`${
                                      getCountryByCode(national)?.name
                                    } (${national})`}
                                    icon={
                                      <CountryFlag countryCode={national} />
                                    }
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          )}
                      </>
                    ),
                  },
                  {
                    name: t("common.countryOfCitizenship"),
                    value: (
                      <>
                        {stageData?.countryOfCitizenship &&
                          stageData?.countryOfCitizenship.length > 0 && (
                            <Grid container rowSpacing={1}>
                              {stageData?.countryOfCitizenship.map(
                                (countryOfCitizen) => (
                                  <Grid item xs={12}>
                                    <Chip
                                      key={countryOfCitizen}
                                      sx={{ pl: "5px", m: 1 }}
                                      label={`${
                                        getCountryByCode(countryOfCitizen)?.name
                                      } (${countryOfCitizen})`}
                                      icon={
                                        <CountryFlag
                                          countryCode={countryOfCitizen}
                                        />
                                      }
                                    />
                                  </Grid>
                                )
                              )}
                            </Grid>
                          )}
                      </>
                    ),
                  },
                  {
                    name: t("common.dateOfBirth"),
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.dateOfBirth != undefined &&
                          stageData.dateOfBirth?.length > 0 &&
                          format(parseISO(stageData.dateOfBirth), "dd-MM-yyyy")}
                      </Typography>
                    ),
                  },
                  {
                    name: t("common.gender"),
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.gender}
                      </Typography>
                    ),
                  },
                  {
                    name: t("common.occupationProfession"),
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.profession}
                      </Typography>
                    ),
                  },
                  {
                    name: t("common.employerBusiness"),
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.business}
                      </Typography>
                    ),
                  },
                  {
                    name: t("common.country"),
                    value: (
                      <>
                        {stageData?.countryOfBusiness && (
                          <Chip
                            sx={{ pl: "5px", m: 1 }}
                            label={`${
                              getCountryByCode(stageData.countryOfBusiness)
                                ?.name
                            } (${stageData.countryOfBusiness})`}
                            icon={
                              <CountryFlag
                                countryCode={stageData.countryOfBusiness}
                              />
                            }
                          />
                        )}
                      </>
                    ),
                  },
                  {
                    name: t("common.industry"),
                    value: (
                      <>
                        {stageData.industry ? (
                          <Chip
                            color="primary"
                            variant="outlined"
                            label={stageData.industry}
                          />
                        ) : (
                          ""
                        )}
                      </>
                    ),
                  },
                  {
                    name: t("common.valueAddedTaxNumber"),
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.vatNo}
                      </Typography>
                    ),
                  },
                  {
                    name: t("common.taxIdentificationNumber"),
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.taxIdentificationNo}
                      </Typography>
                    ),
                  },
                  {
                    name: t("common.nationalInsuranceNumber"),
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.niNumber}
                      </Typography>
                    ),
                  },
                  {
                    name: t("common.permissionToEmployeeNumber"),
                    value: (
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {stageData.peNumber}
                      </Typography>
                    ),
                  },
                  {
                    name: t("common.website"),
                    value: (
                      <Link
                        href={stageData.website}
                        variant="body2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {stageData.website}
                      </Link>
                    ),
                  },
                ]
              : []),
          ]
        : [],
    [entity, stageData, t]
  );

  const address: DetailRow[] = useMemo(
    () =>
      stageData.address
        ? [
            {
              name: "common.addressLine1",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.address.addressLine1}
                </Typography>
              ),
            },
            {
              name: "common.addressLine2",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.address.addressLine2}
                </Typography>
              ),
            },
            {
              name: "common.town",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.address.town}
                </Typography>
              ),
            },
            {
              name: "common.zipPostCode",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.address.postCode}
                </Typography>
              ),
            },
            {
              name: "common.country",
              value: (
                <>
                  {stageData?.address.country && (
                    <Chip
                      sx={{ pl: "5px", m: 1 }}
                      label={`${
                        getCountryByCode(stageData.address.country)?.name
                      } (${stageData.address.country})`}
                      icon={
                        <CountryFlag countryCode={stageData.address.country} />
                      }
                    />
                  )}
                </>
              ),
            },
          ]
        : [],
    [stageData.address]
  );

  return (
    <>
      {entity ? (
        <>
          <Card sx={{ marginTop: "12px" }}>
            <CardHeader
              disableTypography
              title={
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tooltip title={t("common.stage_1_2")} enterDelay={1000}>
                    <Typography variant="h6">
                      {t("common.additionalDetails")}
                    </Typography>
                  </Tooltip>
                  <Grid
                    item
                    // sm={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {((status !== PENDING_APPROVAL_CLIENT_STATUS &&
                      status !== ACTIVE_CLIENT_STATUS &&
                      status !== CLOSED_CLIENT_STATUS) ||
                      (status === PENDING_APPROVAL_CLIENT_STATUS &&
                        entity.reviewerId === profile?.id)) && (
                      <PermissionGuard permission="UPDATE_ENTITY">
                        <>
                          <Button
                            sx={[
                              {
                                whiteSpace: "nowrap",
                              },
                              (theme) => ({
                                [theme.breakpoints.down("sm")]: {
                                  minWidth: "100%",
                                },
                                [theme.breakpoints.up("sm")]: {
                                  minWidth: "auto",
                                },
                              }),
                            ]}
                            variant="contained"
                            onClick={() => onEdit(true)}
                          >
                            {t("common.edit")}
                          </Button>
                        </>
                      </PermissionGuard>
                    )}
                  </Grid>
                </Box>
              }
            />

            <Grid container rowSpacing={3} columnSpacing={2} sx={{ p: "16px" }}>
              {step1_2Data.map(({ name, value }) => (
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  key={name}
                  sx={{ paddingTop: "12px !important" }}
                >
                  <Grid container>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {name}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      {value}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            {stageData.address && (
              <>
                <CardHeader
                  disableTypography
                  sx={{ paddingBottom: "0" }}
                  title={
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h6">
                        {t("common.address")}
                      </Typography>
                    </Box>
                  }
                />
                <CardContent sx={{ paddingTop: "0" }}>
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={2}
                    sx={{ p: "16px" }}
                  >
                    {address.map(({ name, value }) => (
                      <Grid
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        key={name}
                        sx={{ paddingTop: "12px !important" }}
                      >
                        <Grid container>
                          <Grid item sm={6} xs={12}>
                            <Typography variant="body2" color="textSecondary">
                              {t(name)}
                            </Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            {value}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </>
            )}
          </Card>
        </>
      ) : null}
    </>
  );
};
