import { Box } from "@mui/material";
import React, { FC } from "react"; // we need this to make JSX compile
import { keyframes } from "@mui/system";

const spin = keyframes`
0% {
  transform: rotate(0deg);
},
100%: {
  transform: rotate(360deg);
},
`;

// // const useStyles = makeStyles({
// const useStyles = makeStyles((theme: Theme) => ({
//   "@keyframes spin": {
//     "0%": {
//       //-webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
//       //-ms-transform: rotate(0deg);  /* IE 9 */
//       transform: "rotate(0deg)" /* Firefox 16+, IE 10+, Opera */,
//     },
//     "100%": {
//       //-webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
//       //-ms-transform: rotate(360deg);  /* IE 9 */
//       transform: "rotate(360deg)" /* Firefox 16+, IE 10+, Opera */,
//     },
//   },

//   loaderBackGround: {
//     backgroundColor: "#000",
//     opacity: "0.4",
//   },

//   loaderWrapper: {
//     position: "fixed",
//     top: "0",
//     left: "0",
//     width: "100%",
//     height: "100%",
//     zIndex: 1000,

//     "& .loader": {
//       opacity: "1",
//       display: "block",
//       position: "relative",
//       left: "50%",
//       top: "50%",
//       width: "150px",
//       height: "150px",
//       margin: "-75px 0 0 -75px",
//       borderRadius: "50%",
//       border: "3px solid transparent",
//       // borderTopColor: "#1FABB9",
//       borderTopColor: theme.palette.primary.main,
//       //WebkitAnimation: "spin 1s linear infinite", // /* Chrome, Opera 15+, Safari 5+ */
//       animation: "$spin 1s linear infinite", //; /* Chrome, Firefox 16+, IE 10+, Opera */

//       "&:before": {
//         content: "''",
//         position: "absolute",
//         top: "5px",
//         left: "5px",
//         right: "5px",
//         bottom: "5px",
//         borderRadius: "50%",
//         border: "3px solid transparent",
//         // borderTopColor: "#B43D3C",
//         borderTopColor: theme.palette.secondary.main,
//         //WebkitAnimation: "spin 2s linear infinite", // /* Chrome, Opera 15+, Safari 5+ */
//         animation:
//           "$spin 2s linear infinite" /* Chrome, Firefox 16+, IE 10+, Opera */,
//       },

//       "&:after": {
//         content: "''",
//         position: "absolute",
//         top: "15px",
//         left: "15px",
//         right: "15px",
//         bottom: "15px",
//         borderRadius: "50%",
//         border: "3px solid transparent",
//         borderTopColor: "#616161",
//         // WebkitAnimation: "spin 1s linear infinite", /* Chrome, Opera 15+, Safari 5+ */
//         animation:
//           "$spin 1s linear infinite" /* Chrome, Firefox 16+, IE 10+, Opera */,
//       },
//     },
//   },
// }));

export const Loader: FC = (props) => {
  return (
    <>
      <Box
        id="loader-background"
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          zIndex: 1000,
          backgroundColor: "#000",
          opacity: "0.4",
        }}
      ></Box>
      <Box
        id="loader-wrapper"
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          zIndex: 1000,
        }}
      >
        <Box
          className="loader"
          sx={(theme) => ({
            opacity: "1",
            display: "block",
            position: "relative",
            left: "50%",
            top: "50%",
            width: "150px",
            height: "150px",
            margin: "-75px 0 0 -75px",
            borderRadius: "50%",
            border: "3px solid transparent",
            // borderTopColor: "#1FABB9",
            borderTopColor: theme.palette.primary.main,
            //WebkitAnimation: "spin 1s linear infinite", // /* Chrome, Opera 15+, Safari 5+ */
            animation: `${spin} 1s linear infinite`, //; /* Chrome, Firefox 16+, IE 10+, Opera */

            "&:before": {
              content: "''",
              position: "absolute",
              top: "5px",
              left: "5px",
              right: "5px",
              bottom: "5px",
              borderRadius: "50%",
              border: "3px solid transparent",
              // borderTopColor: "#B43D3C",
              borderTopColor: theme.palette.secondary.main,
              //WebkitAnimation: "spin 2s linear infinite", // /* Chrome, Opera 15+, Safari 5+ */
              animation: `${spin} 2s linear infinite` /* Chrome, Firefox 16+, IE 10+, Opera */,
            },

            "&:after": {
              content: "''",
              position: "absolute",
              top: "15px",
              left: "15px",
              right: "15px",
              bottom: "15px",
              borderRadius: "50%",
              border: "3px solid transparent",
              borderTopColor: "#616161",
              // WebkitAnimation: "spin 1s linear infinite", /* Chrome, Opera 15+, Safari 5+ */
              animation: `${spin} 1s linear infinite` /* Chrome, Firefox 16+, IE 10+, Opera */,
            },
          })}
        ></Box>
      </Box>
    </>
  );
};
