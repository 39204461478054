import { ApiEntity, ApiStageAml, STAGE_3_1_TYPE, } from "../../../../../../models/entities"
import { AmlFormValues, amlFormValidationSchema } from "."
import { booleanToYesNo, mapApiMoneyToMoney, moneyInitialValues } from "../../../../../../forms";
import { Country, countries } from "../../../../../../utils/countries";
import { parseISO } from "date-fns";
import { ApiEnumeration, CURRENCY } from "../../../../../../models/enumerations";

export const amlFormValuesInitialValue = (
    entity: ApiEntity,
    enumerables: ApiEnumeration[],
    currency: ApiEnumeration): AmlFormValues => {
    // Get Stage Data
    const stageData: ApiStageAml = entity.stages.find((s) => s.stageType === STAGE_3_1_TYPE) as ApiStageAml;
    // If draft is available use that
    const data: ApiStageAml = stageData.draftData ? stageData.draftData as ApiStageAml : stageData;

    console.log(data);
    return {
        stageType: STAGE_3_1_TYPE,
        stageStatus: data.stageStatus,
        id: entity.id,

        refNo: data.refNo || '',
        transactionDate: data.transactionDate ? parseISO(data.transactionDate) : null,
        transactionDescription: data.transactionDescription || '',
        transactionAmount: data.transactionAmount ? mapApiMoneyToMoney(data.transactionAmount, enumerables) : moneyInitialValues(currency),
        originalTransactionCurrency: data.originalTransactionCurrency ? enumerables.filter(e => e.type === CURRENCY).find(x => x.value === data.originalTransactionCurrency)! : null,
        foreignAmount: data.foreignAmount ? mapApiMoneyToMoney(data.foreignAmount, enumerables) : moneyInitialValues(currency),
        transactionLocation: data.transactionLocation || '',
        role: data.role || '',
        entityFirstName: data.entityFirstName || '',
        entityLastName: data.entityLastName || '',
        country: data.country ? countries.find(x => x.code === data.country) as Country : null,
        reasonTransactionSuspicious: data.reasonTransactionSuspicious || '',
        actionsTakenTransactionDetail: data.actionsTakenTransactionDetail || '',
        hasTransactionLegitimate: data.hasTransactionLegitimate != null ? booleanToYesNo(data.hasTransactionLegitimate) : '',
        rationaleForConclusion: data.hasTransactionLegitimate === true && data.rationaleForConclusion ? data.rationaleForConclusion : '',
        dateOfReport: data.dateOfReport ? parseISO(data.dateOfReport) : null,
        hasMlroAgreementPreparer: data.hasMlroAgreementPreparer != null ? booleanToYesNo(data.hasMlroAgreementPreparer) : '',
        rationaleMlroConclusion: data.hasMlroAgreementPreparer === true && data.rationaleMlroConclusion ? data.rationaleMlroConclusion : '',
        hasFurtherEvidence: data.hasFurtherEvidence != null ? booleanToYesNo(data.hasFurtherEvidence) : '',
        furtherEvidenceInfo: data.hasFurtherEvidence === true && data.furtherEvidenceInfo ? data.furtherEvidenceInfo : '',
        dateMlroReview: data.dateMlroReview ? parseISO(data.dateMlroReview) : null,
        hasInternalReportInstigatedSuspiciousTransaction: data.hasInternalReportInstigatedSuspiciousTransaction != null ? booleanToYesNo(data.hasInternalReportInstigatedSuspiciousTransaction) : '',
        dateStrSubmission: data.dateStrSubmission ? parseISO(data.dateStrSubmission) : null,
        outcomeStr: data.outcomeStr || '',
    };
}

export const isAmlFormCompleted = (isAdmin: boolean) => async (detailsOfContactPersonFormValues: AmlFormValues): Promise<boolean> => {
    try {
        await amlFormValidationSchema(isAdmin).validate(detailsOfContactPersonFormValues);
    } catch (e) {
        return false;
    }
    return true;
}
