import { Info } from "@mui/icons-material";
import {
  Box,
  FormControl,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { FC, useCallback, FocusEvent } from "react";
import { useTranslation } from "react-i18next";
import { Nullable } from "../../../../utils/types";

export interface TextFieldProps {
  disabled: boolean;
  label?: string;
  size?: "small" | "medium";
  margin?: "none" | "dense" | "normal";
  onBlur?: (e: any) => void;
  onChange?: (e: any) => void;
  multiline: boolean;
  type: string;
  info: Nullable<string>;
  rows?: number;
  showErrorText: boolean;
  inputMode: string;
  inputProps: {};
  InputProps: {};
}
export const BasicTextField: FC<
  TextFieldProps & FieldProps<string, string>
> = ({
  disabled = false,
  label = "common.text",
  size = "medium",
  type = "text",
  margin,
  onBlur,
  onChange,
  multiline = false,
  rows = undefined,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  info = null,
  showErrorText = true,
  inputMode = "text",
  inputProps = {},
  InputProps = {},
  ...props
}) => {
  const { t } = useTranslation();

  const onBlurHandler = useCallback((e: FocusEvent<HTMLInputElement>) => {
    handleBlur(field.name)(e);
    typeof onBlur === "function" && onBlur(e);
  }, []);

  const onChangeHandler = useCallback((e: FocusEvent<HTMLInputElement>) => {
    handleChange(field.name)(e);
    typeof onChange === "function" && onChange(e);
  }, []);

  return (
    <>
      {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(field, null, 2)}</pre> */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FormControl
          margin={margin}
          variant="outlined"
          fullWidth
          error={Boolean(
            getIn(touched, field.name) && !!getIn(errors, field.name)
          )}
        >
          <Field
            label={t(label)}
            variant="outlined"
            component={TextField}
            disabled={disabled}
            type={type}
            inputMode={inputMode}
            value={getIn(values, field.name)}
            error={Boolean(
              getIn(touched, field.name) && !!getIn(errors, field.name)
            )}
            helperText={
              showErrorText
                ? getIn(touched, field.name) && t(getIn(errors, field.name))
                : ""
            }
            onBlur={onBlurHandler}
            onChange={onChangeHandler}
            onFocus={(e: any) => {
              if (type === "number")
                e.target.addEventListener(
                  "wheel",
                  (e: any) => e.preventDefault(),
                  {
                    passive: false,
                  }
                );
            }}
            size={size}
            rows={rows}
            multiline={multiline}
            inputProps={{ ...inputProps }}
            InputProps={{
              ...InputProps,
            }}
          />
        </FormControl>
        {info && (
          <Tooltip
            color="action"
            title={<div style={{ whiteSpace: "pre-line" }}>{t(info)}</div>}
            sx={{ mt: "14px", ml: "5px", mr: "5px" }}
          >
            <Info />
          </Tooltip>
        )}
      </Box>
    </>
  );
};
