
import { ApiService, PagedServicesRequest, PageApiService, ApiUpdateServiceRequest, ApiCreateServiceRequest } from '.';
import { removeEmpty } from '../../utils/removeEmpty';
import { apiWithTag } from '../emptySplitApi';


export const servicesApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        services: builder.query<PageApiService, PagedServicesRequest>({
            query: ({ ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    method: 'GET',
                    params: parameters,
                    url: 'services',
                }
            },
            providesTags: ['Service']
        }),
        servicesList: builder.query<ApiService[], {}>({
            query: () => {
                return {
                    method: 'GET',
                    url: 'services/list',
                }
            },
            providesTags: ['Service']
        }),
        service: builder.query<ApiService, { id: string }>({
            query: ({ id }) => ({
                method: 'GET',
                url: `services/${id}`,

            }),
            providesTags: ['Service']
        }),
        createService: builder.mutation<void, ApiCreateServiceRequest>({
            query: ({
                // tenantIdForHeader, 
                ...service }) => ({
                    // headers: (tenantIdForHeader.length > 0) ? {
                    //     'X-TENANT-ID': tenantIdForHeader
                    // } : {},
                    url: 'services',
                    method: 'POST',
                    body: service
                }),
            invalidatesTags: ['Service', 'Audit']
        }),
        updateService: builder.mutation<ApiService, ApiUpdateServiceRequest>({
            query: ({ id, ...rest }) => ({
                body: { ...rest },
                method: 'PUT',
                url: `services/${id}`,
            }),
            invalidatesTags: ['Service', 'Audit']
        }),
        deleteService: builder.mutation<ApiService, { id: string }>({
            query: ({ id, ...rest }) => ({
                method: 'DELETE',
                url: `services/${id}`,
            }),
            invalidatesTags: ['Service', 'Audit']
        }),


    })
})

export const {
    useServicesQuery,
    useLazyServicesQuery,
    useServicesListQuery,
    useServiceQuery,
    useCreateServiceMutation,
    useUpdateServiceMutation,
    useDeleteServiceMutation
} = servicesApi;