import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
// import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import Divider from "@mui/material/Divider";
import {
  // Edit,
  // Archive,
  AccountBox,
  // FileCopy,
  // MoreHoriz,
  // KeyboardArrowDown,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Logout, Password, Settings } from "@mui/icons-material";
import toast from "react-hot-toast";
import { useCallback } from "react";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../../../models/auth";
import { useTranslation } from "react-i18next";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function CustomizedMenus() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();

  const onLogout = useCallback(async () => {
    try {
      await logout();
      // dispatch(resetCredentials());
      // onClose();
      navigate("auth");
    } catch (err) {
      console.error(err);
      toast.error("Unable to logout.");
    }
  }, [logout, navigate]);

  return (
    <div>
      {/* <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Options
      </Button> */}
      <IconButton color="inherit" onClick={handleClick}>
        {/* <Badge badgeContent={4} color="secondary"> */}
        <Settings />
        {/* </Badge> */}
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleClose} disableRipple>
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <FileCopyIcon />
          Duplicate
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleClose} disableRipple>
          <ArchiveIcon />
          Archive
        </MenuItem> */}
        <MenuItem onClick={() => navigate("manage-profile")} disableRipple>
          <AccountBox />
          {t("common.manageProfile")}
        </MenuItem>
        {/* <MenuItem onClick={() => navigate("change-password")} disableRipple>
          <Password />
          {t("common.changePassword")}
        </MenuItem> */}
        <MenuItem onClick={onLogout} disableRipple>
          <Logout />
          {t("common.logout")}
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
