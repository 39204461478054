import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  ApiEntity,
  ApiStageTermination,
} from "../../../../../../../models/entities";
import { PermissionGuard } from "../../../../../../../models/auth";
import { ApiUserResponse } from "../../../../../../../models/users";
import {
  ACTIVE_CLIENT_STATUS,
  ApiEntityStatus,
  CLOSED_CLIENT_STATUS,
  PENDING_APPROVAL_CLIENT_STATUS,
} from "../../../../../../../forms";
import { Nullable } from "../../../../../../../utils/types";

interface DetailRow {
  name: string;
  value: any;
}

export const Stage4_2View: FC<{
  entity: ApiEntity;
  stageData: ApiStageTermination;
  onEdit: (value: boolean) => void;
  status: Nullable<ApiEntityStatus>;
  profile: ApiUserResponse | undefined;
}> = ({ entity, stageData, onEdit, status = null, profile }) => {
  const { t } = useTranslation();

  const step2_1Data: DetailRow[] = useMemo(
    () =>
      stageData
        ? [
            {
              name: "common.dateOfTermination",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.dateOfTermination?.length > 0 &&
                    format(parseISO(stageData.dateOfTermination), "dd-MM-yyyy")}
                </Typography>
              ),
            },

            {
              name: "common.reasonForTermination",
              value: (
                <>
                  {stageData.reasonForTermination ? (
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={stageData.reasonForTermination}
                    />
                  ) : null}
                </>
              ),
            },

            {
              name: "common.reasonForTerminationInfo",
              value: (
                <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                  {stageData.reasonForTerminationInfo}
                </Typography>
              ),
            },
          ]
        : [],
    [stageData]
  );

  return (
    <>
      {entity ? (
        <>
          <Card sx={{ marginTop: "12px" }}>
            <CardHeader
              disableTypography
              title={
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tooltip title={t("common.stage_4_2")} enterDelay={1000}>
                    <Typography variant="h6">
                      {t("common.termination")}
                    </Typography>
                  </Tooltip>
                  <Grid
                    item
                    // sm={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {((status !== PENDING_APPROVAL_CLIENT_STATUS &&
                      status !== ACTIVE_CLIENT_STATUS &&
                      status !== CLOSED_CLIENT_STATUS) ||
                      (status === PENDING_APPROVAL_CLIENT_STATUS &&
                        entity.reviewerId === profile?.id)) && (
                      <PermissionGuard permission="UPDATE_ENTITY">
                        <>
                          <Button
                            sx={[
                              {
                                whiteSpace: "nowrap",
                              },
                              (theme) => ({
                                [theme.breakpoints.down("sm")]: {
                                  minWidth: "100%",
                                },
                                [theme.breakpoints.up("sm")]: {
                                  minWidth: "auto",
                                },
                              }),
                            ]}
                            variant="contained"
                            onClick={() => onEdit(true)}
                          >
                            {t("common.edit")}
                          </Button>
                        </>
                      </PermissionGuard>
                    )}
                  </Grid>
                </Box>
              }
            />

            <Grid container rowSpacing={3} columnSpacing={2} sx={{ p: "16px" }}>
              {step2_1Data.map(({ name, value }) => (
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  key={name}
                  sx={{ paddingTop: "12px !important" }}
                >
                  <Grid container>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {t(name)}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      {value}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Card>
        </>
      ) : null}
    </>
  );
};
