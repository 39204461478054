import { LoadingButton } from "@mui/lab";
import { Box, FormHelperText } from "@mui/material";
import { Field, Formik } from "formik";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { PasswordField } from "../../../../../forms";
import {
  ApiChangePasswordRequest,
  mapChangePasswordFormValuesToApiChangePasswordRequest,
  useChangePasswordMutation,
} from "../../../../../models/user";
import {
  changePasswordFormValuesInitialValue,
  changePasswordFormValidationSchema,
  PASSWORD_NOT_MATCHED,
  PASSWORD_ALREADY_USED,
} from ".";
import { useLogoutMutation } from "../../../../../models/auth";
import { Nullable } from "../../../../../utils/types";

export const ChangePasswordForm: FC = (props) => {
  const { t } = useTranslation();
  const [changePassword] = useChangePasswordMutation();
  const [logout] = useLogoutMutation();
  const [error, setError] = useState<Nullable<string>>(null);

  return (
    <>
      <Formik
        initialValues={{
          ...changePasswordFormValuesInitialValue(),
          submit: null,
        }}
        validationSchema={changePasswordFormValidationSchema()}
        onSubmit={async (
          values,
          { setErrors, setStatus, setSubmitting }
        ): Promise<void> => {
          try {
            setError(null);
            const apiRegistrationRequest: ApiChangePasswordRequest =
              mapChangePasswordFormValuesToApiChangePasswordRequest(values);
            const changePasswordResponse = await changePassword(
              apiRegistrationRequest
            ).unwrap();
            await logout();
            toast.success(
              t(
                `common.passwordChangedSuccessfullyPleaseLoginwithYourNewCredentials`
              )
            );
          } catch (err: any) {
            console.error(err);
            switch (err?.data?.errorCode) {
              case PASSWORD_NOT_MATCHED:
                setError("errors.passwordDoesNotMatched");
                toast.error(t("errors.passwordDoesNotMatched"), {
                  duration: 10000,
                });
                break;
              case PASSWORD_ALREADY_USED:
                setError("errors.cannotSetOldOrSamePassword");
                toast.error(t("errors.cannotSetOldOrSamePassword"), {
                  duration: 10000,
                });
                break;
              default:
                setError(
                  err.data?.message ||
                    err.message ||
                    "errors.somethingWentWrong"
                );
            }

            setSubmitting(false);
          }
        }}
      >
        {({ errors, handleSubmit, isSubmitting }): JSX.Element => (
          <form noValidate onSubmit={handleSubmit} {...props}>
            <Field
              name={`oldPassword`}
              component={PasswordField}
              label="common.oldPassword"
            />
            <Field
              name={`newPassword`}
              component={PasswordField}
              label="common.newPassword"
            />
            <Field
              name={`confirmNewPassword`}
              component={PasswordField}
              label="common.confirmPassword"
            />

            {error && (
              <Box sx={{ mt: 1 }}>
                {error !== null && (
                  <FormHelperText error>{t(error)}</FormHelperText>
                )}
              </Box>
            )}
            <Box sx={{ mt: "24px" }}>
              <LoadingButton
                color="primary"
                loading={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {t("common.submit")}
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};
