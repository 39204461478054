import { BaseQueryApi, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../services/config';
import { RootState } from '../services/redux/store';

const prepareHeaders = (headers: Headers, api: Pick<BaseQueryApi, "getState" | "extra" | "endpoint" | "type" | "forced">) => {
    // alert(api.endpoint)
    if (!['publicTenant'].includes(api.endpoint)) {
        const token = (api.getState() as RootState).auth?.accessToken;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
            headers.set('Content-Type', 'application/json');
        }
        if (!headers.has("X-TENANT-ID")) { // If tenant is not set at API level
            const tenantId = (api.getState() as RootState).auth?.tenantPermissions?.[0]?.tenantId;
            if (tenantId) {
                headers.set('X-TENANT-ID', tenantId);
            }
        }
    }
    return headers;
}

export const baseQuery = fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders,
});

export const emptySplitApi = createApi({
    reducerPath: 'api',
    baseQuery: baseQuery,
    keepUnusedDataFor: 0.0001,
    endpoints: () => ({}),
});

export const apiWithTag = emptySplitApi.enhanceEndpoints({ addTagTypes: ['Audit', 'Auth', 'Biller', 'Business Contact', 'Entity', 'Enumeration', 'Invoice', 'Quotation', 'Rate', 'Receipt', 'Service', 'Signatory', 'Tenant', 'Timesheet', 'User', 'Permission Group', 'Permission', 'Me'] })
