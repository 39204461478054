import { LoadingButton } from "@mui/lab";
import { Box, FormHelperText } from "@mui/material";
import { Field, Formik } from "formik";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PasswordField } from "../../../../../forms";
import {
  ApiConfirmRegistrationRequest,
  mapUserVerificationConfirmFormValuesToApiConfirmRegistrationRequest,
  useRegisterUserConfirmMutation,
} from "../../../../../models/users";

import {
  UserVerificationConfirmFormValues,
  userVerificationFormValuesInitialValue,
  userVerificationConfirmFormValidationSchema,
  VERIFICATION_FAILED,
  VERIFICATION_EXPIRED,
  // VERIFICATION_RETRIES_EXCEEDED,
  USERNAME_NOT_FOUND,
  VERIFICATION_RETRIES_EXCEEDED,
} from ".";
import { Nullable } from "../../../../../utils/types";

export const UserVerificationForm: FC = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [registerUserConfirm] = useRegisterUserConfirmMutation();
  const { t } = useTranslation();
  const [error, setError] = useState<Nullable<string>>(null);

  return (
    <>
      <Formik<UserVerificationConfirmFormValues>
        initialValues={{
          ...userVerificationFormValuesInitialValue(
            searchParams.get("verificationId") as string,
            searchParams.get("verificationCode") as string
          ),
          // submit: null,
        }}
        validationSchema={userVerificationConfirmFormValidationSchema()}
        onSubmit={async (
          values,
          { setErrors, setStatus, setSubmitting }
        ): Promise<void> => {
          setError(null);
          try {
            const apiRegistrationRequest: ApiConfirmRegistrationRequest =
              mapUserVerificationConfirmFormValuesToApiConfirmRegistrationRequest(
                values
              );
            const registerVerificationConfirm = await registerUserConfirm(
              apiRegistrationRequest
            ).unwrap();
            toast.success(t(`common.userVerifiedSuccessfully`), {
              duration: 10000,
            });
            navigate("../login");
          } catch (err: any) {
            console.error(err);
            switch (err?.data?.errorCode) {
              case VERIFICATION_FAILED:
                setError("errors.verificationFailed");
                break;
              case VERIFICATION_EXPIRED:
                setError("errors.verificationExpired");
                break;
              case USERNAME_NOT_FOUND:
                setError("errors.usernameNotFound");
                break;
              case VERIFICATION_RETRIES_EXCEEDED:
                setError("errors.verificationRetriesExceeded");
                break;
              default:
                setError(
                  err.data?.message ||
                    err.message ||
                    "errors.somethingWentWrong"
                );
            }
          }
        }}
      >
        {({ errors, handleSubmit, isSubmitting }): JSX.Element => (
          <form noValidate onSubmit={handleSubmit} {...props}>
            <Field name={`password`} component={PasswordField} />

            <Field
              name={`confirmPassword`}
              component={PasswordField}
              label="common.confirmPassword"
            />

            {error && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{t(error)}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: "24px" }}>
              <LoadingButton
                color="primary"
                loading={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {t("common.submit")}
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};
