import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { Field, Formik } from "formik";
import React, { FC } from "react";
import toast from "react-hot-toast";
import { BasicTextField } from "../../../../forms";
import {
  updateEnumerationFormValuesInitialValue,
  UpdateEnumerationFormValues,
} from ".";
import { updateEnumerationFormValidationSchema } from "./validations";

import { useTranslation } from "react-i18next";
import {
  ApiEnumeration,
  ApiEnumerationType,
  ApiEnumerationUpdate,
  mapUpdateEnumerationFormValuesToApiEnumerationUpdate,
  useUpdateEnumerationMutation,
} from "../../../../models/enumerations";
import { enumerationDataMap } from "../../utils";
import { ENUMERATION_ALREADY_EXISTS } from "../errors";
interface UpdateEnumerationFormProps {
  onClose: () => void;
  type: ApiEnumerationType;
  enumeration: ApiEnumeration;
}
export const UpdateEnumerationForm: FC<UpdateEnumerationFormProps> = ({
  onClose,
  type,
  enumeration,
}) => {
  const [updateEnumeration] = useUpdateEnumerationMutation();
  const { t } = useTranslation();
  return (
    <Formik<UpdateEnumerationFormValues>
      enableReinitialize
      initialValues={{
        ...updateEnumerationFormValuesInitialValue(enumeration),
      }}
      validationSchema={updateEnumerationFormValidationSchema()}
      onSubmit={async (values): Promise<void> => {
        try {
          const apiUpdateRequest: ApiEnumerationUpdate =
            await mapUpdateEnumerationFormValuesToApiEnumerationUpdate(values);
          await updateEnumeration(apiUpdateRequest).unwrap();
          toast.success(
            t(enumerationDataMap.get(type).enumerationUpdatedSuccessfully)
          );
          onClose();
        } catch (err: any) {
          if (err?.data?.errorCode === ENUMERATION_ALREADY_EXISTS) {
            toast.error(
              t(enumerationDataMap.get(type).enumerationAlreadyExists)
            );
          }
          console.log(err);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, values }): JSX.Element => (
        <>
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "24px 38px 24px 32px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={"textPrimary"} variant="h5">
                {t(enumerationDataMap.get(type).updateEnumeration)}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: "32px" }}>
            <form noValidate onSubmit={handleSubmit}>
              <Field
                name={`id`}
                component={BasicTextField}
                label="common.id"
                disabled
                // size="small"
              />

              <Field
                name={`value`}
                component={BasicTextField}
                label="common.value"
                // size="small"
                margin="normal"
              />

              <Field
                name={`valueDescription`}
                component={BasicTextField}
                label="common.description"
                margin="normal"
              />
              <Box sx={{ mt: "24px" }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {t("common.update")}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
