import * as Yup from "yup";
import { countryValidationSchema } from "../../simple";

export const countryCodeAPISchema = Yup.object().nullable().shape({
  code: Yup.string().required('validations.mustSelectACountry'),
  name: Yup.string().required('validations.mustSelectACountry'),
  alpha3: Yup.string().required('validations.mustSelectACountry'),
})
  .test(
    'null-check',
    'validations.mustSelectACountry',
    country => country != null
  );

export const addressValidationSchema = () =>
  Yup.object({
    addressLine1: Yup.string()
      .max(255)
      .required('validations.addressIsRequired'),
    addressLine2: Yup.string()
      .max(255)
      .notRequired(),
    town: Yup.string()
      .max(255)
      .required('validations.townIsRequired'),
    postcode: Yup.string()
      .max(255)
      .required('validations.postcodeIsRequired'),
    country: countryValidationSchema()
    // city: Yup.string()
    //   .max(255)
    //   .required('validations.cityIsRequired'),
    // state: Yup.string()
    //   .when('countryCode', {
    //     is: 'US',
    //     then: Yup.string().required('validations.stateIsRequired'),
    //     otherwise: Yup.string().when('countryCode', {
    //       is: 'CA',
    //       then: Yup.string().required('validations.stateIsRequired'),
    //       otherwise: Yup.string().notRequired(),
    //     })
    //   }),
    // countryCode: countryCodeAPISchema.required('validations.countryIsRequired'),
  });
