
import * as Yup from "yup";
import YupPassword from 'yup-password';
import { localConfig } from "../../../../services/config";

YupPassword(Yup);

export const passwordValidationSchema = () => Yup.string()
    .password()
    .min(localConfig.PASSWORD_MIN_LENGTH)
    .required('validations.passwordIsRequired');

export const newPasswordValidationSchema = () => Yup.string()
    .password()
    .min(localConfig.PASSWORD_MIN_LENGTH)
    .required('validations.newPasswordIsRequired');

export const passwordConfirmValidationSchema = (passwordFieldName: string) =>
    Yup.string()
        .required('validations.confirmPasswordIsRequired')
        .oneOf([Yup.ref(passwordFieldName), null], 'validations.passwordsMustMatch')


